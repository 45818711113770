import React from "react";
import WrapperCard from "../WrapperCard";
import { SunneLink, SunneText, SunneTitle } from "./styles";
import { FaSolarPanel } from "react-icons/fa";

interface IProps {
  smDown: boolean;
}
function SunneCard({ smDown }: IProps) {
  return (
    <WrapperCard>
      <SunneTitle>Cliente 7Cantos tem Desconto na Conta de Luz!</SunneTitle>
      <FaSolarPanel color={"#DD6536"} size={smDown ? 24 : 32} />
      <SunneText>Economize com Energia Solar por Assinatura!</SunneText>
      <SunneLink
        href="https://energia.sunne.com.br/energia-mais-barata-7cantos"
        target="_blank"
      >
        Veja Como
      </SunneLink>
    </WrapperCard>
  );
}

export default SunneCard;
