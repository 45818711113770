import React, { useCallback, useState } from 'react';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';
import StepToAnunciarImovel from './../../components/StepToAnunciarImovel/';

import {Container,
		Content,
		InitialContainer,
		TitleContainer,
		StepsToAnuciarImovelContainer,
		VideoContainer,
		VideoContent,
		ButtonsToInformationsContainer,
		ButtonAndInformationContainer,
		InformationContainer} from './styles';

import anunciarImovelImg from './../../assets/pages/AnunciarImovel/anunciarImovelImg.svg';

import triangles from './../../assets/pages/Home/Triangulos.svg';

import chatImg from './../../assets/pages/AnunciarImovel/chat.svg';
import freedomImg from './../../assets/pages/AnunciarImovel/freedom.svg';
import megaphoneImg from './../../assets/pages/AnunciarImovel/megaphone.svg';

import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import FormToAnunciarImovelPage from '../../components/FormToAnunciarImovelPage';

export default function AnunciarImovel(){
	const [openHowToAnunciarImovel, setOpenHowToAnunciarImovel] = useState(false);
	const [openHowManyCust, setOpenHowManyCust] = useState(false);

	const handleOpenHowToAnunciarImovel = useCallback(() => {
		setOpenHowToAnunciarImovel(true);
	}, []);

	const handleOpenHowManyCust = useCallback(() => {
		setOpenHowManyCust(true);
	}, []);

	const handleCloseHowToAnunciarImovel = useCallback(() => {
		setOpenHowToAnunciarImovel(false);
	}, []);

	const handleCloseHowManyCust = useCallback(() => {
		setOpenHowManyCust(false);
	}, []);

	return(
		<Container>
			<Header hasSearchBar hasOptionToSearch hasBorder/>

			<Content>
				<InitialContainer>
					<TitleContainer>
						<h3>ANUNCIAR IMÓVEL</h3>

						<h1>Pode relaxar, nós cuidamos de tudo para você.</h1>

						<p>Alugue seu imóvel sem sair de casa!</p>

						<img src={anunciarImovelImg} alt="anunciarImovelImg"/>
					</TitleContainer>

					<FormToAnunciarImovelPage />
				</InitialContainer>

				<img src={triangles} alt="triangles"/>

				<h1>É prático, rápido e seguro!</h1>

				<StepsToAnuciarImovelContainer>
					<StepToAnunciarImovel image={chatImg} title="ENTRE EM CONTATO" description="Entre em contato com um de nossos consultores através do Whatsapp, e-mail ou site e agende a sessão de fotos profissionais do seu imóvel. Nossa equipe irá produzir um material de divulgação bacana e atraente para que os interessados cheguem de maneira mais rápida a você!"/>
					<hr />
					<StepToAnunciarImovel notIsFirst={true} image={megaphoneImg} title="DURANTE O ANÚNCIO" description="A 7Cantos também realiza a análise de crédito dos interessados e possíveis inquilinos, garantindo a você, proprietário, total segurança na hora de alugar o seu imóvel."/>
					<hr />
					<StepToAnunciarImovel notIsFirst={true} image={freedomImg} title="SEM CONTRATO DE FIDELIDADE" description="Elaboramos o contrato de aluguel para que você possa assinar diretamente com o inquilino. Após isso, você tem total liberdade para administrar o seu imóvel, ou deixar nas mãos de nossa equipe de profissionais. Ou seja, você escolhe se continua conosco ou não!"/>
				</StepsToAnuciarImovelContainer>

				<VideoContainer>
					<VideoContent>
						<h1>Oferecemos um serviço completo</h1>

						<p>Contamos com uma equipe especializada nas mais diversas áreas, sempre pensando em como melhor lhe atender. Aqui, garantimos total liberdade, sem que você perca a segurança e a transparência.</p>
					
						<iframe title="anunciarImovelVideo" width={window.innerWidth > 767 ? "540" : '310'} height={window.innerWidth > 767 ? "290" : '188'} src="https://www.youtube.com/embed/X-i_tKmv5-g" frameBorder="0" allowFullScreen/>
					</VideoContent>
				</VideoContainer>

				<img src={triangles} alt="triangles"/>

				<h2>Perguntas Frequentes</h2>

				<ButtonsToInformationsContainer>
					<ButtonAndInformationContainer isOpen={openHowToAnunciarImovel}>
						<button onClick={!!openHowToAnunciarImovel ? handleCloseHowToAnunciarImovel : handleOpenHowToAnunciarImovel}>
							<h3>Como anunciar um imóvel?</h3>

							{!!openHowToAnunciarImovel ? (<FiChevronUp size={24} color="#6EA5FF"/>) : (<FiChevronDown size={24} color="#6EA5FF"/>)}
						</button>

						{!!openHowToAnunciarImovel && (
							<InformationContainer>
								<h3>Entre em Contato</h3>
								<p>Entre em contato com um de nossos consultores através do whatsapp, email ou site e agende a sessão de fotos profissionais do seu imóvel.<br/>
								<br/>	Para isso, você pode preencher nosso formulário de contato, que retornaremos o mais rápido possível.</p>
							
								<h3>Anúncio</h3>
								<p>Nossa equipe irá produzir um material de divulgação super bacana e atraente para que os interessados cheguem de maneira mais rápida a você!</p>

								<h3>Após o Anúncio</h3>
								<p>A 7Cantos realiza a análise de crédito dos interessados e possíveis inquilinos, garantindo a você, proprietário, total segurança na hora de alugar o seu imóvel.</p>
							</InformationContainer>
						)}
					</ButtonAndInformationContainer>

					<ButtonAndInformationContainer isOpen={openHowManyCust}>
						<button onClick={!!openHowManyCust ? handleCloseHowManyCust : handleOpenHowManyCust}>
							<h3>Quanto custa anunciar um imóvel?</h3>

							{!!openHowManyCust ? (<FiChevronUp size={24} color="#DD6435"/>) : (<FiChevronDown size={24} color="#DD6435"/>)} 
						</button>

						{!!openHowManyCust && (
							<InformationContainer>
								<h3>10% NÃO OBRIGATÓRIO</h3>

								<p>Elaboramos o contrato de aluguel para que você possa assinar diretamente com o inquilino. Após isso, você tem total liberdade para administrar o seu imóvel, ou deixar nas mãos de nossa equipe de profissionais, ou seja, você escolhe se continua conosco ou não!</p>
							</InformationContainer>
						)}
						
					</ButtonAndInformationContainer>
				</ButtonsToInformationsContainer>
			</Content>
			<Footer />
		</Container>
	);
};