import React from 'react';

import { MarkerPoint, MarkerPulse } from './styles';

interface IMarker{
	color: string;
	lat: number;
	lng: number;
	active: boolean;
	onClick?: any;
}

export default function Marker({
	color, lat, lng, active = false, onClick = () => null
}: IMarker){
	return(
		<>
			<MarkerPoint
				onClick={onClick}
		        style={{ backgroundColor: color, cursor: 'pointer'}}
		    />
		    {active === true && <MarkerPulse/>}
	    </>
	);
};
