import React, { useState } from 'react';

import {
    Container,
    ButtonOption1,
    ButtonOption2
} from './styles';

interface IButtonsTab {
	labels?: Array<string>;
    onChange?: Function;
}

export default function ButtonsTab({ labels = ['', ''], onChange = () => null } : IButtonsTab) {

    const [active, setActive] = useState(0);

    const onChangeHandle = (index : number) => {
        setActive(index);
        typeof onChange === 'function' && onChange(index);
    };

    return (
        <Container>
            <ButtonOption1
                className={active === 0 ? 'active' : ''}
                onClick={() => onChangeHandle(0)}
            >
                {labels[0]}
            </ButtonOption1>
            <ButtonOption2
                className={active === 1 ? 'active' : ''}
                onClick={() => onChangeHandle(1)}
            >
                {labels[1]}
            </ButtonOption2>
        </Container>
    );
};