import styled from "styled-components";

export const DocumentsRequestContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    max-height: 175px;
    width: 100%;

    @media(max-width: 767px){
        max-height: none;

        align-items: center;

        flex-direction: column;
    }
`;

export const DocumentsSpecificationsContainer = styled.div`
    width: 100%;
    min-height: 175px;

    margin: 24px 0;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >h2{
        color: #DD6435;

        font-size: 16px;
        font-weight: 500;
    }

    >h4{
        font-size: 14px;
        font-weight: 400;

        margin: 12px 0;
    }

    >strong{
        & + strong{
            margin-top: 8px;
        }
    }

    @media(max-width: 767px){
        width: 100%;

        min-height: auto;
    }
`;