import React from 'react';

import HeaderComponent from './../../components/Header/';
import FooterComponent from './../../components/Footer/';

import SearchPropertySection from './SearchPropertySection';
import NewcomersPropertiesSection from './NewcomersPropertiesSection';
import PropertyAnnouncementSection from './PropertyAnnouncementSection';
import AdvantagesSection from './AdvantagesSection';
import MapSection from './MapSection';

import { Container } from './styles';

export default function Home() {
  return (
    <>
      <HeaderComponent showAboutUs hasBorder />

      <Container>
        <SearchPropertySection />
        <NewcomersPropertiesSection />
        <PropertyAnnouncementSection />
        <AdvantagesSection />
        <MapSection />
      </Container>

      <FooterComponent />
    </>
  );
}
