import styled from "styled-components";

import chevronDown from "./../../assets/pages/Search/chevronDown.svg";

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1100px;

	margin: 0 auto;

	padding: 64px 0 8%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h1{
		font-size: 29px;
		font-weight: bold;

		margin-bottom: 48px;
	}

	>h4{
		font-size: 14px;

		margin-bottom: 32px;
	}

	@media(max-width: 767px){
		>h1{
			font-size: 26px;
		}

		>h4{
			font-size: 14px;

			text-align: center;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 34px;
		}

		>h4{
			font-size: 16px;
		}
	}
`;

export const FormsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media(max-width: 767px){
		align-items: center;

		flex-direction: column;
	}
`;

export const InformationsForm = styled.form`
	width: 350px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>div#ocupation-container{
		display: none;
	}

	@media(max-width: 767px){
		width: 310px;

		align-items: center;

		>div#ocupation-container{
			display: flex;

			padding: 24px;

			>label{
				>strong{
					font-size: 10px;
				}
			}
		}
	}

	@media(min-width: 1441px){
		width: 450px;
	}
`;

export const SelectContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 24px 42px;

	background: #F9F9F9;

	margin-bottom: 36px;
	
	>label{
		font-size: 12px;

		>strong{
			font-size: 10px;
		}
	}

	>select{
		background: transparent;

		padding-left: 4px;

		font-size: 13px;

		width: 290px;
		height: 30px;

		border: 0;
		border-bottom: 1px solid #B6B6B6;

		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;

	    background-image: url(${chevronDown});
	    background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .95em auto, 100%;
	}

	.css-2b097c-container{
		width: 290px;

		font-size: 13px;
	}

	.css-yk16xz-control{
		border: 0;
		border-bottom: 1px solid #B6B6B6; 
		
		border-radius: 0;

		background: #F9F9F9;
	}

	.css-1okebmr-indicatorSeparator{
		display: none;
	}

	.css-tlfecz-indicatorContainer{
		color: #666666;
	}

	@media(max-width: 767px){
		padding: 24px;

		>label{
			>strong{
				font-size: 10px;
			}	
		}

		>select{
			width: 310px;
		}

		.css-2b097c-container{
			width: 310px;
		}
	}

	@media(min-width: 1441px){
		padding: 28px 42px;

		>label{
			font-size: 14px;

			>strong{
				font-size: 12px;
			}
		}

		>select{
			width: 320px;
		}

		.css-2b097c-container{
			width: 320px;
		}
	}
`;

export const InformationsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>p{
		font-size: 14px;

		margin-bottom: 22px;
	}

	>b{
		font-size: 14px;
	}

	>strong{
		font-size: 11px;
		color: rgba(102, 102, 102, 0.7);

		margin: 15px 0;
	}

	>button{
		background: #00BF33;

		border: 0;

		font-size: 11px;
		font-weight: bold;
		color: #FFF;

		width: 45%;

		padding: 6px 0;
	}

	>label{
		text-align: center;

		font-size: 11px;

		border: 1px solid #666666;

		width: 45%;

		padding: 6px 0;
	}

	@media(max-width: 767px){
		align-items: center;

		text-align: center;

		>p{
			font-size: 14px;
		}

		>b{
			font-size: 14px;
		}

		>strong{
			font-size: 12px;
		}

		>button{
			display: none;
		}

		>label{
			display: none;
		}
	}

	@media(min-width: 1441px){
		>p{
			width: 85%;

			font-size: 16px;
		}

		>b{
			font-size: 16px;
		}

		>strong{
			font-size: 13px;

			margin: 24px 0;
		}

		>button{
			width: 35%;

			padding: 8px 0;

			font-size: 12px;
		}

		>label{
			width: 35%;

			padding: 8px 0;

			font-size: 12px;
		}
	}
`;

export const FilesForm = styled.form`
	width: 350px;

	margin-left: 70px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	@media(max-width: 767px){
		width: 310px;

		margin-left: 0;

		align-items: center;

		>div#ocupation-container{
			display: none;
		}
	}

	@media(min-width: 1441px){
		width: 450px;

		margin-left: 15px;
	}
`;

export const OcupationContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 24px 42px;

	background: #F9F9F9;

	margin-bottom: 36px;

	>label{
		font-size: 12px;

		>strong{
			font-size: 10px;
		}
	}

	>label.description-label {
		padding: 8px 8px 2px;

		font-size: 13px;

		width: 290px;
		min-height: 30px;
		background: transparent;

		border: 0;
	}
	>label#filled-label {
		color: #333333;
	}
	>label#empty-label {
		border-bottom: 1px solid #B6B6B6;
	}

	>input{
		padding-left: 4px;

		font-size: 13px;

		width: 290px;
		min-height: 30px;
		background: transparent;

		border: 0;
		border-bottom: 1px solid #B6B6B6;
	}

	@media(max-width: 767px){
		>label{
			>strong{
				font-size: 10px;
			}
		}

		>input{
			width: 310px;
		}
		>label.description-label{
			width: 310px;
		}
	}

	@media(min-width: 1441px){
		padding: 28px 42px;

		>label{
			font-size: 14px;

			>strong{
				font-size: 12px;
			}
		}

		>input{
			width: 320px;
		}
	}
`;

export const FilesContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>p{
		font-size: 11px;
	}

	>label{
		cursor: pointer;

		margin-bottom: 24px;

		display: flex;
		align-items: center;
		justify-content: center;

		padding: 6px 8px;

		border: 1px solid #666666;

		background: transparent;

		>p{
			margin-left: 8px;

			font-size: 10px;
		}

		>input{
			display: none;
		}
	}

	>button{
		display: none;
	}

	>strong{
		display: none;
	}

	@media(max-width: 767px){
		align-items: center;

		>label{
			width: 260px;

			>svg{
				width: 16px;
				height: 15px;
			}

			>p{
				font-size: 13px;
			}
		}

		>p{
			font-size: 14px;

			text-align: center;
		}

		>button{
			display: flex;
			align-items: center;
			justify-content: center;

			margin-top: 24px;

			background: #00BF33;

			border: 0;

			font-size: 14px;
			font-weight: bold;
			color: #FFF;

			width: 260px;

			padding: 12px 0;
		}

		>strong{
			display: flex;
			align-items: center;
			justify-content: center;

			margin-top: 24px;

			font-size: 14px;

			width: 260px;

			padding: 12px 0;

			border: 1px solid #666666;
		}
	}

	@media(min-width: 1441px){
		>label{
			>svg{
				width: 16px;
				height: 16px;
			}

			>p{
				font-size: 12px;
			}
		}
	}
`;

export const FilesCheckContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;
`;

export const FileChecked = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	>p{
		font-size: 13px;

		margin-left: 8px;
	}

	>button{
		background: transparent;

		border: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		margin-left: 18px;
	}

	@media(max-width: 767px){
		width: 260px;

		align-items: center;
		justify-content: center;

		>svg{
			width: 17px;
			height: 17px;
		}

		>p{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 15px;
		}
	}
`;
