import React from 'react';

import {Container,
		Content,
		TitleContainer,
		FormContainer,
		Form} from './styles';

import { FiX } from 'react-icons/fi';

interface IModalEncaixeDeHorario{
	functionToCloseModalEncaixeDeHorario(): void;
}

export default function ModalEncaixeDeHorario({ functionToCloseModalEncaixeDeHorario }: IModalEncaixeDeHorario){
	return(
		<Container>
			<Content>
				<TitleContainer>
					<button onClick={functionToCloseModalEncaixeDeHorario}><FiX size={30} color="#FFF"/></button>

					<h1>ENCAIXE DE HORÁRIO</h1>
				</TitleContainer>


				<FormContainer>
					<Form>
						<p>Data de preferência:</p>
						<select>
							<option></option>
							<option>1</option>
							<option>2</option>
						</select>

						<p>Hora de preferência:</p>
						<select>
							<option></option>
							<option>1</option>
							<option>2</option>
						</select>
					</Form>

					<button>SOLICITAR ENCAIXE</button>

					<p>Nossa equipe entrará em contato caso o horário fique disponível.</p>
				</FormContainer>
			</Content>
		</Container>
	);
};