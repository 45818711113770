import styled from 'styled-components';

import chevronDown from './../../assets/pages/Search/chevronDown.svg';

export const Container = styled.div`

`;

export const TitleContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 38px 16px 34px 38px;

	background: #DD6435;

	>h1{
		font-size: 26px;
		font-weight: bold;
		color: #FFF;

		margin-top: -10px;
	}

	>button{
		width: 30px;
		height: 30px;

		background: transparent;

		border: 0;

		margin: 0 12px 0 auto;
	}

	@media(max-width: 767px){
		>h1{
			margin-top: 0;

			font-size: 19px;
		}

		>button{
			width: 24px;
			height: 24px;

			>svg{
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const FormContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	padding: 32px 32px 32px 32px;

	@media(max-width: 767px){

		justify-content: flex-start;

		flex-direction: column;

		padding: 0;
	}
`;

export const FirstForm = styled.form`
	width: 450px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 32px;

	>p{
		display: flex;
		flex-direction: column;

		font-size: 15px;

		>strong{
			font-size: 14px;
			color: #FF3838;
		}
	}

	>input{
		margin-top: 4px;
		margin-bottom: 24px;

		width: 100%;

		padding: 0 0 6px 12px;

		background: transparent;

		border: 0;
		border-bottom: 1px solid #66666666;
	}

	>select{
		margin-bottom: 32px;

		background: transparent;

		padding-left: 12px;

		font-size: 16px;

		width: 100%;
		height: 40px;

		border: 0;
		border-bottom: 1px solid #66666666;

		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;

	    background-image: url(${chevronDown});
	    background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .95em auto, 100%;
	}

	@media(max-width: 767px){
		width: 100%;
		>p{
			font-size: 13px;

			>strong{
				font-size: 12px;
			}
		}
	}
`;

export const SecondForm = styled.form`
	width: 450px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 32px;

	>p{
		display: flex;
		flex-direction: column;

		font-size: 15px;
		
		>strong{
			font-size: 14px;
			color: #FF3838;
		}
	}

	>input{
		margin-top: 4px;
		margin-bottom: 24px;

		width: 100%;

		padding: 0 0 6px 12px;

		background: transparent;

		border: 0;
		border-bottom: 1px solid #66666666;
	}

	>b{
		text-align: center;

		color: #006403;

		background: #DAFFCF;

		padding: 12px 32px;

		margin: 18px auto 0 auto;
	}

	>button{
		background: #00BF33;

		border: 0;

		font-weight: bold;
		color: #FFF;

		padding: 12px 32px;

		margin: 18px auto 0 auto;
	}

	>span{
		background: transparent;

		border: 1px solid #00BF33;

		color: #00BF33;

		text-align: center;

		padding: 12px 32px;

		margin: 18px auto 0 auto;
	}

	@media(max-width: 767px){
		width: 100%;
		padding-top: 0;
		>p{
			font-size: 13px;

			>strong{
				font-size: 12px;
			}
		}

		>b{
			font-size: 12px;
		}

		>button{
			font-size: 12px;
		}
	}
`;