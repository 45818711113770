import { Accordion, AccordionDetails, AccordionSummary, Divider } from "@material-ui/core";
import React from "react";
import UserInfoActions from "../../../components/UserInfoCard/UserInfoActions";
import { MainButton, OutlinedButton } from "../../../components/UserInfoCard/style";
import WrapperCard from "../../../components/WrapperCard";
import { FaChevronDown } from "react-icons/fa";
import UserAvatar from "../../../components/UserInfoCard/UserAvatar";
import { useAuth } from "../../../hooks/auth";

const EMAIL_ADDRESS = "contato@7cantos.com";
const EMAIL_SUBJECT = "Tenho uma reclamação da 7cantos.";

function UserInfoNoData({smDown, ...props}:any) {
    const { user } = useAuth();
    const ownerFirstName = user?.first_name ?? 'Proprietário';
    const children = (
        <>
          <Divider style={{ width: "100%", margin: "1rem 0" }} />
          <UserInfoActions>
            <MainButton
              href="https://api.whatsapp.com/send?text=7cantos"
              color="primary"
              style={{ marginTop: "1rem" }}
            >
              Suporte 7Cantos
            </MainButton>
            <OutlinedButton
              href={`mailto:${EMAIL_ADDRESS}?subject=${encodeURIComponent(
                EMAIL_SUBJECT
              )}`}
              color="dark"
              style={{ marginTop: "1rem" }}
            >
              Reclamações
            </OutlinedButton>
          </UserInfoActions>
        </>
      );
    
      return (
        <WrapperCard {...props}>
          {smDown && (
            <Accordion style={{ boxShadow: "none" }}>
              <AccordionSummary expandIcon={<FaChevronDown fontSize="14px" />}>
                <h5 style={{ fontSize: "1.2rem" }}>
                  Bem-vindo(a), {ownerFirstName}!
                </h5>
              </AccordionSummary>
              <AccordionDetails
                style={{ display: "flex", flexDirection: "column" }}
              >
                {children}
              </AccordionDetails>
            </Accordion>
          )}
          {!smDown && (
            <>
              <UserAvatar name={ownerFirstName} isTenant={false} />
              {children}
            </>
          )}
        </WrapperCard>
      );
}

export default UserInfoNoData;