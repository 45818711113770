import React, { useEffect, useRef, useState } from "react";
import ReactImageGallery from "react-image-gallery";

import ImageGalery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router";
import { Container, Content } from "./styles";

interface IPhotos{
    original: string;
    thumbnail: string;
}

interface IModalShowImages{
    functionToCloseModal(): void;
    photos: IPhotos[];
}

export default function ModalShowImages({ functionToCloseModal, photos }: IModalShowImages){
    const history = useHistory();

    const slideRef = useRef<ReactImageGallery>(null);

    const params = new URLSearchParams(window.location.search);

    const [screenSize, setScreenSize] = useState(document.body.clientWidth);

    useEffect(() => {
      const onResize = () => {
        const width =
          window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
  
        setScreenSize(width);
      }
  
      onResize()
      window.addEventListener('resize', onResize)
      
      return () => {
        window.removeEventListener('resize', onResize)
      }
    }, []);

    return(
      <Container>
            <Content>
                <OutsideClickHandler onOutsideClick={() => {
                    functionToCloseModal();
                    history.push(window.location.pathname);
                }}>
                    <ImageGalery 
                        items={photos} 
                        showFullscreenButton={false}
                        thumbnailPosition='right'
                        showThumbnails={screenSize >= 767 ? true : false}
                        ref={slideRef}
                        showIndex
                        startIndex={params.has('gallery') ? Number(params.get('gallery')) - 1 : 0}
                        onSlide={(index) => {
                            history.push(`${window.location.pathname}?gallery=${index + 1}`)
                        }}
                    />
                </OutsideClickHandler>
            </Content>
      </Container>
    );
};