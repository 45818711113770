import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';

import {
  Container,
  SearchFor,
  TagsContent,
  Tag,
  Form,
  InputContainer,
  AddressesOptions,
  AddressesOptionsContent,
  EmptyAddressesOptions,
  OptionsContainer,
  OptionsContant
} from './styles';

import { FiSearch, FiX } from 'react-icons/fi';

import { ICities, IContentSelected, INeightborhoods, IProperties } from './interfaces';

import api from './../../services/api';

import { useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';

const CITIES = [
  { name: "Fortaleza - CE", UF: "CE", city: "Fortaleza" },
  { name: "Recife - PE", UF: "PE", city: "Recife" },
  { name: "Salvador - BA", UF: "BA", city: "Salvador" },
  { name: "Teresina - PI", UF: "PI", city: "Teresina" },
  { name: "Maceió - AL", UF: "AL", city: "Maceió" }
];

export default function SearchBarToHomePage() {
  const inputRef = useRef<HTMLInputElement>(null);

  const history = useHistory();

  const [error, setError] = useState(false);
  const [cities, setCities] = useState<ICities[]>([]);
  const [neighborhoods, setNeighborhoods] = useState<INeightborhoods[]>([]);
  const [property, setProperty] = useState(null as IProperties | null);
  const [showSearch, setShowSearchs] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [neighborhoodsSearchs, setNeighborhoodsSearchs] = useState<INeightborhoods[]>([]);
  const [citiesSearchs, setCitiesSearchs] = useState<ICities[]>([]);
  const [contentSelected, setContentSelected] = useState<IContentSelected[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      setLoading(true);
      api.get('/api/client/addresses').then(response => {
        setCities(response.data.data.cities);
        setNeighborhoods(response.data.data.neighborhoods);
        setLoading(false);
      });
    } catch(e) {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const neighborhoodResults = neighborhoods.filter(neighborhood => neighborhood.neighborhood.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
    setNeighborhoodsSearchs(neighborhoodResults);

    const citiesResults = cities.filter(city => city.city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
    setCitiesSearchs(citiesResults);
  }, [inputSearch, neighborhoods, cities]);

  useEffect(() => {
    try {
      const formatInputSearch = parseInt(inputSearch);

      if(isNaN(formatInputSearch) === false) {
        setLoading(true);
        api.get(`/api/client/properties/${inputSearch}`).then(response => {
          setProperty(response.data.data);
          setLoading(false);
        }).catch(err => {
          setProperty(null);
          setLoading(false);
        });
      }
    } catch(err) {
      setLoading(false);
    }
      
  }, [inputSearch]);

  const handleSelectContent = useCallback(({ id, neighborhood, city, UF }: IContentSelected) => {
    try {
      const data = {
        id,
        neighborhood,
        city,
        UF
      };

      if (data.neighborhood === undefined) {
        history.push({
          pathname: `/search/?city=${data.city}&UF=${data.UF}`,
        });
      }

      if (data.neighborhood !== undefined) {
        history.push({
          pathname: `/search/?city=${data.city}&UF=${data.UF}&neighborhoods=${data.neighborhood}`,
        });
      }
    } catch (err) {
      return err;
    }

  }, [contentSelected, neighborhoods, history]);

  const handleDeleteSelectedContent = useCallback(({ id, neighborhood, city, UF }: IContentSelected) => {
    const data = { id, neighborhood, city, UF };

    const findContents = contentSelected.filter(content => content.id !== data.id);

    if (data.neighborhood !== undefined) {
      const checkNeighborhood = neighborhoods.find(neighborhood => neighborhood.city === data.city);

      if (!checkNeighborhood) {
        api.get('/api/client/addresses').then(response => setNeighborhoods(response.data.data.neighborhoods));
      }
    }

    if (contentSelected.length === 1) {
      api.get('/api/client/addresses').then(response => {
        setNeighborhoods(response.data.data.neighborhoods);
        setCities(response.data.data.cities);
      });
    }

    setContentSelected(findContents);
  }, [contentSelected, neighborhoods]);

  const handleSubmit = useCallback((event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      const checkNumber = parseInt(inputSearch);

      if (isNaN(checkNumber) === false) {
        history.push(`/imovel/${inputSearch}`);
      }

      if (contentSelected.length === 0) {
        throw new Error('Escolha uma localização');
      }

      const neighborhoodsSelected = contentSelected.map(content => content.neighborhood).toString();
      const city = contentSelected.find(content => content.city)?.city;
      const UF = contentSelected.find(content => content.UF)?.UF;

      history.push({
        pathname: `/search/?neighborhoods=${neighborhoodsSelected}&city=${city}&UF=${UF}`,
      });
    } catch (err) {
      setError(true);
    }

  }, [inputSearch, contentSelected, history]);

  const handleSubmitCode = useCallback((id: number) => {
    const checkNumber = parseInt(inputSearch);
    setError(false);
    setShowSearchs(false);

    if (isNaN(checkNumber) === false) {
      history.push(`/imovel/${id}`);
    }
  }, [history, inputSearch]);

  const handleShowSearch = useCallback(() => {
    setShowSearchs(true);
  }, []);

  const handleNotShowSearch = useCallback(() => {
    if (inputRef.current?.focus) {
      setShowSearchs(true);
    }

    setShowSearchs(false);
  }, []);

  return (
    <Container>
      {contentSelected.length >= 1 && (
        <SearchFor>
          <TagsContent knowLength={contentSelected.length}>
            {contentSelected.map((content, index) => (
              <Tag key={index}>
                <p>{content.neighborhood !== undefined ? ` ${content.neighborhood}, ${content.city} - ${content.UF}` : `${content.city}, ${content.UF}`}</p>
                <button onClick={() => handleDeleteSelectedContent({ id: content.id, neighborhood: content.neighborhood, city: content.city, UF: content.UF })}>
                  <FiX size={16} color="#666666" />
                </button>
              </Tag>
            ))}
          </TagsContent>
        </SearchFor>
      )}

      <Form onSubmit={handleSubmit}>
        {error && (
          <span>Por favor, escolha uma localização</span>
        )}
        <InputContainer thereIsAnError={error}>
          <FiSearch size={20} color="#DD6435" />
          <input ref={inputRef}
            autoComplete="off"
            list="autocompleteOff"
            onFocus={handleShowSearch}
            value={inputSearch}
            onChange={e => {
              setInputSearch(e.target.value);
              handleShowSearch();

              if (error) {
                setError(false);
              }
            }}
            type="text"
            placeholder="Pesquise por cidade, bairro ou código" />
        </InputContainer>

        {showSearch && (
          <OutsideClickHandler onOutsideClick={() => {
            handleNotShowSearch();
            setError(false);
          }}>
            <AddressesOptions>
              <AddressesOptionsContent>
                {(cities.length >= 1 && citiesSearchs?.length > 0) && (
                  <OptionsContainer>
                    {(inputSearch !== "" ? citiesSearchs : CITIES).map((city, index) => (
                      <OptionsContant key={city.city}>
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                        <button type="button" onClick={() => handleSelectContent({ id: index, city: city.city, UF: city.UF })}>
                          <p>{city.city} - {city.UF}</p>
                        </button>
                      </OptionsContant>
                    ))}
                  </OptionsContainer>
                )}

                {(neighborhoods?.length > 0 && neighborhoodsSearchs?.length > 0) && (
                  <OptionsContainer>
                    {(inputSearch !== "" ? neighborhoodsSearchs : neighborhoods).map((neighborhood, index) => (
                      <OptionsContant key={`${neighborhood.city}-${neighborhood.neighborhood}`}>
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                        <button type="button" onClick={() => handleSelectContent({ id: index, neighborhood: neighborhood.neighborhood, city: neighborhood.city, UF: neighborhood.UF })}>
                          <p>{neighborhood.neighborhood}, {neighborhood.city} - {neighborhood.UF}</p>
                        </button>
                      </OptionsContant>
                    ))}
                  </OptionsContainer>
                )}

                {property?.address !== undefined && (
                    <OptionsContainer>
                      <OptionsContant key={`${property?.id}`}>
                        <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                        <button key={property.id} type="button" onClick={() => handleSubmitCode(property.id)}>
                          <p>{property.title} ,{property.address.address}, {property.address.city}, {property.address.uf}</p>
                        </button>
                      </OptionsContant>
                    </OptionsContainer>
                )}

              {(loading === true) &&
                  <EmptyAddressesOptions>
                    <p>Carregando...</p>
                  </EmptyAddressesOptions>
                }

                {(loading !== true && inputSearch !== "" && citiesSearchs?.length === 0 && neighborhoodsSearchs?.length === 0 && !property) &&
                  <EmptyAddressesOptions>
                    <p>Nenhum local encontrado</p>
                  </EmptyAddressesOptions>
                }
              </AddressesOptionsContent>
            </AddressesOptions>
          </OutsideClickHandler>
        )}

        <button type="submit">Buscar cantos</button>
      </Form>
    </Container>
  );
}