import React from 'react';

import { useAuth } from './../../hooks/auth';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';
import HouseCardToFavoritePage from './../../components/HouseCardToFavoritePage/';

import {Container,
		Content,
		InitialContainerToMobile,
		TitleAndImageContainer} from './styles';

import favoritesImg from './../../assets/pages/FavoriteProperties/favoritesImg.svg';

export default function ScheduledVisits(){
	const { user } = useAuth();

	return(
		<Container>
			<Header hasBorder={window.innerWidth > 767 ? true : false} hasSearchBar hasOptionToSearch/>

				<Content>
					<InitialContainerToMobile>
						{!!user && (<h3>{user.type === 'inquilino' || !!user.type === false ? 'INQUILINO' : 'PROPRIETÁRIO'}</h3>)}
						<h1>Imóveis Favoritos</h1>

						<img src={favoritesImg} alt="favoritesImg"/>
					</InitialContainerToMobile>

					<HouseCardToFavoritePage />
					

					<TitleAndImageContainer>
						{!!user && (
							<h4>{user.type === 'inquilino' || !user.type ? "INQUILINO": "PROPRIETÁRIO"}</h4>
						)}
						
						<h1>Imóveis Favoritos</h1>

						<img src={favoritesImg} alt="favoritesImg"/>
					</TitleAndImageContainer>
				</Content>
				
			<Footer />
		</Container>
	);
};