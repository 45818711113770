import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import React from "react";
// import GoogleMap from "google-maps-react-markers";
// import Marker from "../../../Search/SearchMap/Marker";

import GoogleMapsReact from "google-map-react";
import Marker from "../Marker";

interface ICoordinates {
  latitude: number;
  longitude: number;
}

const DEFAULT_CENTER = { lat: -3.7301571, lng: -38.5144071 };
const getCenter = (coordinates: ICoordinates) => {
  if (!coordinates) {
    return DEFAULT_CENTER;
  }
  return {
    lat: coordinates.latitude,
    lng: coordinates.longitude,
  };
};
export default function MapSection({ address }: any) {
  const [coordinates, setCoordinates] = useState({} as ICoordinates);

  function getLatLong(apiKey: string | undefined, address: string) {
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${apiKey}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "OK") {
          const location = data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;
          const coordinates = { latitude, longitude };
          setCoordinates(coordinates);
        } else {
          console.error(`Erro: ${data.status}`);
        }
      })
      .catch((error) => {
        console.error("Erro ao obter as coordenadas:", error);
      });
  }

  useEffect(() => {
    async function getCoordinates() {
      if (address) {
        const splittedAddress = address.split(",");
        const [street, number] = splittedAddress[0].split("nº");
        const formattedAddress = `${street}, ${number}, ${
          splittedAddress[splittedAddress.length - 1]
        }, Brasil`;
        getLatLong(process.env.REACT_APP_GOOGLE_MAPS_KEY, formattedAddress);
      }
    }
    getCoordinates();
  }, [address]);

  return (
    <>
      <Divider style={{ width: "100%", margin: "1rem 0" }} />
      <p style={{ fontWeight: "bold" }}>Endereço do Contrato Atual:</p>
      <p style={{ fontSize: "16px", margin: "1rem 0 0" }}>{address}</p>
      {coordinates && (
        <div
          style={{
            width: "100%",
            height: "20vh",
            margin: "1rem 0 0",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <GoogleMapsReact
            bootstrapURLKeys={{
              key:
                process.env.REACT_APP_GOOGLE_MAPS_KEY ??
                "AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc",
            }}
            defaultCenter={DEFAULT_CENTER}
            center={getCenter(coordinates)}
            defaultZoom={14}
            options={{
              gestureHandling: "greedy",
              streetViewControl: false,
              fullscreenControl: false,
              zoomControlOptions: { position: 7 },
            }}
            yesIWantToUseGoogleMapApiInternals={true}
          >
            <Marker
              lat={coordinates.latitude}
              lng={coordinates.longitude}
              color="#6EA6FF"
            />
          </GoogleMapsReact>
        </div>
      )}
    </>
  );
}
