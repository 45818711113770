import React, { useEffect } from 'react';

import { Link, useHistory } from 'react-router-dom';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import { useAuth } from './../../hooks/auth';

import {Container,
		Content} from './styles';
import FormToForgotPasswordPage from '../../components/FormToForgotPasswordPage';

export default function ForgotPassword(){
	const { user } = useAuth();
	const history = useHistory();

	useEffect(() => {
		if(!!user){
			history.goBack()
		}
	}, [user, history]);

	return(
		<Container>
			<Header />
			<Content>
				<h2>RECUPERAR SENHA</h2>

				<FormToForgotPasswordPage />

				<p>Ainda não tem cadastro? <Link to="/register">Crie sua conta!</Link></p>
			</Content>
			<Footer/>
		</Container>
	);
};