import React, { useState, useCallback, FormEvent } from 'react';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationErrors';

import api from './../../services/api';

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import {Container,
		TitleContainer,
		FormContainer,
		FirstForm,
		SecondForm} from './styles';

import MaskedInput from 'react-text-mask';

import { FiX } from 'react-icons/fi';

interface IModalIndicateProperty{
	open: boolean;
	functionToCloseModalIndicateProperty(): void;
}

interface IValidationErrors{
	address?: string;
	type?: string;
	owner_name?: string;
	owner_phone?: string;
	name?: string;
	phone?: string;
	email?: string;
}

export default function ModalIndicateProperty({ open, functionToCloseModalIndicateProperty }: IModalIndicateProperty){
	const [address, setAddress] = useState('');
	const [type, setType] = useState('');
	const [owner_name, setOwner_name] = useState('');
	const [owner_phone, setOwner_phone] = useState('');
	const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');

	const [validationErrors, setValidationErrors] = useState({} as IValidationErrors);

	const [loadingApiRequest, setLoadingApiRequest] = useState(false);

	const [success, setSuccess] = useState(false);

	const handleIndicateImovel = useCallback(async(e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setLoadingApiRequest(true);

		try{
			const data = { address, type, owner_name, owner_phone, name, phone, email };

			// eslint-disable-next-line
			const phoneRegExp = /\(\d{2,}\) \d{4,}\-\d{4}/g;

			const schema = Yup.object().shape({
				address: Yup.string().required('Endereço obrigatório'),
				type: Yup.string().required('Tipo de imóvel obrigatório'),
				name: Yup.string().required('Nome obrigatório'),
				phone: Yup.string().matches(phoneRegExp, 'Número inválido').required('Telefone obrigatório'),
				email: Yup.string().email('E-mail inválido').required('E-mail obrigatório')
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			const response = await api.post('/api/client/advertise/recommendProperty', {
				owner_name,
				owner_phone,
				name,
				email,
				phone,
				address,
				type
			});

			if(!!response.data.success){
				setSuccess(true);

				setAddress('');
				setType('');
				setOwner_name('');
				setOwner_phone('');
				setName('');
				setPhone('');
				setEmail('');
			}

			setValidationErrors({});
			setLoadingApiRequest(false);
		} catch(err){
			if(err instanceof Yup.ValidationError){
				const errors = getValidationErrors(err);

				setValidationErrors(errors);
			}


			setLoadingApiRequest(false);
		}
	}, [address, type, owner_name, owner_phone, name, phone, email]);

	return(
		<Container>
			<Modal styles={{modal: {top:0, bottom: 0}}} open={open} onClose={functionToCloseModalIndicateProperty} classNames={{modal: 'modalIndicate'}} closeIcon={(<FiX size={30} color="#FFF"/>)}>
				<TitleContainer>
					<h1>INDIQUE UM IMÓVEL</h1>
				</TitleContainer>

				<FormContainer>
					<FirstForm>
						<p>Endereço completo do imóvel {!!validationErrors.address && (<strong>{validationErrors.address}</strong>)}</p>
						<input 
							type="text" 
							name="address"
							value={address}
							onChange={e => setAddress(e.target.value)}
						/>

						<p>Cidade {!!validationErrors.type && (<strong>{validationErrors.type}</strong>)}</p>
						<select value={type} onChange={e => setType(e.target.value)}>
							<option value=""></option>
							<option value="Fortaleza CE">Fortaleza CE</option>
							<option value="Recife PE">Recife PE</option>
							<option value="Salvador BA">Salvador BA</option>
							<option value="Maceió AL">Maceió AL</option>
							<option value="Teresina PI">Teresina PI</option>
							<option value="João Pessoa PB">João Pessoa PB</option>
							<option value="Natal RN">Natal RN</option>
							<option value="São Luís MA">São Luís MA</option>
							<option value="Aracaju SE">Aracaju SE</option>
						</select>

						<p>Nome do proprietário {!!validationErrors.owner_name && (<strong>{validationErrors.owner_name}</strong>)}</p>
						<input 
							type="text" 
							name="owner_name"
							value={owner_name}
							onChange={e => setOwner_name(e.target.value)}
						/>

						<p>Telefone do proprietário {!!validationErrors.owner_phone && (<strong>{validationErrors.owner_phone}</strong>)}</p>
						<MaskedInput
							mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
							id="mask-input"
							type="tel" 
							name="owner_phone"
							value={owner_phone}
							onChange={e => setOwner_phone(e.target.value)}
						/>
					</FirstForm>

					<SecondForm onSubmit={handleIndicateImovel}>
						<p>Seu nome {!!validationErrors.name && (<strong>{validationErrors.name}</strong>)}</p>
						<input 
							type="text" 
							name="name"
							value={name}
							onChange={e => setName(e.target.value)}
						/>

						<p>Seu telefone {!!validationErrors.phone && (<strong>{validationErrors.phone}</strong>)}</p>
						<MaskedInput
							mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
							id="mask-input"
							type="tel" 
							name="phone"
							value={phone}
							onChange={e => setPhone(e.target.value)}
						/>

						<p>Seu e-mail {!!validationErrors.email && (<strong>{validationErrors.email}</strong>)}</p>
						<input 
							type="text" 
							name="email"
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>

						{!success && (
							<>
								{!loadingApiRequest ? (<button type="submit">ENVIAR INDICAÇÃO</button>) : (<span>Carregando...</span>)}
							</>
						)}

						{!!success && (
							<b>Imóvel indicado com sucesso!</b>
						)}
						
					</SecondForm>
				</FormContainer>
			</Modal>
		</Container>
	);
};