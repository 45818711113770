import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100vh; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 
`;

export const Content = styled.div`
	position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
	z-index: 6px;

	width: 500px;
	height: 650px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;

	background: #F9F9F9;

	overflow-y: auto;

	>p{
		width: 334px;

		text-align: center;

		margin-top: 32px;

		& + p{
			margin-top: 18px;
		}
	}

	#last-p{
		padding-bottom: 32px;
	}

	>a{
		width: 334px;

		background: #00BF33;

		border: 0;

		font-weight: bold;
		color: #FFF;

		padding: 10px 0;

		text-align: center;

		text-decoration: none;

		margin-top: 24px;
	}

	@media(max-width: 767px){
		width: 330px;

		>a{
			width: 290px;

			font-size: 12px;
		}

		>p{
			width: 290px;

			font-size: 10px;
		}
	}
`;

export const LoadingContainer = styled.div`
	position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
	z-index: 6px;

	width: 500px;
	height: 650px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;

	background: #FFF;

	padding: 0 0 32px;

	overflow-y: auto;

	@media(max-width: 767px){
		top: 45%;

		width: 330px;
	}
`;

export const InitialContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 10px 0 24px;

	background: #DD6435;

	>h1{
		font-size: 26px;
		font-weight: bold;
		color: #FFF;

		margin-top: -10px;
	}

	>button{
		width: 30px;
		height: 30px;

		background: transparent;

		border: 0;

		margin: 0 12px 0 auto;
	}

	@media(max-width: 767px){
		>h1{
			margin-top: 0;

			font-size: 19px;
		}

		>button{
			width: 24px;
			height: 24px;

			>svg{
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const PropertyInformationsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 24px 10px 42px;

	background: #FFF;

	>img{
		width: 184px;
		height: 136px;

		margin-left: 16px;
	}

	@media(max-width: 767px){
		>img{
			width: 136px;
			height: 100px;
		}
	}
`;

export const TitleAndValuesContainer = styled.div`
	width: 55%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-size: 18px;
		font-weight: bold;
		color: #6EA5FF
	}

	>strong{
		font-size: 15px;
		font-weight: 400;

		margin: 8px 0;
	}

	>p{
		font-size: 15px;
		font-weight: 500;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 16px;
		}

		>strong{
			font-size: 13px;
		}

		>p{
			font-size: 13px;
		}
	}
`;

export const FormContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 16px 0 0;

	>h4{
		font-size: 17px;
		font-weight: bold;

		margin-bottom: 24px;
	}

	@media(max-width: 767px){
		>h4{
			font-size: 16px;
		}
	}
`;

export const Form = styled.form`
	>button{
		width: 334px;

		background: #00BF33;

		border: 0;

		font-weight: bold;
		color: #FFF;

		padding: 10px 0;
	}

	>p{
		width: 334px;

		background: transparent;

		border: 1px solid #00BF33;

		text-align: center;

		color: #00BF33;

		padding: 10px 0;
	}

	@media(max-width: 767px){
		>button{
			width: 290px;

			font-size: 12px;
		}

		>p{
			width: 290px;

			font-size: 12px;
		}
	}
`;

export const UserInputContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>p{
		font-size: 16px;

		margin-bottom: 4px;
	}

	>label{
		font-size: 15px;

		margin-bottom: 4px;
	}

	margin-bottom: 8px;

	@media(max-width: 767px){
		>p{
			font-size: 12px;
		}

		>label{
			width: 70%;

			font-size: 12px;

			margin-bottom: 8px;
		}
	}
`;

export const InputContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	background: #FFF;

	padding: 12px;

	>input{
		width: 300px;

		background: transparent;

		border: 0;

		padding-right: 8px;

		font-weight: bold;

		&::placeholder{
			font-weight: bold;
			color: #B6B6B6;
		}
	}

	>textarea{
		max-width: 300px;
		width: 300px;

		background: transparent;

		border: 0;

		font-family: 'Montserrat', sans-serif;

		resize: none;

		padding-right: 8px;

		&::placeholder{
			font-family: 'Montserrat', sans-serif;
		}
	}

	@media(max-width: 767px){
		>input{
			width: 256px;
		}

		>textarea{
			max-width: 256px;
			width: 256px;
		}
	}
`;