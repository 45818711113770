import React, { useState, useCallback, useEffect } from 'react';

import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

import formatValue from './../../utils/formatValue';

import {Container,
        TitleContainer,
        ButtonsContainer,
        ImovelTypeContainer,
        CheckboxContainer,
        SliderContainer,
        ValuesToSearch,
        StyledSlider,
        StyledThumb,
        StyledTrack,
        TotalCheckboxContainer,
        NumbersContainer,
        NumbersOfContainer,
        NumberOfButton,
		PropertyBenefitsContainer,
		PropertyBenefitsContent} from './styles';

import { FiX } from 'react-icons/fi';

export interface ISearchCredentials{
	uf? : any,
    city? : any,
    neighborhood? : any, 
    type? : any,
    rentValue? : any,
    area? : any,
    rooms? : any,
    garages? : any,
    isToSearchTotalValue? : any,
    bathrooms? : any,
    page? : string | null | undefined,
    rentValueOrder? : string | null | undefined,
    furnished? : any
    minimal? : number
}

interface IModalToTest{
    open: boolean;
    onClose(): void;
    neighborhoods?: any;
	imovelType?: any;
	city?: any;
	UF?: any;
	priceValues: number[];
	areaValues: number[];
	functionToSearch(props: ISearchCredentials): any;
	isLoadingApiRequest: boolean;
}

export default function ModalToTest(properties: IModalToTest){    
    const [neighborhood, setNeighborhood] = useState<string | undefined>(undefined);
	const [imovelType, setImovelType] = useState<number | undefined>(properties.imovelType);
	const [imovelPriceValues, setImovelPriceValues] = useState<number[]>([properties.priceValues[0], properties.priceValues[1]]);
	const [isToSearchTotalValue, setIsToSearchTotalValue] = useState(false);
	const [imovelAreaValues, setImovelAreaValues] = useState<number[]>([properties.areaValues[0], properties.areaValues[1]]);
	const [roomsNumber, setRoomsNumber] = useState<number | undefined>(undefined);
	const [garageNumber, setGarageNumber] = useState<number | undefined>(undefined);
	const [bathroomsNumber, setBathroomsNumber] = useState<number | undefined>(undefined);
	const [furnished, setFurnished] = useState<number | undefined>(undefined);

	useEffect(() => {
		setImovelType(properties.imovelType);
	}, [properties]);

	const handleCleanFilterSearch = useCallback(() => {
		setNeighborhood(undefined);
		setImovelType(undefined);
		setImovelPriceValues([properties.priceValues[0], properties.priceValues[1]]);
		setImovelAreaValues([properties.areaValues[0], properties.areaValues[1]]);
		setRoomsNumber(undefined);
		setGarageNumber(undefined);
		setBathroomsNumber(undefined);
		setFurnished(undefined);
	}, [properties]);

	const handleApplyFilters = () => {

		let request: ISearchCredentials = {
			neighborhood: neighborhood,
			city: properties.city,
			uf: properties.UF,
			type: imovelType,
			rentValue: imovelPriceValues,
			isToSearchTotalValue: isToSearchTotalValue === true ? true : undefined,
			area: imovelAreaValues,
			rooms: roomsNumber,
			garages: garageNumber,
			bathrooms: bathroomsNumber
		}

		if (furnished === 1) {
			request = { ...request, furnished }
		}
		properties.functionToSearch({ ...request });
	}

    const ThumbToPrice = (props: any, state: any) => <StyledThumb {...props}/>;
	const Track = (props: any, state: any) => <StyledTrack {...props} index={state.index} />;
	const ThumbToSize = (props: any, state: any) => <StyledThumb {...props}/>;

    return (
        <Container>
            <Modal open={properties.open} onClose={properties.onClose} classNames={{
                modal: 'customModal'
            }} closeIcon={(<FiX size={30} color="#FFF"/>)}>
            	<TitleContainer>
					<h2>FILTROS DE BUSCA</h2>
				</TitleContainer>

				<ButtonsContainer>
					<button onClick={handleCleanFilterSearch}>LIMPAR FILTROS</button>
					{!!properties.isLoadingApiRequest ? 
						(<p>Carregando...</p>) : 
						(<button className='send-filter-button' onClick={handleApplyFilters}>APLICAR FILTROS</button>)
					}
				</ButtonsContainer>
			
				<ImovelTypeContainer>
					<h3>TIPO DE IMÓVEL</h3>
					<CheckboxContainer onClick={() => setImovelType(1)}>
						<input type="radio" name="selector" checked={imovelType == 1} onChange={() => setImovelType(1)}/>
						<label>Apartamento</label>
					</CheckboxContainer>

					<CheckboxContainer onClick={() => setImovelType(2)}>
						<input type="radio" name="selector" checked={imovelType == 2} onChange={() => setImovelType(2)}/>
						<label>Casa</label>
					</CheckboxContainer>

					<CheckboxContainer onClick={() => setImovelType(3)}>
						<input type="radio" name="selector" checked={imovelType == 3} onChange={() => setImovelType(3)}/>
						<label>Flat</label>
					</CheckboxContainer>

					<CheckboxContainer onClick={() => setImovelType(4)}>
						<input type="radio" name="selector" checked={imovelType == 4} onChange={() => setImovelType(4)}/>
						<label>Casa de condomínio</label>
					</CheckboxContainer>
				</ImovelTypeContainer>

				<SliderContainer>
					<h3>VALOR DO ALUGUEL</h3>

					<ValuesToSearch>De {formatValue(imovelPriceValues[0])} até {formatValue(imovelPriceValues[1])}</ValuesToSearch>	

					<StyledSlider 
						max={20000}
						min={400}
						defaultValue={[imovelPriceValues[0], imovelPriceValues[1]]}
						pearling
						step={50}
						renderTrack={Track}
						renderThumb={ThumbToPrice}
						onChange={(e) => {
							if(Array.isArray(e)){
								setImovelPriceValues(e);
							}
						}}
						onAfterChange={(e) => {
							if(!!e){
								if(Array.isArray(e))
									setImovelPriceValues(e);
							}
						}}
					/>
				</SliderContainer>

				<TotalCheckboxContainer>
                    <input type="checkbox" checked={isToSearchTotalValue} onClick={() => setIsToSearchTotalValue(!isToSearchTotalValue)}/>

                    <label onClick={() => setIsToSearchTotalValue(!isToSearchTotalValue)}>Incluir IPTU e Condomínio</label>
				</TotalCheckboxContainer>

				<SliderContainer>
					<h3>ÁREA DO IMÓVEL</h3>

					<ValuesToSearch>De {imovelAreaValues[0]}m² até {imovelAreaValues[1]}m²</ValuesToSearch>

					<StyledSlider 
						max={1060}
						min={20}
						defaultValue={[imovelAreaValues[0], imovelAreaValues[1]]}
						pearling
						step={10}
						renderTrack={Track}
						renderThumb={ThumbToSize}
						onChange={(e) => {
							if(Array.isArray(e)){
								setImovelAreaValues(e);
							}
						}}
						onAfterChange={(e) => {
							if(!!e){
								if(Array.isArray(e)){
									setImovelAreaValues(e);
								}
							}
						}}
					/>
				</SliderContainer>

				<NumbersOfContainer>
					<h3>NÚMERO DE QUARTOS</h3>
					<NumbersContainer>
						<NumberOfButton
							onClick={() => setRoomsNumber(1)}
							isSelected={roomsNumber === 1}	
							>1</NumberOfButton>
						<NumberOfButton
							onClick={() => setRoomsNumber(2)}
							isSelected={roomsNumber === 2}	
							>2</NumberOfButton>
						<NumberOfButton
							onClick={() => setRoomsNumber(3)}
							isSelected={roomsNumber === 3}	
							>3</NumberOfButton>
						<NumberOfButton
							onClick={() => setRoomsNumber(4)}
							isSelected={roomsNumber === 4}	
							>4+</NumberOfButton>
					</NumbersContainer>

					<h3>NÚMERO DE VAGAS</h3>				
					<NumbersContainer>
						<NumberOfButton
							onClick={() => setGarageNumber(1)}
							isSelected={garageNumber === 1}	
							>1</NumberOfButton>
						<NumberOfButton
							onClick={() => setGarageNumber(2)}
							isSelected={garageNumber === 2}	
							>2</NumberOfButton>
						<NumberOfButton
							onClick={() => setGarageNumber(3)}
							isSelected={garageNumber === 3}	
							>3</NumberOfButton>
						<NumberOfButton
							onClick={() => setGarageNumber(4)}
							isSelected={garageNumber === 4}	
							>4+</NumberOfButton>
					</NumbersContainer>

					<h3>NÚMERO DE BANHEIROS</h3>				
					<NumbersContainer>
						<NumberOfButton
							onClick={() => setBathroomsNumber(1)}
							isSelected={bathroomsNumber === 1}	
							>1</NumberOfButton>
						<NumberOfButton
							onClick={() => setBathroomsNumber(2)}
							isSelected={bathroomsNumber === 2}	
							>2</NumberOfButton>
						<NumberOfButton
							onClick={() => setBathroomsNumber(3)}
							isSelected={bathroomsNumber === 3}	
							>3</NumberOfButton>
						<NumberOfButton
							onClick={() => setBathroomsNumber(4)}
							isSelected={bathroomsNumber === 4}
							>4+</NumberOfButton>
					</NumbersContainer>
				</NumbersOfContainer>

				<PropertyBenefitsContainer>
					<h3>BENEFÍCIOS DO IMÓVEL</h3>

					<PropertyBenefitsContent>
						<input id="inp-mobiliado" type="checkbox" checked={furnished !== undefined ? true : false} onChange={() => setFurnished(furnished !== undefined ? 0 : 1)}/>

						<label htmlFor="inp-mobiliado">Mobiliado</label>
					</PropertyBenefitsContent>
				</PropertyBenefitsContainer>
				<ButtonsContainer>
					<button onClick={handleCleanFilterSearch}>LIMPAR FILTROS</button>
					{!!properties.isLoadingApiRequest ? 
						(<p>Carregando...</p>) : 
						(<button className='send-filter-button' onClick={handleApplyFilters}>APLICAR FILTROS</button>)
					}
				</ButtonsContainer>
            </Modal>
        </Container>
    );
}