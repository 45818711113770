import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    width:100%;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
`;
