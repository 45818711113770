import React, { useState, useCallback, FormEvent } from 'react';

import { Form } from './styles';

import api from './../../services/api';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationErrors';
import { useRouteMatch } from 'react-router';

interface IValidationErrors{
	email?: string;
	password?: string;
	passwordConfirmation?: string;
}

interface IParams{
	token: string;
}

export default function FormToResetPassword(){
    const { params } = useRouteMatch<IParams>();

    const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [passwordConfirmation, setPasswordConfirmation] = useState('');

	const [validationError, setValidationError] = useState({} as IValidationErrors);
	const [apiError, setApiError] = useState('');

	const [success, setSuccess] = useState(false);

	const [loading, setLoading] = useState(false);

    const handleSubmit = useCallback(async(e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setLoading(true);

		try{
			const data = { email, password, passwordConfirmation };

			const schema = Yup.object().shape({
				email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
				password: Yup.string().required('Senha obrigatória').min(8, 'Senha deve conter no mínimo 8 caracteres'),
				passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], 'Confirmação de senha incorreta').required('Confirmação de senha obrigatória')
			});

			await schema.validate(data, {
				abortEarly: false
			});

			const response = await api.post('/api/auth/reset', {
				email: data.email,
				password: data.password,
				password_confirmation: data.passwordConfirmation,
				token: params.token
			});

			if(response.status === 200){
				setSuccess(true);
			}

			setApiError('');
			setValidationError({});
			setLoading(false);
		} catch(err){
			if(err instanceof Yup.ValidationError){
				const errors = getValidationErrors(err);

				setApiError('');
				setValidationError(errors);
			} else{
				setValidationError({});
				setApiError('Erro ao alterar a senha, tente novamente');
			}

			setLoading(false);
		}
	}, [email, password, passwordConfirmation, params.token]);

    return(
        <Form onSubmit={handleSubmit}>
			{!!apiError && (<h4>{apiError}</h4>)}

			<p>E-mail: {!!validationError && (<span>{validationError.email}</span>)}</p>
			<input 
				type="text"
				name="email"
				value={email}
				onChange={e => setEmail(e.target.value)}
			/>

			<p>Senha: {!!validationError && (<span>{validationError.password}</span>)}</p>
			<input 
				type="password"
				name="password"
				value={password}
				onChange={e => setPassword(e.target.value)}
			/>

			<p>Confirmar senha: {!!validationError && (<span>{validationError.passwordConfirmation}</span>)}</p>
			<input 
				type="password"
				name="passwordConfirmation"
				value={passwordConfirmation}
				onChange={e => setPasswordConfirmation(e.target.value)}
			/>

			{!!loading && (<strong>Carregando</strong>)}

			{!loading && (
				<>
					{!!success ? (<label>Senha alterada com sucesso</label>) : (<button type="submit">SALVAR NOVA SENHA</button>)}
				</>
			)}
		</Form>
    );
}