import React, { useEffect, useState, useMemo, useCallback, FormEvent } from 'react';

import api from './../../services/api';

import { useAuth } from './../../hooks/auth';

import {Container,
		Content,
		TitleContainer,
		Form,
		SelectContainer} from './styles';

import { FiX } from 'react-icons/fi';
import Select from 'react-select';

interface IModalReSchedule{
	consultantID: number;
	propertyID: number;
	scheduleID: number;
	functionToCloseModalReSchedule(): void;
}

interface IDates{
	id: number;
	date: string;
	consultant_id: number;
}

interface ISelectOptions{
	label: string;
	value: string;
}

export default function ModalReSchedule({ consultantID, propertyID, scheduleID, functionToCloseModalReSchedule }: IModalReSchedule){
	const { scheduleAppointment, access_token } = useAuth();

	const [dates, setDates] = useState<IDates[]>([]);
	const [hours, setHours] = useState<ISelectOptions[]>([]);

	const [selectedDate, setSelectedDate] = useState("");
	const [selectedHour, setSelectedHour] = useState("");

	const [error, setError] = useState('');
	const [errorInSelectedDate, setErrorInSelectedDate] = useState(false);
	const [errorInSelectedHour, setErrorInSelectedHour] = useState(false);

	const [loading, setLoading] = useState(true);
	const [loadingApiRequest, setLoadingApiRequest] = useState(false);

	useEffect(() => {
		api.get(`/api/client/schedules/consultant/${consultantID}`).then(response => {
			setDates(response.data.data);
			setLoading(false);
		}).catch(err => {
			console.log(err);
			setLoading(false);
		});
	}, [consultantID]);

	const handleSubmit = useCallback(async(event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoadingApiRequest(true);

		try{
			if(selectedDate === ""){
				setLoadingApiRequest(false);
				return setErrorInSelectedDate(true);
			}

			if(selectedHour === ""){
				setLoadingApiRequest(false);
				return setErrorInSelectedHour(true);
			}

			const getID = dates.find(date => (date.date === `${selectedDate} ${selectedHour}`))
			
			if(!!getID){
				api.defaults.headers.authorization = `Bearer ${access_token}`;

				await api.put(`/api/client/visits/${scheduleID}`).catch(err => console.log(err));

				const response = await scheduleAppointment({ id: getID.id, property_id: String(propertyID) });

				if(!!response.success){
					functionToCloseModalReSchedule();
				} else{
					setError(response.message);
				}
			}
			
			setLoadingApiRequest(false);
			setErrorInSelectedDate(false);
			setErrorInSelectedHour(false);
		} catch(err){
			console.log(err);

			setLoadingApiRequest(false);
		}
	}, [selectedDate, selectedHour, dates, propertyID, scheduleID, scheduleAppointment, access_token, functionToCloseModalReSchedule]);

	const handleSelectDate = useCallback((selectedDate: string) => {
		if(selectedDate === ""){
			setSelectedDate("");
			return setSelectedHour("");
		}

		const formatDate = `${selectedDate.substring(6,10)}-${selectedDate.substring(3,5)}-${selectedDate.substring(0,2)}`;

		const getHours = dates.filter(date => (date.date.includes(formatDate)));

		const orderHours = getHours.sort((a, b) => {
			return Number(a.date.substring(11, 13)) - Number(b.date.substring(11, 13));
		});

		const formatHours = orderHours.map(hour => ({
			label: hour.date.substr(11, 19),
			value: hour.date.substr(11, 19)
		}));

		setSelectedDate(formatDate);
		setHours(formatHours);
	}, [dates]);

	const handleSelectHour = useCallback((hour: string) => {
		if(hour === ""){
			return setSelectedHour("");
		}
		if(!!selectedDate){
			setSelectedHour(hour);
		}
	}, [selectedDate]);

	const datesFilter = useMemo(() => {
		const filter = dates.map(date => date.date);
		const getDates = filter.map(date => date.substring(0,10));

		const uniques = [...new Set(getDates)];

		const orderDates = uniques.sort((a,b) => (+new Date(a) - +new Date(b)));

		const buildDate = orderDates.map(date => ({
			label: `${date.substring(8,10)}/${date.substring(5,7)}/${date.substring(0,4)}`,
			value: `${date.substring(8,10)}/${date.substring(5,7)}/${date.substring(0,4)}`
		}));

		return(buildDate);
	}, [dates]);

	return(
		<Container>
			<Content>
				<TitleContainer>
					<button onClick={functionToCloseModalReSchedule}><FiX size={30} color="#FFF"/></button>

					<h1>REAGENDAMENTO</h1>
				</TitleContainer>

				<Form onSubmit={handleSubmit}>
					<SelectContainer>
						<p>Data de preferência: {!!errorInSelectedDate ? <span style={{color: '#FF3838'}}>Escolha uma data</span> : ""}</p>
						<Select 
							options={datesFilter}
							onChange={e => handleSelectDate(e!.value)}
							placeholder={!!loading ? "Carregando..." : ""}
							noOptionsMessage={(obj: {inputValue: string}) => (!!loading ? 'Carregando...' : 'Não temos datas para disponíveis para agendamento')}
						/>
					</SelectContainer>	

					<SelectContainer>
						<p>Hora de preferência: {!!errorInSelectedHour ? <span style={{color: '#FF3838'}}>Escolha uma hora</span> : ""}</p>
						<Select 
							options={hours}
							onChange={e => handleSelectHour(e!.value)}
							placeholder=""
							noOptionsMessage={(obj: {inputValue: string}) => ('Não temos horários disponíveis nessa data.')}
						/>
					</SelectContainer>

					{!loadingApiRequest && (<button id="first-button" type="submit" style={{background: !!error ? "#FF3838" : "#00BF33"}}>{!!error ? error : "REAGENDAR"}</button>)}
					{!!loadingApiRequest && (<label>Carregando...</label>)}
					
					<button type="button" onClick={functionToCloseModalReSchedule} style={{background: "#BF0000"}}>CANCELAR</button>
				</Form>
			</Content>
		</Container>
	);
};