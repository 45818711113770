import { createGlobalStyle } from 'styled-components';

// CONSTANTS
export const HORIZONTAL_PADDING_PAGE_DEFAULT = {
    SM: '20px',
    MD: '40px',
    LG: '100px' 
}

export const MAX_WIDTH_PAGE = '1600px';

export default createGlobalStyle`
    *{
		margin: 0;
		padding: 0;
		box-sizing: border-box;
		outline: 0;
	}
	body {
		background: #FFF;
		color: #666666;
		-webkit-font-smoothing: antialiased;

		.MuiRadio-colorSecondary.Mui-checked{
			color: #DD6435;
		}

		.customModal{
			width: 100%;

			padding: 0;
			margin: 0;
		}

		.modalIndicate{
			width: 100%;

			padding: 0;
			margin: 0;

			top: 10%;
		}

		.show-image-modal{
			width: 40%;
			max-width: 100%;

			top: 25%;

			box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0);

			background: transparent;
			
			overflow-x: hidden;
		}

		@media(max-width: 767px){
			.MuiSvgIcon-root{
				width: 18px;
			}

			.PrivateSwitchBase-root-1{
				padding: 3px;
			}
		}
	}

	body, input, button {
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
	}
	h1, h2, h3, h4, h5, h6, strong{
		font-weight: 500;
	}
	a{
		text-decoration: none;
	}
	button{
		cursor: pointer;
	}
`;