import React, { FormEvent, useEffect, useState, useCallback, ChangeEvent } from 'react';
import Footer from "../../components/Footer/";
import Header from "../../components/Header/";
import ModalConfirmRequestTermination from '../../components/ModalConfirmRequestTermination';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { useAuth } from '../../hooks/auth';
import api from '../../services/api';
import { useHistory, Link } from 'react-router-dom';

import {Container,
        Content,
        Form,
        OptionsContainer,
        OptionContent,
        UploadDocumentsLinkContainer} from './styles';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationErrors';
import Axios, { CancelTokenSource } from 'axios';

import OutsideClickHandler from 'react-outside-click-handler';
import { Button, Tooltip } from '@material-ui/core';

interface IProperties{
    id: number;
    address: string;
    neighborhood: string;
    number: string;
    title: string;
}

interface IValidationError{
    [key: string]: string;
}

export default function RequestTermination(){
    const { user, access_token } = useAuth();

    const [date, setDate] = useState(new Date());

    const [searchTerm, setSearchTerm] = useState('');
    const [properties, setProperties] = useState<IProperties[]>([]);
    const [optionSelected, setOptionSelected] = useState({} as IProperties);
    const [reason, setReason] = useState('');
    const [propertyUnity, setPropertyUnity] = useState('');

    const [validationError, setValidationError] = useState({} as IValidationError);

    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);

    const [anyPropertyFinded, setAnyPropertyFinded] = useState(false);

    const [success, setSuccess] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if(!user){
            history.goBack();
        }
    }, [user, access_token, history]);

    let cancelToken: undefined | CancelTokenSource;

    const handleSearchChange = useCallback(async(e: ChangeEvent<HTMLInputElement>) => {
        setLoadingSearch(true);
        setAnyPropertyFinded(false);

        const newSearchTerm = e.target.value;

        setSearchTerm(newSearchTerm);

        if(cancelToken !== undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
        }
        
        // eslint-disable-next-line
        cancelToken = Axios.CancelToken.source();

        try{
            api.defaults.headers.authorization = `Bearer ${access_token}`;

            const response = await api.get('/api/client/tenant/properties', {
                params: {
                    address: newSearchTerm,
                },
                cancelToken: cancelToken!.token
            });

            if(response.data.data.length >= 1){
                setProperties(response.data.data);
            } else{
                setAnyPropertyFinded(true);
                setProperties([]);
            }
        } catch(err){
            console.log(err);
        } finally{
            setLoadingSearch(false);
        }
    }, [cancelToken, access_token]);

    const handleSubmit = useCallback(async(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setValidationError({} as IValidationError);
        setLoadingRequest(true);

        try{
            // eslint-disable-next-line
            const dateRegex = /^([0-2][0-9]|(3)[0-1])(\-)(((0)[0-9])|((1)[0-2]))(\-)\d{4}$/i;
            const dateFormat = date.toISOString().substring(0, 10);

            const dataToRequest = {
                reason,
                optionSelected,
                date: `${dateFormat.substring(8, 10)}-${dateFormat.substring(5,7)}-${dateFormat.substring(0,4)}`,
            }

            const schema = Yup.object().shape({
                reason: Yup.string().required('Campo obrigatório!'),
                optionSelected: Yup.object().shape({
                    address: Yup.string().required('Campo obrigatório!')
                }),
                date: Yup.string().matches(dateRegex, 'Data inválida!').required('Campo obrigatório!')
            });

            await schema.validate(dataToRequest, {
                abortEarly: false,
            });

            api.defaults.headers.authorization = `Bearer ${access_token}`;

            const response = await api.post('/api/client/tenant/contract-termination', {
                property_id: dataToRequest.optionSelected.id,
                reason: dataToRequest.reason,
                start_date: dataToRequest.date,
                property_unity: propertyUnity ? propertyUnity : undefined
            });

            if(response.data.success){
                setSuccess(true);
            }
        } catch(err){
            console.log(err);

            if(err instanceof Yup.ValidationError){
                const error = getValidationErrors(err);

                setValidationError(error);
            }
        } finally{
            setLoadingRequest(false);
        }
    }, [reason, optionSelected, date, access_token, propertyUnity]);

    return(
        <Container>
            <Header />
            <Content>
                <h2>Solicitar Rescisão</h2>

                <Form onSubmit={handleSubmit}>
                    <div className="label-validationError">
                        <p>Imóvel:</p>
                        {validationError['optionSelected.address'] && <span className="error">{validationError['optionSelected.address']}</span>}
                        {anyPropertyFinded && <span className="error">Nenhum imóvel encontrado</span>}
                        {loadingSearch && <span>Carregando...</span>}
                    </div>
                    
                    <div className="input-options">
                        <input
                            className="properties-input"
                            value={searchTerm}
                            onChange={e => searchTerm.length < 2 ? setSearchTerm(e.target.value) : handleSearchChange(e as ChangeEvent<HTMLInputElement>)}
                        />
                        {properties.length >= 1 && (
                            <OutsideClickHandler onOutsideClick={() => {
                                setProperties([]);
                                setSearchTerm('');
                            }}>
                                <OptionsContainer>
                                    {properties.map(property => (
                                        <OptionContent type="button" onClick={() => {
                                            setOptionSelected(property);
                                            setSearchTerm(`${property.title}, ${property.address}, ${property.neighborhood}, ${property.number}`);
                                            setProperties([]);
                                        }}>
                                            <p>{`${property.title}, ${property.address}, ${property.neighborhood}, ${property.number}`}</p>
                                        </OptionContent>
                                    ))}
                                </OptionsContainer>
                            </OutsideClickHandler>
                            
                        )}
                    </div>

                    <div className="label-validationError">
                        <p>Unidade: </p>

                        {validationError.reason && <span>{validationError.reason}</span>}
                    </div>
                    
                    <input  
                        value={propertyUnity}
                        onChange={e => setPropertyUnity(e.target.value)}
                        placeholder="Apto 12"
                    />

                    <div className="label-validationError">
                        <p>Data da Solicitação:</p>
                        {validationError.date && <span>{validationError.date}</span>}
                    </div>

                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                        <KeyboardDatePicker 
                            value={date}
                            minDate={new Date()}
                            onChange={e => setDate(e as Date)}
                            format="dd/MM/yyyy"
                            invalidLabel="Data inválida"
                            invalidDateMessage="Data inválida"
                            minDateMessage="Data não pode ser anterior ao dia de hoje"
                        />
                    </MuiPickersUtilsProvider>
                    
                    <div className="label-validationError">
                        <p>Motivo da saída: </p>

                        {validationError.reason && <span>{validationError.reason}</span>}
                    </div>
                    
                    <input  
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                    />
                    
                    <button type={loadingRequest ? 'button' : "submit"}>{loadingRequest ? "Carregando..." : "PRÓXIMO"}</button>
                </Form>

                <UploadDocumentsLinkContainer>
                    <p>Já realizou a solicitação de rescisão?</p>
                    <b>Agora só falta enviar a documentação.</b>

                    <Link to="/request-termination-upload-documents">ENVIAR DOCUMENTOS</Link>
                </UploadDocumentsLinkContainer>

                {success && (
                    <ModalConfirmRequestTermination 
                        functionToClose={() => setSuccess(false)}
                    />
                )}
            </Content>
            <Footer />
        </Container>
    );
}