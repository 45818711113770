import styled from 'styled-components';

export const Container = styled.div`
`;

export const Content = styled.div`
	max-width: 767px;
	margin: 42px auto;

	>h2{
		font-size: 1.5rem;
		color: #DD6435;
		text-align: center;
		margin-bottom: 1.25rem;
	}
	>h3 {
		margin: 16px 0;
		font-size: 1.25rem;
	}

	@media(max-width: 767px){
		margin: 0 16px;
		>h2{
			font-size: 1.2rem;
			text-align: left;
		}

		>p{
			font-size: 13px;
		}
	}
`;