import loadingGif from "../../../../assets/components/loading.gif";
import OptionsRow from "./OptionsRow";
import React, { useState } from "react";
import api from "../../../../services/api";
import { FaChevronDown, FaFileDownload } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import formatValue from "../../../../utils/formatValue";
const isReceived = (status: string) => status === "RECEIVED";

interface IPayment {
  due_date: string;
  tenant: string;
  address: string;
  complement: string;
  value: string;
  status: string;
  payment_id: string;
  kenlo_property_id: string;
}

interface IBills {
  bills: IPayment[];
}

export default function OptionsAccordion({ bills }: IBills) {
  const [isDownloadingPayment, setIsDownloadingPayment] = useState({
    isDowloading: false,
    paymentId: null,
  });
  const StatusCell = ({ status }: any) => {
    let statusText;
    let textColor;
    switch (status) {
      case "RECEIVED":
        statusText = "Recebido";
        textColor = "#0ca11a";
        break;
      case "SENT":
        statusText = "A receber";
        textColor = "#8b8a8e";
        break;
      default:
        statusText = "Atrasado";
        textColor = "#b91c1b";
        break;
    }
    return <p style={{ fontSize: "0.8rem", color: textColor }}>{statusText}</p>;
  };

  async function handleDownloadPayment({ paymentId, date }: any) {
    setIsDownloadingPayment({ isDowloading: true, paymentId });
    try {
      const response = await api.get(
        `/api/client/owner/dashboard/payment/${paymentId}`,
        {
          responseType: "arraybuffer",
        }
      );
      // SnackbarService.showSuccess({ message: "Boleto baixado com sucesso!" });
      const pdfUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `${date} - ${paymentId} - 7cantos.pdf`);
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);

      // SnackbarService.showError({
      //   message: "Houve um erro ao baixar o boleto. Tente novamente mais tarde",
      // });
    }
    setIsDownloadingPayment({ isDowloading: false, paymentId: null });
  }

  return (
    <div style={{ width: "100%" }}>
      {bills.map((bill) => {
        const value = Number.parseFloat(bill.value);
        const received = isReceived(bill.status);
        const paymentData = {
          date: bill.due_date,
          paymentId: bill.payment_id,
        };
        return (
          <Accordion key={bill.tenant}>
            <AccordionSummary expandIcon={<FaChevronDown size="14px" />}>
              <p style={{ textAlign: "left" }}>
                {bill.address} - {bill.complement}
              </p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <OptionsRow title="Vencimento">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {bill.due_date}
                  </p>
                </OptionsRow>
                <OptionsRow title="Inquilino">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {bill.tenant}
                  </p>
                </OptionsRow>
                <OptionsRow title="Endereço">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {bill.address}
                  </p>
                </OptionsRow>
                <OptionsRow title="Complemento">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {bill.complement}
                  </p>
                </OptionsRow>
                <OptionsRow title="Valor">
                  <p style={{ fontSize: "0.8rem" }}>{formatValue(value)}</p>
                </OptionsRow>
                <OptionsRow title="Status">
                  <StatusCell status={bill.status} />
                </OptionsRow>
                <OptionsRow title="Boleto">
                  {isDownloadingPayment.isDowloading &&
                  bill.payment_id === isDownloadingPayment.paymentId ? (
                    <img src={loadingGif} width="25px" alt="Baixando..." />
                  ) : (
                    <FaFileDownload
                      onClick={
                        received
                          ? () => handleDownloadPayment(paymentData)
                          : () => {}
                      }
                      style={{
                        color: received ? "rgb(110, 165, 255)" : "#8b8a8e",
                        cursor: received ? "pointer" : "not-allowed",
                      }}
                    />
                  )}
                </OptionsRow>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
