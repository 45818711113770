import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const PropertyAnnouncementSectionContainer = styled.section`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 60px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    width: 100%;
    background-color: #6EA5FF;

    // MEDIUM
    @media(min-width: 768px){
        padding: 20px ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
    }

    // LARGE
    @media(min-width: 992px){
        gap: 75px;
        flex-direction: row;
        padding: 20px ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
    }
`;

export const PropertyAnnouncementSectionTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;

    h3.title {
        font-family: Montserrat;
        font-size: 42px;
        font-style: normal;
        font-weight: bold;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-top: 20px;
    }

    p.description {
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-top: 10px;
    }

    a {
        height: 50px;
        width: 290px;
        border-radius: 0px;
        background: #DD6536;
        color: #FFFFFF;
        font-weight: 700;
        font-size: 16px;
        border: none;
        margin: 0 auto;
        margin-top: 40px;
        line-height: 50px;
        text-align: center;
    }

    // LARGE
    @media(min-width: 992px){
        max-width: 40%;

        h3.title {
            font-size: 44px;
            text-align: left;
            margin-top: 0;
        }

        p.description {
            text-align: left;
        }

        a {
            margin: 0;
            margin-top: 40px;
        }
    }
`;

export const PropertyAnnouncementSectionImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    
    img {
        width: 100%;
        max-width: 300px;
    }

    // LARGE
    @media(min-width: 992px){

        img {
            max-width: 500px;
            min-width: 200px;
            width: 500px;
            align-self: flex-end;
        }
    }
`;