import React, { useState } from "react";

import {
  AdvantagesSectionContainer,
  AdvantagesSectionHeaderContainer,
  AdvantagesSectionHeaderTitleContainer,
  AdvantagesSectionHeaderButtonsContainer,
  AdvantagesSectionCardContainer,
  AdvantagesSectionCardTextsContainer,
  AdvantagesSectionCardImageContainer,
  AdvantagesSectionCardTextsHeaderContainer,
  AdvantagesSectionCardTextsListContainer,
  AdvantagesSectionCardTextsListItem,
} from "./styles";

import ButtonsTab from "./ButtonsTab";

import MarcosLessa1Image from "./../../../assets/pages/Home/marcos-lessa-1.webp";

const ADVANTAGES_ITENS = {
  OWNER: {
    title: "Fácil para o anunciante",
    description: "Tenha seu imóvel alugado em tempo recorde!",
    advantagesList: [
      "Cadastre as informações do seu imóvel",
      "Aguarde o contato dos nossos especialistas",
      "Acompanhe agendamentos e visitas pelo site",
      "Receba e aceite propostas online",
      "Nosso time cuida da segurança da transação",
      "Assine o contrato digitalmente",
    ],
  },
  TENANT: {
    title: "Fácil para o inquilino",
    description: "Dê adeus às complicações do aluguel tradicional!",
    advantagesList: [
      "Encontre o imóvel dos seus sonhos e agende uma visita",
      "O consultor encontrará você no local e hora marcada",
      "Resultado da análise de crédito em até 1 hora",
      "Conte com nosso time pra ajudar na locação",
      "Você pode optar pela garantia no cartão de crédito",
      "Assine o contrato digital diretamente no site",
    ],
  },
};

export default function AdvantagesSection() {
  const [activeAdvantagesType, setActiveAdvantagesType] = useState(
    ADVANTAGES_ITENS.OWNER
  );

  const onChangeAdvantagesTypeHandle = (index: number) => {
    if (index === 0) {
      setActiveAdvantagesType(ADVANTAGES_ITENS.OWNER);
    } else if (index === 1) {
      setActiveAdvantagesType(ADVANTAGES_ITENS.TENANT);
    }
  };

  return (
    <AdvantagesSectionContainer>
      <svg
        className="left-top"
        width="189"
        height="189"
        viewBox="0 0 189 189"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-10"
          y="94.4265"
          width="147.681"
          height="147.681"
          rx="25"
          transform="rotate(-45 -10 94.4265)"
          fill="#ECF4FF"
        />
      </svg>
      <svg
        className="left-bottom"
        width="137"
        height="269"
        viewBox="0 0 137 269"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-139.466"
          y="134.294"
          width="200.27"
          height="200.27"
          rx="23"
          transform="rotate(-45 -139.466 134.294)"
          stroke="#D8E7FF"
          strokeOpacity="0.5"
          strokeWidth="4"
        />
      </svg>

      <svg
        className="right-top"
        width="137"
        height="269"
        viewBox="0 0 137 269"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-139.466"
          y="134.294"
          width="200.27"
          height="200.27"
          rx="23"
          transform="rotate(-45 -139.466 134.294)"
          stroke="#D8E7FF"
          strokeOpacity="0.5"
          strokeWidth="4"
        />
      </svg>
      <svg
        className="right-middle"
        width="189"
        height="189"
        viewBox="0 0 189 189"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-10"
          y="94.4265"
          width="147.681"
          height="147.681"
          rx="25"
          transform="rotate(-45 -10 94.4265)"
          fill="#ECF4FF"
        />
      </svg>
      <svg
        className="right-bottom"
        width="137"
        height="269"
        viewBox="0 0 137 269"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="-139.466"
          y="134.294"
          width="200.27"
          height="200.27"
          rx="23"
          transform="rotate(-45 -139.466 134.294)"
          stroke="#D8E7FF"
          strokeOpacity="0.5"
          strokeWidth="4"
        />
      </svg>

      <AdvantagesSectionHeaderContainer>
        <AdvantagesSectionHeaderTitleContainer>
          <h3 className="title">Todo mundo sai ganhando!</h3>
          <p className="description">
            Conheça as vantagens de anunciar e alugar com a 7Cantos.
          </p>
        </AdvantagesSectionHeaderTitleContainer>
        <AdvantagesSectionHeaderButtonsContainer>
          <ButtonsTab
            labels={["Anunciante", "Inquilino "]}
            onChange={onChangeAdvantagesTypeHandle}
          />
        </AdvantagesSectionHeaderButtonsContainer>
      </AdvantagesSectionHeaderContainer>

      <AdvantagesSectionCardContainer>
        <AdvantagesSectionCardTextsContainer>
          <AdvantagesSectionCardTextsHeaderContainer>
            <h4 className="title">{activeAdvantagesType.title}</h4>
            <p className="description">{activeAdvantagesType.description}</p>
          </AdvantagesSectionCardTextsHeaderContainer>
          <AdvantagesSectionCardTextsListContainer>
            {activeAdvantagesType.advantagesList.map((item, index) => (
              <AdvantagesSectionCardTextsListItem key={index}>
                <svg
                  width="16"
                  height="8"
                  viewBox="0 0 16 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z"
                    fill="#DD6536"
                  />
                </svg>
                <p className="item">{item}</p>
              </AdvantagesSectionCardTextsListItem>
            ))}
          </AdvantagesSectionCardTextsListContainer>
        </AdvantagesSectionCardTextsContainer>

        <AdvantagesSectionCardImageContainer>
          <img src={MarcosLessa1Image} />
        </AdvantagesSectionCardImageContainer>
      </AdvantagesSectionCardContainer>
    </AdvantagesSectionContainer>
  );
}
