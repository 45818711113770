import styled from 'styled-components';

import ReactSlick from 'react-slick';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 80vw;
    max-width: 80vw;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 9px 3px rgba(229, 233, 240, 0.3);
    border-radius: 25px;
    margin-right: 16px;

    // MEDIUM
    @media(min-width: 768px){
        width: 100%;
        height: 203px;
        flex-direction: row;
        margin-right: 0;
        margin-bottom: 16px;
    }
   
    // LARGE
    @media(min-width: 992px){
        width: 100%;
        height: 203px;
        min-width: 500px;
        flex-direction: row;
    }
`;

export const CoverImageContainer = styled.div`
    width: 100%;
    min-width: 100%;
    max-width: 300px;
    height: 200px;

    img.cover-image {
        background-size: cover;
        object-fit: cover;
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
        width: 100%;
        height: 100%;
    }

    // MEDIUM
    @media(min-width: 768px){
        width: 30%;
        min-width: 30%;
        height: 203px;

        img.cover-image {
            border-top-right-radius: 0px;
            border-bottom-left-radius: 25px;
            border-top-left-radius: 25px;
        }
    }
   
    // LARGE
    @media(min-width: 992px){
        width: 30%;
        min-width: 30%;
        height: 203px;
        
        img.cover-image {
            border-bottom-left-radius: 25px;
            border-top-left-radius: 25px;
        }
    }
`;

export const PhotosSlider = styled(ReactSlick)`
    width: 100%;
    height: 100%;

    .slick-list {
        height: 100%;
    }
    
    .slick-track {
        height: 100%;
    }

    .slick-list {
        border-top-right-radius: 25px;
        border-top-left-radius: 25px;
    }

    .slick-slide {
        height: 100%;
        
        > div {
            height: 100%;
        }

        img {
            height: 100%;
            background-size: cover;
            object-fit: cover;
            border-top-right-radius: 25px;
            border-top-left-radius: 25px;
            width: 100%;
            height: 100%;
        }
    }

    .slick-prev {
        left: 5px;
        z-index: 9;

        &::before {
            opacity: 1;
        }
    }

    .slick-next {
        right: 5px;
        z-index: 9;

        &::before {
            opacity: 1;
        }
    }

    .slick-dots {
        bottom: 10px;
    }

    // MEDIUM
    @media(min-width: 768px){
        .slick-list { 
            border-top-right-radius: 0px;
            border-bottom-left-radius: 25px;
            border-top-left-radius: 25px;
        }

        .slick-slide {
            img {
                border-top-right-radius: 0px;
                border-bottom-left-radius: 25px;
                border-top-left-radius: 25px;
            }
        }
    }
`;

export const PropertyInfosContainer = styled.a`
    
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    // MEDIUM
    @media(min-width: 768px){
        height: 203px;
        flex-direction: row;
    }
   
    // LARGE
    @media(min-width: 992px){
        height: 203px;
        flex-direction: row;
    }
`;

export const PropertyInfoFirstCollContainer = styled.div`
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;

    .city {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        color: #898989;
    }

    .property-name {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
        color: #606060;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .address {
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        color: #898989;
    }

    .property-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #606060;
    }

    .cta {
        display: none;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #606060;

        svg {
            margin-left: 10px;
        }
    }

    .address-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        margin-top: 8px;
    }
    
    // MEDIUM
    @media(min-width: 768px){
        justify-content: space-between;
        
        .cta {
            display: block;
        }
    
        .address-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            margin-top: 8px;
        }
      
    }
   
    // LARGE
    @media(min-width: 992px){
    
    }
`;

export const PropertyInfoSecondCollContainer = styled.div`
    flex-direction: column;
    align-items: center;
    padding: 20px;
    padding-top: 0;
    white-space: nowrap;

    .prices-container {
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 10;
    }

    .rental-price {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 1.2rem;
        color: #6EA6FF;
    }

    .rental-label{
        padding-bottom: 8px;
        color: #6EA6FF;
        font-weight: 500;
        font-size: 0.9rem;
    }

    .price-label-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .price {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            color: #898989;
        }

        .label {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            color: #898989;
        }
    }

    .buttons-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 100%;

        .share {
            margin-left: 10px;
        }
    }
    
    // MEDIUM
    @media(min-width: 768px){
        padding: 20px;
        align-items: flex-end;
        .price-label-container {
            align-items: flex-end;
        }
        .prices-container {
            height: 100%;
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: flex-end;
        }

        .label {
            text-align: right;
        }
        .rental-price {
            font-size: 1.1rem;
            padding: 0;
        }
    }
   
    // LARGE
    @media(min-width: 992px){
        justify-content: start;
    }
`;