import React, { useEffect, useState } from "react";

import { useAuth } from "./../../hooks/auth";

import api from "./../../services/api";

import { useHistory } from "react-router-dom";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";
import LoadingComponent from "./../../components/Loading/";
import VisitPropertyCard from "./../../components/VisitPropertyCard/";

import {
  Container,
  Content,
  EmptyVisitsContainer,
  VisitPropertyCardContainer,
  TitleAndImageContainer,
} from "./styles";

import scheduledVisitsImg from "./../../assets/pages/ScheduledVisits/scheduleImg.svg";
import { useRefresh } from "../../hooks/refresh";

interface IAddress {
  id: number;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
}

interface IPhotos {
  id: number;
  url_l: string;
  url_m: string;
  url_s: string;
}

interface IApiResult {
  id: number;
  schedule_id: number;
  consultant_id: number;
  first_name: string;
  title: string;
  date: string;
  address: IAddress;
  photos: IPhotos[];
}

interface IError {
  message: string;
}

export default function ScheduledVisits() {
  const { user, access_token } = useAuth();
  const { refresh } = useRefresh();

  const history = useHistory();

  const [apiResult, setApiResult] = useState<IApiResult[]>([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<IError>();

  useEffect(() => {
    async function loadApi() {
      if (!user) {
        history.goBack();

        return;
      }

      api.defaults.headers.authorization = `Bearer ${access_token}`;
      try {
        const responseApiVisits = await api.get("/api/client/visits");
        setApiResult(responseApiVisits.data.data);
        console.log("oi");
      } catch (err) {
        setError({
          message: "O usuário não tem autorização para acessar essa tela.",
        });
      }
      setLoading(false);
    }

    loadApi();
  }, [access_token, user, history, refresh]);

  return (
    <Container>
      <Header hasBorder hasSearchBar />

      {!!loading && <LoadingComponent />}

      {!loading && (
        <Content>
          {!!user && (
            <h3>
              {user.type === "inquilino" || !!user.type === false
                ? "INQUILINO"
                : "PROPRIETÁRIO"}
            </h3>
          )}
          <h1>Visitas Agendadas</h1>

          {apiResult.length >= 1 && !error && (
            <VisitPropertyCardContainer>
              {apiResult.map((result) => (
                <div key={result.id}>
                  <VisitPropertyCard
                    property_id={result.id}
                    first_name={result.first_name}
                    title={result.title}
                    date={result.date}
                    address={result.address}
                    photos={result.photos}
                    schedule_id={result.schedule_id}
                    consultant_id={result.consultant_id}
                  />
                </div>
              ))}
            </VisitPropertyCardContainer>
          )}

          {apiResult.length === 0 && !error && (
            <EmptyVisitsContainer>
              <h1>Você ainda não tem nenhuma visita agendada.</h1>
            </EmptyVisitsContainer>
          )}
          {error && (
            <EmptyVisitsContainer>
              <h1>{error.message}</h1>
            </EmptyVisitsContainer>
          )}

          <TitleAndImageContainer>
            {!!user && (
              <h4>
                {user.type === "inquilino" || !!user.type === false
                  ? "INQUILINO"
                  : "PROPRIETÁRIO"}
              </h4>
            )}

            <h1>Visitas Agendadas</h1>

            <img src={scheduledVisitsImg} alt="scheduledVisitsImg" />
          </TitleAndImageContainer>
        </Content>
      )}

      <Footer />
    </Container>
  );
}
