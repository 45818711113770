import { Divider, Grid } from "@material-ui/core";
import React from "react";

export default function OptionsRow({ title, children }: any) {
  return (
    <>
      <Divider style={{ width: "100%", margin: "0.5rem 0" }} />
      <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "0.8rem", textAlign: "left" }}>{title}</p>
      </Grid>
      <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
        {children}
      </Grid>
    </>
  );
}
