import React, { useState, useCallback } from 'react';

import ReactGA from 'react-ga';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import ModalEncaixeDeHorario from './../../components/ModalEncaixeDeHorario/';

import {Container, 
		Content,
		FirstContainer} from './styles';

import scheduleImg from './../../assets/pages/Schedule/scheduleImg.svg';
import FormToSchedulePage from '../../components/FormToSchedulePage';

export default function Schedule(){
	const [openModalEncaixeDeHorario, setOpenModalEncaixeDeHorario] = useState(false);

	ReactGA.initialize('UA-70050339-1');
	ReactGA.pageview(window.location.pathname + window.location.search);

	const handleCloseModalEncaixeDeHorario = useCallback(() => {
		setOpenModalEncaixeDeHorario(false);
	}, []);

	return(
		<Container>
			<Header hasBorder hasSearchBar/>

			{!!openModalEncaixeDeHorario && (
				<ModalEncaixeDeHorario functionToCloseModalEncaixeDeHorario={handleCloseModalEncaixeDeHorario}/>
			)}

			<Content>
				<FirstContainer>
					<h3>AGENDAR VISITA</h3>

					<h1>Seu novo lar pode estar mais perto do que você imagina.</h1>

					<img src={scheduleImg} alt="scheduleImg"/>
					
					<FormToSchedulePage />
				</FirstContainer>

				<img src={scheduleImg} alt="scheduleImg"/>
			</Content>
			
			<Footer />
		</Container>
	);
};