import React from "react";

import { useAuth } from "./../../hooks/auth";

import { Link } from "react-router-dom";

import {
  Container,
  Content,
  CompanyContainer,
  About7CantosContainer,
  RouteOptions,
  Options,
  UserActions,
} from "./styles";

import { FaHeart, FaCalendarAlt } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";

import clube7CantosImg from "./../../assets/components/Menu/7-cantos.png";
import logo7cantosImg from "./../../assets/components/Menu/7-cantos-logo.png";

import CreditCardIcon from "./../../assets/components/Menu/creditCardIcon.svg";
import PaperIcon from "./../../assets/components/Menu/paper.png";
import { VscChecklist } from "react-icons/vsc";
import { MdDashboard } from "react-icons/md";

export default function MenuInquilino() {
  const { logOut, user } = useAuth();

  return (
    <Container>
      <Content>
        <CompanyContainer>
          <About7CantosContainer to="/about-us" id="first-link">
            <img src={logo7cantosImg} alt="7CantosLogo" />

            <p>Sobre a 7Cantos</p>
          </About7CantosContainer>

          <a href="https://clube7cantos.com.br/">
            <img src={clube7CantosImg} alt="clube7Cantos" />
          </a>
        </CompanyContainer>

        <hr style={{ height: "1px", width: "100%", color: "#FFF" }} />

        <RouteOptions hasUser={!!user}>
          {!!user && <h3>INQUILINO</h3>}
          <Options>
            <div>
              <Link
                to={
                  !!user
                    ? "/favorites"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaHeart size={27} color="#FFF" />
                <p>Meus Favoritos</p>
              </Link>
            </div>

            <div>
              <Link
                to={
                  !!user
                    ? "/scheduled-visits"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaCalendarAlt size={27} color="#FFF" />
                <p>Visitas Agendadas</p>
              </Link>
            </div>

            <div>
              <Link
                to={
                  !!user
                    ? "/proposals"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <GiWallet size={27} color="#FFF" />
                <p>Propostas Enviadas</p>
              </Link>
            </div>

            <div>
              <Link
                to={
                  !!user
                    ? "/schedule-process"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <VscChecklist size={27} color="#FFF" />
                <p>Acompanhar Locação</p>
              </Link>
            </div>

            {!!user && (
              <>
                <div>
                  <a href="https://checkout-parceleja.cappta.com.br/7cantos">
                    <img src={CreditCardIcon} alt="creditCardIcon" />
                    <p>Parcelamento de boletos</p>
                  </a>
                </div>

                <div>
                  <Link
                    to={
                      !!user
                        ? "/request-termination"
                        : {
                            pathname: "/login",
                            state: { fromSite: true },
                          }
                    }
                  >
                    <img src={PaperIcon} alt="paper-icon" />
                    <p>Rescisão de Contrato</p>
                  </Link>
                </div>
              </>
            )}
            <div>
              <Link to={"/tenant-dashboard"}>
                <MdDashboard size={27} color="#FFF" />
                <p>Área do {!!user ? 'Locatário' : 'Cliente'}</p>
              </Link>
            </div>
          </Options>
        </RouteOptions>

        {!!user && (
          <hr style={{ height: "1px", width: "100%", color: "#FFF" }} />
        )}

        {!!user && (
          <UserActions>
            {/*<Link to="/settings">CONFIGURAÇÕES</Link>*/}
            <Link to="/me">Editar Perfil</Link>

            <button onClick={logOut}>Sair</button>
          </UserActions>
        )}
      </Content>
    </Container>
  );
}
