import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  width: 80%;
  margin: 7px 0 0;
  @media (min-width: 1441px) {
    width: 82%;
  }
  @media(max-width: 767px){
    width: 100%;
  }
`;

export const IconWrapper = styled.div<{ size: string }>`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 8px;
  margin: auto;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  pointer-events: none;
`;

export const NativeSelect = styled.select`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;

  & option {
    font-size: ${16 / 16}rem;
    padding: 8px 12px;
    &:first-of-type {
      margin-top: 8px
    }
    &:last-of-type {
      margin-bottom: 8px;
    }
  }
`;

export const PresentationalBit = styled.div<{ isSelected: boolean }>`
  font-size: ${16 / 16}rem;
  border-bottom: 1px solid #b6b6b6;
  padding: 2px 8px;
  min-height: 38px;
  display: flex;

  & p {
    line-height: 27px;
    margin: 0 2px;
    color: hsl(0, 0%, ${(props) => (props.isSelected ? "20%" : "50%")});
  }

  ${NativeSelect}:focus + & {
    outline: 5px auto #2684ff;
  }
`;
