import React from 'react';
import { Link } from 'react-router-dom';

import {
  PropertyAnnouncementSectionContainer,
  PropertyAnnouncementSectionTextContainer,
  PropertyAnnouncementSectionImageContainer,
} from './styles';

import PuppetImage from './../../../assets/pages/Home/boneco-1.webp';

export default function PropertyAnnouncementSection() {
  
  return (
    <PropertyAnnouncementSectionContainer>
      <PropertyAnnouncementSectionTextContainer>
        <h3 className='title'>Anuncie na 7Cantos</h3>
        <p className='description'>E desfrute da liberdade e praticidade de acompanhar seus aluguéis de qualquer lugar.</p>
        <a href="https://mkt.7cantos.com/proprietarios" target="_blank">Anunciar imóvel</a>
      </PropertyAnnouncementSectionTextContainer>
      <PropertyAnnouncementSectionImageContainer>
        <img src={PuppetImage} />
      </PropertyAnnouncementSectionImageContainer>
    </PropertyAnnouncementSectionContainer>
  );
};