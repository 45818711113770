import styled from 'styled-components';

import backgroundImageToBigDesktop from './../../assets/pages/AboutUs/pattern.svg';

import newspaper1 from './../../assets/pages/AboutUs/newspaper1.png';
import newspaper2 from './../../assets/pages/AboutUs/newspaper2.png';
import newspaper3 from './../../assets/pages/AboutUs/newspaper3.png';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1200px;

	margin: 64px auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>img{
		width: 210px;
        height: 32px;

		margin: 24px 0 48px;
	}

	>img#triangles-after-videos{
		margin: 64px 0 12px;
	}

	>h1{
		font-size: 24px;
		font-weight: bold;

		margin-bottom: 52px;
	}

	>h2{
		font-size: 26px;
		font-weight: bold;

		margin-bottom: 24px;
	}

	>p{
		width: 70%;

		text-align: center;

		font-size: 12px; 

		margin-bottom: 64px;
	}

	@media(max-width: 767px){
		>h1{
			font-size: 26px;

			text-align: center;
		}

		>img{
			width: 190px;
            height: 30px;
		}

		>p{
			width: 90%;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 28px;
		}

		>h2{
			font-size: 29px;
		}

		>p{
			font-size: 13px;
		}

		>img{
			width: 220px;
			height: 38px;

			margin: 16px 0 40px;
		}

		>img#triangles-after-videos{
			margin: 72px 0 16px;
		}
	}
`;

export const InitialContainer = styled.div`
	max-width: 1000px;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 56px;

	>img{
		width: 310px;
		height: 150px;
	}

	@media(max-width: 767px){
		>img{
			display: none;
		}
	}

	@media(min-width: 1441px){
		max-width: 1120px;

		margin-bottom: 75px;

		>img{
			width: 400px;
			height: 180px;
		}
	}
`;

export const AboutUsInformation = styled.div`
	width: 85%;

	margin-right: 64px;

	>h2{
		font-size: 16px;
		color: #B6B6B6;

		text-align: initial;

		margin-bottom: 22px;
	}

	>h1{
		width: 80%;

		font-size: 24px;
		font-weight: bold;

		margin-bottom: 22px;
	}

	>p{
		width: 94%;

		font-size: 11px;
	}

	>img{
		display: none;
	}

	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		margin-right: 0;

		>h2{
			text-align: center;

			font-size: 17px;

			margin-bottom: 24px;
		}

		>img{
			display: flex;

			width: 275px;
			height: 130px;
		}

		>h1{
			margin: 24px 0;

			font-size: 18px;

			text-align: center;
		}

		>p{
			font-size: 12px;

			text-align: center;
		}
		
	}

	@media(min-width: 1441px){
		>h2{
			font-size: 20px;

			margin-bottom: 46px;
		}

		>h1{
			width: 90%;

			font-size: 28px;

			margin-bottom: 26px;
		}

		>p{
			width: 100%;

			font-size: 13px;
		}
	}
`;

export const MoreInformations = styled.div`
	display: none;
	align-items: center;

	margin-top: 22px;

	>img{
		padding: 6px 32px;

		background: #F9F9F9;

		margin: auto 0;

		width: 105px;
	}

	>a{
		text-decoration: none;

		font-size: 11px;
		font-weight: 500;
		color: #DD6435;

		margin-left: 24px;
	}

	@media(max-width: 767px){
		>img{
			padding: 12px 24px; 
		}

		>a{
			font-size: 14px;

			margin-left: 16px;
		}
	}

	@media(min-width: 1441px){
		>img{
			padding: 9px 32px;

			width: 110px;
		}

		>a{
			font-size: 13px;
		}
	}
`;

export const MeterialInNewspaperContainer = styled.div`
	max-width: 1000px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	margin-bottom: 82px;

	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;
	}

	@media(min-width: 1441px){
		max-width: 1120px;
	}
`;

export const Material = styled.div`
	width: 290px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h3{
		width: 93%;

		font-size: 14px;
		font-weight: bold;

		margin-bottom: 18px;
	}

	>strong{
		width: 95%;

		font-size: 11px;

		margin-bottom: 24px;
	}

	>a{
		text-decoration: none;

		font-size: 11px;
		font-weight: 600;
		color: #DD6435;
	}

	& + div{
		margin-left: 64px;
	}

	@media(max-width: 767px){
		width: 310px;

		>h3{
			font-size: 18px;
		}

		>strong{
			font-size: 12px;
		}

		>a{
			font-size: 14px;
		}

		& + div{
			margin-left: 0;
			margin-top: 32px;
		}
	}

	@media(min-width: 1441px){
		>h3{
			width: 120%;

			font-size: 17px;
		}

		>strong{
			font-size: 13px;
		}

		>a{
			font-size: 13px;
		}

		& + div{
			margin-left: 120px;
		}
	}
`;

export const ImageContainerTvJangadeiro = styled.div`
	width: 290px;
	height: 190px;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	margin-bottom: 24px;

	background-image: url(${newspaper1});
	background-size: 290px 190px;
	background-repeat: no-repeat;

	>img{
		padding: 10px 16px;

		width: 130px;

		background: #F9F9F9;
	}

	@media(max-width: 767px){
		width: 310px;
		height: 215px;

		background-size: 310px 215px;
	}

	@media(min-width: 1441px){
		width: 340px;
		height: 235px;

		background-size: 340px 235px;

		>img{
			padding: 10px 16px;

			width: 140px;
			height: 55px;
		}
	}
`;

export const ImageContainerOPovo = styled.div`
	width: 290px;
	height: 190px;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	margin-bottom: 24px;

	background-image: url(${newspaper2});
	background-size: 290px 190px;
	background-repeat: no-repeat;

	>img{
		padding: 10px 16px;

		width: 130px;

		background: #F9F9F9;
	}

	img#oPovoImg{
		padding: 0 16px;

		width: 100px;
	}

	@media(max-width: 767px){
		width: 310px;
		height: 215px;

		background-size: 310px 215px;
	}

	@media(min-width: 1441px){
		width: 340px;
		height: 235px;

		background-size: 340px 235px;

		img#oPovoImg{
			padding: 0 16px;

			width: 130px;
			height: 55px;
		}
	}
`;

export const ImageContainerCaririEIsso = styled.div`
	width: 290px;
	height: 190px;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	margin-bottom: 24px;

	background-image: url(${newspaper3});
	background-size: 290px 190px;
	background-repeat: no-repeat;

	>img{
		padding: 10px 16px;

		width: 130px;

		background: #F9F9F9;
	}

	img#caririÉIsso{
		width: 100px;
	}

	@media(max-width: 767px){
		width: 310px;
		height: 215px;

		background-size: 310px 215px;
	}

	@media(min-width: 1441px){
		width: 340px;
		height: 235px;

		background-size: 340px 235px;

		img#caririÉIsso{
			padding: 16px 32px;

			width: 140px;
			height: 55px;
		}
	}
`;


export const PeopleTalkAboutUsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	margin-bottom: 72px;

	@media(max-width: 767px){
		flex-direction: column;
	}
`;

export const PeopleTalkAboutUsContent = styled.div`
	width: 250px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>img{
		width: 40px;
		height: 40px;

		margin-bottom: 24px;
	}

	>p{
		width: 90%;

		font-size: 12px;

		text-align: center;

		margin-bottom: 24px;
	}

	>b{
		font-size: 11px;
	}

	& + div{
		margin-left: 48px;
	}

	@media(max-width: 767px){
		>img{
			width: 47px;
			height: 33px;
		}

		>p{
			font-size: 12px;
		}

		>b{
			font-size: 14px;
		}

		& + div{
			margin-left: 0;
			margin-top: 64px
		}
	}

	@media(min-width: 1441px){
		>img{
			width: 45px;
			height: 45px;
		}

		>p{
			width: 95%;

			font-size: 12px;
		}

		>b{
			font-size: 12px;
		}

		& + div{
			margin-left: 64px;
		}
	}
`;

export const VideoSliderContainer = styled.div`
	width: 100%;

	margin-bottom: 24px;

	padding: 48px 0;

	background: #F9F9F9;

	margin: 0 auto;

	.slick-slider{
		margin: 0 auto;

		width: 100%;
	}

	.center .slick-center iframe{
	    opacity: 1;
	    -ms-transform: scale(1);
	    transform: scale(1);
	}

	.center .slick-center p{
		display: flex;

	    opacity: 1;
	    -ms-transform: scale(1);
	    transform: scale(1);
	}

    .slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

    .slick-next{
		right: 30.5%;
		top: 98.5%;
	}

	.slick-prev{
		left: 65.5%;
		top: 98.5%;
	}

	@media(max-width: 767px){
		.slick-slider{
			margin: 0 auto;

			width: 310px;
		}

		.center .slick-center iframe{
		    opacity: 1;
		    -ms-transform: scale(1.1);
		    transform: scale(1.1);
		}

		.center .slick-center p{
			display: flex;

		    opacity: 1;
		    -ms-transform: scale(1.1);
		    transform: scale(1.1);
		}

		.slick-next{
			right: 0;
			top: 96.9%;
		}

		.slick-prev{
			left: 85%;
			top: 96.9%;
		}
	}
`;

export const VideoContent = styled.div`
	iframe {
	    line-height: 100px;
	    margin: 10px;
	    position: relative;
	    text-align: center;
    }

    >p{
    	display: none;

		font-size: 13px;

    	margin-top: 12px;
		margin-left: 10px;

    	text-align: left;

    	position: relative;
    }

	@media(max-width: 767px){
		iframe{
			line-height: 0;
		}

		>p{
			font-size: 12px;

			margin-left: 16px;
		}
	}
`;

export const OfficePicturesContainer = styled.div`
	width: 100%;

	margin-bottom: 24px;

	padding: 0 0 24px; 

	margin: 0 auto;

	.slick-slider{
		margin: 0 auto;

		width: 100%;
	}

	.slick-prev:before,
	.slick-next:before {
	color: #212121;
	}

	.slick-next{
		right: 34.9%;
		top: 97.7%;
	}

	.slick-prev{
		left: 60.9%;
		top: 97.7%;
	}

	.center .slick-center p{
		display: flex;
	}

	@media(max-width: 767px){
		>.slick-slider{
			margin: 0 auto;

			width: 360px;
		}

		.center .slick-center img{
			opacity: 1;
			-ms-transform: scale(1.1);
			transform: scale(1.1);
		}

		.slick-next{
			right: 5%;
			top: 96.6%;
		}

		.slick-prev{
			left: 81%;
			top: 96.6%;
		}
	}

	@media(min-width: 1441px){
		.slick-next{
			right: 37.2%;
			top: 97.7%;
		}

		.slick-prev{
			left: 58.5%;
			top: 97.7%;
		}
	}
`;

export const OfficePictureContent = styled.div`	
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	img{
		height: 280px;
	}

	>p{
		display: none;

		font-size: 15px;
		font-weight: 500;

		margin-top: 12px;
	}

	@media(max-width: 767px){
		img{
			width: 90%;
			height: 200px;
			margin: 0 auto;
		}

		>p{
			display: flex;

			font-size: 14px;

			margin-left: 18px;
		}
	}

	@media(min-width: 1441px){
		img{
			width: 350px;
			height: 260px;
		}
	}
`;

export const WhereIndicate = styled.div`
	@media(min-width: 1024px){
		background: url(${backgroundImageToBigDesktop});
		background-position: 0 -250px;

		padding: 64px 0;
	}

	max-width: 1140px;

	width: 100%;

	margin-top: 64px;

	display: flex;
	align-items: center;
	justify-content: center;
`;

export const TextContainer = styled.div`
	width: 70%;

	background: #FFFFFF;
	
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h1{
		font-size: 30px;
		font-weight: bold;

		margin-bottom: 48px;
	}

	>h2{
		font-size: 20px;

		& + h2{
			margin-top: 12px;
		}
	}

	>p{
		margin-top: 48px;

		text-align: center;

		max-width: 690px;
	}

	@media(max-width: 767px){
		margin-top: 0;

		>h1{
			width: 85%;

			font-size: 18px;

			text-align: center;
		}

		>h2{
			font-size: 14px;
		}

		>p{
			width: 76%;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 34px;
		}

		>h2{
			font-size: 23px;
		}
	}
`;
