import React, { useMemo } from 'react';

import {Container,
		Content,
		PropertyInformationsContainer,
		ButtonsContainer} from './styles';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

interface IPhotos{
	id: number;
	url_l: string;
	url_m: string;
}

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}

interface IProperties{
	title: string;
	photos: IPhotos[];
	address: IAddress;
}

export default function PropertiesRentedCard({title, photos, address}: IProperties){
	const settings = {
		arrows: true,
		infinite: true,
		speed: 500,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	}

	const formatTitle = useMemo(() => {
		if(title.length >= 16){
			return `${title.substr(0, 16)}...`;
		};

		return title;
	}, [title]);

	const formatAddress = useMemo(() => {
		const addressFormatted = `${address.address}, ${address.neighborhood}, ${address.city} - ${address.uf}`;

		if(addressFormatted.length >= 65){
			return `${addressFormatted.substr(0, 65)}...`;
		};

		return `${address.address}, ${address.neighborhood}, ${address.city} - ${address.uf}`;
	}, [address]);

	return(
		<Container>
			<Content>
				<Slider {...settings}>
					{photos.map(photo => (
						<img key={String(photo.id)} src={photo.url_m} alt="propertyImg"/>
					))}
				</Slider>


				<PropertyInformationsContainer>
					<h2>{formatTitle}</h2>

					<p>{formatAddress}</p>
				</PropertyInformationsContainer>

				<ButtonsContainer>
					<h3>ALUGADO</h3>

					{/*<button>VER DOCUMENTOS</button>*/}
				</ButtonsContainer>
			</Content>
		</Container>
	);
};