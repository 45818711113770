import styled from "styled-components";

export const Content = styled.div`
	min-width: 90%;
	display: flex;
	align-items: start;
	justify-content: flex-start;
	gap: 1%;
	padding: 2.25rem 1.75rem;

	border: 1px solid #B6B6B6;
	border-radius: 20px;

	@media(max-width: 767px){
		min-width: 80%;
		flex-direction: column;

		padding: 0 0 1rem;

		>hr{
			display: flex;
			width: 100%;
			height: 1px;

			color: #B6B6B666;
		}
	}
`;

export const PropertyInformartionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5%;
	min-width: 27%;

	>img{
		width: 212px;
		height: 157px;

	}

	@media(max-width: 767px){
		padding: 24px;

		>img{
			width: 121px;
			height: 89px;

			margin-right: 16px;
		}
	}
`;

export const InformationsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		text-transform: uppercase;
		font-weight: 700;
		color: #6EA5FF;
		font-size: 1rem;
	}

	>p{
		font-size: 0.9rem;
		margin: 1em 0;
	}

	>strong{
		font-size: 0.9rem;
		font-weight: 600;
		margin-bottom: 0.5em;
		white-space: nowrap;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 13px;
		}

		>p{
			font-size: 12px;
		}

		>strong{
			font-size: 12px;
		}
	}
`;