import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import api from './../../services/api';

import Header from './../../components/Header';
import SearchFilter, { ISearchCredentials } from './../../components/SearchFilter';

import PropertyCardList from './PropertyCardList';
import SearchMap from './SearchMap';
import MapFilter from './MapFilter';

import { IFilter } from './interfaces';

import {
    Content,
    PropertiesListContainer,
    SearchMapContainer
} from './style';

const INITIAL_FILTER : IFilter = {
    condominium: undefined,
    neighborhood: undefined, 
    city: undefined,
    uf: undefined,
    type: undefined,
    rentValue: undefined,
    area: undefined,
    rooms: undefined,
    garages: undefined,
    bathrooms: undefined,
    page: undefined,
    total: undefined,
    rentValueOrder: undefined,
    furnished: undefined,
    minimal: 1
}

export default function SearchPropertiesMap(){
    const history = useHistory();

    const [mapHasChanged, setMapHasChanged] = useState<boolean>(false);
    const [properties, setProperties] = useState<Array<any>>([]);
    const [filteredProperties, setFilteredProperties] = useState<Array<any>>([]);
    const [filter, setFilter] = useState<IFilter>(INITIAL_FILTER);
    const [loading, setLoading] = useState<boolean>(true);
    const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
    const [selectedProperties, setSelectedProperties] = useState<Array<any>>([]);
    const [disableFilterButton, setDisableFilterButton] = useState<boolean>(true);

    const [valueToShow, setValueToShow] = useState<number[]>([400, 20000]);
	const [areaToShow, setAreaToShow] = useState<number[]>([20, 1000]);

    useEffect( () => {
        initFilters();
    }, [history, window.location.search]);

    const getDistanceFromLatLonInKm = (lat1: number, lon1: number,lat2: number, lon2: number) => {
        var R = 6371; // Radius of the earth in km
        var dLat = deg2rad(lat2-lat1);  // deg2rad below
        var dLon = deg2rad(lon2-lon1);
        var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
        var d = R * c; // Distance in km
        return d;  // distance returned
    }

    const deg2rad = (deg: number) => {
        return deg * (Math.PI/180)
    }

    const findPropertyByNeighborhood = (propertyList: Array<any> = [], neighborhood : any) => {

        if (!neighborhood) return propertyList[0];

        const filteredProperties = propertyList.filter(property => {
            return property?.address?.neighborhood === neighborhood
        });

        return filteredProperties ? filteredProperties[0] : propertyList[0];
    }

    const sortByDistance = (propertyList: Array<any> = [], latitude: number, longitude: number) => {

        try {
            for(let i = 0; i < propertyList.length; i++) {
                let distance = getDistanceFromLatLonInKm(
                    latitude, 
                    longitude,
                    propertyList[i]?.address?.latitude,
                    propertyList[i]?.address?.longitude
                    );
                    propertyList[i].distance = distance;
                }
                
                return propertyList.sort((a, b) => {
                    return a.distance - b.distance
                });
        } catch(e) {
            return propertyList;
        }
    }

    const sortPropertiesByNeighborhood = (propertiesList: Array<any> = [], selectedNeighborhood: any) => {
        if(!selectedNeighborhood) return propertiesList;

        return propertiesList.sort((a: any, b: any) => {
            return a?.address?.neighborhood === selectedNeighborhood ? -1 : b?.address?.neighborhood === selectedNeighborhood ? 1 : 0; 
        });
    }

    const sortPropertiesByRank = (propertiesList: Array<any> = [], selectedNeighborhood: any) => {
        if(!selectedNeighborhood) return propertiesList;
        const filteredProperties = propertiesList.filter(property => property?.address?.neighborhood === selectedNeighborhood);
        if (!filteredProperties || filteredProperties.length === 0) return propertiesList;
        propertiesList.splice(0, filteredProperties.length);
        const sortedProperties = filteredProperties.sort((a, b) =>  b.rank - a.rank);
        return [...sortedProperties, ...propertiesList];
    }

    const findTheNeighborhoodWithMoreProperties = (propertiesList: Array<any> = []) => {
        const property = propertiesList.sort((a, b) =>
            propertiesList.filter(v => v?.address?.neighborhood===a?.address?.neighborhood).length - propertiesList.filter(v => v?.address?.neighborhood===b?.address?.neighborhood).length
        ).pop();

        return property?.address?.neighborhood;
    }

    const sortProperties = (propertiesList: Array<any> = [], selectedNeighborhood: any, selectedCondominium: any) => {
        if (selectedNeighborhood) {
            const firstProperty = findPropertyByNeighborhood([...propertiesList], selectedNeighborhood);
            const sortedByDistance = sortByDistance([...propertiesList], firstProperty?.address?.latitude, firstProperty?.address?.longitude);
            const sortedByByNeighborhood = sortPropertiesByNeighborhood(sortedByDistance, selectedNeighborhood);
            const sortedByRank = sortPropertiesByRank(sortedByByNeighborhood, selectedNeighborhood);
            return sortedByRank;
        } else if (selectedCondominium) {
            return [...propertiesList];
        } else {
            const initialNeighborhood = findTheNeighborhoodWithMoreProperties([...propertiesList]);
            const firstProperty = findPropertyByNeighborhood([...propertiesList], initialNeighborhood);
            const sortedByDistance = sortByDistance([...propertiesList], firstProperty?.address?.latitude, firstProperty?.address?.longitude);
            const sortedByByNeighborhood = sortPropertiesByNeighborhood(sortedByDistance, initialNeighborhood);
            const sortedByRank = sortPropertiesByRank(sortedByByNeighborhood, initialNeighborhood);
            return sortedByRank;
        }
    }

    const initFilters = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const selectedFilters: IFilter = {
            condominium: urlParams.get('condominium'),
            uf: urlParams.get('UF'),
            city: urlParams.get('city'),
            neighborhood: urlParams.get('neighborhoods'), 
            type: urlParams.get('imovelType'),
            rentValue: urlParams.get('values'),
            area: urlParams.get('area'),
            rooms: urlParams.get('rooms'),
            garages: urlParams.get('garages'),
            bathrooms: urlParams.get('bathrooms'),
            furnished: urlParams.get('furnished'),
            total: urlParams.get('total'),
            minimal: 1
        };
        setFilter(selectedFilters);
        getPropertiesHandle(selectedFilters);
        setMapHasChanged(false);
    }

    const applyFilter = (props: ISearchCredentials) => {
        const selectedFilters: IFilter = {
            neighborhood: filter.neighborhood,
            city: filter.city,
            uf: filter.uf,
            type: props.type,
            rentValue: props.rentValue.join(),
            area: props.area.join(),
            rooms: props.rooms,
            total: props.isToSearchTotalValue,
            garages: props.garages,
            bathrooms: props.bathrooms,
            furnished: props.furnished,
            minimal: 1
        };

        setFilter(selectedFilters);
        setOpenFiltersModal(false);
        getPropertiesHandle(selectedFilters);
        setMapHasChanged(false);
        updateUrlWithFilters(selectedFilters);
    }

    const updateUrlWithFilters = (filters: IFilter) => {

        if (!filters || !filters.uf || !filters.city) return;

        let newUrl = `?UF=${filters.uf}&city=${filters.city}`;
        if (filters.neighborhood) newUrl += `&neighborhoods=${filters.neighborhood}`;
        if (filters.condominium) newUrl += `&condominium=${filters.neighborhood}`;
        if (filters.type) newUrl += `&imovelType=${filters.type}`;
        if (filters.rentValue) newUrl += `&values=${filters.rentValue}`;
        if (filters.total) newUrl += `&total=${filters.total}`;
        if (filters.area) newUrl += `&area=${filters.area}`;
        if (filters.rooms) newUrl += `&rooms=${filters.rooms}`;
        if (filters.garages) newUrl += `&garages=${filters.garages}`;
        if (filters.bathrooms) newUrl += `&bathrooms=${filters.bathrooms}`;
        if (filters.furnished) newUrl += `&furnished=${filters.furnished}`;

        history.push(`${window.location.pathname}${newUrl}`);
    }

    const getPropertiesHandle = async (filters: IFilter) => {
        try {
            setLoading(true);
            const requestBody = { ...filters, neighborhood: undefined };
            const { data } = await api.get("/api/client/properties", { params: requestBody });
            console.log(data.data);
            
            const sortedProperties = sortProperties(data.data, filters?.neighborhood, filters?.condominium);
            setProperties(sortedProperties);
            setSelectedProperties([]);
            setLoading(false);
            setDisableFilterButton(false);
        } catch(e) {
            history.push('/404');
        };
    }

    const getNumberOfProperties = () => {
        if (mapHasChanged === true && filteredProperties) {
            return filteredProperties.length;
        }
        return (properties && properties.length) ? properties.length : 0;
    }
    
    const getNumberOfSelectedProperties = () => {
        return (selectedProperties && selectedProperties.length > 0) ? selectedProperties.length : 0;
    }

    const getPropertiesToList = () => {
        if (mapHasChanged && filteredProperties) {
            return filteredProperties;
        }
        return properties;
    }

    const isPropertyInBounds = (propertyLat: number, propertyLng: number, bounds: any) => {
        const eastBound = propertyLng < bounds.NE.lng;
        const westBound = propertyLng > bounds.SW.lng;
        let inLong;
    
        if (bounds.NE.lng < bounds.SW.lng) {
            inLong = eastBound || westBound;
        } else {
            inLong = eastBound && westBound;
        }
    
        var inLat = propertyLat > bounds.SW.lat && propertyLat < bounds.NE.lat;
        return inLat && inLong;
    }

    // MAP EVENTS

    const onMarkerClickHandle = (propertyId: number) => {
        setSelectedProperties([propertyId]);
    }
    const onMapClickHandle = () => {
        setSelectedProperties([]);
    }
    const onMapChangeHandle = (bonds: any) => {
        if (!loading && properties && bonds) {
            setSelectedProperties([]);
            setMapHasChanged(true);
            const propertiesInBonds: Array<any> = properties.filter((property: any) => {
                return isPropertyInBounds(
                    property?.address?.latitude,
                    property?.address?.longitude,
                    bonds
                );
            });
            setFilteredProperties(propertiesInBonds);
        }
    }
    
    return (
        <>
            <Header hasBorder hasSearchBar isToShowSearchBarMobile />
           <SearchFilter 
                open={openFiltersModal} 
                onClose={() => setOpenFiltersModal(false)}
                neighborhoods={filter?.neighborhood} 
                city={filter?.city}
                UF={filter?.uf}
                imovelType={filter?.type}
                priceValues={[valueToShow[0], valueToShow[1]]} 
                areaValues={[areaToShow[0], areaToShow[1]]} 
                functionToSearch={(props: ISearchCredentials) => applyFilter(props)}
                isLoadingApiRequest={loading}
            />
            <Content>
                <PropertiesListContainer>
                    <MapFilter
                        numberOfProperties={getNumberOfProperties()}
                        numberOfSelectedProperties={getNumberOfSelectedProperties()}
                        city={filter?.city}
                        uf={filter?.uf}
                        loading={loading}
                        disableFilterButton={disableFilterButton}
                        onClickFilterButton={() => setOpenFiltersModal(true)}
                    />
                    <PropertyCardList
                        properties={getPropertiesToList()}
                        selectedProperties={selectedProperties}
                        loading={loading}
                    />
                </PropertiesListContainer>
                <SearchMapContainer>
                    <SearchMap
                        loading={loading}
                        properties={properties}
                        onMarkerClick={onMarkerClickHandle}
                        onMapClick={onMapClickHandle}
                        onMapChange={onMapChangeHandle}
                        selectedProperties={selectedProperties}
                    />
                </SearchMapContainer>
            </Content>
        </>
    );
}