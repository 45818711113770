import styled from 'styled-components';

import backgroundImage from './../../assets/pages/Login/objects.svg';

export const Container = styled.div`
	@media(min-width: 768px){
		background: url(${backgroundImage}) no-repeat right 32%;
		background-size: 500px 350px;
	}
`;

export const Content = styled.div`
	max-width: 400px;

	margin: 42px auto 12% auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h2{
		font-size: 20px;
		color: #DD6435;

		margin-bottom: 18px;
	}

	>h1{
		font-size: 30px;
		font-weight: bold;

		margin-bottom: 42px;
	}

	>p{
		font-size: 12px;

		margin-top: 42px;
	}

	a{
		margin-top: 9px;

		font-size: 12px;
		font-weight: bold;
		color: #DD6435;
	}

	@media(max-width: 767px){
		>h2{
			font-size: 17px;
		}

		>h1{
			width: 85%;

			font-size: 26px;
		}

		>p{
			font-size: 13px;
		}

		>a{
			font-size: 13px;
		}
	}

	@media(min-width: 1441px){
		max-width: 460px;

		margin: 52px auto 12% auto;

		>h2{
			font-size: 22px;
		}

		>h1{
			font-size: 35px;
		}

		>p{
			font-size: 14px;
		}

		a{
			font-size: 14px;
		}
	}
`;

export const FinancialReportContainer = styled.div`
	margin-top: 40px;

	background: #F5F5F5;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 32px 0px;

	>p{
		width: 80%;

		font-size: 16px;

		margin-bottom: 16px;

		>strong{
			font-weight: 500
		}
	}

	>a{
		width: 70%;

		font-weight: 500;

		color: #FFFFFF;

		text-decoration: none;

		padding: 12px 0;

		background: #6EA5FF;

		& + a{
			margin-top: 16px;
		}
	}

	@media(max-width: 767px){
		width: 92%;

		>p{
			font-size: 12px;
		}

		>a{
			font-size: 12px;
		}
	}
`;