import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	min-width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 64px 0;

	>h3{
		font-size: 24px;
		font-weight: 500;
		color: #B6B6B6;
	}

	>h1{
		font-weight: bold;

		margin: 8px 0 52px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;
		}
	}
`;

export const ScheduleProcessComponentContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 95%;
	flex-direction: column;

	>div{
		& + div{
			margin-top: 24px;
		}
	}
`;