import React, {
  useEffect,
  useCallback,
  useState,
  ChangeEvent,
  useMemo,
} from "react";

import api from "./../../services/api";

import { useAuth } from "./../../hooks/auth";
import { useHistory, useLocation } from "react-router-dom";

import imageCompression from "browser-image-compression";

import Select from "react-select";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";

import ModalSendDocumentationSuccess from "./../../components/ModalSendDocumentationSuccess";

import {
  Container,
  Content,
  FormsContainer,
  InformationsForm,
  SelectContainer,
  InformationsContainer,
  FilesForm,
  OcupationContainer,
  FilesContainer,
  FilesCheckContainer,
  FileChecked,
} from "./styles";

import { FiPaperclip, FiCheck, FiX } from "react-icons/fi";
import asyncForEach from "../../utils/asyncForEach";
import Loading from "../../components/Loading";

interface IFiles extends Blob {
  name: string;
}

interface IState {
  property_id: string;
  rent_process_id: number;
  isToSendExpressOffer?: boolean;
}

interface IGuarantee {
  id: number;
  name: string;
  description: string;
  documents: string[];
}

export default function SendDocumentation() {
  const { user, access_token } = useAuth();
  const history = useHistory();
  const { state } = useLocation<IState>();

  const [civilState, setCivilState] = useState<string | undefined>("");
  const [profession, setProfession] = useState("");

  const [files, setFiles] = useState<IFiles[]>([]);

  const [errorFileExists, setErrorFileExists] = useState("");
  const [errorInFileType, setErrorInFileType] = useState("");
  const [errorInFileSize, setErrorInFileSize] = useState("");

  const [errorGuaranteeEmpty, setErrorGuaranteeEmpty] = useState(false);
  const [errorCivilStateEmpty, setErrorCivilStateEmpty] = useState(false);
  const [errorProfessionEmpty, setErrorProfessionEmpty] = useState(false);
  const [errorFilesEmpty, setErrorFilesEmpty] = useState(false);

  const [success, setSuccess] = useState(false);

  const [loadingApiRequest, setLoadingApiRequest] = useState(false);
  const [loadingSendDocumentations, setLoadingSendDocumentations] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [guaranteesList, setGuaranteesList] = useState<IGuarantee[]>();
  const [guarantee, setGuarantee] = useState<string | undefined>("");
  const [guaranteeObject, setGuaranteeObject] = useState<
    IGuarantee | undefined
  >();
  const [useDefaultGuarantee, setUseDefaultGuarantee] = useState(false);

  const civilStateOptions = [
    {
      value: "Solteiro(a)",
      label: "Solteiro(a)",
    },
    {
      value: "Casado(a)",
      label: "Casado(a)",
    },
    {
      value: "Separado(a)",
      label: "Separado(a)",
    },
    {
      value: "Divorciado(a)",
      label: "Divorciado(a)",
    },
    {
      value: "Viúvo(a)",
      label: "Viúvo(a)",
    },
  ];

  const guaranteeOptions = useMemo(() => {
    return guaranteesList?.map((guarantee) => {
      return { value: guarantee.name, label: guarantee.name };
    });
  }, [guaranteesList]);

  const setDefaultGuarantee = useCallback(() => {
    const defaultGuarantee = {
      id: 1,
      name: "Default",
      description: "Default",
      documents: [
        "RG e CPF ou CNH",
        "Comprovante de renda",
        "Comprovante de residência",
      ],
    };
    setGuaranteeObject(defaultGuarantee);
    setUseDefaultGuarantee(true);
  }, []);

  useEffect(() => {
    async function loadApi() {
      if (!user) {
        history.goBack();
      }
      const response = await api.get(
        `/api/client/tenant/guarantees/${state.property_id}`
      );
      response.data.data.length > 0
        ? setGuaranteesList(response.data.data)
        : setDefaultGuarantee();

      setLoading(false);
    }
    loadApi();
  }, [user, history]);

  useEffect(() => {
    setGuaranteeObject(() => {
      return !!guarantee
        ? guaranteesList?.find((element) => {
            return element.name === guarantee;
          })
        : undefined;
    });
  }, [guarantee]);

  const handleSelectGuarantee = useCallback(
    (guarantee) => {
      setGuarantee(guarantee);
    },
    [guaranteesList]
  );

  const handleSendFile = useCallback(
    async (event: ChangeEvent<HTMLInputElement>) => {
      setErrorInFileType("");
      setErrorInFileSize("");
      setErrorFileExists("");

      try {
        if (event.target.files) {
          // console.log(event.target.files);
          setLoadingApiRequest(true);

          const orderFiles = [...event.target.files].map((file, index) => ({
            file,
            order: index + 1,
          }));

          const filesWithErrors = orderFiles.some(
            (file) =>
              file.file.type !== "image/jpeg" &&
              file.file.type !== "image/png" &&
              file.file.type !== "application/pdf"
          );

          const checkFileExists = files.some((fileFinded) => {
            return (
              orderFiles
                .map((file) => file.file.name)
                .indexOf(fileFinded.name) >= 0
            );
          });

          if (!!filesWithErrors) {
            setErrorInFileType(
              "Tipo de arquivo não é suportado. Só aceitamos .jpg, .png, .pdf"
            );

            setLoadingApiRequest(false);

            return;
          }

          if (!!checkFileExists) {
            setLoadingApiRequest(false);

            setErrorFileExists("Arquivos com o mesmo nome não são permitidos");

            return;
          }

          await asyncForEach(
            orderFiles.sort((a, b) => a.order - b.order),
            async (file) => {
              if (
                file.file.type === "image/jpeg" ||
                file.file.type === "image/png"
              ) {
                const options = {
                  maxSizeMB: 4,
                  maxWidthOrHeight: 1920,
                  useWebWorker: true,
                };

                const imageFile = file.file;

                const compressedFile = await imageCompression(
                  imageFile,
                  options
                );

                if (compressedFile.size <= 4000000) {
                  const data = new FormData();

                  data.append("document", compressedFile);
                  data.append("rent_process_id", String(state.rent_process_id));
                  data.append("property_id", String(state.property_id));

                  api.defaults.headers.authorization = `Bearer ${access_token}`;
                  const response = await api.post(
                    "/api/client/tenant/documents",
                    data
                  );

                  if (!!response.data.success) {
                    setFiles((prevValue) => [...prevValue, imageFile]);

                    if (file.order === orderFiles.length) {
                      setLoadingApiRequest(false);
                    }
                  }

                  return;
                } else {
                  setErrorInFileSize(
                    "Arquivo muito grande. Por favor, envie um arquivo de até 4mb."
                  );
                  setLoadingApiRequest(false);
                }
              } else if (file.file.type === "application/pdf") {
                const pdfFIle = file.file;

                if (pdfFIle.size <= 4000000) {
                  const data = new FormData();

                  data.append("document", pdfFIle);
                  data.append("rent_process_id", String(state.rent_process_id));
                  data.append("property_id", String(state.property_id));

                  api.defaults.headers.authorization = `Bearer ${access_token}`;
                  const response = await api.post(
                    "/api/client/tenant/documents",
                    data
                  );

                  if (!!response.data.success) {
                    setFiles((prevValue) => [...prevValue, pdfFIle]);

                    if (file.order === orderFiles.length) {
                      setLoadingApiRequest(false);
                    }
                  }
                } else {
                  setErrorInFileSize(
                    "Arquivo muito grande. Por favor, envie um arquivo de até 4mb."
                  );
                  setLoadingApiRequest(false);
                }
              }
            }
          );
        }
      } catch (err) {
        setLoadingApiRequest(false);
      }
    },
    [access_token, files]
  );

  const handleDeleteFile = useCallback(
    async (file_name: string) => {
      try {
        const updateFiles = files.filter((file) => file.name !== file_name);
        setFiles(updateFiles);
      } catch (err) {
        console.log(err);
      }
      // eslint-disable-next-line
    },
    [access_token, files]
  );

  const handleSendDocumentation = useCallback(async () => {
    if (!civilState) {
      setErrorProfessionEmpty(false);
      setErrorFilesEmpty(false);
      setErrorGuaranteeEmpty(false);
      return setErrorCivilStateEmpty(true);
    }

    if (!profession) {
      setErrorCivilStateEmpty(false);
      setErrorFilesEmpty(false);
      setErrorGuaranteeEmpty(false);
      return setErrorProfessionEmpty(true);
    }

    if (!guarantee && !guaranteeObject) {
      setErrorCivilStateEmpty(false);
      setErrorFilesEmpty(false);
      setErrorProfessionEmpty(false);
      return setErrorGuaranteeEmpty(true);
    }

    if (files.length === 0) {
      setErrorProfessionEmpty(false);
      setErrorCivilStateEmpty(false);
      setErrorGuaranteeEmpty(false);
      return setErrorFilesEmpty(true);
    }

    setLoadingSendDocumentations(true);

    setErrorGuaranteeEmpty(false);
    setErrorFilesEmpty(false);
    setErrorProfessionEmpty(false);
    setErrorCivilStateEmpty(false);

    api.defaults.headers.authorization = `Bearer ${access_token}`;

    const response = await api.post("/api/client/tenant/documents/send", {
      material_status: civilState,
      profession: profession,
      express_offer: !!state.isToSendExpressOffer ? 1 : 0,
      rent_process_id: state.rent_process_id,
      guarantee_id: guaranteeObject?.id,
    });

    if (!!response.data.success) {
      setSuccess(true);

      setGuarantee("");
      setCivilState("");
      setProfession("");
      setFiles([]);

      setTimeout(() => {
        history.goBack();
      }, 2500);
    }

    setLoadingSendDocumentations(false);
  }, [
    civilState,
    profession,
    files,
    access_token,
    state.property_id,
    state.isToSendExpressOffer,
  ]);

  return (
    <Container>
      <Header hasSearchBar hasBorder />
      {!!loading && <Loading />}
      {!!success && (
        <ModalSendDocumentationSuccess
          functionToClose={() => setSuccess(false)}
        />
      )}

      <Content>
        <h1>Envio de documentação</h1>

        <h4>Primeiramente, precisamos das seguintes informações:</h4>

        <FormsContainer>
          <InformationsForm>
            {!useDefaultGuarantee && (
              <SelectContainer id="guarantee-select">
                <label>
                  Garantia:{" "}
                  {!!errorGuaranteeEmpty && (
                    <strong style={{ color: "#FF3838" }}>
                      Garantia obrigatória.
                    </strong>
                  )}
                </label>
                <Select
                  placeholder=""
                  value={{
                    value: !!guarantee ? guarantee : "",
                    label: !!guarantee ? guarantee : "",
                  }}
                  options={guaranteeOptions}
                  onChange={(e) => handleSelectGuarantee(e?.value)}
                />
              </SelectContainer>
            )}

            {!useDefaultGuarantee && (
              <OcupationContainer id="ocupation-container">
                <label>Descrição da garantia:</label>
                <label
                  className="description-label"
                  id={!!guaranteeObject ? "filled-label" : "empty-label"}
                >
                  {!!guaranteeObject
                    ? guaranteeObject.description
                    : "Selecione uma garantia"}
                </label>
              </OcupationContainer>
            )}
            <SelectContainer>
              <label>
                Estado Civil:{" "}
                {!!errorCivilStateEmpty && (
                  <strong style={{ color: "#FF3838" }}>
                    Estado Civil obrigatório.
                  </strong>
                )}
              </label>
              <Select
                placeholder=""
                value={{
                  value: !!civilState ? civilState : "",
                  label: !!civilState ? civilState : "",
                }}
                options={civilStateOptions}
                onChange={(e) => setCivilState(e?.value)}
              />

              {/* <select onChange={(e) => setCivilState(e.target.value)}>
								<option value=""></option>
								<option value="Solteiro(a)">Solteiro(a)</option>
								<option value="Casado(a)">Casado(a)</option>
								<option value="Separado(a)">Separado(a)</option>
								<option value="Divorciado(a)">Divorciado(a)</option>
								<option value="Viúvo(a)">Viúvo(a)</option>
							</select> */}
            </SelectContainer>

            <OcupationContainer id="ocupation-container">
              <label>
                Ocupação:{" "}
                {!!errorProfessionEmpty && (
                  <strong style={{ color: "#FF3838" }}>
                    Ocupação obrigatório.
                  </strong>
                )}
              </label>
              <input
                type="text"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </OcupationContainer>

            <InformationsContainer>
              <p>
                Agora precisamos dos seguintes documentos para análise, anexe os
                arquivos clicando no botão <b>Anexar Documentos.</b>
              </p>

              {!guaranteeObject ? (
                <b>Escolha uma garantia</b>
              ) : (
                guaranteeObject?.documents.map((document) => {
                  return <b>- {document}</b>;
                })
              )}
              {/* <b>- RG e CPF ou CNH</b>
              <b>- Comprovante de renda</b>
              <b>- Comprovante de residência</b> */}

              <strong>* Dê preferência para arquivos no formato .pdf.</strong>

              {!loadingSendDocumentations && (
                <button type="button" onClick={handleSendDocumentation}>
                  ENVIAR
                </button>
              )}

              {!!loadingSendDocumentations && <label>Carregando...</label>}
            </InformationsContainer>
          </InformationsForm>

          <FilesForm>
            {!useDefaultGuarantee && (
              <OcupationContainer id="ocupation-container">
                <label>Descrição da garantia:</label>
                <label
                  className="description-label"
                  id={!!guaranteeObject ? "filled-label" : "empty-label"}
                >
                  {!!guaranteeObject
                    ? guaranteeObject.description
                    : "Selecione uma garantia"}
                </label>
              </OcupationContainer>
            )}
            <OcupationContainer id="ocupation-container">
              <label>
                Ocupação:{" "}
                {!!errorProfessionEmpty && (
                  <strong style={{ color: "#FF3838" }}>
                    Ocupação obrigatório.
                  </strong>
                )}
              </label>
              <input
                type="text"
                value={profession}
                onChange={(e) => setProfession(e.target.value)}
              />
            </OcupationContainer>

            <FilesContainer>
              {!!errorInFileType && (
                <p style={{ color: "#FF3838", marginBottom: "8px" }}>
                  {errorInFileType}
                </p>
              )}

              {!!errorInFileSize && (
                <p style={{ color: "#FF3838", marginBottom: "8px" }}>
                  {errorInFileSize}
                </p>
              )}

              {!!errorFileExists && (
                <p style={{ color: "#FF3838", marginBottom: "8px" }}>
                  {errorFileExists}
                </p>
              )}

              {!!errorFilesEmpty && (
                <p style={{ color: "#FF3838", marginBottom: "8px" }}>
                  Envie um arquivo.
                </p>
              )}

              {!loadingApiRequest && (
                <label>
                  <FiPaperclip size={12} color="#DD6435" />
                  <p>ANEXAR DOCUMENTOS</p>
                  <input type="file" multiple onChange={handleSendFile} />
                </label>
              )}

              {!!loadingApiRequest && (
                <label style={{ cursor: "default" }}>
                  <p>Carregando...</p>
                </label>
              )}

              <FilesCheckContainer>
                {files.map((file) => (
                  <FileChecked key={file.name}>
                    <FiCheck size={20} color="#00BF33" />
                    <p>{file.name}</p>

                    <button
                      type="button"
                      onClick={() => handleDeleteFile(file.name)}
                    >
                      <FiX size={12} color="#666666" />
                    </button>
                  </FileChecked>
                ))}
              </FilesCheckContainer>

              {!loadingSendDocumentations && (
                <button type="button" onClick={handleSendDocumentation}>
                  ENVIAR
                </button>
              )}

              {!!loadingSendDocumentations && <strong>Carregando...</strong>}
            </FilesContainer>
          </FilesForm>
        </FormsContainer>
      </Content>
      <Footer />
    </Container>
  );
}
