import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100vh; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 
`;

export const Content = styled.div`
	position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
	z-index: 6px;

	width: 500px;

	margin: 0 auto;

	padding-bottom: 36px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	background: #FFF;

	-webkit-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);

	@media(max-width: 767px){
		width: 330px;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 10px 0 24px;

	background: #DD6435;

	>h1{
		font-size: 26px;
		font-weight: bold;
		color: #FFF;

		margin-top: -10px;
	}

	>button{
		width: 30px;
		height: 30px;

		background: transparent;

		border: 0;

		margin: 0 12px 0 auto;
	}

	@media(max-width: 767px){
		>h1{
			margin-top: 0;

			font-size: 19px;
		}

		>button{
			width: 24px;
			height: 24px;

			>svg{
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const PropertyInformationsContainer = styled.div`
	width: 95%;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 24px 36px;

	margin-bottom: 28px;

	>img{
		margin-left: 18px;

		width: 300px;
		height: 130px;
	}

	@media(max-width: 767px){
		>img{
			width: 136px;
			height: 100px;
		}
	}
`;

export const PropertyLocationContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		color: #6EA5FF;
		font-weight: bold;

		margin-bottom: 24px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 16px;
		}

		>p{
			font-size: 13px;
		}
	}
`;

export const ScheduledVisitsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-weight: bold;

		margin-bottom: 24px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 16px;
		}
	}
`;

export const VisitsDateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	overflow-y: auto;

	height: 200px;
	width: 200px;

	border: 0;

	>p{
		& + p{
			margin-top: 16px;
		}
	}

	@media(max-width: 767px){
		>p{
			font-size: 16px;
		}
	}
`;