import styled from 'styled-components';

import backgroundImage from './../../assets/pages/Login/objects.svg';

export const Container = styled.div`
	@media(min-width: 768px){
		background: url(${backgroundImage}) no-repeat right 25%;
		background-size: 500px 350px;
	}
`;

export const Content = styled.div`
	max-width: 380px;

	margin: 48px auto 128px auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h2{
		font-size: 19px;
		color: #DD6435;

		margin-bottom: 18px;

		@media(max-width: 767px){
			font-size: 17px;
		}
	}

	>h1{
		font-size: 30px;
		font-weight: bold;

		margin-bottom: 42px;

		@media(max-width: 767px){
			font-size: 26px;
		}
	}

	>p{
		font-size: 14px;
		margin-top: 20px;
	}

	a{
		font-size: 14px;
		font-weight: bold;
		color: #DD6435;
	}

	@media(max-width: 767px){
		
	}

	@media(min-width: 1441px){
		max-width: 430px;

		margin: 56px auto 128px auto;

		>h2{
			font-size: 21px;

			margin-bottom: 21px;
		}

		>h1{
			font-size: 35px;
		}
	}
`;