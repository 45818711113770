import styled from "styled-components"

export const Container = styled.div`
   
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    max-width: 1280px;

    margin: 24px auto 11%;

    >h2{
        font-size: 32px;
        font-weight: bold;

        color: #666666;
    }
`;

export const Form = styled.form`
    width: 32%;

    margin-top: 48px;

    .label-validationError{
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-bottom: 4px;

        >p{
            font-size: 14px;
            font-weight: 400;
        }

        >span{
            font-size: 12px;

            margin-left: 4px;
        }

        .error{
            color: #FF3838;

            font-size: 12px;

            margin-left: 4px;
        }
    }

    .input-options{
        width: 100%;

        position: relative;

        >input{
            border: 0;
            border-bottom: 1px solid #B6B6B6;

            margin-bottom: 32px;

            width: 100%;

            padding: 8px 0 8px 8px;
        }
    }

    .MuiFormControl-root{
        width: 100%;

        margin-bottom: 32px;
    }

    >input{
        border: 0;
        border-bottom: 1px solid #B6B6B6;

        margin-bottom: 32px;

        width: 100%;

        padding: 8px 0 8px 8px;
    }

    >button{
        font-size: 14px;
        font-weight: bold;

        color: #FFF;

        width: 100%;
        padding: 12px 0;

        background: #6EA5FF;

        border: 0;
    }

    @media(max-width: 767px){
        width: 80%;
    }
`;

export const OptionsContainer = styled.div`
    position: absolute;

    bottom: -170px;
    left: 0;

    z-index: 24;

    width: 100%;
    height: 200px;

    background-color: #F9F9F9;

    overflow-y: auto;
`;

export const OptionContent = styled.button`
    border: 0;

    font-size: 14px;

    text-align: start;

    background: transparent;

    padding: 12px;

    border-bottom: 1px solid #B6B6B6;

    &:hover{
        background: #E9E9E9;
    }
`;

export const UploadDocumentsLinkContainer = styled.div`
    width: 32%;

    margin-top: 36px;

    padding: 24px;

    background: #F9F9F9;

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    text-align: center;

    font-size: 14px;

    >a{
        margin-top: 16px;

        width: 100%;
        padding: 12px 0;

        background: #DD6435;

        color: #F9F9F9;

        font-size: 14px;
        font-weight: bold;

        text-align: center;
    }

    @media(max-width: 767px){
        width: 80%;
    }
`;