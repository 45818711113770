import React from 'react';

import { Container, Content } from './styles';

interface IStepToIndicateProperty{
	number: string;
	step: string;
	description: string;
	color: string;
}

export default function StepToIndicateProperty({ number, step, description, color }: IStepToIndicateProperty){
	return(
		<Container>
			<Content thisColor={color}>
				<h1>{number}</h1>

				<h3>{step}</h3>

				<p>{description}</p>
			</Content>
		</Container>
	);
};