import styled from 'styled-components';

interface IContent{
	thisColor: string;
}

export const Container = styled.div`

`;

export const Content = styled.div<IContent>`
	width: 290px;
	height: 290px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;

	text-align: center;

	background: #FCFCFC;

	padding: 32px 16px 24px;

	>h1{
		font-size: 64px;
		font-weight: bold;

		color: ${props => props.thisColor};
	}

	>h3{
		font-size: 13px;
		font-weight: bold;

		width: 90%;

		margin: 10px 0 14px;
	}

	>p{
		width: 96%;

		font-size: 11px;
	}

	@media(max-width: 767px){
		>h1{
			font-size: 77px;
		}

		>h3{
			width: 60%;

			font-size: 14px;
		}

		>p{
			width: 80%;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		width: 340px;
		height: 330px;

		padding: 39px 16px 24px;

		>h1{
			font-size: 78px;
		}

		>h3{
			font-size: 15px;
		}

		>p{
			width: 92%;

			font-size: 12px;
		}
	}
`;