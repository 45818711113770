import React from 'react';


import {
    Container,
    TextContainer,
    FilterButtonContainer
} from './style';

export default function MapFilter({
    city,
    uf,
    numberOfProperties = 0,
    numberOfSelectedProperties = 0,
    loading = true,
    disableFilterButton = false,
    onClickFilterButton = () => null
} : any) {

    const getNumberOfPropertiesText = () => {
        if (loading === true) return "Buscando os imóveis...";

        if (numberOfSelectedProperties && numberOfSelectedProperties > 0) {
            if (numberOfSelectedProperties === 1) {
                return "1 imóvel selecionado";
            } else if (numberOfSelectedProperties > 1) {
                return `${numberOfSelectedProperties} imóveis selecionados`;
            }
        }

        if (numberOfProperties === 0) {
            return "Nenhum imóvel encontrado";
        } else if (numberOfProperties === 1) {
            return "1 imóvel encontrado";
        } else {
            return `${numberOfProperties} imóveis encontratos`;
        }
    }

    return (
        <Container>
            <TextContainer>
                <h3 className='title'>Imóveis em {city}, {uf}</h3>
                <p className='propeties-quantity'>{getNumberOfPropertiesText()}</p>
            </TextContainer>
            <FilterButtonContainer>
                <button disabled={disableFilterButton} onClick={onClickFilterButton} className='filter-button'>Filtros</button>
            </FilterButtonContainer>

        </Container>
    );
}