import React from "react";

import { MdDescription, MdLocalAtm } from "react-icons/md";

const iconColor = "#6ea8ff";
const iconSize = "28px";

export const MENU_ITEMS = [
  {
    icon: <MdDescription style={{ color: iconColor, fontSize: iconSize }} />,
    label: "Meu Contrato",
    link: "",
    external: false,
  },
  // {
  //   icon: (
  //     <ConstructionOutlined
  //       color={iconColor}
  //       sx={{ fontSize: iconSize, color: iconColor }}
  //     />
  //   ),
  //   label: "Clube da Manu",
  //   link: "",
  //   external: false,
  // },
  // {
  //   icon: (
  //     <PhotoOutlined
  //       color={iconColor}
  //       sx={{ fontSize: iconSize, color: iconColor }}
  //     />
  //   ),
  //   label: "Baixar Vistoria",
  //   link: "",
  //   external: false,
  // },
  {
    icon: <MdLocalAtm style={{ color: iconColor, fontSize: iconSize }} />,
    label: "Indique & Ganhe",
    link: "/indicar-imovel",
    external: false,
  },
  // {
  //   icon: (
  //     <LocationOnOutlined
  //       color={iconColor}
  //       sx={{ fontSize: iconSize, color: iconColor }}
  //     />
  //   ),
  //   label: "Blog da 7Cantos",
  // },
  // {
  //   icon: (
  //     <MailOutlined
  //       color={iconColor}
  //       sx={{ fontSize: iconSize, color: iconColor }}
  //     />
  //   ),
  //   label: "Notificações do Aluguel",
  // },
];
