import React from "react";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";

import ScheduleProcessComponent from "./../../components/ScheduleProcessComponent/";

import {
  Container,
  Content,
  ScheduleProcessComponentContainer,
} from "./styles";

import { useHistory } from "react-router-dom";
import { useAuth } from "../../hooks/auth";
import { useRefresh } from "../../hooks/refresh";
import { useEffect, useState } from "react";
import api from "../../services/api";
import Loading from "../../components/Loading";

interface IApiResult {
  id: number;
  status: string;
  property_id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  photo: string;
}

export default function ScheduleProcess() {
  const { user, access_token } = useAuth();
  const { refresh } = useRefresh();

  const history = useHistory();

  const [apiResult, setApiResult] = useState<IApiResult[]>([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadApi() {
      if (!user) {
        history.goBack();

        return;
      }

      api.defaults.headers.authorization = `Bearer ${access_token}`;

      const responseApiVisits = await api.get(
        "/api/client/tenant/rent-process"
      );

      setApiResult(responseApiVisits.data.data);
      setLoading(false);
    }

    loadApi();
  }, [access_token, user, history, refresh]);

  if (!!loading) return <Loading />;

  return (
    <Container>
      <Header hasBorder hasSearchBar />
      <Content>
        <h3>INQUILINO</h3>
        <h1>Acompanhamento</h1>

        <ScheduleProcessComponentContainer>
          {apiResult.map((rentInformation) => {
            return (
              <ScheduleProcessComponent rentInformation={rentInformation} />
            );
          })}
        </ScheduleProcessComponentContainer>
      </Content>
      <Footer />
    </Container>
  );
}
