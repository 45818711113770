import React, { useState, useEffect } from 'react';

import { useAuth } from './../../hooks/auth';

import { useHistory } from 'react-router-dom';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import LoadingComponent from './../../components/Loading/';

import {Container,
		Content} from './styles';
import FormToMyAccountPage from '../../components/FormToMyAccountPage';

export default function MyAccount(){
	const { user } = useAuth();
	const history = useHistory();

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(!user){
			history.goBack();
		}

		setLoading(false);

	}, [history, user]);

	return(
		<Container>
			<Header />
			{!!loading && (
				<LoadingComponent />
			)}

			{!loading && (
			<Content>
				<h2>EDITAR PERFIL</h2>

				<FormToMyAccountPage />
				
			</Content>
			)}
			<Footer />
		</Container>
	);
};