import React, { FormEvent, useCallback, useState } from 'react';

import { useAuth } from '../../hooks/auth';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationErrors';

import MaskedInput from 'react-text-mask';

import {SuccessMessageContainer,
        Form,
        InputForm,
        ButtonsContainer} from './styles';

import { FiX } from 'react-icons/fi';

interface IErrors{
	first_name?: string;
	email?: string;
	phone?: string;
	type?: string;
	password?: string;
	password_confirmation?: string;
}

interface IApiErrors{
	first_name?: string[];
	email?: string[];
	phone?: string[];
	type?: string[];
	password?: string[];
	password_confirmation?: string[];
}

export default function FormToMyAccountPage(){
    const { user, updateUser } = useAuth();

	const [loadingApiRequest, setLoadingApiRequest] = useState(false);

    const [first_name, setFirst_Name] = useState(() => {
		if(!!user){
			return (user.first_name);
		}

		return "";
	});
	const [email, setEmail] = useState(() => {
		if(!!user){
			return (user.email);
		}

		return "";
	});
	const [phone, setPhone] = useState(() => {
		if(!!user){
			return (user.phone);
		}

		return "";
	});
	const [password, setPassword] = useState('');
	const [password_confirmation, setPassword_Confirmation] = useState('');

	const [errors, setErrors] = useState({} as IErrors);
	const [apiError, setApiError] = useState({} as IApiErrors);

	const [successMessage, setSuccessMessage] = useState('');

	const handleSubmit = useCallback(async(event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		setLoadingApiRequest(true);

		const data = { first_name, email, phone, password, password_confirmation };

		// eslint-disable-next-line
		const phoneRegExp = /\(\d{2,}\) \d{4,}\-\d{4}/g;

		try{
			const schema = Yup.object().shape({
				first_name: Yup.string().required('Nome obrigatório'),
				email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
				phone: Yup.string().required('Telefone obrigatório').matches(phoneRegExp, 'Número inválido'),
				password: Yup.string().required('Senha Obrigatória').min(6, 'Senha deve conter pelo menos 6 caracteres'),
				password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'Confirmação de senha incorreta')
			});

			await schema.validate(data, {
				abortEarly: false
			});

			const {message} = await updateUser({
				first_name,
				email,
				phone,
				password,
				password_confirmation
			});

			setErrors({});
			setApiError({});

			setLoadingApiRequest(false);
			setSuccessMessage(message);

			setFirst_Name('');
			setEmail('');
			setPhone('');
			setPassword('');
			setPassword_Confirmation('');
		} catch(err){
			if(err instanceof Yup.ValidationError){
				const findedErrors = getValidationErrors(err);

				setLoadingApiRequest(false);
				setErrors(findedErrors);
			} else{
				setLoadingApiRequest(false);
				setErrors({});
				setApiError(err.response.data.errors);
			}
		}
	}, [first_name, email, phone, password, password_confirmation, updateUser]);

	const handleReset = useCallback(() => {
		setFirst_Name('');
		setEmail('');
		setPhone('');
		setPassword('');
		setPassword_Confirmation('');
	}, []);

    return(
        <>
        {!!successMessage && (
            <SuccessMessageContainer>
                <p>{successMessage}</p>
                <button onClick={() => setSuccessMessage('')}><FiX size={16} color="#006403"/></button>
            </SuccessMessageContainer>
        )}	

        <Form onSubmit={handleSubmit} hasError={!!errors.phone || !!apiError.phone}>
            <p>Nome: <span style={{color: '#FF3838'}}>{apiError.first_name ? apiError.first_name[0] : errors.first_name}</span></p>
            <InputForm
                hasError={!!errors.first_name || !!apiError.first_name}
                type="text" 
                name="name"
                value={first_name} 
                onChange={e => setFirst_Name(e.target.value)}/>

            <p>E-mail: <span style={{color: '#FF3838'}}>{apiError.email ? apiError.email[0] : errors.email}</span></p>
            <InputForm 
                hasError={!!errors.email || !!apiError.email}
                type="text" 
                name="email"
                value={email}
                onChange={e => setEmail(e.target.value)}/>

            <p>Telefone: <span style={{color: '#FF3838'}}>{apiError.phone ? apiError.phone[0] : errors.phone}</span></p>
            <MaskedInput
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                id="mask-input"
                type="tel" 
                name="phone"
                value={phone}
                onChange={e => setPhone(e.target.value)}/>

            <p>Atualizar senha: <span style={{color: '#FF3838'}}>{apiError.password ? apiError.password[0] : errors.password}</span></p>
            <InputForm 
                hasError={!!errors.password || !!apiError.password}
                type="password" 
                name="password"
                value={password} 
                onChange={e => setPassword(e.target.value)}/>

            <p>Confirmar senha: <span style={{color: '#FF3838'}}>{apiError.password_confirmation ? apiError.password_confirmation[0]  : errors.password_confirmation}</span></p>
            <InputForm 
                hasError={!!errors.password_confirmation || !!apiError.password_confirmation}
                type="password" 
                name="password_confirmation"
                value={password_confirmation}
                onChange={e => setPassword_Confirmation(e.target.value)}/>

            <ButtonsContainer>
                {!loadingApiRequest && (<button type="submit" style={{background: "#00BF33", color: "#FFF", border: "0"}}>SALVAR</button>)}
                {!!loadingApiRequest && (<label>Carregando...</label>)}
                <button type="reset" onClick={handleReset}>RESETAR</button>
            </ButtonsContainer>
        </Form>
        </>
    );
}