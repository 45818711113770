import React from 'react';

import {LoadingContent} from './styles';

import loadingGif from './../../assets/pages/Search/loading.gif';

export default function Loading(){
	return(
		<LoadingContent>
			<img src={loadingGif} alt="loading"/>
		</LoadingContent>
	);
};