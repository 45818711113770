import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 80vw;
    background-color: #FFFFFF;
    box-shadow: 3px 3px 9px 3px rgba(229, 233, 240, 0.3);
    border-radius: 25px;
    margin-right: 16px;

    // MEDIUM
    @media(min-width: 768px){
        flex-direction: row;
        width: 100%;
        height: 203px;
        margin-right: 0;
        margin-bottom: 16px;
    }

    // LARGE
    @media(min-width: 992px){
        min-width: 500px;
    }
`;

export const CoverImageContainer = styled.div`
    width: 100%;
    min-width: 100%;
    max-width: 300px;
    height: 200px;
    
    // MEDIUM
    @media(min-width: 768px){
        width: 30%;
        min-width: 30%;
        height: 203px;
        max-width: 300px;
    }
`;

export const PropertyInfosContainer = styled.div`
    padding: 20px;
    height: 203px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
`;