import styled from 'styled-components';

import chevronDown from './../../assets/pages/Search/chevronDown.svg';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100%; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 
`;

export const Content = styled.div`
	position: fixed;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
	z-index: 6px;

	width: 500px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	background: #FFF;

	-webkit-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);

	@media(max-width: 767px){
		width: 330px;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 10px 0 24px;

	background: #DD6435;

	>h1{
		font-size: 26px;
		font-weight: bold;
		color: #FFF;

		margin-top: -10px;
	}

	>button{
		width: 30px;
		height: 30px;

		background: transparent;

		border: 0;

		margin: 0 12px 0 auto;
	}

	@media(max-width: 767px){
		>h1{
			margin-top: 0;

			font-size: 19px;
		}

		>button{
			width: 24px;
			height: 24px;

			>svg{
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const Form = styled.form`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 24px 0 42px 0;

	>button{
		border: 0;

		margin-top: 16px;

		font-size: 17px;
		font-weight: bold;
		color: #FFF;

		width: 50%;
		padding: 12px 0;
	}

	button#first-button{
		margin-top: 52px;
	}

	>label{
		width: 50%;

		margin-top: 52px;

		border: 1px solid #666666;

		font-size: 17px;
		color: #666666;

		text-align: center;

		padding: 12px 0;
	}

	@media(max-width: 767px){
		width: 280px;

		>button{
			width: 80%;

			font-size: 13px;
		}

		>label{
			width: 80%;

			margin-top: 52px;

			font-size: 13px;
		}
	}
`;

export const SelectContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	margin-left: 100px;

	p{
		margin-top: 28px;
	}

	select{
		background: transparent;

		padding-left: 12px;

		font-size: 16px;

		width: 80%;
		height: 40px;

		border: 0;
		border-bottom: 1px solid #666666;

		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;

	    background-image: url(${chevronDown});
	    background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .95em auto, 100%;
	}

	.css-2b097c-container{
		width: 80%;
	}

	.css-yk16xz-control{
		border: 0;
		border-bottom: 1px solid #B6B6B6;

		border-radius: 0;
	}

	.css-1okebmr-indicatorSeparator{
		display: none;
	}

	.css-tlfecz-indicatorContainer{
		color: #666666;
	}

	.css-1pahdxg-control{
		box-shadow: 0 0 0 0 transparent;

		&:hover{
			border-color: transparent;
		}
	}

	@media(max-width: 767px){
		margin-left: 0;

		>select{
			width: 100%;
		}

		.css-2b097c-container{
			width: 100%;
		}
	}
`;