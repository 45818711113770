import styled from "styled-components";

interface ITagsContentProps{
    knowLength: number;
}

interface IInputContainer{
    thereIsAnError?: boolean;
}

export const Container = styled.div`
    position: relative;
    margin-top: 42px;
`;
export const SearchFor = styled.div`
    @media(max-width: 767px){
        margin-top: 32px;

        max-width: 294px;
        width: 100%;
    }

    @media(min-width: 768px){
        margin-top: 24px;

        max-width: 478px;
        width: 100%;
    }

    @media(min-width: 1441px){
        margin-top: 9px;
        max-width: 478px;
        width: 100%; 
    }
`;

export const TagsContent = styled.div<ITagsContentProps>`

    position: absolute;
    top: -18px;

    @media(max-width: 767px){
        display: flex;
        overflow: auto;
        white-space: nowrap;
        width: 100%;
        >div{
            & + div{
                margin-left: 12px;
            }
        }
    }

    @media(min-width: 768px){
        display: flex;

        max-width: 478px;

        margin: 0 auto;

        overflow: auto;
        white-space: nowrap;

        >div{
            & + div{
                margin-left: 12px;
            }
        }
    }

    @media(min-width: 1441px){
        display: flex;
        max-width: 707px;
        margin: 0 auto;
        overflow: auto;
        white-space: nowrap;
        >div{
            & + div{
                margin-left: 12px;
            }
        }
    }
`;

export const Tag = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 6px;

    background: #F0F6FF;
    
    border-radius: 24px;

    >p{
        font-size: 11px;
    }

    >button{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 8px;
        

        background: transparent;
        border: 0;

        >svg{
            width: 12px;
            height: 12px;
        }

        @media(max-width: 767px){
            >svg{
                width: 12px;
                height: 12px;
            }
        }

        @media(min-width: 1441px){
            >svg{
                width: 16px;
                height: 16px;
            }
        }
    }

    @media(max-width: 767px){
        >p{
            font-size: 12px;
        }
    }

    @media(min-width: 1441px){
        >p{
            font-size: 14px;
        }
    }
`;

export const Form = styled.form`
    display: flex;

    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    margin-top: 12px;

    >span{
        color: #FF3838;
        font-weight: bold;

        margin-bottom: 8px;
        margin-left: 8px;

        @media(max-width: 767px){
            font-size: 12px;
        }
    }

    >button{
        display: flex;
        height: 50px;
        border-radius: 50px;
        margin-top: 16px;
        align-items: center;
        justify-content: center;
        padding: 8px;
        font-size: 14px;
        width: 100%;
        border: 0;
        background: #6EA5FF;
        font-weight: 600;
        color: #FFF;
        
        @media(max-width: 767px){
            padding: 8px;

            font-size: 14px;
        }

        @media(min-width: 1441px){
            padding: 10px;

            font-size: 16px;
        }
    }

    @media(max-width: 767px){
        margin-top: 16px;
    }
`;

export const InputContainer = styled.div<IInputContainer>`
	border: 1px solid ${props => !!props.thereIsAnError ? "#FF3838" : "#B6B6B6"};
    border-radius: 50px;
    height: 50px;
    width: 100%;
    background-color: white;
	padding: 11px 11px 11px 16px;

	display:flex;
	align-items: center;
	justify-content: center;

    @media(max-width: 767px){
        padding: 10px 0 10px 10px;

        >svg{
            width: 16px;
            height: 16px;
        }

        input{
            width: 260px;
            background: #FFF;
            border: 0;
            font-size: 18px;

            margin-left: 6px;

            padding-right: 14px;

            font-size: 12px;

            &::placeholder{
                font-size: 12px;
            }
        }
    }

	@media(min-width: 768px){
        input{
            width: 425px;
            background: #FFF;
            border: 0;
            font-size: 14px;

            margin-left: 15px;

            &::placeholder{
                font-size: 15px;
            }
        }
    }

    @media(min-width: 1441px){
        padding: 14px 10px 14px 16px;

        >svg{
            width: 20px;
            height: 20px;
        }

        input{
            width: 464px;
            font-size: 16px;

            &::placeholder{
                font-size: 16px;
            }
        }
    }
`;

export const AddressesOptions = styled.div`
    overflow: auto;
    overflow: overlay;
    background: #FFFFFF;
    position: absolute;
    z-index: 999;
    width: 100%;
    margin-top: 10px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;

    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.15), 0px 4px 32px 0px rgba(0,0,0,0.1);
`;

export const AddressesOptionsContent = styled.div`
    @media(max-width: 767px){
        padding: 6px 0px;
        width: 100%;
        min-height: 60px;
        max-height: 200px;
    }

    @media(min-width: 768px){
        padding: 6px 0px;
        width: 100%;
        min-height: 60px;
        max-height: 200px;
    }

    @media(min-width: 1441px){
        padding: 6px 0px;
        width: 100%;
        min-height: 60px;
        max-height: 200px;
    }
`;

export const EmptyAddressesOptions = styled.div`

    padding: 10px 20px;
    font-size: 16px;

`;

export const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
`;

export const OptionsContant = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    
    &:hover, &:focus {
        background-color: rgb(248,249,245);
    }

    >svg {
        margin-left: 20px;
        color: #ccc;
    }

    >button {
        border: 0;
        background: transparent;
        width: 100%;
        text-align: left;
        padding: 14px 10px;

        >p{
            font-size: 14px;
        }

        & + button{
            margin-top: 4px;
        }

        @media(max-width: 767px){
            text-align: initial;
            font-size: 14px;
        }

        @media(min-width: 1441px){
            >p {
                font-size: 16px;
            }
        }
    }
`;