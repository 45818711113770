import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1366px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>img{
		width: 200px;
		height: 100px;

		margin: 64px 0;
	}

	@media(max-width: 767px){
		>img{
			width: 190px;
            height: 30px;
		}
	}
`;

export const InitialContainer = styled.div`
	width: 100%;
	height: 500px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	-webkit-box-shadow: inset 0px 0px 300px 0px rgba(0,0,0,0.9);
	-moz-box-shadow: inset 0px 0px 300px 0px rgba(0,0,0,0.9);
	box-shadow: inset 0px 0px 300px 0px rgba(0,0,0,0.9);

	>h1{
		font-size: 70px;
		font-weight: bold;
		color: #FFF;
	}

	>h3{
		font-size: 22px;
		font-weight: regular;
		color: #FFF;

		margin-top: 12px;
	}

	a#available-properties-link{
		padding: 12px 24px;

		background: #DD6435;

		margin: 32px 0 64px;
	}

	>a{
		text-decoration: none;

		font-weight: 500;
		color: #FFF;
	}

	@media(max-width: 767px){
		height: 306px;

		>h1{
			font-size: 34px;
			text-align: center;
		}

		>h3{
			font-size: 14px;
		}

		a#available-properties-link{
			font-size: 13px;

			margin: 16px 0 32px;
		}

		>a{
			font-size: 14px;
		}
	}
`;

export const LocalizationAndDescriptionContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	margin: 64px 0;

	>p{

		text-align: center;

		width: 1200px;
	}

	@media(max-width: 767px){
		>p{
			width: 80%;

			font-size: 12px;
		}
	}
`;

export const LocalizationContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	>p{
		font-size: 24px;
		font-weight: 500;

		margin-left: 8px;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>svg{
			width: 25px;
			height: 25px;
		}

		>p{
			width: 85%;

			margin-top: 12px;

			font-size: 18px;

			text-align: center;
		}
	}
`;

export const CondominiumInformationsContainer = styled.div`
	width: 80%;

	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 64px;

	@media(max-width: 767px){
		width: auto;

		align-items: flex-start;
		flex-direction: column;
	}
`;

export const SizeContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-right: 2px solid #66666666;

	padding-right: 18px;

	>p{
		margin-left: 8px;

		font-size: 24px;
	}

	@media(max-width: 767px){
		width: 100%;

		justify-content: flex-start;

		border-right: 0;
		padding-right: 0;

		border-bottom: 2px solid #DD6435;
		padding-bottom: 12px;

		>svg{
			width: 18px;
			height: 18px;
		}

		>p{
			font-size: 18px;
		}
	}
`;

export const RoomsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	border-right: 2px solid #66666666;

	padding: 0 18px;

	>p{
		margin-left: 8px;

		font-size: 24px;
	}

	@media(max-width: 767px){
		border-right: 0;
		border-bottom: 2px solid #DD6435;

		padding: 12px 0;

		>svg{
			width: 18px;
			height: 18px;
		}

		>p{
			font-size: 18px;
		}
	}
`;

export const GarageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding-left: 18px;

	>p{
		margin-left: 8px;

		font-size: 24px;
	}

	@media(max-width: 767px){
		padding-left: 0;
		padding-top: 12px;

		>svg{
			width: 18px;
			height: 18px;
		}

		>p{
			font-size: 18px;
		}
	}
`;

export const CondominiumFacilitiesContainer = styled.div`
	width: 100%;

	background: #F9F9F9;

	padding: 32px; 0;

	>h1{
		font-weight: bold;

		text-align: center;
	}

	>p{
		text-align: center;

		margin: 32px 0 56px;
	}

	.slick-slider{
		width: 560px;

		margin: 0 auto;
	}

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

	.slick-prev, 
	.slick-next{
		background: #FFF;
		position: absolute;
	    top: 50%;
	    z-index:1;
	    border-radius: 50%;
	}

	.slick-next{
		right: 12px;
		top: 96.9%;
	}

	.slick-prev{
		left: 89%;
		top: 96.9%;
	}

	img{
		width: 100%;
		height: 320px;

		margin-bottom: 24px;
	}

	@media(max-width: 767px){
		>h1{
			font-size: 18px;
		}

		>p{
			font-size: 12px;
		}

		.slick-prev{
			left: 80%;
		}

		.slick-slider{
			width: 310px;
		}

		img{
			width: 310px;
			height: 188px;
		}

		>div{
			>p{
				font-size: 14px;
			}
		}
	}
`;

export const PropertiesToRentContainer = styled.div`
	max-width: 1200px;

	margin: 0 auto 92px;

	>h1{
		font-weight: bold;

		margin-bottom: 52px;

		text-align: center;
	}

	>.slick-slider{
		width: 1200px;

		margin-left: 32px;
	}

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

    .slider.slick-prev,
    .slider.slick-next {
      position: absolute;
      top: 50%;
      z-index:1;
      border-radius: 50%;
      background: #fff;
    }

    .uUTcI,
    .slick-next{
    	right: 56px;
    }

    @media(max-width: 767px){
    	>h1{
    		font-size: 18px;
    	}

    	>.slick-slider{
    		display: none;
    	}
    }
`;

export const PropertiesToRentToMobile = styled.div`
	display: none;

	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		>div{
			& + div{
				margin-top: 24px;
			}
		}
	}
`;

export const NeighborhoodConvenienceContainer = styled.div`
	max-width: 1200px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>img{
		display: none;
	}

	>h1{
		font-weight: bold;
	}

	@media(max-width: 767px){
		>img{
			display: flex;

			width: 190px;
            height: 30px;

            margin-bottom: 24px;
		}

		>h1{
			font-size: 18px; 
		}
	}
`;

export const ConveniencesContainer = styled.div`
	margin-top: 32px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	>hr{
		height: 200px;
		width: 2px;
		color: #66666666;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>hr{
			width: 100%;
			height: 1px;
		}

		>div{
			margin: 32px 0;
		}
	}
`;

export const AnotherPropertiesContainer = styled.div`
	max-width: 1200px;

	margin: 128px auto;

	>h1{
		font-weight: bold;

		margin-bottom: 52px;

		text-align: center;
	}

	>.slick-slider{
		width: 1200px;

		margin-left: 32px;
	}

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

    .slider.slick-prev,
    .slider.slick-next {
      position: absolute;
      top: 50%;
      z-index:1;
      border-radius: 50%;
      background: #fff;
    }

    .uUTcI,
    .slick-next{
    	right: 15px;
    }

    @media(max-width: 767px){
    	margin: 96px 0 128px;

    	>h1{
    		font-size: 16px;
    	}

    	>.slick-slider{
    		width: 315px;
    	}

    	>.slick-dots li{
    		bottom: -25px;
    	}

    	.slick-next{
    		right: 0;
    	}
    }
`;

export const Title = styled.div`
 	>h1{
	    font-size: 34px;

		margin-bottom: 52px;
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 34px;

			margin-bottom: 52px;
		}
	}
`;