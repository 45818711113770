import styled from "styled-components";

interface OrangeLineContainerProps {
  lineWidth?: string;
  leftDist?: string;
  lineHeight?: string;
}
interface VerticalGrayLineContainerProps {
  leftDist?: string;
}

export const GrayLineContainer = styled.div<VerticalGrayLineContainerProps>`
    position: absolute;
    z-index: 0;
    top: 0.73rem;
    width: 75%;
    border: 1px solid #b6b6b6;

    @media(max-width: 767px) {
        top: 1rem;
        left: ${props => props.leftDist};
        width: 1px;
        height: 80%;
    }
`;
export const OrangeLineContainer = styled.div<OrangeLineContainerProps>`
    position: absolute;
    z-index: 0;
    top: 0.73rem;
    left: 12.5%;
    width: ${props => props.lineWidth};
    border: 1px solid #dd6434;
    @media(max-width: 767px) {
        top: 1rem;
        left: ${props => props.leftDist};
        width: 1px;
        height: ${props => props.lineHeight};
    }
`;

export const StepsContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 1rem;
    @media(max-width: 767px) {
        flex-direction: column;
        align-items: center;
        gap: 0.75rem;
    }
`;
