import React from 'react';

import {Container,
		Content} from './styles';

interface IStepToAnunciarImovel{
	image: string;
	title: string;
	description: string;
	notIsFirst?: boolean;
}

export default function StepToAnunciarImovel({ image, title, description, notIsFirst }: IStepToAnunciarImovel){
	return(
		<Container>
			<Content notIsFirst={notIsFirst}>
				<img src={image} alt="stepImg"/>

				<h3>{title}</h3>

				<p>{description}</p>

			</Content>
		</Container>
	);
};