import styled from 'styled-components';

import chevronDown from './../../assets/pages/Search/chevronDown.svg';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100%; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 
`;

export const Content = styled.div`
	position: fixed;
    top: 45%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
	z-index: 6px;

	width: 500px;

	margin: 0 auto;

	padding-bottom: 32px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	background: #FFF;

	@media(max-width: 767px){
		width: 330px;
	}
`;

export const TitleContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 10px 0 24px;

	background: #DD6435;

	>h1{
		font-size: 26px;
		font-weight: bold;
		color: #FFF;

		margin-top: -10px;
	}

	>button{
		width: 30px;
		height: 30px;

		background: transparent;

		border: 0;

		margin: 0 12px 0 auto;
	}

	@media(max-width: 767px){
		>h1{
			margin-top: 0;

			font-size: 19px;
		}

		>button{
			width: 24px;
			height: 24px;

			>svg{
				width: 24px;
				height: 24px;
			}
		}
	}
`;

export const FormContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>button{
		background: #00BF33;

		border: 0;

		font-weight: bold;
		color: #FFF;

		padding: 12px 32px;
	}

	>p{
		margin-top: 24px;
		max-width: 240px;

		font-size: 14px;

		text-align: center;
	}

	@media(max-width: 767px){
		>button{
			font-size: 13px;
		}

		>p{
			font-size: 12px;
		}
	}
`;

export const Form = styled.form`
	width: 400px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 32px;

	>select{
		margin-bottom: 24px;

		background: transparent;

		padding-left: 12px;

		font-size: 16px;

		width: 100%;
		height: 40px;

		border: 0;
		border-bottom: 1px solid #666666;

		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;

	    background-image: url(${chevronDown});
	    background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .95em auto, 100%;
	}

	@media(max-width: 767px){
		width: 320px;

		>p{
			font-size: 13px;
		}
	}
`;