import OptionsRow from "./OptionsRow";
import React from "react";
import { FaChevronDown } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import formatValue from "../../../../utils/formatValue";

interface IRentByDate {
  date: string;
  final_value: number;
  totalReceived: number;
}

interface IBills {
  bills: IRentByDate[];
}

export default function OptionsAccordion({ bills }: IBills) {
  return (
    <div style={{ width: "100%" }}>
      {bills.map((bill) => {
        return (
          <Accordion key={`${bill.date}:${bill.final_value}`}>
            <AccordionSummary expandIcon={<FaChevronDown size="14px" />}>
              <p style={{ textAlign: "left" }}>{bill.date}</p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <OptionsRow title="Total Recebido">
                  <p
                    style={{
                      fontSize: "0.8rem",
                      textAlign: "right",
                      color: "#0ca11a",
                    }}
                  >
                    {formatValue(bill.final_value)}
                  </p>
                </OptionsRow>
                <OptionsRow title="Total Acumulado">
                  <p style={{ fontSize: "0.8rem", color: "#0ca11a" }}>
                    {formatValue(bill.totalReceived)}
                  </p>
                </OptionsRow>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
