import React, { useState, useEffect } from 'react';
import Header from './../../components/Header/';
import Footer from './../../components/Footer/';
import { useAuth } from './../../hooks/auth';
import { hasScheduleRequest } from './../../utils/scheduleUtils';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { Container, Content } from './styles';
import FormToRegisterPage from '../../components/FormToRegisterPage';


interface IState{
	prevPath: string | undefined;
	username: string | undefined;
	user_email: string | undefined;
	user_password: string | undefined;
	previousScreen?: 'property';
	propertyId?: string;
	consultant_id?: string;
}

export default function Register(){
	const { user } = useAuth();
	const history = useHistory();
	const { state } = useLocation<IState>();
	const [redirectToSchedule, setRedirectToSchedule] = useState(true);

	useEffect(() => {
		setRedirectToSchedule(hasScheduleRequest());
		if(!!user && !!state){
			if(state.prevPath === '/login' && !state.previousScreen){
				history.push('/');
			} else{
				history.goBack();
			}	
		} else if(!!user && !state){
			history.goBack();
		}
	}, []);

	return(
		<Container>
			<Header />
			<Content>
				<h2>{redirectToSchedule ? 'SÓ MAIS UMA COISA:' : 'FAÇA SEU CADASTRO'}</h2>
				<h1>{redirectToSchedule ? 'Para confirmar o agendamento, faça seu cadastro.' : 'O primeiro passo para encontrar seu canto.'}</h1>

				<FormToRegisterPage />

				<p>
					Já é cadastrado? <Link to={{ pathname: "/login", state: {fromSite: true}}}>Entre aqui!</Link>
				</p>
			</Content>
			<Footer />
		</Container>
	);
};