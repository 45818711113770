import React, { InputHTMLAttributes } from 'react';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

interface ICurrencyInput extends InputHTMLAttributes<HTMLInputElement>{
}

export default function CurrencyInput({ ...inputProps }: ICurrencyInput){
	const defaultMaskOptions = {
	  prefix: 'R$ ',
	  suffix: '',
	  includeThousandsSeparator: true,
	  thousandsSeparatorSymbol: '',
	  allowDecimal: true,
	  decimalSymbol: ',',
	  decimalLimit: 2, // how many digits allowed after the decimal
	  //integerLimit: 7, // limit length of integer numbers
	  allowNegative: false,
	  allowLeadingZeroes: false,
	}

	const currencyMask = createNumberMask({
  	...defaultMaskOptions,
	})

	return <MaskedInput mask={currencyMask} {...inputProps} />
}
