import { FaChevronDown } from "react-icons/fa";
import OptionsRow from "./OptionsRow";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import React from "react";
import { OutlinedButton } from "./style";

interface IOffer {
  date: string;
  tenant: string;
  address: string;
  status: string;
  tenant_id: number;
  property_id: number;
}

interface IOffers {
  offers: IOffer[];
}

export default function OptionsAccordion({ offers }: IOffers) {
  const history = useHistory();
  const StatusCell = ({ status, propertyId, tenantId }: any) => {
    let statusText;
    let textColor;
    switch (status) {
      case "ACCEPT":
        statusText = "Aceito";
        textColor = "#0ca11a";
        break;
      case "CANCELED":
        statusText = "Cancelado";
        textColor = "#b91c1b";
        break;
      default:
        statusText = "Pendente";
        textColor = "#debe0b";
        break;
    }
    return (
      <OutlinedButton
        style={{
          color: textColor,
          border: `1px solid ${textColor}`,
        }}
        onClick={() =>
          history.push(`/proposal-received/${propertyId}`, {
            client_id: tenantId,
          })
        }
      >
        {statusText}
      </OutlinedButton>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {offers.map((offer) => {
        return (
          <Accordion
            key={`${offer.date}, ${offer.property_id}, ${offer.tenant_id}`}
          >
            <AccordionSummary expandIcon={<FaChevronDown fontSize="14px" />}>
              <p style={{ textAlign: "left" }}>{offer.tenant}</p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <OptionsRow title="Data">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {offer.date}
                  </p>
                </OptionsRow>
                <OptionsRow title="Endereço">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {offer.address}
                  </p>
                </OptionsRow>
                <OptionsRow title="Status">
                  <StatusCell
                    status={offer.status}
                    propertyId={offer.property_id}
                    tenantId={offer.tenant_id}
                  />
                </OptionsRow>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
