import styled from 'styled-components';

interface ICardCover {
    imgUrl: string;
}

export const CardContainer = styled.div`

    width: 100%;
    min-width: 240px;
    height: 312px;
    border-radius: 25px;
    background-color: #FFF;
    box-shadow: 3px 3px 9px 3px rgba(229, 233, 240, 0.3);
`;

export const CardCover = styled.div<ICardCover>`

    background: url(${(props)=>props.imgUrl});
    background-size: cover;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    width: 100%;
    height: 185px;
`;

export const CardInfoContainer = styled.div`

   display: flex;
   flex-direction: column;
   padding: 13px 22px;
`;

export const CardPropertyCityAndName = styled.div`

   display: flex;
   flex-direction: row;
   justify-content: space-between;

   p.city {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    color: #898989;
   }

   p.price {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #6EA6FF;
   }

`;

export const CardPropertyName = styled.div`

    margin-top: 11px;

    p.name {
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;
        color: #606060;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
    }   
`;

export const CardPropertyAddress = styled.div`

    display: flex;
    flex-direction: row;
    margin-top: 6px;

    p.address {
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #898989;
        padding-left: 6px;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
        overflow: hidden;
    }   
`;

export const CardPropertyGeneralInfo = styled.div`

    margin-top: 19px;

    p.infos {
        font-family: Montserrat;
        font-size: 10px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0em;
        text-align: left;
        color: #606060;
    }   
`;