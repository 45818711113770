import React, { useMemo } from 'react';

import {Container, 
		Content, 
		TitleContainer,
		ButtonsContainer,
		PropertyValuesAndVisitButtonContainer,
		PropertyValueContainer,
		ValuesContainer,
		AccountValuesContainer,
		TotalContainer,
		InformationsAboutProperty,
		PropertyCharacteristics,
		WhatLocalHasContainer,
		WhatLocalHasCharacteristics,
		HowToRentImovelContainer,
		HowToRentImovelTexts} from './styles';

import formatValue from './../../utils/formatValue';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import { Link, useHistory } from 'react-router-dom';

import GoogleMapsReact from 'google-map-react';
import Marker from './../Marker/';

import { FiCheck, FiShare2, FiHeart } from 'react-icons/fi';
import {RiHotelBedLine, RiDragMove2Fill} from 'react-icons/ri';
import {BiCar, BiBath} from 'react-icons/bi';
import {MdPets} from 'react-icons/md';
import {FaCouch, FaHeart} from 'react-icons/fa';

import { Icon } from '@iconify/react';
import outlineElevator from '@iconify/icons-ic/outline-elevator';

import mesaDeTrabalhoImg from './../../assets/components/PropertyInformationsToImovelPage/mesa-de-trabalho.svg';
import {
	CgSmartHomeCooker,
	IoIosWater
} from "react-icons/all";

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
	complement: string;
	latitude: string;
	longitude: string;
	number: number;
}

interface IPhotos{
	id: number;
	url_l: string;
}

interface IApiResults{
	id: number;
	consultant_id: number;
	address: IAddress;
	photos: IPhotos[];
	title: string;
	description: string;
	allow_pets: number;
	water_included: number;
	gas_included: number;
	area: number;
	rooms: number;
    bathrooms: number;
    suites: number;
    garages: number;
	garbage_tax: string;
    rent_value: string;
    condominium_value: string;
    condominium_free: number;
    iptu_value: string;
    is_furnished: number;
    is_nearby_clinics: number;
    is_nearby_major_roads: number;
    is_nearby_schools: number;
    is_nearby_mall: number;
    is_nearby_public_transport: number;
    is_rented: number;
    has_ac: number;
    has_backyard: number;
    has_porch: number;
    has_storage: number;
    has_cable_tv: number;
    has_bbq: number;
    has_elevator: number;
    has_garden: number;
    has_gourmet: number;
    has_gym: number;
    has_party: number;
    has_playground: number;
    has_pool: number;
    has_home_cinema: number;
    has_tennis_court: number;
    has_sports: number;
    has_sauna: number;
    has_spa: number;
    has_sea_view: number;
    has_gourmet_porch: number;
    has_24h_concierge: number;
    has_game_room: number;
    user: boolean;
    isFavorite: boolean;
    loadingFavorite: boolean;
    loadingGif: string;
	isHashParam: boolean,
    functionToFavorite(): void;
}

function hasGarbageTax(tax: string | null) {
	if (tax === "0.00") return false;
  
	return !!tax;
  }

export default function PropertyInformationsToImovelPage({ id, consultant_id, address, photos, title, description,allow_pets, water_included,gas_included,area, rooms, bathrooms, suites, garages, garbage_tax, rent_value, condominium_value, condominium_free, iptu_value, is_furnished, is_nearby_clinics, is_nearby_major_roads, is_nearby_schools, is_nearby_mall, is_nearby_public_transport, is_rented, has_ac, has_backyard, has_porch, has_storage, has_cable_tv, has_bbq, has_elevator, has_garden, has_gourmet, has_gym, has_party, has_playground, has_pool, has_home_cinema, has_tennis_court, has_sports, has_sauna, has_spa, has_sea_view, has_gourmet_porch, has_24h_concierge, has_game_room, user, isFavorite, loadingFavorite, loadingGif, isHashParam, functionToFavorite }: IApiResults){
	const history = useHistory();
	
	const settings = {
		arrows: true,
		infinite: true,
		speed: 500,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	}

	const account = useMemo(() => {
		const iptuValue = iptu_value ?? 0;
		const garbageTax = hasGarbageTax(garbage_tax) ? garbage_tax : '0'; 

		return  parseFloat(condominium_value) + parseFloat(iptuValue) + parseFloat(rent_value) + parseFloat(garbageTax)
	}, [iptu_value, condominium_value, rent_value]);

	return (
		<Container>
			<Content>
				<TitleContainer>
					<h1>{title}</h1> 
					<h4>CÓD. {id}</h4>

					<ButtonsContainer>
						{!loadingFavorite ? (<button onClick={functionToFavorite}>{!!isFavorite ? (<FaHeart size={21} color="#FF3838"/>) : (<FiHeart size={21} color="#666666"/>)}</button>) : (<img src={loadingGif} alt="loadingGif"/>)}
						<a href={`https://api.whatsapp.com/send?text=${window.location.origin}/imovel/${id}`}><FiShare2 size={21} color="#666666"/></a>
					</ButtonsContainer>
				</TitleContainer>

				<p>{address.address}, {!!address.number ? `${address.number}, `: ''} {address.neighborhood}{address.complement !== null && `, ${address.complement}`} | {address.city} - {address.uf}</p>

				<Slider {...settings}>
					{photos.map((photo, index) => (
						<img key={photo.id} src={photo.url_l} alt={String(photo.id)} onClick={() => history.push(`${window.location.pathname}?gallery=${index + 1}`)} />
					))}
				</Slider>

				{!isHashParam && <PropertyValuesAndVisitButtonContainer>
					<PropertyValueContainer>
						<ValuesContainer>
							<AccountValuesContainer>
								<h3>ALUGUEL: <b>{formatValue(parseFloat(rent_value))}</b></h3>

								<h4><b>Condomínio:</b> {!!condominium_free ? 'Isento' : `${formatValue(parseFloat(condominium_value))}`}</h4> 
								<h4><b>IPTU:</b> {formatValue(parseFloat(iptu_value))}</h4>
								{hasGarbageTax(garbage_tax) && <h4><b>TMRSU:</b> {formatValue(parseFloat(garbage_tax))}</h4>}
							</AccountValuesContainer>

							<TotalContainer>
								<h3>TOTAL</h3>
								<h2>{formatValue(account)}</h2>
							</TotalContainer>
						</ValuesContainer>

						<strong>*Os valores do condomínio e do IPTU podem sofrer alterações.</strong>
					</PropertyValueContainer>

					<Link to={{
							pathname:`/schedule/${id}`,
							state: { consultant_id: consultant_id, property_id: id }
						}}
					>AGENDAR VISITA</Link>

				</PropertyValuesAndVisitButtonContainer>}
			
				<InformationsAboutProperty>
					<div>
						<PropertyCharacteristics>
							<RiDragMove2Fill size={20} color="#6EA6FF"/>
							<p>{area} m²</p>
						</PropertyCharacteristics>
					</div>

					<div>
						<PropertyCharacteristics>
							<RiHotelBedLine size={20} color="#6EA6FF"/>
							<p>{rooms} Quarto(s)</p>
						</PropertyCharacteristics>
					</div>

					<div>
						<PropertyCharacteristics>
							<RiHotelBedLine size={20} color="#6EA6FF"/>
							<p>{suites} Suíte(s)</p>
						</PropertyCharacteristics>
					</div>


					{!!is_furnished && (
						<div>

							<PropertyCharacteristics>
								<FaCouch size={20} color="#6EA6FF"/>
								<p>Mobiliado</p>
							</PropertyCharacteristics>
						</div>
					)}
					
					{!!address.complement && (
						<div>
							<PropertyCharacteristics>
								<Icon icon={outlineElevator} color="#6EA6FF" width="20" height="20" />
								<p>{address.complement}</p>
							</PropertyCharacteristics>
						</div>
					)}
					
					<div>
						<PropertyCharacteristics>
							<BiCar size={20} color="#6EA6FF"/>
							<p>{garages} Vaga(s)</p>
						</PropertyCharacteristics>
					</div>

					{!!allow_pets && (
						<div>
							<PropertyCharacteristics>
								<MdPets size={20} color="#6EA6FF"/>
								<p>Aceita Pets</p>
							</PropertyCharacteristics>
						</div>
					)}

					{!!water_included && (
						<div>
							<PropertyCharacteristics>
								<IoIosWater size={20} color="#6EA6FF"/>
								<p>Água inclusa</p>
							</PropertyCharacteristics>
						</div>
					)}

					{!!gas_included && (
						<div>
							<PropertyCharacteristics>
								<CgSmartHomeCooker size={20} color="#6EA6FF"/>
								<p>Gás incluso</p>
							</PropertyCharacteristics>
						</div>
					)}

					<div>
						<PropertyCharacteristics>
							<BiBath size={20} color="#6EA6FF"/>
							<p>{bathrooms} Banheiro(s)</p>
						</PropertyCharacteristics>
					</div>
				</InformationsAboutProperty>

				<strong>{description}</strong>

				<WhatLocalHasContainer>
					{!!has_ac && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Ar-condicionado</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}
					
					{!!has_backyard && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Quintal</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}
				

					{!!has_bbq && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Churrasqueira</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_cable_tv && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Tv à cabo</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_porch && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Sacada</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_storage && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Dispensa</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_sea_view && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Vista para o mar</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_home_cinema && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Sala de cinema</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_gourmet && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Espaço gourmet</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_gourmet_porch && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Varanda gourmet</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!is_rented && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Alugado</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}
				</WhatLocalHasContainer>

				<WhatLocalHasContainer>
					{!!is_nearby_mall && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Perto do shopping</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!is_nearby_schools && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Perto de escolas</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!is_nearby_clinics && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Perto de hospitais</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!is_nearby_public_transport && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Perto de transporte público</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!is_nearby_major_roads && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Perto de rodovias principais</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_playground && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Playground</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_tennis_court && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Quadra de tênis</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_game_room && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Salão de jogos</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_24h_concierge && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Portaria 24h</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_spa && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Spar</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_sauna && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Sauna</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_sports && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Espaço para esportes</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_party && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Salão de festas</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_pool && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Piscina</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_gym && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Academia</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}

					{!!has_elevator && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Elevador</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}
					
					{!!has_garden && (
						<div>
							<WhatLocalHasCharacteristics>
								<FiCheck size={20} color="#DD6435"/>
								<p>Jardim</p>
							</WhatLocalHasCharacteristics>
						</div>
					)}
				</WhatLocalHasContainer>

				{!!process.env.REACT_APP_GOOGLE_MAPS_KEY && (
					<div style={{ height: '250px', width: '100%', marginTop: '58px' }}>
						<GoogleMapsReact 
							bootstrapURLKeys={{key: process.env.REACT_APP_GOOGLE_MAPS_KEY}}
							defaultCenter={{lat: parseFloat(address.latitude), lng: parseFloat(address.longitude)}}
							defaultZoom={18}
						>
							<Marker 
								lat={parseFloat(address.latitude)}
					            lng={parseFloat(address.longitude)}
					            color="#FF3838"/>
						</GoogleMapsReact>
					</div>
				)}
				
				<HowToRentImovelContainer>
					<img src={mesaDeTrabalhoImg} alt="mesaDeTrabalhoImg"/>

					<HowToRentImovelTexts>
						<h1><b>Como alugar este imóvel?</b></h1>

						<p>É necessário comprovar renda mensal de <b>3X o valor do aluguel + condomínio + IPTU.</b></p>
						<strong>A renda pode ser composta por até 4 pessoas físicas.</strong>
						
						<p>Contratos administrados pela <b>7Cantos</b> possuem <b>garantia facilitada</b>, sem necessidade de fiador ou caução.</p>

					</HowToRentImovelTexts>
				</HowToRentImovelContainer>
			</Content>
		</Container>
	);
};