import React, { useState, useCallback, FormEvent } from "react";

import getValidationErrors from "./../../utils/getValidationErrors";
import * as Yup from "yup";
import { Form, InputForm } from "./styles";
import { useAuth } from "../../hooks/auth";
import {
  hasScheduleRequest,
  getScheduleRequest,
} from "../../utils/scheduleUtils";
import { useHistory, useLocation } from "react-router";

interface IState {
  fromSite: boolean | undefined;
  previousScreen?: "property";
  propertyId?: string;
  consultant_id?: string;
}

interface IErrors {
  email?: string;
  password?: string;
}

const FormToLoginPage: React.FC = () => {
  const history = useHistory();

  const { state } = useLocation<IState>();
  const { signIn, signInFacebook } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as IErrors);
  const [apiError, setApiError] = useState("");

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setLoading(true);

      const formattedEmail = email.trim();
      const data = { email: formattedEmail, password };

      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .required("E-mail obrigatório")
            .email("Coloque um e-mail válido"),
          password: Yup.string().required("Senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({ email: data.email, password: data.password });

        if (hasScheduleRequest()) {
          const scheduleRequest = getScheduleRequest();
          history.push(
            `/schedule/${scheduleRequest.propertyId}`,
            scheduleRequest.state
          );
        } else {
          history.push(`/`);
        }

        setLoading(false);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const findedErrors = getValidationErrors(err);

          setLoading(false);
          setErrors(findedErrors);
        } else {
          setLoading(false);
          setErrors({});
          setApiError("E-mail/Senha inválido");
        }
      }
    },
    [signIn, history, email, password]
  );

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {!!apiError && <h3>{apiError}</h3>}

        <p>
          E-mail: <span style={{ color: "#FF3838" }}>{errors.email}</span>
        </p>
        <InputForm
          hasError={!!errors.email || !!apiError}
          type="text"
          name="email"
          value={email}
          onChange={(e) => {
            if (e.target.value === " ") {
              return;
            } else {
              setEmail(e.target.value);
            }
          }}
        />

        <p>
          Senha: <span style={{ color: "#FF3838" }}>{errors.password}</span>
        </p>
        <InputForm
          hasError={!!errors.password || !!apiError}
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />

        {!loading ? (
          <button type="submit">ENTRAR</button>
        ) : (
          <label>Carregando...</label>
        )}
      </Form>
    </>
  );
};

export default FormToLoginPage;
