import { Grid } from "@material-ui/core";
import React from "react";

export default function GridItem({ label, value, xs }: any) {
  return (
    <Grid
      item
      xs={xs}
      style={{
        border: "0.5px solid rgb(139, 139, 139)",
        padding: "0.75rem",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <p style={{ fontSize: "26px", fontWeight: 600 }}>{value}</p>
      <p style={{ fontSize: "13px", textAlign: "center" }}>{label}</p>
    </Grid>
  );
}
