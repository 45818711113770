import styled, { keyframes } from 'styled-components';

import backgroundImage from './../../assets/pages/AnunciarImovel/pattern.svg';

interface ISvg{
	isOpen: boolean;
}

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1366px;

	margin: 0 auto;

	padding: 64px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>img{
		margin: 64px 0 42px 0;

		width: 210px;
        height: 32px;
	}

	>h1{
		font-size: 23px;
		font-weight: bold;

		margin-bottom: 64px;
	}

	>h2{
		font-size: 23px;
		font-weight: bold;

		margin-bottom: 48px;
	}

	@media(max-width: 767px){
		>img{
			margin: 92px 0 32px 0;

			width: 190px;
            height: 30px;
		}

		>h1{
			font-size: 18px;

			text-align: center;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 28px;
		}

		>h2{
			font-size: 28px;
		}

		>img{
			margin: 64px 0 42px 0;

			width: 220px;
			height: 38px;
		}
	}
`;

export const InitialContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;
	}
`;

export const TitleContainer = styled.div`
	width: 500px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-size: 17px;

		color: #DD6435;
	}

	>h1{
		width: 82%;

		font-size: 26px;
		font-weight: bold;

		margin: 20px 0;
	}

	>p{
		font-size: 17px;

		margin-bottom: 8px;
	}

	>img{
		width: 332px;
		height: 350px;
	}

	@media(max-width: 767px){
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: center;

		>h3{
			font-size: 17px;
		}

		>h1{
			width: 70%;

			font-size: 26px;

			text-align: center;
		}

		>p{
			font-size: 14px;
		}

		>img{
			width: 338px;
			height: 229px;
		}
	}

	@media(min-width: 1441px){
		>h3{
			font-size: 21px;
		}

		>h1{
			width: 90%;

			font-size: 31px;

			margin: 20px 0;
		}

		>p{
			font-size: 20px;

			margin-bottom: 6px;
		}

		>img{
			width: 392px;
			height: 410px;
		}
	}
`;

export const StepsToAnuciarImovelContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	>hr{
		width: 0.1px;
		height: 230px;

		color: #B6B6B6B6;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>hr{
			display: none;
		}
	}

	@media(min-width: 1441px){
		>hr{
			width: 0.1px;
			height: 260px;

			color: #B6B6B6B6;
		}
	}
`;

export const VideoContainer = styled.div`
	width: 100%;

	margin-top: 90px;

	@media(min-width: 1024px){
		background: url(${backgroundImage});
		background-position: 0 -250px;
		background-repeat: repeat;
	}

	@media(min-width: 1441px){
		margin-bottom: 110px;
	}
`;

export const VideoContent = styled.div`
	width: 70%;

	background: #FFFFFF;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	margin: 48px auto 0;

	padding-bottom: 48px;

	>h1{
		font-size: 26px;
		font-weight: bold;
	}

	>p{
		width: 67%;

		font-size: 12px;

		margin: 22px 0 64px 0;
	}

	@media(max-width: 767px){
		padding-bottom: 0;

		>h1{
			font-size: 18px;
		}

		>p{
			width: 90%;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 28px;
		}

		>p{
			width: 76%;

			font-size: 14px;
		}
	}
`;

export const ButtonsToInformationsContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;
`;

const AnimationSvgOpen = keyframes`
	from{
		transform: rotate(-180deg);
	}

	to{
		transform: rotate(-360deg);
	}
`;

const AnimationSvgClose = keyframes`
	from{
		transform: rotate(180deg);
	}

	to{
		transform: rotate(360deg);
	}
`;

export const ButtonAndInformationContainer = styled.div<ISvg>`
	width: 42%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	margin-bottom: 24px;

	>button{
		width: 100%;

		padding: 8px 16px;

		display: flex;
		align-items: center;
		justify-content: center;

		border: 1px solid #B6B6B6;

		background: transparent;

		color: #666666;

		>h3{
			font-size: 14px;

			text-align: center;

			margin: 0 auto;
		}

		>svg{
			animation: ${props => props.isOpen === false ? AnimationSvgClose : AnimationSvgOpen} 1s;
		}
	}

	@media(max-width: 767px){
		width: 330px;

		>button{
			padding: 8px 24px;

			>h3{
				font-size: 12px;
			}
		}
	}

	@media(min-width: 1441px){
		width: 47%;

		>button{
			padding: 10px 16px;

			>h3{
				font-size: 16px;
			}
		}
	}
`;

const InformationContainerAnimation = keyframes`
	0%{
		transform: translateY(-50px);
		opacity: 0;
	}

	100%{
		transform: translateY(0px);
		opacity: 1;
	}
`;

export const InformationContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	animation: ${InformationContainerAnimation} 1s;

	>h3{
		font-size: 12px;

		font-weight: bold;
		color: #6EA5FF;

		margin: 32px 0 12px 0;
	}

	>p{
		width: 90%;

		font-size: 11px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 12px;
		}

		>p{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>h3{
			font-size: 13px;

			font-weight: bold;
			color: #6EA5FF;

			margin: 32px 0 12px 0;
		}

		>p{
			width: 100%;

			font-size: 12px;
		}
	}
`;