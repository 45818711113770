import React, { ChangeEvent, useState } from "react";
import { TABLE_TITLES } from "./constants";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import formatValue from "../../../../utils/formatValue";

interface IPayment {
  date: string;
  final_value: number;
  totalReceived: number;
}

interface IBills {
  bills: IPayment[];
}

export default function OptionsTable({ bills }: IBills) {
  let totalReceived = 0;
  const PAGE_SIZE = 4;

  const billsListLength = bills.length;
  const paginationLength = Math.floor(billsListLength / PAGE_SIZE) + 1;
  const [range, setRange] = useState({
    startIndex: 0,
    endIndex: PAGE_SIZE - 1,
  });

  function pageChangeHandler(event: ChangeEvent<unknown>, page: number) {
    setRange((prevValue) => ({
      ...prevValue,
      startIndex: (page - 1) * PAGE_SIZE,
      endIndex:
        page * PAGE_SIZE - 1 > billsListLength
          ? billsListLength
          : page * PAGE_SIZE - 1,
    }));
  }

  const displayableBills = bills.slice(range.startIndex, range.endIndex + 1);

  return (
    <TableContainer
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "2rem",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_TITLES.map((title) => (
              <TableCell align="left" key={title} style={{ color: "#8b8a8e" }}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayableBills?.map((bill: IPayment) => {
            return (
              <TableRow key={`${bill.date}:${bill.final_value}`}>
                <TableCell>{bill.date}</TableCell>
                <TableCell style={{ color: "#0ca11a" }}>
                  {formatValue(bill.final_value)}
                </TableCell>
                <TableCell style={{ color: "#0ca11a" }}>
                  {formatValue(bill.totalReceived)}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Pagination count={paginationLength} onChange={pageChangeHandler} />
    </TableContainer>
  );
}
