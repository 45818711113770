import styled, { css } from 'styled-components';

interface IContent{
	notIsFirst: boolean | undefined;
}

export const Container = styled.div`

`;

export const Content = styled.div<IContent>`
	width: 350px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	flex-direction: column;

	text-align: center;

	>img{
		width: 40px;
		height: 40px;
	}

	>h3{
		font-size: 14px;
		font-weight: bold;

		margin: 18px 0 24px 0;
	}

	>p{
		font-size: 12px;

		width: 72%;
	}

	@media(max-width: 767px){
		width: 350px;

		border: 0;

		${props => !!props.notIsFirst && css`
			margin-top: 42px;
		`}

		>img{
			width: 43px;
			height: 43px;
		}

		>h3{
			font-size: 14px;
		}

		>p{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		width: 400px;

		>img{
			width: 48px;
			height: 48px;
		}

		>h3{
			font-size: 16px;
			font-weight: bold;

			margin: 18px 0 24px 0;
		}

		>p{
			font-size: 13px;

			width: 63%;
		}
	}
`;