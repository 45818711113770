import styled from 'styled-components';

export const Container= styled.div`

`;

export const Content= styled.div`
	max-width: 1200px;

	margin: 0 auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 52px 0 12%;

	>h3{
		font-size: 15px;
		font-weight: 600;
		color: #B6B6B6;
	}

	>h1{
		font-size: 30px;
		font-weight: bold;

		margin: 6px 0 40px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;
		}
	}

	@media(min-width: 1441px){
		padding: 64px 0 12%;

		>h3{
			font-size: 16px;
		}

		>h1{
			font-size: 34px;
		}
	}
`;

export const ProposalsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;
`;

export const PropertyInformationsContainer = styled.div`
	width: 890px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	background: #F9F9F9;

	padding: 24px 32px;

	>img{
		width: 90px;
		height: 65px;
	}

	& + div{
		margin-top: 24px;
	}

	@media(max-width: 767px){
		width: 310px;

		padding: 24px;

		>img{
			width: 111px;
			height: 82px;
		}
	}

	@media(min-width: 1441px){
		width: 1000px;

		padding: 26px 36px;

		>img{
			width: 90px;
			height: 70px;
		}
	}
`;

export const PropertyLocalContainer = styled.div`
	margin-left: 24px;

	width: 30%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-size: 14px;
		font-weight: bold;
		color: #6EA5FF;

		margin-bottom: 8px;
	}

	>p{
		font-size: 11px;
	}

	>div{
		display: none;
	}

	@media(max-width: 767px){
		width: 60%;

		>h3{
			font-size: 15px;
		}

		>p{
			font-size: 12px;
		}

		>div{
			margin-top: 12px;
			margin-left: 0;

			display: flex;
			align-items: flex-start;
			justify-content: center;

			flex-direction: column;

			font-size: 12px;

			>a{
				margin-top: 24px;

				text-decoration: none;
				text-align: center;

				width: 130px;

				padding: 12px 0;

				background: #00BF33;

				font-weight: 500;
				color: #FFF;
			}
		}
	}

	@media(min-width: 1441px){
		>h3{
			font-size: 16px;
		}

		>p{
			font-size: 12px;
		}
	}
`;

export const PricesContainer = styled.div`
	margin-left: auto;

	display: flex;
	align-items: flex-end;
	justify-content: center;

	flex-direction: column;

	>strong{
		font-size: 11px;
		font-weight: 600;

		& + strong{
			margin-top: 8px;
		}
	}

	@media(max-width: 767px){
		display: none;
	}

	@media(min-width: 1441px){
		>strong{
			font-size: 13px;
		}
	}
`;

export const OfferContainer = styled.div`
	width: 890px;

	display: flex;
	align-items: center;
	justify-content: flex-start;

	border: 1px solid #B6B6B6;

	>hr{
		margin-left: auto;

		width: 1px;
		height: 151px;

		color: #B6B6B6;
	}

	& + div{
		margin-top: 24px;
	}

	@media(max-width: 767px){
		width: 310px;

		flex-direction: column;

		>hr{
			display: none;
		}
	}

	@media(min-width: 1441px){
		width: 1000px;
	}
`;

export const InformationsAboutOfferContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 32px 0 32px 32px;

	>p{
		width: 400px;

		font-size: 12px;
	}

	>span{
		margin-top: 32px;

		font-size: 9px;
		color: #B6B6B6;
	}

	@media(max-width: 767px){
		padding: 24px 24px 0 24px;

		>p{
			width: 270px;

			font-size: 12px;
		}

		>span{
			font-size: 9px;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 13px;
		}

		>span{
			font-size: 10px;
		}
	}
`;

export const OfferActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 32px;

	>button{
		background: transparent;

		border: 0;

		font-size: 12px;
		font-weight: 500;
		color: #FFF;

		width: 175px;

		padding: 8px 0;

		& + button{
			margin-top: 8px;
		}
	}

	>p{
		background: transparent;

		border: 1px solid #00BF33;

		font-size: 12px;
		font-weight: 500;
		color: #00BF33;

		text-align: center;

		width: 175px;

		padding: 8px 0;

		margin-bottom: 8px;
	}

	button#accept-proposal{
		background: #00BF33;
	}


	button#answer-proposal{
		border: 1px solid #666666;

		color: #666666;
	}

	>h3{
		width: 175px;

		font-size: 14px;

		text-align: center;
	}

	@media(max-width: 767px){
		margin-top: 18px;

		padding: 24px;

		>button{
			font-size: 12px;
		}

		>p{
			font-size: 12px;
		}

		>h3{
			font-size: 16px;
		}
	}
`;