import React from 'react';

import { Container, Content } from './styles';

import HeaderComponent from './../../components/Header/';
import FooterComponent from './../../components/Footer/';

import error404Image from './../../assets/pages/Error404/404.svg';

import { Link } from 'react-router-dom';

export default function Error404(){
	return (
		<Container>
			<HeaderComponent />

			<Content>
				<h2>ERRO 404</h2>

				<h1>Página não encontrada</h1>
				<span>Opa! Não conseguimos localizar esta página. O que você quer fazer?</span>

				<Link to="/">VOLTAR PARA O INÍCIO</Link>

				<img src={error404Image} alt="error404Image"/>
			</Content>

			<FooterComponent />
		</Container>
	);
};