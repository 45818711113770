import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1000px;
	margin: 0 auto;

	padding: 48px 0 64px;

	display: flex;
	align-items: flex-start;
	justify-content: center;
	
	flex-direction: column;

	@media(max-width: 767px){
		align-items: center;
	}

	@media(min-width: 1441px){
		max-width: 1120px;
	}
`;

export const InitialContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;

export const PropertyValues = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	margin-left: 42px;

	position: -webkit-sticky;
	position: sticky;
	top: 32px;
	z-index: 3px;

	>img{
		margin-top: 32px;

		width: 230px;
		height: 27px;
	}

	>h2{
		margin-top: 8px;

		font-size: 16px;
		font-weight: bold;
	}

	>a{
		text-decoration: none;

		margin-top: 18px;

		padding: 8px 32px;

		border: 0;

		background: #6EA5FF;

		font-size: 13px;
		font-weight: 600;
		color: #FFF;
	}

	@media(max-width: 767px){
		display: none;
	}

	@media(min-width: 1441px){
		margin-left: 52px;

		>img{
			width: 235px;
			height: 30px;

			margin-top: 35px;
		}

		>h2{
			font-size: 18px;
		}

		>a{
			padding: 8px 28px;

			font-size: 15px;
		}
	}
`;

export const Values = styled.div`
	width: 260px;

	padding: 24px 1px;

	border: 1px solid #B6B6B6;
	border-radius: 16px;

	>h3{
		font-weight: bold;

		font-size: 16px;

		margin-bottom: 12px;
	}

	h1#aluguel-value{
		font-size: 25px;

		color: #6EA5FF;
	}

	>h1{
		font-size: 25px;
		font-weight: bold;
		color: #DD6435;

		margin-bottom: 21px;
	}

	h3#total{
		margin-top: 21px;
	}

	>p{
		font-size: 13px;
	}

	>p#p-warning{
		width: 70%;

		text-align: center;

		margin: 21px auto 0;

		font-size: 11px;
	}

	@media(min-width: 1441px){
		width: 280px;

		padding: 28px 1px;

		>h3{
			font-size: 19px;
		}

		h1#aluguel-value{
			font-size: 29px;
		}

		>h1{
			font-size: 29px;
		}

		>p{
			font-size: 16px;
		}

		>p#p-warning{
			font-size: 12px;
		}
	}
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	>button{
		background: transparent;

		border: 0;
	}

	>img{
		width: 29px;
		height: 29px;
	}

	>a{
		margin-left: 12px;
	}

	@media(min-width: 1441px){
		margin-top: 16px;
		
		svg{
			width: 23px;
			height: 23px;
		}
	}
`;

