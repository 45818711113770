import React from 'react';

import { Container, Content } from './styles';

import HeaderComponent from './../../components/Header/';
import FooterComponent from './../../components/Footer/';

import { BiCalendarCheck } from 'react-icons/bi';

import { Link } from 'react-router-dom';

export default function ScheduleSuccess(){
	return (
		<Container>
			<HeaderComponent />

			<Content>

				<h2>
					<BiCalendarCheck size={57} color="#00BF33" />
				</h2>

				<h1>PRONTINHO!</h1>

				<span>Sua visita já foi agendada. Você consegue reagendar ou cancelar na tela de Meus Agendamentos.</span>

				<div className="button-container">
					<Link to="/scheduled-visits">MEUS AGENDAMENTOS</Link>
					<Link to="/">VOLTAR PARA O INÍCIO</Link>
				</div>

			</Content>

			<FooterComponent />
		</Container>
	);
};