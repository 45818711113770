import styled from "styled-components";

export const AvatarContainer = styled.div`
width: 7rem;
height: 7rem;
border-radius: 50%;
background: #6ea8ff;
display: flex;
align-items: center;
justify-content: center;
margin-top: 1rem;
`;
