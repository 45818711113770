import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const MapSectionContainer = styled.section`

display: flex;
flex-direction: column;
background-color: #FFFFFF;
padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};

// MEDIUM
@media(min-width: 768px){
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
}

// LARGE
@media(min-width: 992px){
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
    padding-top: 100px;
}
`;

export const MapSectionHeaderContainer = styled.div`

position: relative;

&:after {
    content: '';
    position: absolute;
    background-color: #6EA6FF;
    width: 40px;
    height: 4px;
    left: 0;
    margin-top: 30px;
}

h3.title {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

p.description {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
}

// LARGE
@media(min-width: 992px){

    h3.title {
        font-size: 36px;
    }

    p.description {
        font-size: 16px;
    }
}
`;

export const MapSectionContent = styled.div`
display: flex;
flex-direction: column-reverse;
justify-content: space-space-around;
align-items: center;
padding-top: 40px;

// MEDIUM
@media(min-width: 768px){
    flex-direction: row;
    justify-content: space-between;
}

// LARGE
@media(min-width: 992px){
    padding-top: 0px;
    margin-top: -100px;
    flex-direction: row;
    justify-content: space-around;
}

`;

export const MapSectionCitiesContainer = styled.div`
display: flex;
flex-direction: column;
`;

export const MapSectionCitiesItem = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
gap: 50px;

a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666666;

    &:visited {
        color: #666666;
    }
}

p.city {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
}
`;

export const MapSectionCitiesItemCenter = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;

a {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #666666;

    &:visited {
        color: #666666;
    }
}

p.city {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 65px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 10px;
}
`;

export const MapSectionImageContainer = styled.div`
    img {

        width: 500px;

    }

`;