import styled from 'styled-components';

import backgroundImage from './../../assets/pages/IndicateProperty/pattern.svg';
import backgroundImageToBigDesktops from './../../assets/pages/IndicateProperty/pattern2.svg';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1000px;

	padding: 48px 0 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	margin: 0 auto;

	>img{
		display: none;
	}

	@media(max-width: 767px){
		>img{
			display: flex;

			width: 190px;
            height: 30px;

            margin: 128px 0 18px;
		}
	}

	@media(min-width: 1441px){
		max-width: 1100px;

		padding: 62px 0 64px;
	}
`;

export const InitialContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-bottom: 72px;

	>img{
		width: 320px;
		height: 255px;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>img{
			margin-top: 24px;

			width: 299px;
			height: 235px;
		}
	}

	@media(min-width: 1441px){
		margin-bottom: 86px;

		>img{
			width: 360px;
			height: 285px;
		}
	}
`;

export const IndicateContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	max-width: 500px;

	margin-right: 50px;

	>h1{
		width: 70%;

		text-align: center;
		font-size: 30px;
		font-weight: bold;
	}

	>p{
		width: 80%;

		font-size: 14px;
		text-align: center;

		margin: 24px 0 32px;
	}

	>button{
		padding: 10px 32px;

		text-decoration: none;
		font-size: 13px;
		font-weight: 600;
		color: #FFF;

		background: #DD6435;

		border: 0;
 	}

 	@media(max-width: 767px){
 		margin-right: 0;
 		margin-bottom: 48px; 

 		>h1{
 			width: 90%;

 			font-size: 26px;
 		}

 		>p{
 			width: 85%;

 			font-size: 14px;
 		}

 		>button{
 			width: 65%;

 			font-size: 14px;
 		}
 	}

	@media(min-width: 1441px){
		margin-right: 92px;

		>h1{
			width: 90%;

			font-size: 36px;
		}
		
		>p{
			width: 100%;

			font-size: 17px;
		}

		>button{
			padding: 12px 36px;

			font-size: 15px;
		}
	}
`;

export const StepsToIndicateContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	>div{
		& + div{
			margin-left: 20px;
		}
	}

	& + div{
		margin-top: 24px;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>div{
			& + div{
				margin-left: 0;
				margin-top: 64px;
			}
		}
	}
`;

export const WhereIndicate = styled.div`
	@media(min-width: 1024px){
		background: url(${backgroundImage});
		background-position: 0 -250px;
	}

	width: 100%;

	margin-top: 64px;

	display: flex;
	align-items: center;
	justify-content: center;

	@media(min-width: 1441px){
		background: url(${backgroundImageToBigDesktops});
		background-position: 0 -250px;
	}
`;

export const TextContainer = styled.div`
	width: 70%;

	background: #FFFFFF;
	
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 52px 0;

	>h1{
		font-size: 29px;
		font-weight: bold;

		margin-bottom: 42px;
	}

	>h2{
		font-size: 20px;

		& + h2{
			margin-top: 12px;
		}
	}

	>p{
		width: 75%;

		font-size: 11px;

		margin-top: 48px;

		text-align: center;
	}

	@media(max-width: 767px){
		margin-top: 0;

		>h1{
			width: 85%;

			font-size: 18px;

			text-align: center;
		}

		>h2{
			font-size: 14px;
		}

		>p{
			width: 76%;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>h1{
			font-size: 34px;

			margin-bottom: 52px;
		}

		>h2{
			font-size: 22px;
		}

		>p{
			width: 80%;

			font-size: 13px;
		}
	}
`;
