import styled from "styled-components";

export const OutlinedButton = styled.button`
font-weight: 600;
width: 100%;
text-align: center;
font-size: 12px;
background: white;
border-radius: 1rem;
padding: 0.15rem 0.5rem;
text-transform: none;
&:hover {
    background: #f3f1eb;
    transition: 0.5s linear;
}
`;
