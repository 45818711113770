import React from "react";

import { Container } from "./style";

export default function MenuItem({
  Icon,
  label,
  link,
  external,
  onClick,
  ...props
}: any) {
  return (
    <Container onClick={() => onClick(link, external)} style={props}>
      {Icon}
      <p style={{ textAlign: "center", marginTop: "6px" }}>{label}</p>
    </Container>
  );
}
