import React, { useMemo } from 'react';

import {Container,
		Content,
		TitleContainer,
		PropertyInformationsContainer,
		PropertyLocationContainer,
		ScheduledVisitsContainer,
		VisitsDateContainer} from './styles';

import { FiX } from 'react-icons/fi';

interface IPhotos{
	id: number;
	url_l: string;
	url_m: string;
}

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}

interface ISchedules{
	id: number;
	date: string;
}

interface IModalVisits{
	id?: number;
	title: string | undefined;
	photos: IPhotos;
	schedules: ISchedules[];
	address: IAddress;
	functionToCloseModalVisits(): void;
}

export default function ModalVisits({id, title, photos, schedules, address, functionToCloseModalVisits}: IModalVisits){
	
	const formatedDates = useMemo(() => {
		const parseDate = schedules.map(schedule => ({
			id: schedule.id,
			date: new Date(schedule.date)
		}));

		const formatDate = parseDate.map(date => ({
			id: date.id,
			date: `${String(date.date.getDate()).padStart(2, '0')}/${String(date.date.getMonth() + 1).padStart(2, '0')}/${date.date.getFullYear()} - ${String(date.date.getHours()).padStart(2, '0')}:${String(date.date.getMinutes()).padStart(2, '0')}h`
		}))
	
		return(formatDate);
	}, [schedules]);

	return (
		<Container>
			<Content>
				<TitleContainer>
					<button onClick={functionToCloseModalVisits}><FiX size={30} color="#FFF"/></button>

					<h1>LISTA DE VISITAS</h1>
				</TitleContainer>

				<PropertyInformationsContainer>
					<PropertyLocationContainer>
						<h3>{title}</h3>

						<p>{address.address}, {address.neighborhood} {address.city} - {address.uf}</p>
					</PropertyLocationContainer>

					<img src={photos.url_m} alt="propertyImg"/>
				</PropertyInformationsContainer>

				<ScheduledVisitsContainer>
					<h3>Visitas Agendadas</h3>

					<VisitsDateContainer>
						{formatedDates.map(schedule => (
							<p key={schedule.id}>{schedule.date}</p>
						))}	
					</VisitsDateContainer>	
				</ScheduledVisitsContainer>
			</Content>
		</Container>
	);
};