import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 990px;

	padding: 56px 0 12%;

	margin: 0 5% 0 20%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	>img{
		margin-left: 12px;

		width: 550px;
	}

	@media(max-width: 767px){
		width: 310px;

		align-items: center;

		flex-direction: column;

		margin: 0 auto;

		>img{
			display: none;
		}
	}

	@media(min-width: 1441px){
		padding: 58px 0 12%;

		max-width: 1130px;

		>img{
			width: 660px;
		}
	}
`;

export const FirstContainer = styled.div`
	width: 450px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-size: 18px;
		color: #DD6435;
	}

	>h1{
		font-size: 26px;
		font-weight: bold;

		margin-top: 18px;
	}

	>img{
		display: none;
	}

	>p{
		margin-top: 42px;
		margin-bottom: 18px;
		margin-left: 16%;
	}

	>button{
		background: transparent;

		border: 1px solid #707070;

		font-weight: bold;
		color: #666666;

		width: 80%;
		padding: 12px 0;
	}

	@media(max-width: 767px){
		width: 90%;

		align-items: center;

		>h3{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;

			text-align: center;
		}

		>img{
			display: flex;

			width: 310px;
			height: 231px;
		}

		>p{
			margin-left: 0;

			font-size: 11px;
		}

		>button{
			width: 100%;

			font-size: 12px;
			color: #666666;
		}
	}

	@media(min-width: 1441px){
		width: 860px;

		>h3{
			font-size: 20px;
		}

		>h1{
			font-size: 30px;
		}
	}
`;