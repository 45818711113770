import React, {lazy, Suspense} from 'react';
import { Link } from 'react-router-dom';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import ConvenienceComponent from './../../components/ConvenienceComponent/';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container,
		Content,
		InitialContainer,
		LocalizationAndDescriptionContainer,
		LocalizationContainer,
		CondominiumInformationsContainer,
		SizeContainer,
		RoomsContainer,
		GarageContainer,
		CondominiumFacilitiesContainer,
		PropertiesToRentContainer,
		NeighborhoodConvenienceContainer,
		ConveniencesContainer,
		AnotherPropertiesContainer} from './styles';

import { CgPin } from 'react-icons/cg';
import { RiHotelBedLine, RiDragMove2Fill, RiBusLine } from 'react-icons/ri';
import { BiCar } from 'react-icons/bi';
import { MdSchool } from 'react-icons/md';
import { AiOutlineShopping } from 'react-icons/ai';

import fachadaImg from './../../assets/pages/LaCitta/fachada.png';

import court from './../../assets/pages/LaCitta/court.png';
import gameRoom from './../../assets/pages/LaCitta/game-room.png';
import gourmet2 from './../../assets/pages/LaCitta/gourmet-2.png';
import gourmet from './../../assets/pages/LaCitta/gourmet.png';
import gym from './../../assets/pages/LaCitta/gym.png';
import playground from './../../assets/pages/LaCitta/playground.png';
import pool from './../../assets/pages/LaCitta/pool.png';
import triangulosImg from './../../assets/pages/Imovel/triangulos.svg';

const HouseCondominium = lazy(() => import('./../../components/HouseCondominium/'));

export default function CondominiumScreen(){
	const settings = {
	    arrows: true,
	    speed: 500,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	}

	// const settingsPropertiesToRent = {
	// 	dots: true,
	//     arrows: true,
	//     speed: 500,
	//     slidesToShow: window.innerWidth > 767 ? 3 : 1,
	//     slidesToScroll: 1,
	//     touchMove: false
	// }

	return(
		<Container>
			<Header hasSearchBar hasOptionToSearch/>
			<Content>
				<InitialContainer style={{
					background: `url(${fachadaImg})`,
					backgroundSize: window.innerWidth > 767 ? '100% 500px' : '100% 306px',
					backgroundRepeat: 'no-repeat'
				}}>
					<h1>La Cittá Parangaba Residence</h1>
					<h3>Magis Incorporações</h3>
					<Link id="available-properties-link" to="/search/?city=Fortaleza&UF=CE&condominium=lacitta">VER IMÓVEIS DISPONÍVEIS</Link>
				</InitialContainer>

				<LocalizationAndDescriptionContainer>
					<LocalizationContainer>
						<CgPin size={37} color="#6EA5FF" />
						<p>Rua Afrânio Peixoto, 288 - Parangaba - Fortaleza, CE</p>
					</LocalizationContainer>

					<p>O conceito more perto de tudo ganha força com este empreendimento. A Parangaba é um bairro de fácil acesso para outras regiões  da cidade, além de ser um grande polo comercial, contando com shopping, hospital e diversas padarias. O La Cittá está situado perto de colégios, faculdades, próximo ao Shopping Parangaba e do North Shopping Parangaba, polo de lazer, estação do Metrô e muito mais.</p>
				</LocalizationAndDescriptionContainer>

				<CondominiumInformationsContainer>
					<SizeContainer>
						<RiDragMove2Fill size={33} color="#6EA5FF"/>
						<p>51m² a 62m²</p>
					</SizeContainer>

					<RoomsContainer>
						<RiHotelBedLine size={33} color="#6EA5FF"/>
						<p>2 ou 3 quartos</p>
					</RoomsContainer>

					<GarageContainer>
						<BiCar size={33} color="#6EA5FF"/>
						<p>1 ou 2 vagas</p>
					</GarageContainer>
				</CondominiumInformationsContainer>

				<CondominiumFacilitiesContainer>
					<h1>Instalações do condomínio</h1>

					<p>O La Cittá é um empreendimento que surpreende pelo projeto inovador, um edifício bonito, funcional, de padrão diferenciado e único na região. Bem completo, possui deck, piscina, playground e brinquedoteca, spa, sauna e muitas outras opções de lazer nas adjacências.</p>

					<Slider {...settings}>
						<div>
							<img src={court} alt="img"/>
							<p>Quadra</p>
						</div>

						<div>
							<img src={gym} alt="img"/>
							<p>Academia</p>
						</div>

						<div>
							<img src={playground} alt="img"/>
							<p>Playground</p>
						</div>

						<div>
							<img src={gameRoom} alt="img"/>
							<p>Salão de Jogos</p>
						</div>

						<div>
							<img src={gourmet2} alt="img"/>
							<p>Área Gourmet</p>
						</div>

						<div>
							<img src={pool} alt="img"/>
							<p>Piscina</p>
						</div>

						<div>
							<img src={gourmet} alt="img"/>
							<p>Área Gourmet</p>
						</div>
					</Slider>
				</CondominiumFacilitiesContainer>

				<img src={triangulosImg} alt="triangulosImg" />
			</Content>
				<NeighborhoodConvenienceContainer>
					<img src={triangulosImg} alt="triangulosImg" />

					<h1>Comodidades na vizinhança</h1>

					<ConveniencesContainer>
						<ConvenienceComponent
							icon={MdSchool}
							color="#F2C80E"
							title="EDUCAÇÃO"
							locals={['Universidade Estadual do Ceará', 'Centro Universitário Estácio']}
						/>
						<hr />
						<ConvenienceComponent
							icon={AiOutlineShopping}
							color="#00ABA6"
							title="COMÉRCIO E LAZER"
							locals={['Shopping Parangaba', 'Assaí Atacadista']}
						/>
						<hr />
						<ConvenienceComponent
							icon={RiBusLine}
							color="#FF5079"
							title="TRANSPORTE"
							locals={['Paradas de ônibus a poucos metros', 'Estações do Bicicletar próximas', 'Terminal da Parangaba']}
						/>
						<hr />
						<ConvenienceComponent
							icon={CgPin}
							color="#B792D3"
							title="OUTROS"
							locals={['BB, Bradesco, Caixa, Itaú, Santander, Lagoa da Parangaba, La Cittá Mall']}
						/>
					</ConveniencesContainer>
				</NeighborhoodConvenienceContainer>

				<AnotherPropertiesContainer>
				</AnotherPropertiesContainer>

				<PropertiesToRentContainer>
					<h1>Imóveis para alugar neste empreendimento</h1>

				</PropertiesToRentContainer>
				<Suspense fallback={<div>Loading...</div>}>
					<HouseCondominium condominium={'lacitta'} />
				</Suspense>

				<AnotherPropertiesContainer>
				</AnotherPropertiesContainer>
			<Footer/>
		</Container>
	);
};