import React from 'react';

import  {
    MapSectionContainer,
    MapSectionHeaderContainer,
    MapSectionContent,
    MapSectionCitiesContainer,
    MapSectionCitiesItem,
    MapSectionCitiesItemCenter,
    MapSectionImageContainer
} from './styles';

import MapImage from './../../../assets/pages/Home/7cantos-cidades.png';

export default function MapSection() {

    return (
        <MapSectionContainer>
            <MapSectionHeaderContainer>
                <h3 className='title'>Do Nordeste para o Nordeste</h3>
                <p className='description'>Já estamos presentes em 5 estados e nos preparando para os próximos!</p>
            </MapSectionHeaderContainer>

            <MapSectionContent>
                <MapSectionCitiesContainer>
                    <MapSectionCitiesItem>
                        <a href="/search/?city=Fortaleza&UF=CE">
                            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                            <p className='city'>Fortaleza</p>
                        </a>
                        <a href="/search/?city=Salvador&UF=BA">
                            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                            <p className='city'>Salvador</p>
                        </a>
                    </MapSectionCitiesItem>
                    <MapSectionCitiesItem>
                        <a href="/search/?city=Recife&UF=PE">
                            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                            <p className='city'>Recife</p>
                        </a>
                        <a href="/search/?city=Teresina&UF=PI">
                            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                            <p className='city'>Teresina</p>
                        </a>
                    </MapSectionCitiesItem>
                    <MapSectionCitiesItemCenter>
                        <a href="/search/?city=Maceió&UF=AL">
                            <svg width="16" height="21" viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 10.927C6.9 10.927 6 10.027 6 8.927C6 7.827 6.9 6.927 8 6.927C9.1 6.927 10 7.827 10 8.927C10 10.027 9.1 10.927 8 10.927ZM14 9.127C14 5.497 11.35 2.927 8 2.927C4.65 2.927 2 5.497 2 9.127C2 11.467 3.95 14.567 8 18.267C12.05 14.567 14 11.467 14 9.127ZM8 0.927002C12.2 0.927002 16 4.147 16 9.127C16 12.447 13.33 16.377 8 20.927C2.67 16.377 0 12.447 0 9.127C0 4.147 3.8 0.927002 8 0.927002Z" fill="#DD6536" /></svg>
                            <p className='city'>Maceió</p>
                        </a>
                    </MapSectionCitiesItemCenter>

                </MapSectionCitiesContainer>
                <MapSectionImageContainer>
                    <img src={MapImage} />
                </MapSectionImageContainer>

            </MapSectionContent>
        </MapSectionContainer>
    );
};