import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100vh; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 

`;

export const Content = styled.div`
	position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

	width: 500px;

	z-index: 6px;

	padding: 16px 0 52px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	background: #FFF;

	border-radius: 6px;

	-webkit-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);

	>button{
		background: transparent;
		border: 0;

		margin-left: auto;
		margin-right: 32px;
	}

	@media(max-width: 767px){
		width: 330px;
	}
`;

export const MessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h1{
		font-weight: bold;

		width: 90%;

		margin: 18px 0;
	}

	@media(max-width: 767px){
		>svg{
			width: 40px;
			height: 40px;
		}

		>h1{
			width: 60%;

			font-size: 14px;
		}
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-top: 16px;

	>button{
		border: 0;

		width: 150px;

		font-size: 17px;
		font-weight: bold;
		color: #FFF;

		padding: 12px 0;

		& + button{
			margin-left: 16px;
		}
	}

	>label{
		width: 150px;

		border: 1px solid #666666;

		text-align: center;

		font-size: 17px;
		color: #666666;

		padding: 12px 0;

		margin-right: 16px;
	}

	@media(max-width: 767px){
		flex-direction: column;

		>button{
			width: 200px;

			padding: 10px 0;

			font-size: 12px;

			& + button{
				margin-top: 12px;
				margin-left: 0px;
			}
		}

		>label{
			width: 200px;

			padding: 10px 0;

			font-size: 12px;

			margin-bottom: 12px;
			margin-right: 0;
		}
	}
`;