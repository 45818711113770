import styled from "styled-components";

interface IInputForm{
	hasError: boolean;
}

export const Form = styled.form<IInputForm>`
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	margin-bottom: 22px;

	>p{
		font-size: 14px;
	}

	span{
		font-size: 12px;
	}
	
	#mask-input{
		width: 100%;

		padding: 8px;

		border: 0;
		border-bottom: ${props => !!props.hasError ? "2px solid #FF3838" : "1px solid #B6B6B6"};

		margin-bottom: 24px;

		background: transparent;
	}

	>button{
		width: 100%;

		padding: 10px 24px;

		background: #6EA5FF;

		border: 0;

		margin-top: 12px;

		font-size: 13px;
		font-weight: 600;
		color: #FFF;
	}

	>label{
		width: 100%;

		padding: 10px 24px;

		background: #FFF;

		border: 2px solid #6EA5FF;

		margin-top: 12px;

		font-size: 13px;
		font-weight: 600;
		color: #6EA5FF;
	}

	@media(max-width: 767px){
		width: 85%;

		>p{
			display: flex;
			align-items: center;
			justify-content: flex-start;

			font-size: 13px;

			>span{
				font-size: 10px;
			}
		}

		>button{
			font-size: 12px;
		}

		>label{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 16px;
		}

		span{
			font-size: 14px;
		}

		>button{
			margin-top: 0px;

			font-size: 15px;

			padding: 12px 24px;
		}

		>label{
			margin-top: 0px;

			font-size: 15px;

			padding: 12px 24px;
		}
	}
`;

export const InputForm = styled.input<IInputForm>`
	width: 100%;

	padding: 4px 8px 8px;

	border: 0;
	border-bottom: ${props => !!props.hasError ? "2px solid #FF3838" : "1px solid #B6B6B6"};

	margin-bottom: 24px;

	background: transparent;

	@media(min-width: 1441px){
		padding: 8px;

		margin-bottom: 38px;
	}
`;

export const UserTypes = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	padding: 16px;

	background: #F5F5F5;

	margin-bottom: 24px;

	>div{
		display: flex;
		align-items: center;
		justify-content: center;

		border: 0;

		cursor: pointer;

		label{
			font-size: 14px;

			cursor: pointer;

			@media(max-width: 767px){
				font-size: 12px;
			}
		}

		& + div{
			margin-left: 48px;
		}
	}

	@media(min-width: 1441px){
		>div{
			>label{
				font-size: 18px;
			}
		}
	}
`;

export const OthersLoginOptions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 100%;

	.kep-login-facebook.metro {
	    border-radius: 5px;

	    >svg{
			margin-left: 35%;
	    	margin-right: 8px;
	    }
	}

	>span{
		width: 100%;
	}

	button{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		font-size: 12px;
		font-weight: 600;
		color: #666666;

		padding: 6px 18px;

		background: transparent;

		border: 1px solid #B6B6B6;
		border-radius: 5px;

		width: 100%;

		>p{
			margin: 0 auto;

			font-size: 15px;
		}

		& + span{
			margin-left: auto;
		}
	}

	@media(max-width: 767px){
		width: 85%;

		.kep-login-facebook.metro {
		    >svg{
		    	margin-right: 8px;
		    }
		}

		button{
			padding: 8px 18px;

			font-size: 9px;

			width: 100%;

			>svg{
				width: 15px;
				height: 15px;
			}

			>p{
				font-size: 9px;
			}
		}
	}

	@media(min-width: 1441px){
		.kep-login-facebook.metro {
		    >svg{
		    	width: 26px;
				height: 26px;
		    }
		}
	}
`;