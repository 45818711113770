import React, {lazy, Suspense} from 'react';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import ConvenienceComponent from './../../components/ConvenienceComponent/';

import { Link } from 'react-router-dom';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container,
		Content,
		InitialContainer,
		LocalizationAndDescriptionContainer,
		LocalizationContainer,
		CondominiumInformationsContainer,
		SizeContainer,
		RoomsContainer,
		GarageContainer,
		CondominiumFacilitiesContainer,
		PropertiesToRentContainer,
		PropertiesToRentToMobile,
		NeighborhoodConvenienceContainer,
		ConveniencesContainer,
		AnotherPropertiesContainer} from './styles';

import { CgPin } from 'react-icons/cg';
import { RiHotelBedLine, RiDragMove2Fill, RiBusLine } from 'react-icons/ri';
import { BiCar } from 'react-icons/bi';
import { MdSchool } from 'react-icons/md';
import { AiOutlineShopping } from 'react-icons/ai';

import fachadaImg from './../../assets/pages/CondominiumScreen/fachada.png';

import fitness from './../../assets/pages/CondominiumScreen/fitness.png';
import lavanderia from './../../assets/pages/CondominiumScreen/lavanderia.png';
import lounge from './../../assets/pages/CondominiumScreen/lounge.png';

import triangulosImg from './../../assets/pages/Imovel/triangulos.svg';

const HouseCondominium = lazy(() => import('./../../components/HouseCondominium/'));

export default function CondominiumScreen(){
	const settings = {
	    arrows: true,
	    speed: 500,
	    slidesToShow: 1,
	    slidesToScroll: 1,
	}

	const settingsPropertiesToRent = {
		dots: true,
	    arrows: true,
	    speed: 500,
	    slidesToShow: window.innerWidth > 767 ? 3 : 1,
	    slidesToScroll: 1,
	    touchMove: false
	}

	return(
		<Container>
			<Header hasSearchBar hasOptionToSearch/>
			<Content>
				<InitialContainer style={{
					background: `url(${fachadaImg})`,
					backgroundSize: window.innerWidth > 767 ? '100% 500px' : '100% 306px',
					backgroundRepeat: 'no-repeat'
				}}>
					<h1>J.Smart</h1>
					<h3>Inteligente e Eficiente Como Você</h3>

					<Link id="available-properties-link" to="/search/?city=Fortaleza&UF=CE&condominium=jsmart">VER IMÓVEIS DISPONÍVEIS</Link>

					{/*<a href="https://google.com">VER NO MAPA</a>*/}
				</InitialContainer>

				<LocalizationAndDescriptionContainer>
					<p>Um empreendimento inteligente e eficiente como você: apartamentos compactos, com baixo custo de manutenção e áreas compartilhadas como coworking, lavanderia compartilhada, academia, piscina, delivery space, pet place, espaço kids, playground, salão de festas e muito mais. O imóvel fica perto a tudo que você precisa para ter bem-estar e qualidade de vida, como: supermercados, shoppings, praças, farmácias, bares e restaurantes. Não perca tempo, venha conhecer o melhor do bairro Aldeota e construir momentos incríveis no que pode ser seu futuro lar!</p>
				</LocalizationAndDescriptionContainer>

				<CondominiumInformationsContainer>
					<SizeContainer>
						<RiDragMove2Fill size={33} color="#6EA5FF"/>
						<p>37 m² a 46 m²</p>
					</SizeContainer>

					<RoomsContainer>
						<RiHotelBedLine size={33} color="#6EA5FF"/>
						<p>Loft ou 1 quarto (suíte)</p>
					</RoomsContainer>

					<GarageContainer>
						<BiCar size={33} color="#6EA5FF"/>
						<p>1 vaga privativa</p>
					</GarageContainer>
				</CondominiumInformationsContainer>

				<CondominiumFacilitiesContainer>
					<h1>Instalações do condomínio</h1>

					<p>A melhor opção de smart home para você morar no melhor da Aldeota e pertinho de tudo. Unidades totalmente inteligentes e com as áreas compartilhadas na localização mais valorizada do empreendimento: o Rooftop.</p>

					<Slider {...settings}>
						<div>
							<img src={fitness} alt="img"/>
							<p>Academia</p>
						</div>

						<div>
							<img src={lavanderia} alt="img"/>
							<p>Lavanderia</p>
						</div>

						<div>
							<img src={lounge} alt="img"/>
							<p>Lounge</p>
						</div>
					</Slider>
				</CondominiumFacilitiesContainer>

				<img src={triangulosImg} alt="triangulosImg" />
			</Content>
				<NeighborhoodConvenienceContainer>
					<img src={triangulosImg} alt="triangulosImg" />

					<h1>Comodidades na vizinhança</h1>

					<ConveniencesContainer>
						<ConvenienceComponent
							icon={MdSchool}
							color="#F2C80E"
							title="EDUCAÇÃO"
							locals={['Colégio Batista Santos Dumont', 'Colégio Santa Cecília', 'Unichristus - Dom Luís']}
						/>
						<hr />
						<ConvenienceComponent
							icon={AiOutlineShopping}
							color="#00ABA6"
							title="COMÉRCIO E LAZER"
							locals={['Shopping Del Paseo', 'Mundo Pet Aldeota', 'El Chancho']}
						/>
						<hr />
						<ConvenienceComponent
							icon={RiBusLine}
							color="#FF5079"
							title="TRANSPORTE"
							locals={['Paradas de ônibus a poucos metros', 'Estações do Bicicletar próximas']}
						/>
						<hr />
						<ConvenienceComponent
							icon={CgPin}
							color="#B792D3"
							title="OUTROS"
							locals={['BB, Bradesco, Caixa, Itaú, Santander', 'Hospital Geral de Fortaleza', 'Praça das Flores']}
						/>
					</ConveniencesContainer>
				</NeighborhoodConvenienceContainer>

				<AnotherPropertiesContainer>
				</AnotherPropertiesContainer>

				<PropertiesToRentContainer>
					<h1>Imóveis para alugar neste empreendimento</h1>

{/*					<Slider {...settingsPropertiesToRent}>
						<HouseCardToTest />
						<HouseCardToTest />
						<HouseCardToTest />
						<HouseCardToTest />
					</Slider>*/}

{/*					<PropertiesToRentToMobile>
						<HouseCardToTest />
						<HouseCardToTest />
						<HouseCardToTest />
					</PropertiesToRentToMobile>*/}
				</PropertiesToRentContainer>
				<Suspense fallback={<div>Loading...</div>}>
					<HouseCondominium condominium={'jsmart'}/>
				</Suspense>

				<AnotherPropertiesContainer>
				</AnotherPropertiesContainer>
			<Footer/>
		</Container>
	);
};