import React from "react";
import { ClearCacheProvider } from "react-clear-cache";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "styled-components";

const theme = {
  typography: {},
  palette: {
    primary: {
      blue50: "rgb(241, 246, 255)",
      blue100: "rgb(210, 227, 255)",
      blue200: "rgb(188, 214, 255)",
      blue300: "rgb(158, 195, 255)",
      blue400: "rgb(139, 184, 255)",
      blue500: "rgb(110, 166, 255)",
      blue600: "rgb(100, 151, 232)",
      blue700: "rgb(78, 118, 181)",
      blue800: "rgb(61, 91, 140)",
      blue900: "rgb(46, 70, 107)",
    },
    secondary: {
      orange50: "rgb(252, 240, 235)",
      orange100: "rgb(244, 207, 193)",
      orange200: "rgb(239, 184, 163)",
      orange300: "rgb(232, 152, 120)",
      orange400: "rgb(228, 132, 94)",
      orange500: "rgb(221, 101, 54)",
      orange600: "rgb(201, 92, 49)",
      orange700: "rgb(157, 72, 38)",
      orange800: "rgb(122, 56, 30)",
      orange900: "rgb(93, 42, 23)",
    },
    greyscale: {
      grey50: "rgb(240, 240, 240)",
      grey100: "rgb(208, 208, 208)",
      grey200: "rgb(185, 185, 185)",
      grey300: "rgb(152, 152, 152)",
      grey400: "rgb(133, 133, 133)",
      grey500: "rgb(102, 102, 102)",
      grey600: "rgb(93, 93, 93)",
      grey700: "rgb(72, 72, 72)",
      grey800: "rgb(56, 56, 56)",
      grey900: "rgb(43, 43, 43)",
    },
    black: {
      default: "rgb(0, 9, 41)",
    },
  },
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <ClearCacheProvider duration={5000}>
        <App />
      </ClearCacheProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
