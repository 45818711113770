import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const InitialSectionContainer = styled.section`
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    padding: 0px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    padding-bottom: 40px;

    // MEDIUM
    @media(min-width: 768px){
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
        padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
    }

    // LARGE
    @media(min-width: 992px){
        flex-direction: row;
        justify-content: space-between;
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
        padding-right: 42px;
    }
`;

export const InitialSectionFormContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    
    h2.title {
        font-family: Montserrat;
        font-size: 37px;
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        letter-spacing: 0em;
        text-align: center;
    }
    
    p.description {
        font-family: Montserrat;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 22px;
    } 

    // LARGE
    @media(min-width: 992px){
        max-width: 475px;
        padding-top: 100px;
        width: 50%;

        h2.title {
            font-size: 48px;
            line-height: 60px;
            text-align: left;
        }
        
        p.description {
            font-size: 20px;
            text-align: left;
        } 
    }
`;

export const InitialSectionImageContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    
    img, video {
        width: 100%;
    }

    // MEDIUM
    @media(min-width: 768px){
        align-items: center;

        img, video {
            max-width: 600px;
        }
    }

    // LARGE
    @media(min-width: 992px){
        flex-direction: row;
        align-items: center;
        width: calc(100% - 475px);
        padding: 0px;
        padding-left: 60px;
        padding-top: 75px;

        img, video {
            width: calc(100%);
            max-width: 600px;
            margin: 0 auto;
        }
    }
`;