import React from "react";
import CardHeader from "../../../components/CardHeader";
import WrapperCard from "../../../components/WrapperCard/";
import { Link } from "react-router-dom";
import OptionsAccordion from "./OptionsAccordion";
import OptionsCard from "./OptionsCard";

interface IOffer {
  date: string;
  tenant: string;
  address: string;
  status: string;
  tenant_id: number;
  property_id: number;
}

export default function NewOffers({ offersList, smDown, ...props }: any) {
  // const [selectedStatus, setSelectedStatus] = useState("");

  // useEffect(() => {
  //   selectedStatus === ""
  //     ? setBillsList(initialBillsList)
  //     : setBillsList(
  //         initialBillsList.filter((bill) => bill.status === selectedStatus)
  //       );
  // }, [selectedStatus]);

  let content = smDown ? (
    <OptionsAccordion offers={offersList} />
  ) : (
    <div
      style={{
        rowGap: "0.5rem",
        display: "flex",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {offersList.map((offer: IOffer) => (
        <OptionsCard
          offer={offer}
          key={`${offer.date}, ${offer.property_id}, ${offer.tenant_id}`}
        />
      ))}
    </div>
  );

  return (
    <WrapperCard padding={smDown ? "0.8rem" : "2rem"} {...props}>
      <CardHeader
        title="Propostas de Contrato"
        flexDirection="column"
        alignItems="flex-start"
      >
        <p
          style={{
            textAlign: "left",
            fontSize: "0.8rem",
            fontWeight: 500,
            margin: "0.25rem 0 0",
          }}
        >
          Você pode aceitar, recusar ou negociar
        </p>
      </CardHeader>
      {content}
      <Link to={`/proposals`}>
        <p
          style={{
            fontSize: "0.9rem",
            textDecoration: "underline",
            cursor: "pointer",
            margin: "1.5rem 0 0",
          }}
        >
          Ver todas as propostas
        </p>
      </Link>
    </WrapperCard>
  );
}
