import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1180px;

	margin: 0 auto;

	padding: 32px 0 12%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	>h3{
		display: none;
	}

	>h1{
		display: none;
	}

	@media(max-width: 767px){
		align-items: center;

		flex-direction: column;

		>h3{
			display: flex;

			font-size: 17px;
			font-weight: 500;
			color: #B6B6B6;
		}

		>h1{
			display: flex;

			font-size: 26px;
			font-weight: bold;

			margin: 12px 0 24px;
		}
	}

	@media(min-width: 1441px){
		max-width: 1420px;

		padding: 42px 42px 12% 42px;
	}
`;

export const VisitPropertyCardContainer = styled.div`
	width: 1366px;

    >div{
    	display: inline-block;

    	margin-top: 18px;
  		margin-left: 18px;
    }

    @media(max-width: 767px){
    	width: 310px;

    	display: flex;
    	align-items: center;
    	justify-content: center;

    	flex-direction: column;

    	>div{
    		margin-top: 24px;
    		margin-left: 0;
    	}
    }
`;

export const EmptyVisitsContainer = styled.div`
	margin-top: 18px;

	width: 1200px;
	height: 700px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	>h1{
		font-size: 42px;
		font-weight: bold;
	}

	@media(max-width: 767px){
		margin-bottom: 24px;

		display: flex;
		align-items: flex-start;
		justify-content: center;

		width: 310px;
		height: auto;

		>h1{
			font-size: 32px;

			text-align: center;
		}
	}
`;


export const TitleAndImageContainer = styled.div`
	margin-top: 18px;
	margin-left: 32px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h4{
		font-size: 10px;
		color: #B6B6B6;

		margin-bottom: 14px;
	}

	>h1{
		font-size: 29px;
		font-weight: bold;

		margin-bottom: 15px;
	}

	>img{
		width: 280px;
		height: 300px;
	}

	@media(max-width: 767px){
		>h4{
			display: none;
		}

		>h1{
			display: none;
		}

		>img{
			width: 190px;
			height: 190px;

			margin-left: -24px;
		}
	}

	@media(min-width: 1441px){
		>h4{
			font-size: 12px;
		}

		>h1{
			width: 80%;

			font-size: 34px;
		}

		>img{
			width: 330px;
			height: 350px;
		}
	}
`;