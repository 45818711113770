import React, { useState, useCallback, FormEvent } from "react";
import { useHistory, useLocation } from "react-router";
import { useAuth } from "../../hooks/auth";
import {
  hasScheduleRequest,
  getScheduleRequest,
} from "../../utils/scheduleUtils";
import { Form, InputForm, UserTypes, OthersLoginOptions } from "./styles";
import { Radio } from "@material-ui/core";
import * as Yup from "yup";
import getValidationErrors from "./../../utils/getValidationErrors";
import MaskedInput from "react-text-mask";

interface IErrors {
  first_name?: string;
  email?: string;
  phone?: string;
  type?: string;
  password?: string;
  password_confirmation?: string;
}

interface IApiErrors {
  first_name?: string[];
  email?: string[];
  phone?: string[];
  type?: string[];
  password?: string[];
  password_confirmation?: string[];
}

interface IState {
  prevPath: string | undefined;
  username: string | undefined;
  user_email: string | undefined;
  user_password: string | undefined;
}

function isNationalNumber(number: string) {
  let length = 0;
  const splittedNum = number.split('');
  
  for(let i = 0; i < splittedNum.length; i++)
    length += !isNaN(parseInt(number[i])) ? 1 : 0; 
  
  console.log(length);
  
  return length <= 11;
}
const FormToRegisterPage: React.FC = () => {
  const { registerUser, signInFacebook } = useAuth();

  const history = useHistory();
  const { state } = useLocation<IState>();

  const [first_name, setFirst_Name] = useState(() => {
    if (!!state) {
      return state.username;
    }

    return "";
  });
  const [email, setEmail] = useState(() => {
    if (!!state) {
      return state.user_email;
    }

    return "";
  });
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [password, setPassword] = useState(() => {
    if (!!state) {
      return state.user_password;
    }

    return "";
  });
  const [password_confirmation, setPassword_Confirmation] = useState(() => {
    if (!!state) {
      return state.user_password;
    }

    return "";
  });

  const [isUsingFacebook, setIsUsingFacebook] = useState(() => {
    if (!!state) {
      return !!state.username;
    }

    return false;
  });

  const [errors, setErrors] = useState({} as IErrors);
  const [apiError, setApiError] = useState({} as IApiErrors);

  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setLoading(true);
      const formattedEmail = email?.trim();
      const formattedPhone = phone?.trim();
      const data = {
        first_name,
        email: formattedEmail,
        phone,
        type,
        password,
        password_confirmation,
      };

      // eslint-disable-next-line
      // const phoneRegExp = /\(\d{2,}\) \d{4,}\-\d{4}/g;

      try {
        const schema = Yup.object().shape({
          first_name: Yup.string().required("Nome obrigatório"),
          email: Yup.string()
            .email("E-mail inválido")
            .required("E-mail obrigatório"),
          phone: Yup.string()
            .required("Telefone obrigatório")
            .min(11, "Número inválido"),
          type: Yup.string().required("Escolha uma opção"),
          password: Yup.string()
            .required("Senha obrigatória")
            .min(6, "Senha deve conter pelo menos 6 caracteres"),
          password_confirmation: Yup.string()
            .oneOf([Yup.ref("password")], "Confirmação de senha incorreta")
            .required("Confirmação de senha obrigatória"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await registerUser({
          first_name,
          email,
          phone,
          type,
          password,
          password_confirmation,
        });

        setLoading(false);
        if (hasScheduleRequest()) {
          const scheduleRequest = getScheduleRequest();
          history.push(
            `/schedule/${scheduleRequest.propertyId}`,
            scheduleRequest.state
          );
        } else {
          history.push(`/`);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const findedErrors = getValidationErrors(err);

          setLoading(false);
          setErrors(findedErrors);
        } else {
          setLoading(false);
          setErrors({});
          setApiError(err.response.data.errors);
        }

        setLoading(false);
      }
    },
    [
      first_name,
      email,
      phone,
      type,
      password,
      password_confirmation,
      registerUser,
    ]
  );

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        hasError={!!errors.phone || !!apiError.phone}
      >
        <p>
          Nome:{" "}
          <span style={{ color: "#FF3838" }}>
            {apiError.first_name ? apiError.first_name[0] : errors.first_name}
          </span>
        </p>
        <InputForm
          hasError={!!errors.first_name || !!apiError.first_name}
          type="text"
          name="name"
          value={first_name}
          onChange={(e) => setFirst_Name(e.target.value)}
          readOnly={!!isUsingFacebook ? true : false}
        />

        <p>
          E-mail:{" "}
          <span style={{ color: "#FF3838" }}>
            {apiError.email ? apiError.email[0] : errors.email}
          </span>
        </p>
        <InputForm
          hasError={!!errors.email || !!apiError.email}
          type="text"
          name="email"
          value={email}
          onChange={(e) => {
            if (e.target.value === " ") {
              return;
            } else {
              setEmail(e.target.value);
            }
          }}
          readOnly={!!isUsingFacebook ? true : false}
        />

        <p>
          Telefone:{" "}
          <span style={{ color: "#FF3838" }}>
            {apiError.phone ? apiError.phone[0] : errors.phone}
          </span>
        </p>
        {isNationalNumber(phone) ? <MaskedInput
          mask={[
            "(",
            /\d/,
            /\d/,
            ")",
            " ",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          id="mask-input"
          type="tel"
          name="phone"
          guide={false}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        /> :
        <MaskedInput
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
          ]}
          id="mask-input"
          type="tel"
          name="phone"
          guide={false}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
}
        <p style={{ color: "#FF3838" }}>
          {apiError.type ? apiError.type[0] : errors.type}
        </p>
        <UserTypes>
          <div onClick={() => setType("inquilino")}>
            <Radio
              checked={type === "inquilino"}
              value="inquilino"
              name="selector"
              onChange={() => setType("inquilino")}
            />
            <label>Sou Inquilino</label>
          </div>

          <div onClick={() => setType("proprietario")}>
            <Radio
              checked={type === "proprietario"}
              value="proprietario"
              name="selector"
              onChange={() => setType("proprietario")}
            />
            <label>Sou Proprietário</label>
          </div>
        </UserTypes>

        <p style={{ display: !!isUsingFacebook ? "none" : "unset" }}>
          Criar senha:{" "}
          <span style={{ color: "#FF3838" }}>
            {apiError.password ? apiError.password[0] : errors.password}
          </span>
        </p>
        <InputForm
          hasError={!!errors.password || !!apiError.password}
          type="password"
          name="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          readOnly={!!isUsingFacebook ? true : false}
          style={{ display: !!isUsingFacebook ? "none" : "unset" }}
        />

        <p style={{ display: !!isUsingFacebook ? "none" : "unset" }}>
          Confirmar senha:{" "}
          <span style={{ color: "#FF3838" }}>
            {apiError.password_confirmation
              ? apiError.password_confirmation[0]
              : errors.password_confirmation}
          </span>
        </p>
        <InputForm
          hasError={
            !!errors.password_confirmation || !!apiError.password_confirmation
          }
          type="password"
          name="password_confirmation"
          value={password_confirmation}
          onChange={(e) => setPassword_Confirmation(e.target.value)}
          readOnly={!!isUsingFacebook ? true : false}
          style={{ display: !!isUsingFacebook ? "none" : "unset" }}
        />

        {!loading ? (
          <button type="submit">CRIAR MINHA CONTA</button>
        ) : (
          <label>Carregando...</label>
        )}
      </Form>
    </>
  );
};

export default FormToRegisterPage;
