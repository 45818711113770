import React from "react";

import { Link } from "react-router-dom";

import { useAuth } from "./../../hooks/auth";

import {
  Container,
  Content,
  LoginContainer,
  About7CantosContainer,
  UserRoutesContainer,
  FirstOptionsContainer,
  SecondOptionsContainer,
  RoutesContainer,
  SettingsContainer,
} from "./styles";

import { FaHeart, FaCalendarAlt } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BiX } from "react-icons/bi";

import clube7CantosImg from "./../../assets/components/Menu/7-cantos.png";
import logo7cantosImg from "./../../assets/components/Menu/7-cantos-logo.png";

import CreditCardIcon from "./../../assets/components/Menu/creditCardIcon.svg";
import PaperIcon from "./../../assets/components/Menu/paper.png";
import { VscChecklist } from "react-icons/vsc";
import { MdDashboard } from "react-icons/md";

interface IMenuInquilinoToMobile {
  functionToCloseMenuInquilinoToMobile(): void;
}

export default function MenuInquilinoToMobile({
  functionToCloseMenuInquilinoToMobile,
}: IMenuInquilinoToMobile) {
  const { user, logOut } = useAuth();

  return (
    <Container>
      <Content>
        <button onClick={functionToCloseMenuInquilinoToMobile}>
          <BiX size={32} color="#FFF" />
        </button>

        <LoginContainer>
          {!user && (
            <>
              <h2>ACESSE SUA CONTA</h2>

              <Link
                to={{
                  pathname: "/login",
                  state: { fromSite: true },
                }}
              >
                ENTRAR
              </Link>
            </>
          )}

          {!!user && <h2>Olá, {user.first_name}</h2>}
        </LoginContainer>

        <hr />

        <About7CantosContainer>
          <Link to="/about-us">
            <img src={logo7cantosImg} alt="logo7cantosImg" />
            <p>Sobre a 7Cantos</p>
          </Link>

          <a href="https://clube7cantos.com.br/">
            <img src={clube7CantosImg} alt="clube7CantosImg" />
          </a>
        </About7CantosContainer>

        <hr />

        <UserRoutesContainer>
          {!!user && <h3>INQUILINO</h3>}

          <RoutesContainer>
            <FirstOptionsContainer>
              <Link
                to={
                  !!user
                    ? "/favorites"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaHeart size={28} color="#FFF" />
                <p>Meus Favoritos</p>
              </Link>

              <Link
                to={
                  !!user
                    ? "/scheduled-visits"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaCalendarAlt size={28} color="#FFF" />
                <p>Visitas Agendadas</p>
              </Link>
            </FirstOptionsContainer>

            <SecondOptionsContainer>
              <Link
                to={
                  !!user
                    ? "/proposals"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <GiWallet size={28} color="#FFF" />
                <p>Propostas Enviadas</p>
              </Link>
              <Link
                to={
                  !!user
                    ? "/schedule-process"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <VscChecklist size={28} color="#FFF" />
                <p>Acompanhar Locação</p>
              </Link>
            </SecondOptionsContainer>

            <SecondOptionsContainer>
              {!!user && (
                <>
                  <a href="https://checkout-parceleja.cappta.com.br/7cantos">
                    <img src={CreditCardIcon} alt="creditCardIcon" />
                    <p>Parcelamento de boletos</p>
                  </a>

                  <Link
                    to={
                      !!user
                        ? "/request-termination"
                        : {
                            pathname: "/login",
                            state: { fromSite: true },
                          }
                    }
                  >
                    <img src={PaperIcon} alt="paper-icon" />
                    <p>Rescisão de Contrato</p>
                  </Link>
                </>
              )}
            </SecondOptionsContainer>
            <SecondOptionsContainer>
              <Link to={"/tenant-dashboard"}>
                <MdDashboard size={27} color="#FFF" />
                <p>Área do {!!user ? 'Locatário' : 'Cliente'}</p>
              </Link>
            </SecondOptionsContainer>
          </RoutesContainer>
        </UserRoutesContainer>

        <hr />

        {!!user && (
          <SettingsContainer>
            {/*<Link to="/settings">CONFIGURAÇÕES</Link>*/}
            <Link to="/me">Editar Perfil</Link>

            <button onClick={logOut}>Sair</button>
          </SettingsContainer>
        )}
      </Content>
    </Container>
  );
}
