import React, { useState, useCallback } from 'react';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';
import StepToIndicateProperty from './../../components/StepToIndicateProperty/';

import ModalIndicateProperty from './../../components/ModalIndicateProperty/';

import {Container, 
		Content,
		InitialContainer,
		IndicateContainer,
		StepsToIndicateContainer,
		WhereIndicate,
		TextContainer} from './styles';

import indicateImg from './../../assets/pages/IndicateProperty/indicateImg.svg';
import triangulosImg from './../../assets/pages/AboutUs/triangulos.svg';

export default function IndicateProperty(){
	const [openModalIndicateProperty, setOpenModalIndicateProperty] = useState(false);

	const handleOpenModalIndicateProperty = useCallback(() => {
		setOpenModalIndicateProperty(true);
	}, []);

	const handleCloseModalIndicateProperty = useCallback(() => {
		setOpenModalIndicateProperty(false);
	}, []);

	return (
		<Container>
			<Header hasBorder={window.innerWidth > 767 ? true : false} hasSearchBar hasOptionToSearch/>

			<ModalIndicateProperty open={openModalIndicateProperty} functionToCloseModalIndicateProperty={handleCloseModalIndicateProperty} />


			<Content>
				<InitialContainer>
					<IndicateContainer>
						<h1>Indique imóveis e fature sem limites!</h1>

						<p>Indique imóveis para a 7Cantos e receba <strong>R$ 100</strong> por cada imóvel publicado. Não tem limites para indicar e faturar!</p>
					
						<button onClick={handleOpenModalIndicateProperty}>INDICAR IMÓVEL</button>
					</IndicateContainer>

					<img src={indicateImg} alt="indicateImg"/>
				</InitialContainer>

				<StepsToIndicateContainer>
					<StepToIndicateProperty color="#B792D3" number="1" step="CADASTRE O IMÓVEL RESIDENCIAL PELO SITE" description="Preencha o formulário com os dados do proprietário ou responsável pela locação. Depois, é só aguardar o contato da nossa equipe. Fique sempre atento ao regulamento."/>
					<StepToIndicateProperty color="#FF5079" number="2" step="O IMÓVEL SENDO PUBLICADO, VOCÊ GARANTE R$ 100" description="Validamos a sua indicação e entramos em contato com o proprietário. Se ele autorizar, publicamos o imóvel em nosso site e você recebe em sua conta o valor de R$ 100."/>
					<StepToIndicateProperty color="#6EA5FF" number="3" step="VOCÊ RECEBE O DINHEIRO DIRETO NA SUA CONTA" description="É só deixar seus dados de pagamento com nossa equipe e em até 15 dias úteis, a contar da data da publicação do imóvel, o depósito é feito em sua conta bancária."/>
				</StepsToIndicateContainer>

				<StepsToIndicateContainer>
					<StepToIndicateProperty color="#00ABA6" number="4" step="SEM LIMITES PARA INDICAR" description="Aqui na 7Cantos você não tem limites para faturar e pode indicar quantos imóveis quiser. Por exemplo: se indicar 10 imóveis e todos forem publicados no site, você recebe R$ 700 em sua conta."/>
					<StepToIndicateProperty color="#F2C80E" number="5" step="VEJA NO PRÉDIO QUE VOCÊ MORA, PROCURE NA INTERNET OU CONSULTE OS AMIGOS" description="Busque nas redes sociais, grupos de WhatsApp e entre amigos, vocês podem faturar juntos. Peça ao porteiro do seu prédio e dos prédios vizinhos o contato dos proprietários dos imóveis ou procure em classificados online."/>
				</StepsToIndicateContainer>

				<img src={triangulosImg} alt="triangulosImg"/>

				<WhereIndicate>
					<TextContainer>
						<h1>Onde posso indicar imóveis para alugar?</h1>

						<h2>FORTALEZA, CEARÁ</h2>
						<h2>SALVADOR, BAHIA</h2>
						<h2>RECIFE, PERNAMBUCO</h2>
						<h2>TERESINA, PIAUÍ</h2>

						<p>* A premiação é válida para imóveis que ainda não foram indicados ou publicados em nosso site e que façam parte da nossa área de atuação. Confira o nosso regulamento.</p>
					</TextContainer>
				</WhereIndicate>
			</Content>
			<Footer />
		</Container>
	);
};