import styled from 'styled-components';

export const Container = styled.div`
`;

export const Footer = styled.footer`
	max-width: 100%;
	padding: 48px 80px 24px;

	background: #F9F9F9;
	
	>p{
		font-size: 9px;

		text-align: center;
		margin-top: 32px;

		@media(max-width: 767px){
			font-size: 9px;
		}
	}

	@media(min-width: 1441px){
		max-width: 100%;
		padding: 48px 100px 24px;

		>p{
			font-size: 12px;
		}
	}
`;

export const FooterContent = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media(max-width: 767px){
		flex-direction: column;
		align-items: center;
	}
`;

export const ContactContainer = styled.div`
	display: flex;
	flex-direction: column;

	margin-right: 24px;

	>img{
		width: 130px;
		height: 35px;
	}

	p{
		font-size: 13px;
		font-weight: 600;
	}

	@media(max-width: 767px){
		align-items: center;
		justify-content: center;

		margin-right: 0;

		>img{
			width: 138px;
			height: 39px;
		}

		>p{
			font-size: 14px;
		}
	}

	@media(min-width: 1441px){
		>img{
			width: 150px;
			height: 43px;
		}

		p{
			font-size: 15px;
		}
	}
`;

export const SocialMedias = styled.div`
	display: flex;
	align-items: center;

	margin-top: 24px;

	a{
		>img{
			width: 23px;
			height: 23px;
		}

		& + a{
			margin-left: 12px;
		}
	}

	@media(min-width: 1441px){
		a{
			>img{
				width: 26px;
				height: 26px;
			}
		}
	}
`;

export const SimiliarContents = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 7.5%;

	b{
		font-size: 14px;

		margin-bottom: 24px;
	}

	a{
		text-decoration: none;

		font-size: 12px;
		color: #666666;

		margin-bottom: 12px;
	}

	@media(max-width: 767px){
		margin-top: 48px;

		align-items: center;
		justify-content: center;
	}

	@media(min-width: 1441px){
		>b{
			font-size: 16px;
		}

		>a{
			font-size: 14px;
		}
	}
`;
