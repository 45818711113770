import React, { forwardRef } from "react";
import { Content } from "./style";

const WrapperCard = forwardRef(function WrapperCard(
  { children, ...props }: any,
  ref: any
) {
  return (
    <Content ref={ref} style={{ ...props }}>
      {children}
    </Content>
  );
});
export default WrapperCard;
