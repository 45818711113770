import styled from "styled-components";

interface IInputForm{
	hasError: boolean;
}

export const Form = styled.form`
	width: 92%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	margin-bottom: 24px;

	>p{
		font-size: 14px;
	}

	span{
		font-size: 12px;
	}

	>h3{
		color: #FF3838;

		margin-bottom: 18px;
	}

	>button{
		width: 100%;

		padding: 9px 24px;

		background: #6EA5FF;

		border: 0;

		font-size: 14px;	
		font-weight: 600;
		color: #FFF;
	}

	>label{
		width: 100%;

		background: #FFF;

		border: 2px solid #6EA5FF;

		padding: 9px 24px;

		font-size: 14px;
		font-weight: 600;
		color: #6EA5FF;
	}

	@media(max-width: 767px){
		width: 85%;

		>p{
			font-size: 13px;
		}

		>button{
			padding: 10px 24px;

			font-size: 12px;
		}

		>label{
			padding: 14px 24px;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 16px;
		}

		>button{
			padding: 10px 24px;

			font-size: 15px;
		}

		>label{
			padding: 10px 24px;

			font-size: 15px;
		}
	}
`;

export const InputForm = styled.input<IInputForm>`
	width: 100%;

	padding: 8px;

	border: 0;
	border-bottom: ${props => !!props.hasError ? "2px solid #FF3838" : "1px solid #B6B6B6"};

	margin-bottom: 24px;

	background: transparent;

	font-size: 14px;

	@media(min-width: 1441px){
		margin-bottom: 42px;
	}
`;

export const OthersLoginOptions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	width: 92%;

	.kep-login-facebook.metro {
	    border-radius: 5px;

	    >svg{
			margin-left: 35%;
	    	margin-right: 8px;
	    }
	}

	>span{
		width: 100%;
	}

	button{
		display: flex;
		align-items: center;
		justify-content: flex-start;

		font-size: 14px;
		font-weight: 600;
		color: #666666;

		padding: 2px 18px;

		background: transparent;

		border: 1px solid #B6B6B6;
		border-radius: 5px;

		width: 100%;

		>p{
			margin: 0 auto;

			font-size: 15px;
		}

		& + span{
			margin-left: auto;
		}
	}

	@media(max-width: 767px){
		width: 85%;

		.kep-login-facebook.metro {
		    >svg{
		    	margin-right: 8px;
		    }
		}

		button{
			padding: 8px 18px;

			font-size: 12px;

			width: 100%;

			>svg{
				width: 15px;
				height: 15px;
			}

			>p{
				font-size: 9px;
			}
		}
	}

	@media(min-width: 1441px){
		button{
			font-size: 12px;
		}
	}
`;