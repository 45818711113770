import styled, { keyframes } from "styled-components";

export const HouseCardToFavoritePageContainer = styled.div`
	width: 1366px;

    >div{
    	display: inline-block;

    	margin-top: 18px;
  		margin-left: 18px;
    }

   	@media(max-width: 767px){
    	width: 310px;

    	display: flex;
    	align-items: center;
    	justify-content: center;

    	flex-direction: column;

    	>div{
    		margin-top: 24px;
    		margin-left: 0;
    	}
    }
`;

export const EmptyFavoritesContainer = styled.div`
	margin-top: 18px;

	width: 1200px;
	height: 700px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	>h1{
		font-size: 42px;
		font-weight: bold;
	}

	@media(max-width: 767px){
		margin-bottom: 24px;

		display: flex;
		align-items: flex-start;
		justify-content: center;

		width: 310px;
		height: auto;

		>h1{
			font-size: 32px;

			text-align: center;
		}
	}
`;

export const Card = styled.div`
	width: 250px;

	border: 1px solid #B6B6B6;
	border-radius: 16px;

	cursor: pointer;

	img{
		width: 100%;
		height: 175px;

		border-radius: 16px 16px 0 0;
	}

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

	.slick-prev, 
	.slick-next{
		background: #FFF;
		position: absolute;
	    top: 50%;
	    z-index: 1;
	    border-radius: 50%;
	}

	.slick-next{
		right: 8px;
	}

	.slick-prev{
		left: 8px;
	}

	.slick-dots li button:before {
	   	color: #212121;
	}

	.slick-dots{
		bottom: 12px;
	}

	@media(min-width: 1441px){
		width: 295px;

		img{
			height: 190px;
		}
	}
`;

const load = keyframes`
	from{
		left: -150px;
	}

	to{
		left: 100%;
	}
`;

export const LoadingImageContainer = styled.div`
	width: 250px;
	height: 175px;

	position: relative;

	overflow: hidden;

	&::before{
		content: '';
		display: block;
		position: absolute;
		left: -150px;
		top: 0;
		height: 100%;
		width: 250px;
		background: linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%);
		animation: ${load} 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
	}

	@media(min-width: 1441px){
		width: 295px;
		height: 190px;
	}
`;

export const HouseInformationsAndActionsContainer = styled.div`
	padding: 12px 18px;

	border-bottom: 1px solid #B6B6B6;

	@media(min-width: 1441px){
		padding: 12px 21px;
	}
`;

export const HouseInformationsAndActions = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>span{
		margin-top: 8px;
		height: 32px;

		font-size: 11px;
	}

	@media(min-width: 1441px){
		font-size: 13px;
	}
`;

export const HouseTitleAndAction = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const TitleContainer = styled.div`
	>h3{
		font-size: 16px;
		color: #6EA5FF;
		font-weight: bold;
	}

	@media(min-width: 1441px){
		>h3{
			font-size: 18px;
		}
	}
`;

export const Actions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-left: 32px;
	
	>button{
		background: transparent;
		border: 0;
	}

	>img{
		width: 24px;
		height: 24px;
	}

	>a{
		margin-left: 8px;
	}

	@media(min-width: 1441px){
		svg{
			width: 20px;
			height: 20px;
		}
	}
`;

export const HouseAdditionalInfoemationsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 12px;

	border-bottom: 1px solid #B6B6B6;

	>div{
		display: flex;
		align-items: center;

		>span{
			font-size: 11px;

			margin-left: 4px;
		}

		& + div{
			margin-left: 14px;
		}
	}

	@media(min-width: 1441px){
		padding: 15px 12px;

		>div{
			>svg{
				width: 15px;
				height: 15px;
			}

			>span{
				font-size: 13px;
			}

			& + div{
				margin-left: 22px;
			}
		}
	}
`;

export const HousePrice = styled.div`
	text-align: center;

	padding: 12px 0;

	>b{
		font-size: 12px;
	}

	>h2{
		font-size: 15px;
		color: #DD6435;
		font-weight: bold;

		margin: 6px 0;
	}

	>a{
		text-decoration: none;
		color: #6EA5FF;
		font-weight: 600;
		font-size: 12px;
	}

	@media(min-width: 1441px){
		padding: 15px 0;

		>b{
			font-size: 13px;
		}

		>h2{
			font-size: 17px;

			margin: 4px 0 12px;
		}

		>a{
			font-size: 13px;
		}
	}
`;