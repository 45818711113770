import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  NewcomersPropertiesSectionContainer,
  NewcomersPropertiesSectionHeaderContainer,
  NewcomersPropertiesSectionCardsContainer,
} from "./styles";

import PropertyCard from "../PropertyCard";

import api from "../../../services/api";

import formatValue from "./../../../utils/formatValue";

interface IApiPhotos {
  id: number;
  url_m: string;
  url_s: string;
}

interface IApiAddress {
  id: number;
  city: string;
  address: string;
  neighborhood: string;
}

interface IApiResults {
  id: number;
  title: string;
  area: number;
  rooms: number;
  address: IApiAddress;
  rent_value: string;
  photos: IApiPhotos[];
}

export default function NewcomersPropertiesSection() {
  const [properties, setProperties] = useState<IApiResults[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNewcomersPropertiesHandle();
  }, []);

  const getNewcomersPropertiesHandle = async () => {
    const response = await api.get("/api/client/properties", {
      params: { focus: true },
    });
    setProperties(response.data.data.slice(0, 4));
    setLoading(false);
  };

  return (
    <NewcomersPropertiesSectionContainer>
      <NewcomersPropertiesSectionHeaderContainer>
        <h3 className="title">Imóveis recém chegados</h3>
        <p className="description">
          Anúncios quentinhos! Veja antes de todo mundo.
        </p>
      </NewcomersPropertiesSectionHeaderContainer>

      <NewcomersPropertiesSectionCardsContainer>
        {properties.map((property) => (
          <Link to={`/imovel/${property.id}`} key={property?.id}>
            <PropertyCard
              name={property?.title}
              generalInfo={`${property?.area} m² | ${property?.rooms} quartos`}
              address={property?.address?.address}
              city={property?.address?.city}
              price={formatValue(parseFloat(property?.rent_value))}
              imageUrl={property?.photos[0]?.url_m}
            />
          </Link>
        ))}
      </NewcomersPropertiesSectionCardsContainer>
    </NewcomersPropertiesSectionContainer>
  );
}
