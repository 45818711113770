import { useHistory } from "react-router-dom";
import STEPS from "../../pages/ScheduleProcess/enum";
import ModalSendProposal from "../ModalSendProposal";
import ScheduleProcessBeltComponent from "../ScheduleProcessBeltComponent";
import {
  GrayLineContainer,
  OrangeLineContainer,
  StepsContainer,
} from "./styles";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../services/api";

interface IRentInformation {
  id: number;
  status: string;
  property_id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  photo: string;
}

interface IScheduleProcessBelt {
  rentInformation: IRentInformation;
  currentStep: number;
}

const ScheduleProcessBelt = ({
  rentInformation,
  currentStep,
}: IScheduleProcessBelt) => {
  const [openModalSendProposal, setOpenModalSendProposal] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isDownloadingContract, setIsDownloadingContract] = useState(false);
  const [errorInDownloadingContract, setErrorInDownloadingContract] =
    useState(false);
  const history = useHistory();
  const NUMBER_OF_STEPS = 4; //Checar no enum STEPS e remover 1

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const orangeLineWidth = useMemo(() => {
    const tempCurrentStep =
      currentStep > NUMBER_OF_STEPS ? NUMBER_OF_STEPS : currentStep;
    const absoluteWidth = (tempCurrentStep - 1) / (NUMBER_OF_STEPS - 1);
    const relativeWidth = absoluteWidth * 0.75;
    const finalWidth = relativeWidth * 100;
    return `${finalWidth.toFixed(0)}%`;
  }, [currentStep]);

  const orangeLineHeight = useMemo(() => {
    const tempCurrentStep =
      currentStep > NUMBER_OF_STEPS ? NUMBER_OF_STEPS : currentStep;
    const absoluteHeight = (tempCurrentStep - 1) / (NUMBER_OF_STEPS - 1);
    const finalWidth = absoluteHeight * 0.8 * 100;
    return `${finalWidth.toFixed(0)}%`;
  }, [currentStep]);

  const horizontalLinesLeftDist = useMemo(() => {
    const fractionedWindowWidth = (windowDimensions.width * 9.5) / 100;
    const leftDist = fractionedWindowWidth + 11;
    return `${leftDist}px`;
  }, [windowDimensions]);

  const toScheduledVisitsHandler = useCallback(() => {
    history.push("/scheduled-visits");
  }, []);

  const openModalSendProposalHandler = useCallback(() => {
    setOpenModalSendProposal(true);
  }, []);

  const toSendDocumentationHandler = useCallback(() => {
    history.push("/send-documentation", {
      property_id: rentInformation.property_id,
      rent_process_id: rentInformation.id,
      isToSendExpressOffer: true,
    });
  }, [rentInformation, history]);

  const toViewContractHandler = useCallback(() => {
    history.push("/sign-contract", {
      rent_process_id: rentInformation.id,
    });
  }, [rentInformation, history]);

  const toDownloadContractHandler = useCallback(async () => {
    setIsDownloadingContract(true);
    try {
      const response = await api.get(
        `/api/client/rent-contract/dowload/${rentInformation.id}`
      );
      const url = response.data.data.url;
      if (!!url) {
        window.open(url, "_blank");
      }
    } catch (error) {
      setErrorInDownloadingContract(true);
    }
    setIsDownloadingContract(false);
  }, []);

  const getStepProperties = useCallback(
    (currentStep) => {
      return [
        {
          stepLabel: "Visita",
          buttonLabel: "Visita Agendada",
          isActive: false,
          onClick: toScheduledVisitsHandler,
        },
        {
          stepLabel: "Proposta",
          buttonLabel:
            currentStep === STEPS.SCHEDULE_DONE
              ? "Fazer Proposta"
              : "Ver Proposta",
          isActive: currentStep === STEPS.SCHEDULE_DONE,
          onClick: openModalSendProposalHandler,
        },
        {
          stepLabel: "Análise de Crédito",
          buttonLabel:
            currentStep === STEPS.SCHEDULE_DONE ||
            currentStep === STEPS.OFFER_DONE
              ? "Enviar Documentos"
              : currentStep === STEPS.DOCUMENTS_TO_ANALYSIS
              ? "Documentação em Análise"
              : "Documentação Aprovada",
          isActive:
            currentStep === STEPS.SCHEDULE_DONE ||
            currentStep === STEPS.OFFER_DONE,
          onClick:
            currentStep >= STEPS.DOCUMENTS_TO_ANALYSIS
              ? () => {
                  return;
                }
              : toSendDocumentationHandler,
        },
        {
          stepLabel: "Contrato",
          buttonLabel:
            currentStep === STEPS.CONTRACT_DONE
              ? "Ver Contrato"
              : currentStep === STEPS.CONTRACT_FINISHED
              ? isDownloadingContract
                ? "Baixando..."
                : errorInDownloadingContract
                ? "Erro!"
                : "Baixar Contrato"
              : "",
          isActive:
            currentStep === STEPS.CONTRACT_DONE ||
            currentStep === STEPS.CONTRACT_FINISHED,
          onClick:
            currentStep === STEPS.CONTRACT_DONE
              ? toViewContractHandler
              : toDownloadContractHandler,
        },
      ];
    },
    [
      openModalSendProposalHandler,
      toScheduledVisitsHandler,
      toSendDocumentationHandler,
      toViewContractHandler,
      toDownloadContractHandler,
      isDownloadingContract,
    ]
  );

  return (
    <>
      {!!openModalSendProposal && (
        <ModalSendProposal
          rentInformation={rentInformation}
          functionToCloseModalSendProposal={() =>
            setOpenModalSendProposal(false)
          }
        />
      )}

      <StepsContainer>
        <GrayLineContainer leftDist={horizontalLinesLeftDist} />
        <OrangeLineContainer
          lineWidth={orangeLineWidth}
          leftDist={horizontalLinesLeftDist}
          lineHeight={orangeLineHeight}
        />
        {getStepProperties(currentStep).map((step, index) => {
          return (
            <ScheduleProcessBeltComponent
              step={index + 1}
              stepLabel={step.stepLabel}
              buttonLabel={step.buttonLabel}
              isCurrentOrOld={index + 1 <= currentStep ? true : false}
              isActive={step.isActive}
              onClick={step.onClick}
            />
          );
        })}
      </StepsContainer>
    </>
  );
};

export default ScheduleProcessBelt;
