import { TextField, Theme, createStyles, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
createStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}),
);


function getCurrentDate(date: Date){
  return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2,'0')}`
}

export default function MonthPicker({ onDateSelect }: any) {

const classes = useStyles();
  return (
    <TextField
    id="date"
    label="Selecione um Período"
    type="month"
    className={classes.textField}
    InputLabelProps={{
      shrink: true,
    }}
    defaultValue={getCurrentDate(new Date())}
    onChange={(event) => onDateSelect(event.target.value)}
  />
  );
}
