import styled from 'styled-components';

import { Link } from 'react-router-dom';

interface IRouteOptions{
	hasUser: boolean;
}


export const Container = styled.div`
	&::before{
		content: '';
		border-style: solid;
		border-color: #00ABA6 transparent;
		border-width: 0 12px 12px 12px;
		top: 60px;
		position: absolute;	
		transform: translateX(-50%);
		z-index: 5;
	}

	@media(min-width: 1441px){
		&::before{
			content: '';
			border-style: solid;
			border-color: #00ABA6 transparent;
			border-width: 0 12px 12px 12px;
			top: 60px;
			position: absolute;
			transform: translateX(-50%);
			z-index: 5;
		}
	}

	// MEDIUM

    @media(min-width: 768px){

		&::before{
			right: 27px;
		}
	}

    // LARGE

    @media(min-width: 992px){
		&::before{
			right: 86px;
		}
	}
`;

export const Content = styled.div`
	overflow: auto;
	width: 350px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	position: absolute;
	top: 70px;
	right: 20px;
	z-index: 4;
	-webkit-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 10px 18px 0px rgba(0,0,0,0.75);
	background: #00ABA6;
	color: #FFF;

	a {
		text-decoration: none;
		color: #FFF;
	
		>img {
			width: 27px;
			height: 27px;
		}
	}
`;

export const CompanyContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	padding: 24px 0;
`;

export const About7CantosContainer = styled(Link)`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-decoration: none;

	>img{
		margin-bottom: 12px;

		width: 27px;
		height: 27px;
	}

	>p{
		font-size: 14px;
	}

	& + a{
		margin-left: 32px;

		>img{
			width: 90px;
			height: 60px;
		}
	}

`;

export const RouteOptions = styled.div<IRouteOptions>`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 18px 0 28px 0px;

	>h3{
		font-size: 18px;
	}
`;

export const Options = styled.div`
	margin-left: 24px;
	
	>div{
		width: 100px;

		display: inline-block;
		text-align: center;
			
		margin-top: 24px;
		margin-left: 36px;

		p{
			width: 100%;

			font-size: 14px;

			margin: 12px auto 0 auto;
		}
	}
`;

export const UserActions = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding: 18px 0 19px 0;

	>a{
		& + a{
			margin-top: 12px;
		}
	}

	button{
		margin-top: 18px;

		background: transparent;

		border: 0;

		color: #FFF;
		font-weight: bold;
	}
`;