import styled from 'styled-components';

export const Container = styled.section`

    display: flex;
    flex-direction: row;
    gap: 0;
    width: 100%;

    button {
        font-family: Montserrat;
        border: 2px solid #6EA6FF;
        height: 50px;
        flex-grow: 1;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        color: #6EA6FF;
        background-color: transparent;
        width: 100%;
    }

    button.active {
        color: #FFFFFF;
        background-color: #6EA6FF;
    }

    // MEDIUM
    @media(min-width: 768px){
    
    }

    // LARGE
    @media(min-width: 992px){
        
    }
`;

export const ButtonOption1 = styled.button`
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
`;

export const ButtonOption2 = styled.button`
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
`;