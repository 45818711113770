import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1180px;

	margin: 0 auto;

	padding: 54px 0 12%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	@media(max-width: 767px){
		align-items: center;

		flex-direction: column;
	}

	@media(min-width: 1441px){
		max-width: 1330px;
	}
`;

export const InitialContainerToMobile = styled.div`
	display: none;

	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		>h3{
			font-size: 17px;
			font-weight: 500;
			color: #B6B6B6;
		}

		>h1{
			font-size: 26px;
			font-weight: bold;

			margin: 12px 0 16px;
		}

		>img{
			width: 269px;
			height: 237px;

			margin-bottom: 32px;
		}
	}
`;


export const TitleAndImageContainer = styled.div`
	margin-top: 18px;
	margin-left: 32px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h4{
		font-size: 12px;
		color: #B6B6B6;

		margin-bottom: 8px;
	}

	>h1{
		width: 90%;

		font-size: 29px;
		font-weight: bold;

		margin-bottom: 32px;
	}

	>img{
		width: 270px;
		height: 270px;
	}

	@media(max-width: 767px){
		display: none;
	}

	@media(min-width: 1441px){
		>h4{
			font-size: 13px;
		}

		>h1{
			font-size: 35px;
		}

		>img{
			width: 300px;
			height: 300px;
		}
	}
`;