import React, { useCallback, useMemo, useState } from "react";

import {
  Container,
  Content,
  LocalAndActions,
  HourAndActions,
  ButtonsContainer,
} from "./styles";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ModalConfirmCancelVisit from "../ModalConfirmCancelVisit";
import { useAuth } from "../../hooks/auth";
import api from "../../services/api";
import ModalReSchedule from "../ModalReSchedule";
import { useRefresh } from "../../hooks/refresh";

interface IAddress {
  id: number;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
}

interface IPhotos {
  id: number;
  url_l: string;
  url_m: string;
  url_s: string;
}

interface IApiResult {
  property_id: number;
  first_name: string;
  title: string;
  date: string;
  address: IAddress;
  photos: IPhotos[];
  schedule_id: number;
  consultant_id: number;
}

export default function VisitPropertyCard({
  property_id,
  schedule_id,
  consultant_id,
  first_name,
  title,
  date,
  address,
  photos,
}: IApiResult) {
  const { access_token } = useAuth();
  const { handleRefresh } = useRefresh();

  const [scheduleID, setScheduleID] = useState<number>();
  const [openModalConfirmCancelVisit, setOpenModalConfirmCancelVisit] =
    useState(false);
  const [loadingCancelScheduleApiRequest, setLoadingCancelScheduleApiRequest] =
    useState(false);

  const [consultantID, setConsultantID] = useState<number>(0);
  const [propertyID, setPropertyID] = useState(0);
  const [scheduleIDToReSchedule, setScheduleIDToReSchedule] = useState(0);

  const [propertyIDToSendProposal, setPropertyIDToSendProposal] = useState(0);
  const [openModalSendProposal, setOpenModalSendProposal] = useState(false);

  const [openModalReSchedule, setOpenModalReSchedule] = useState(false);

  const settings = useMemo(() => {
    return {
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
  }, []);

  const formatTitle = useMemo(() => {
    if (title.length >= 18) {
      return `${title.substr(0, 18)}...`;
    }

    return title;
  }, [title]);

  const formatAddress = useMemo(() => {
    const addressFormatted = `${address.address}, ${address.neighborhood}, ${address.city} - ${address.uf}`;

    if (addressFormatted.length >= 55) {
      return `${addressFormatted.substr(0, 55)}...`;
    }

    return `${address.address}, ${address.neighborhood}, ${address.city} - ${address.uf}`;
  }, [address]);

  const formatInformation = useMemo(() => {
    return `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(
      0,
      4
    )} - ${date.substring(11, 13)}H`;
  }, [date]);

  const handleOpenModalCancelVisit = useCallback(() => {
    setScheduleID(schedule_id);
    setOpenModalConfirmCancelVisit(true);
  }, [schedule_id]);

  const handleCancelVisit = useCallback(async () => {
    setLoadingCancelScheduleApiRequest(true);

    api.defaults.headers.authorization = `Bearer ${access_token}`;

    await api
      .put(`/api/client/visits/${scheduleID}`)
      .catch((err) => console.log(err));

    setLoadingCancelScheduleApiRequest(false);
    setOpenModalConfirmCancelVisit(false);
    handleRefresh();
  }, [access_token, scheduleID, handleRefresh]);

  const handleOpenModalSendProposal = useCallback(() => {
    setPropertyIDToSendProposal(property_id);

    setOpenModalSendProposal(true);
  }, [property_id]);

  const handleOpenModalReSchedule = useCallback(() => {
    setConsultantID(consultant_id);
    setPropertyID(property_id);
    setScheduleIDToReSchedule(schedule_id);

    setOpenModalReSchedule(true);
  }, [consultant_id, property_id, schedule_id]);

  const handleCloseModalReSchedule = useCallback(() => {
    handleRefresh();

    setOpenModalReSchedule(false);
  }, [handleRefresh]);

  return (
    <>
      {!!openModalConfirmCancelVisit && (
        <ModalConfirmCancelVisit
          functionToConfirm={handleCancelVisit}
          functionToClose={() => setOpenModalConfirmCancelVisit(false)}
          isLoadingApiRequest={loadingCancelScheduleApiRequest}
        />
      )}

      {/* {!!openModalSendProposal && (
        <ModalSendProposal
          property_id={propertyIDToSendProposal}
          functionToCloseModalSendProposal={() =>
            setOpenModalSendProposal(false)
          }
        />
      )} */}

      {!!openModalReSchedule && (
        <ModalReSchedule
          consultantID={consultantID}
          propertyID={propertyID}
          scheduleID={scheduleIDToReSchedule}
          functionToCloseModalReSchedule={handleCloseModalReSchedule}
        />
      )}

      <Container>
        <Content>
          <Slider {...settings}>
            {photos.map((photo) => (
              <img key={photo.id} src={photo.url_m} alt="propertyImg" />
            ))}
          </Slider>

          <LocalAndActions>
            <h2>{formatTitle}</h2>

            <p>{formatAddress}</p>

            {/* <button id="button-propose" onClick={handleOpenModalSendProposal}>FAZER PROPOSTA</button> */}
            {/* <Link
              to={{
                pathname: "/send-documentation/",
                state: { property_id: property_id },
              }}
              id="button-document"
            >
              ENVIAR DOCUMENTOS
            </Link> */}
          </LocalAndActions>

          <HourAndActions>
            <h2>{formatInformation}</h2>

            <p>
              <b>Consultor: </b>
              {first_name}
            </p>

            <ButtonsContainer>
              <button
                id="reSchedule-button"
                onClick={handleOpenModalReSchedule}
              >
                REAGENDAR
              </button>
              <p>OU</p>
              <button id="cancel-button" onClick={handleOpenModalCancelVisit}>
                CANCELAR
              </button>
            </ButtonsContainer>
          </HourAndActions>
        </Content>
      </Container>
    </>
  );
}
