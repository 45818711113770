import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	width: 270px;

	border: 1px solid #B6B6B6;
	border-radius: 16px;

	img{
		width: 100%;
		height: 180px;

		border-radius: 16px 16px 0 0;
	}

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

	.slick-prev, 
	.slick-next{
		background: #FFF;
		position: absolute;
	    top: 50%;
	    z-index:1;
	    border-radius: 50%;
	}

	.slick-next{
		right: 8px;
	}

	.slick-prev{
		left: 8px;
	}

	.slick-dots li button:before {
	   	color: #212121;
	}

	.slick-dots{
		bottom: 12px;
	}

	@media(max-width: 767px){
		width: 310px;

		.slick-dots li button:before {
		   	color: #FFF;
		}
	}

	@media(min-width: 1441px){
		width: 290px;

		img{
			height: 200px;
		}
	}
`;

export const PropertyInformationsContainer = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	padding: 8px 24px;

	>h2{
		font-size: 17px;
		color: #6EA5FF;
		font-weight: bold;
	}

	>p{
		margin: 12px 0 18px 0;

		font-size: 13px;
	}

	@media(max-width: 767px){
		>h2{
			margin-top: 12px;

			font-size: 21px;
		}

		>p{
			font-size: 14px;
		}
	}

	@media(min-width: 1441px){
		>h2{
			font-size: 20px;
		}
		
		>p{
			font-size: 14px;
		}
	}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	padding-bottom: 32px;

	button#button-see-visits{
		background: #00BF33;

		border: 0;

		width: 70%;
		padding: 8px 0;

		font-size: 12px;
		font-weight: 500;
		color: #FFF;
	}

	>a{
		margin-top: 12px;
		
		background: transparent;

		text-decoration: none;
		text-align: center;

		font-size: 12px;
		font-weight: 500;
		color: #666666;

		border: 1px solid #666666;

		width: 70%;
		padding: 8px 0;
	}

	@media(max-width: 767px){
		>button{
			font-size: 12px;
		}

		>a{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		button#button-see-visits{
			font-size: 13px;
		}

		>a{
			font-size: 13px;
		}
	}
`;