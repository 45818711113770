import styled from 'styled-components';

export const LoadingContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	max-width: 100%;
	height: 100vh;

	margin: auto;
`;