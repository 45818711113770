import React from "react";

import { Link } from "react-router-dom";

import { useAuth } from "./../../hooks/auth";

import {
  Container,
  Content,
  About7CantosContainer,
  LoginContainer,
  UserRoutesContainer,
  FirstOptionsContainer,
  SecondOptionsContainer,
  RoutesContainer,
  SettingsContainer,
} from "./styles";

import { FaCalendarAlt } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BsHouseFill } from "react-icons/bs";
import { BiX } from "react-icons/bi";

import clube7CantosImg from "./../../assets/components/Menu/7-cantos.png";
import logo7cantosImg from "./../../assets/components/Menu/7-cantos-logo.png";

import financialIcon from "./../../assets/components/Menu/reportIcon.svg";
import CreditCardIcon from "./../../assets/components/Menu/creditCardIcon.svg";
import { MdDashboard } from "react-icons/md";

interface IMenuProprietarioToMobile {
  functionToCloseMenuProprietarioToMobile(): void;
}

export default function MenuProprietarioToMobile({
  functionToCloseMenuProprietarioToMobile,
}: IMenuProprietarioToMobile) {
  const { user, logOut } = useAuth();

  return (
    <Container>
      <Content>
        <button onClick={functionToCloseMenuProprietarioToMobile}>
          <BiX size={32} color="#FFF" />
        </button>

        <LoginContainer>
          {!user && (
            <>
              <h2>ACESSE SUA CONTA</h2>

              <Link
                to={{
                  pathname: "/login",
                  state: { fromSite: true },
                }}
              >
                ENTRAR
              </Link>
            </>
          )}

          {!!user && <h2>Olá, {user.first_name}</h2>}
        </LoginContainer>

        <hr />

        <About7CantosContainer>
          <Link to="/about-us">
            <img src={logo7cantosImg} alt="logo7cantosImg" />
            <p>Sobre a 7Cantos</p>
          </Link>

          <a href="https://clube7cantos.com.br/">
            <img src={clube7CantosImg} alt="clube7CantosImg" />
          </a>
        </About7CantosContainer>

        <hr />

        <UserRoutesContainer>
          {!!user && <h3>PROPRIETÁRIO</h3>}

          <RoutesContainer>
            <FirstOptionsContainer>
              <Link
                to={
                  !!user
                    ? "/my-properties"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <BsHouseFill size={28} color="#FFF" />
                <p>Meus Imóveis</p>
              </Link>

              <Link
                to={
                  !!user
                    ? "/scheduled-visits"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaCalendarAlt size={28} color="#FFF" />
                <p>Visitas Agendadas</p>
              </Link>

              {/*<Link to={!!user ? "/my-leases" : {
								pathname: "/login",
								state: {fromSite: true}
							}}>
								<FaKey size={28} color="#FFF"/>
								<p>Minhas Locações</p>
							</Link>*/}
            </FirstOptionsContainer>

            <SecondOptionsContainer>
              <Link
                to={
                  !!user
                    ? "/proposals"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <GiWallet size={28} color="#FFF" />
                <p>Propostas Recebidas</p>
              </Link>
              {!!user && (
                <a href="https://checkout-parceleja.cappta.com.br/7cantos">
                  <img src={CreditCardIcon} alt="creditCardIcon" />
                  <p>Parcelamento de boletos</p>
                </a>
              )}
            </SecondOptionsContainer>

            <SecondOptionsContainer>
              <Link to={"/owner-dashboard"}>
                <MdDashboard size={27} color="#FFF" />
                <p>Área do Proprietário</p>
              </Link>
            </SecondOptionsContainer>
          </RoutesContainer>
        </UserRoutesContainer>

        <hr />

        {!!user && (
          <SettingsContainer>
            {/*<Link to="/settings">CONFIGURAÇÕES</Link>*/}
            <Link to="/me">Editar Perfil</Link>

            <button onClick={logOut}>Sair</button>
          </SettingsContainer>
        )}
      </Content>
    </Container>
  );
}
