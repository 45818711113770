import styled from 'styled-components';

import { MAX_WIDTH_PAGE } from '../../styles';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    max-width: ${MAX_WIDTH_PAGE};

    // MEDIUM
   @media(min-width: 768px){
      
   }

   // LARGE
   @media(min-width: 992px){
      
   }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column-reverse;
    margin 0 auto;
    padding: 0;

    // MEDIUM
    @media(min-width: 768px){
       
    }

    // LARGE
    @media(min-width: 992px){
        height: calc(100vh - 99px);
        flex-direction: row;
    }
`;

export const PropertiesListContainer = styled.div`
    width: 100%;
    background: #F7F9FC;

    // LARGE
    @media(min-width: 992px){
        flex-grow: 1;
        overflow-y: auto;
    }
`;

export const SearchMapContainer = styled.div`
    width: 100%;
    height: 300px;

    // LARGE
    @media(min-width: 992px){
        flex-grow: 1;
        max-width: 38%;
        height: 100%;
    }
`;

