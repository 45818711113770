import React from 'react';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import {Container,
		Content,
		InitialContainer,
		AboutUsInformation,
		MoreInformations,
		MeterialInNewspaperContainer,
		Material,
		ImageContainerTvJangadeiro,
		ImageContainerOPovo,
		ImageContainerCaririEIsso,
		PeopleTalkAboutUsContainer,
		PeopleTalkAboutUsContent,
		VideoSliderContainer,
		VideoContent,
		OfficePicturesContainer,
		OfficePictureContent,
		WhereIndicate,
		TextContainer} from './styles';

import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import logoScaleUpEndeavorImg from './../../assets/pages/AboutUs/logo-scale-up-endeavor.png';
import logoTribunaDoCearaImg from './../../assets/pages/AboutUs/tribuna-do-ceara-logo.png';

import newspaper1Logo from './../../assets/pages/AboutUs/newspaper1Logo.png';
import newspaper2Logo from './../../assets/pages/AboutUs/newspaper2Logo.png';
import newspaper3Logo from './../../assets/pages/AboutUs/newspaper3Logo.png';

import triangulosImg from './../../assets/pages/AboutUs/triangulos.svg';

import aspasImg from './../../assets/pages/AboutUs/aspas.svg';

import firstOfficeImg from './../../assets/pages/AboutUs/office_picture01.jpeg';
import secondOfficeImg from './../../assets/pages/AboutUs/office_picture02.jpg';
import thirdOfficeImg from './../../assets/pages/AboutUs/office_picture03.jpeg';

export default function AboutUs(){
	const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "320px",
      slidesToShow: 1,
      speed: 500,
      responsive: [{
      	breakpoint: 767,
      	settings: {
      		centerPadding: '0px'
      	}
      }]
	};
	
	const settingsToOfficePictures = {
		className: "center",
		centerMode: true,
		infinite: true,
		centerPadding: "400px",
		slidesToShow: 1,
		speed: 500,
		responsive: [{
			breakpoint: 767,
			settings: {
				centerPadding: '0px',
				centerMode: false
			}
		}]
	  };

	return(
		<Container>
			<Header hasBorder hasSearchBar hasOptionToSearch/>
			<Content>
				<InitialContainer>
					<AboutUsInformation>
						<h2>7CANTOS NA IMPRENSA</h2>

						<img src={logoScaleUpEndeavorImg} alt="scaleUpEndeavor"/>

						<h1>A 7Cantos está entre as 14 empresas cearenses selecionadas para receber apoio no programa Scale-Up Endeavor.</h1>
					
						<p>O Scale-Up Endeavor Nacional é o programa de aceleração para empreendedores do Brasil todo e que estão à frente de negócios com grande potencial de crescimento, com diferenciais competitivos claros e um modelo de negócios comprovado pelo mercado. As empresas que participam do programa estão no momento certo para escalar e colocar em prática os conselhos dos mentores para crescer mais e de forma mais rápida.</p>
					
						<MoreInformations>
							<img src={logoTribunaDoCearaImg} alt="tribunaDoCeara" />

							<a href="/more-informations">LER MATÉRIA</a>
						</MoreInformations>
					</AboutUsInformation>

					<img src={logoScaleUpEndeavorImg} alt="scaleUpEndeavor"/>
				</InitialContainer>

				<MeterialInNewspaperContainer>
					<Material>
						<ImageContainerTvJangadeiro>
							<img src={newspaper1Logo} alt="newspaper1Logo" />
						</ImageContainerTvJangadeiro>

						<h3>Como a 7Cantos pretende dominar o mercado de locação residencial no Nordeste</h3>

						<strong>Startup, que já está presente em quatro cidades nordestinas, deseja expandir para todos os estados do Nordeste até 2023.</strong>
					
						<a href="https://exame.com/bussola/como-esta-proptech-quer-dominar-mercado-de-locacao-de-imoveis-no-nordeste">LER MATÉRIA</a>
					</Material>

					<Material>
						<ImageContainerOPovo>
							<img src={newspaper2Logo} alt="newspaper2Logo" id="oPovoImg" />
						</ImageContainerOPovo>

						<h3>Plataforma online quer "transformar" o mercado de locação de imóveis</h3>

						<strong>Proposta de alugar apartamentos através de sites, pelo cartão de crédito e sem fiador ou fiança é objetivo da 7Cantos</strong>
					
						<a href="https://www.opovo.com.br/noticias/tecnologia/opovotecnologia/2022/02/22/plataforma-online-quer-transformar-o-mercado-de-locacao-de-imoveis.html">LER MATÉRIA</a>
					</Material>

					<Material>
						<ImageContainerCaririEIsso>
							<img src={newspaper3Logo} alt="newspaper3Logo" id="caririÉIsso" />
						</ImageContainerCaririEIsso>

						<h3>Equipes pequenas e focadas: 7Cantos apresenta sua estratégia de sucesso para mercado de locação</h3>

						<strong>A 7Cantos foi um dos palestrantes no ICXP que reuniu centenas de importantes players do setor além de palestrantes e painelistas das principais empresas do mercado</strong>
					
						<a href="https://www.spacemoney.com.br/geral/equipes-pequenas-e-focadas-7cantos-apresenta-sua-estrategia-de/178937">LER MATÉRIA</a>
					</Material>
				</MeterialInNewspaperContainer>

				<img src={triangulosImg} alt="triangulos"/>

				<h1>O que nossos clientes dizem sobre nós</h1>

				<PeopleTalkAboutUsContainer>
					<PeopleTalkAboutUsContent>
						<img src={aspasImg} alt="aspas"/>

						<p>A 7Cantos consiste em uma empresa moderna, antenada com o mercado atual. Sua forma de atuação é dinâmica e inovadora. Agradeço a atenção e a segurança que eles passaram para mim e para minha família!</p>
						<b>Francisco Soares</b>
					</PeopleTalkAboutUsContent>

					<PeopleTalkAboutUsContent>
						<img src={aspasImg} alt="aspas"/>

						<p>Uma coisa que eu sempre quero falar em relação a 7Cantos é a agilidade
                    do processo de locação e a aglilidade da locação em si, algo que deixa a gente realmente muito feliz e satisfeito.</p>
						<b>Marcos Esmeraldo Melo</b>
					</PeopleTalkAboutUsContent>

					<PeopleTalkAboutUsContent>
						<img src={aspasImg} alt="aspas"/>

						<p>De todas as experiências que já tivemos, a única empresa que nos deu total liberdade foi a 7Cantos, foi a que trouxe melhores
                    resultados.</p>
						<b>João e Mônica Gentil</b>
					</PeopleTalkAboutUsContent>
				</PeopleTalkAboutUsContainer>

				<VideoSliderContainer>
					<Slider {...settings}>
						<VideoContent>
				            <iframe title="video7Cantos" width={window.innerWidth > 767 ? "500" : '310'} height={window.innerWidth > 767 ? "280" : '188'} src="https://www.youtube.com/embed/qxjRvBbU_bE" frameBorder="0" allowFullScreen/>
				          
				            <p><b>Alessandra</b> | Proprietária</p>
				        </VideoContent>
				        <VideoContent>
				            <iframe title="video7Cantos" width={window.innerWidth > 767 ? "500" : '310'} height={window.innerWidth > 767 ? "280" : '188'} src="https://www.youtube.com/embed/kjYnfgqR9cE" frameBorder="0" allowFullScreen/>
				          
				            <p><b>Marcos Vinícios</b> | Médico</p>
				        </VideoContent>
					</Slider>
				</VideoSliderContainer>

				<img src={triangulosImg} alt="triangulos" id="triangles-after-videos"/>

				<h2>Sobre a empresa</h2>

				<p>A 7Cantos é uma empresa focada nas tendências de mercado e por isso acredita que o melhor relacionamento é aquele baseado na liberdade de escolha do cliente, tudo isso sem burocracia e amarrações. Nossa metodologia de trabalho permite que você, proprietário ou pessoa interessada em alugar um imóvel possa resolver tudo de forma prática e simplificada, online mesmo.</p>

				<OfficePicturesContainer>
					<Slider {...settingsToOfficePictures}>
						<OfficePictureContent>
							<img src={firstOfficeImg} alt="firstOfficeImage"/>

							<p>Fortaleza-CE</p>
						</OfficePictureContent>

						<OfficePictureContent>
							<img src={secondOfficeImg} alt="secondOfficeImage"/>

							<p>Juazeiro do Norte-CE</p>
						</OfficePictureContent>

						<OfficePictureContent>
							<img src={thirdOfficeImg} alt="thirdOfficeImg"/>

							<p>Recife-PE</p>
						</OfficePictureContent>
					</Slider>
				</OfficePicturesContainer>

				<WhereIndicate>
					<TextContainer>
						<h1>Onde estamos</h1>

						<h2>FORTALEZA, CEARÁ</h2>
						<h2>JUAZEIRO DO NORTE, CEARÁ</h2>
						<h2>RECIFE, PERNAMBUCO</h2>
					</TextContainer>
				</WhereIndicate>
			</Content>
			<Footer />
		</Container>
	);
};