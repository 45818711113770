import React from 'react';

import OutSideClickHandler from 'react-outside-click-handler';

import {Container,
		Content,
		MessageContainer,
		ButtonsContainer} from './styles';

import { FiX } from 'react-icons/fi';
import { MdAnnouncement } from 'react-icons/md';

interface IModalConfirmCancelVisit{
	functionToConfirm(): void;
	functionToClose(): void;
	isLoadingApiRequest: boolean;
}

export default function ModalConfirmCancelVisit({ functionToConfirm, functionToClose, isLoadingApiRequest }: IModalConfirmCancelVisit){
	return (
		<Container>
			<OutSideClickHandler onOutsideClick={functionToClose}>
			<Content>
				<button onClick={functionToClose}><FiX size={30} color="#666666"/></button>

				<MessageContainer>
					<MdAnnouncement size={40} color="#666666" />

					<h1>Tem certeza que gostaria de cancelar sua visita?</h1>

					<ButtonsContainer>
						{!isLoadingApiRequest && (<button onClick={functionToConfirm} style={{background: '#00BF33'}}>SIM</button>)}
						{!!isLoadingApiRequest && (<label>Carregando...</label>)}
						<button onClick={functionToClose} style={{background: '#BF0000'}}>NÃO</button>
					</ButtonsContainer>
				</MessageContainer>
			</Content>
			</OutSideClickHandler>
		</Container>
	);
};