import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import DocumentsRequestComponent from '../../components/DocumentsRequestComponent';

import Footer from "../../components/Footer";
import Header from "../../components/Header";

import {Container,
        Content,
        DocumentsContainer,
        DocumentsSpecificationsContainer,
        DocumentsAndSubmitButtonContainer,
        DocumentContent,
        DocumentationContainer} from './styles';

import { FiCheck, FiPaperclip } from 'react-icons/fi';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import imageCompression from 'browser-image-compression';
import api from '../../services/api';
import asyncForEach from './../../utils/asyncForEach';

interface IFiles extends Blob{
	name: string;
}

export default function RequestTerminationUploadDocs(){
    const history = useHistory();

    const { user, access_token } = useAuth();

    const [errorFileExists, setErrorFileExists] = useState('');
	const [errorInFileType, setErrorInFileType] = useState('');
	const [errorInFileSize, setErrorInFileSize] = useState('');

    const [loadingApiRequest, setLoadingApiRequest] = useState(false);
	const [documentsSended, setDocumentsSended] = useState(false);
    const [errorFilesEmpty, setErrorFilesEmpty] = useState(false);

    const [files, setFiles] = useState<IFiles[]>([]);

    useEffect(() => {
		if(!user){
			history.goBack();
		}
	}, [user, history]);

	const handleSendFile = useCallback(async(event: ChangeEvent<HTMLInputElement>) => {
		setErrorInFileType('');
		setErrorInFileSize('');	
		setErrorFileExists('');

		try{
			if(event.target.files){
				setLoadingApiRequest(true);

				const orderFiles = [...event.target.files].map((file, index) => ({file, order: index + 1}));

				const filesWithErrors = orderFiles.some(file => file.file.type !== 'image/jpeg' && file.file.type !== 'image/png' && file.file.type !== 'application/pdf');

				const checkFileExists = files.some(fileFinded => {
					return orderFiles.map(file => file.file.name).indexOf(fileFinded.name) >= 0;
				});

				if(!!filesWithErrors){
					setErrorInFileType('Tipo de arquivo não é suportado. Só aceitamos .jpg, .png, .pdf');

					setLoadingApiRequest(false);

					return;
				}

				if(!!checkFileExists){
					setLoadingApiRequest(false);
					
					setErrorFileExists('Arquivos com o mesmo nome não são permitidos');

					return;
				}

				await asyncForEach(orderFiles.sort((a, b) => a.order - b.order), async(file) => {
					if(file.file.type === 'image/jpeg' || file.file.type === 'image/png'){
						const options = {
							maxSizeMB: 4,
							maxWidthOrHeight: 1920,
							useWebWorker: true
						};
	
						const imageFile = file.file;
	
						const compressedFile = await imageCompression(imageFile, options);
	
						if(compressedFile.size <= 4000000){
							const data = new FormData();
	
							data.append('document', compressedFile);

							api.defaults.headers.authorization = `Bearer ${access_token}`;
							const response = await api.post('/api/client/tenant/contract-termination/documents', data);
									
							if(!!response.data.success){
								setFiles(prevValue => [...prevValue, imageFile]);

								if(file.order === orderFiles.length){
									setLoadingApiRequest(false);
								}
							}
	
							return;
						} else{
							setErrorInFileSize('Arquivo muito grande. Por favor, envie um arquivo de até 4mb.');
							setLoadingApiRequest(false);
						}
					} 

					else if(file.file.type === 'application/pdf'){
						const pdfFIle = file.file;
	
						if(pdfFIle.size <= 4000000){
							const data = new FormData();
	
							data.append('document', pdfFIle);
							api.defaults.headers.authorization = `Bearer ${access_token}`;
							const response = await api.post('/api/client/tenant/contract-termination/documents', data);
									
							if(!!response.data.success){
								setFiles(prevValue => [...prevValue, pdfFIle]);

								if(file.order === orderFiles.length){
									setLoadingApiRequest(false);
								}
							}
						} else{
							setErrorInFileSize('Arquivo muito grande. Por favor, envie um arquivo de até 4mb.');
							setLoadingApiRequest(false);
						}
					} 
				});
			}	
		} catch(err){
			setLoadingApiRequest(false);
		}
	}, [access_token, files]);

    const handleSendDocumentation = useCallback(() => {
        setErrorFilesEmpty(false);

		if(files.length === 0){
            setErrorFilesEmpty(true);

            return;
        }

        setDocumentsSended(true);
	}, [files]);

    return(
        <Container>
            <Header />
                <Content>
                    <h1>Solicitar Rescisão</h1>
                    <h3>Para prosseguir com a solicitação de rescisão, envie os documentos abaixo que se aplicam ao seu caso:</h3>

                    <DocumentationContainer>
                        <DocumentsSpecificationsContainer>
                            <h5>Anexe todos arquivos clicando no botão ao lado.</h5>
                            <span>Dê preferência para arquivos no formato .pdf.</span>

                            <DocumentsRequestComponent 
                                title="CONDOMÍNIO"
                                document_specifications={[" - Boleto e comprovante da última conta paga do condomínio;", "- Certidão negativa de débitos emitida pelo síndico ou administradora do condomínio."]}
                            />

                            <DocumentsRequestComponent 
                                title="IPTU"
                                document_specifications={["- Boleto e comprovante da última conta paga do IPTU (caso tenha sido pago com os boletos emitidos pela SEFIN);", "- Certidão negativa de débitos."]}
                            />

                            <DocumentsRequestComponent 
                                title="GÁS"
                                document_specifications={["- Boleto e comprovante da ultima conta paga do gás;", "- Certidão negativa de débitos."]}
                            />

                            <DocumentsRequestComponent 
                                title="ENERGIA"
                                document_specifications={["- Boleto e comprovante ultima conta paga da energia, protocolo de encerramento da energia;", "- Certidão negativa de débitos."]}
                            />

                            <DocumentsRequestComponent 
                                title="ÁGUA"
                                document_specifications={["- Boleto e comprovante da ultima conta paga, comprovante da solicitação de corte;", "- Certidão negativa de débitos."]}
                            />
                        </DocumentsSpecificationsContainer>

                        <DocumentsAndSubmitButtonContainer>
                            <DocumentsContainer>
                                {loadingApiRequest ? (
                                    <label style={{cursor: 'default'}}>
                                        <p>CARREGANDO...</p>
                                    </label>
                                ) : (
                                    <label>
                                        <input type="file" accept="application/pdf, images" multiple onChange={e => handleSendFile(e)}/>
                                        <FiPaperclip color={"#DD6435"} size={18}/>
                                        <p>ANEXAR DOCUMENTOS</p>
                                    </label>
                                )}
                
                                {files.map(file => (
                                    <DocumentContent>
                                        <FiCheck color={"#00BF33"} size={18}/>
                                        <p>{file.name.length >= 22 ? file.name.substring(0, 22) + '...' : file.name}</p>
                                    </DocumentContent>
                                ))}
                            </DocumentsContainer>

                            {documentsSended ? (<strong>DOCUMENTOS ENVIADOS</strong>) : (<button onClick={handleSendDocumentation}>ENVIAR DOCUMENTOS</button>)}

                            {!!errorInFileType && (
								<p style={{color: '#FF3838', marginTop: '8px'}}>{errorInFileType}</p>
							)}

							{!!errorInFileSize && (
								<p style={{color: '#FF3838', marginTop: '8px'}}>{errorInFileSize}</p>
							)}

							{!!errorFileExists && (
								<p style={{color: '#FF3838', marginTop: '8px'}}>{errorFileExists}</p>
							)}

							{!!errorFilesEmpty && (
								<p style={{color: '#FF3838', marginTop: '8px'}}>Envie um arquivo.</p>
							)}
                        </DocumentsAndSubmitButtonContainer>
                    </DocumentationContainer>
                </Content>
            <Footer />
        </Container>
    );
}