import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const  AdvantagesSectionContainer = styled.section`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #F7F9FC;
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};

    svg.left-bottom {
        position: absolute;
        left: -90px;
        bottom: 0;
        z-index: 11;
    }
    svg.left-top {
        position: absolute;
        left: 0px;
        bottom: 400px;
        z-index: 1;
    }
    svg.right-top {
        position: absolute;
        transform: rotate(180deg);
        right: 0px;
        bottom: 400px;
        z-index: 11;
        width: 50px;
    }
    svg.right-middle {
        position: absolute;
        right: 0px;
        bottom: 200px;
        z-index: 1;
    }
    svg.right-bottom {
        position: absolute;
        transform: rotate(180deg);
        right: 0;
        bottom: -20px;
        z-index: 11;
        width: 85px;
    }

    // MEDIUM
    @media(min-width: 768px){
        padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};

        svg.left-bottom {
            left: 0;
            bottom: 100px;
        }
        svg.left-top {
            left: 5px;
            bottom: 400px;
        }
        svg.right-top {
            width: auto;
            right: 0;
            bottom: 400px;
        }
        svg.right-middle {
            right: 0;
            bottom: 200px;
        }
        svg.right-bottom {
            right: 0;
            bottom: -20px;
            width: 75px;
        }
    }

    // LARGE
    @media(min-width: 992px){
        padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};

        svg.left-top {
            left: 40px;
        }
    }
`;

export const AdvantagesSectionHeaderContainer = styled.div`
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    // MEDIUM
    @media(min-width: 768px){
        
    }

    // LARGE
    @media(min-width: 992px){
        flex-direction: row;
    }
`;

export const AdvantagesSectionHeaderButtonsContainer = styled.div`
    
    width: 100%;
    margin-top: 40px;
    max-width: 450px;

    // LARGE
    @media(min-width: 992px){
        width: 500px;
        max-width: 500px;
        margin-top: 0;
    }
`;

export const  AdvantagesSectionHeaderTitleContainer = styled.div`

    position: relative;
    padding-left: 25px;
    width: 100%;

    &:before {
        content: '';
        position: absolute;
        background-color: #6EA6FF;
        width: 4px;
        height: 100%;
        bottom: 0;
        left: 0;
    }

    h3.title {
        font-family: Montserrat;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 0em;
        text-align: left;
    }

    p.description {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 10px;
    }

    // LARGE
    @media(min-width: 992px){

        h3.title {
            font-size: 36px;
        }
    
        p.description {
            font-size: 16px;
        }
    }
`;

export const  AdvantagesSectionCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    margin-top: 40px;
    border-radius: 50px;
    z-index: 10;

    // MEDIUM
    @media(min-width: 768px){
        padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
    }

    // LARGE
    @media(min-width: 992px){
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;
        padding: 75px 90px;
    }
`;

export const  AdvantagesSectionCardTextsContainer = styled.div`

    display: flex;
    flex-direction: column;
`;

export const  AdvantagesSectionCardTextsHeaderContainer = styled.div`

    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    border-bottom: 1px solid #CDCDCD;
    

    h4.title {
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 35px;
        letter-spacing: 0em;
        text-align: center;

    }

    p.description {
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 20px;
    }

    // LARGE
    @media(min-width: 992px){
        h4.title {
            font-size: 30px;
            text-align: left;
        }
    
        p.description {
            font-size: 16px;
            text-align: left;
        }
    }
`;

export const  AdvantagesSectionCardTextsListContainer = styled.div`

    display: flex;
    flex-direction: column;
    margin-top: 33px;
    align-items: flex-start;

    // MEDIUM
    @media(min-width: 768px){
        align-items: center;
    }

    // LARGE
    @media(min-width: 992px){
        align-items: flex-start;
    }

`;

export const  AdvantagesSectionCardTextsListItem = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 22px;

    p.item {
        margin-left: 20px;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0em;
    }
`;


export const  AdvantagesSectionCardImageContainer = styled.div`
    display: none;

    
    // LARGE
    @media(min-width: 992px){
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        align-items: flex-end;

        img {
            border-radius: 50px;
            width: 100%;
        }
    }
`;

