import React from "react";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";

import { Container, Content } from "./styles";

export default function UseTerms() {
  return (
    <Container>
      <Header />
      <Content>
        <h2>Termos de Uso</h2>

        <h3>1. Aceitação dos Termos:</h3>
        <p>
          Ao acessar ou usar nosso site, você concorda em cumprir estes Termos
          de Uso. Se você não concordar com estes termos, por favor, não use
          nosso site.
        </p>
        <h3>2. Uso Aceitável:</h3>
        <p>
          Você concorda em usar nosso site apenas para fins legais e de maneira
          que não viole os direitos de terceiros ou impeça outros de usar o
          site.
        </p>
        <h3>3. Modificações / Encerramento:</h3>
        <p>
          Nos reservamos o direito de modificar ou encerrar o site a qualquer
          momento, sem aviso prévio. Continuar a usar o site após tais
          modificações constitui sua aceitação dos novos termos.
        </p>
        <h3>4. Limitação de Responsabilidade:</h3>
        <p>
          Não nos responsabilizamos por quaisquer danos diretos, indiretos,
          acidentais, especiais ou consequenciais decorrentes do uso ou
          incapacidade de usar nosso site.
        </p>
      </Content>
      <Footer />
    </Container>
  );
}
