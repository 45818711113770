import styled from "styled-components";

import chevronDown from './../../assets/pages/Search/chevronDown.svg';

export const Form = styled.form`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	width: 100%;

	>p{
		font-size: 12px;

		margin-top: 32px;

		>span{
			font-size: 10px;
		} 
	}

	select{
		background: transparent;

		padding-left: 12px;

		font-size: 11px;
		font-weight: 500;

		/* width: 80%; */
		height: 35px;

		border: 0;
		border-bottom: 1px solid #B6B6B6;

		-moz-appearance: none;
	    -webkit-appearance: none;
	    appearance: none;

	    background-image: url(${chevronDown});
	    background-repeat: no-repeat, repeat;
		background-position: right .7em top 50%, 0 0;
		background-size: .95em auto, 100%;
	}

	.css-2b097c-container{
		width: 80%;
	}

	.css-yk16xz-control{
		border: 0;
		border-bottom: 1px solid #B6B6B6;

		border-radius: 0;
	}

	.css-1okebmr-indicatorSeparator{
		display: none;
	}

	.css-tlfecz-indicatorContainer{
		color: #666666;
	}

	>button{
		font-size: 12px;
		font-weight: 600;
		color: #FFF;

		border: 0;

		width: 80%;
		padding: 8px 0;

		background: #6EA5FF;
	}

	>label{
		text-align: center;
		
		font-size: 12px;
		font-weight: 600;
		color: #6EA5FF;

		border: 2px solid #6EA5FF;

		width: 80%;
		padding: 8px 0;

		background: #FFF;
	}

	@media(max-width: 767px){
		margin: 0 auto;

		>p{
			font-size: 13px;

			>span{
				font-size: 13px;
			}
		}

		.css-2b097c-container{
			width: 100%;
		}

		>button{
			width: 100%;

			font-size: 12px;
		}

		>label{
			width: 100%;

			font-size: 12px;
		}

		>p{
			text-align: left;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 14px;

			>span{
				font-size: 12px;
			}
		}

		.css-2b097c-container{
			width: 82%;
		}

		>button{
			width: 82%;

			font-size: 14px;
		}

		>label{
			width: 82%;

			font-size: 14px;
		}
	}
`;

export const CheckBoxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin: 24px 0;

	width: 80%;

	background: #F9F9F9;

	padding: 18px 24px;

	cursor: pointer;

	>label{
		font-size: 12px;

		margin-left: 8px;

		cursor: pointer;
	}

	@media(max-width: 767px){
		>label{
			font-size: 10px;
		}
	}

	@media(min-width: 1441px){
		width: 82%;

		>label{
			font-size: 14px;
		}
	}
`;

export const SpaceToFooter = styled.div`
	flex: 1;
`;