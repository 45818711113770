import React from 'react';

import {
    InitialSectionContainer,
    InitialSectionFormContainer,
    InitialSectionImageContainer
} from './styles';

import SearchBarToHomePage from '../../../components/SearchBarToHomePage';

export default function SearchPropertySection() {

    return (
      <InitialSectionContainer>
          
        <InitialSectionFormContainer>
          <h2 className='title'>Aluguel rápido e descomplicado.</h2>
          <p className='description'>Encontre o seu novo canto de forma digital.</p>
          <SearchBarToHomePage />
        </InitialSectionFormContainer> 

        <InitialSectionImageContainer>
          <video autoPlay muted loop playsInline>
            <source src={require('./../../../assets/pages/Home/mudanca-animada.webm')} type="video/webm" />
            <source src={require('./../../../assets/pages/Home/mudanca-animada.mp4')} type="video/mp4" />
          </video>
        </InitialSectionImageContainer> 

      </InitialSectionContainer>
    )

};