import React, { FormEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import OutsideClickHandler from 'react-outside-click-handler';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { uuid } from 'uuidv4';
import api from '../../services/api';

import {SearchContainer,
        InputContainer,
        OptionsToSearch,
        ContentSelectedContainer,
        ContentSelectedTag,
        NeighborhoodsOptionsContainer,
        CitiesOptionsContainer,} from './styles';

interface INeighborhoods{
	id?: string;
	neighborhood: string;
	city: string;
	UF: string;
}

interface IPropertiesAddress{
	address: string;
	city: string;
	uf: string;
}

interface ICities{
	city: string;
	UF: string;
}

interface IProperties{
	id: number;
	title: string;
	address: IPropertiesAddress | undefined;
}

interface ISeacthBarHeader{
    hasSearchBar: boolean;
    isToShowSearchBarMobile?: boolean;
    showSearchBar?: boolean;
}

export default function SearchBarToHeader({ hasSearchBar, isToShowSearchBarMobile, showSearchBar }: ISeacthBarHeader){
    const history = useHistory();
    const inputRef = useRef<HTMLInputElement>(null);
    const [neighborhoods, setNeighborhoods] = useState<INeighborhoods[]>([]);
	const [cities, setCities] = useState<ICities[]>([]);
	const [property, setProperty] = useState({} as IProperties);

	const [showOptions, setShowOptions] = useState(false);

    const [inputSearch, setInputSearch] = useState('');

	const [neighborhoodsSearchs, setNeighborhoodsSearchs] = useState<INeighborhoods[]>([]);
  	const [citiesSearchs, setCitiesSearchs] = useState<ICities[]>([]);

  	const [contentSelected, setContentSelected] = useState<INeighborhoods[]>([]);

  	const [errorInSearchBar, setErrorInSearchBar] = useState('');
    const [showContentSelected, setShowContentSelected] = useState(false);

    useEffect(() => {
		async function loadApi(){
			const response = await api.get('/api/client/addresses');

			setNeighborhoods(response.data.data.neighborhoods);
			setCities(response.data.data.cities);
		}

		if(!!hasSearchBar){
			loadApi();	
		}
	}, [hasSearchBar]);

	useEffect(() => {  
    	const neighborhoodResults = neighborhoods.filter(neighborhood => neighborhood.neighborhood.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        setNeighborhoodsSearchs(neighborhoodResults);
  
        const citiesResults = cities.filter(city => city.city.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(inputSearch.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")));
        setCitiesSearchs(citiesResults);  
  	}, [inputSearch, neighborhoods, cities]);

  	useEffect(() => {
	    try{
	    	const formatInputSearch = parseInt(inputSearch);

	    	if(isNaN(formatInputSearch) === false){
		      	api.get(`/api/client/properties/${inputSearch}`).then(response => {
		          	setProperty(response.data.data);
		      	}).catch(err => {
		        	setProperty({
		        		id: 1,
		        		title: 'null',
		        		address: undefined
		        	});
		      	});
	   		}
	    } catch(err){
	      return err;
    	}
      
  	},[inputSearch]);

    const handleSelectNeighbohoods = useCallback(({ id, neighborhood, city, UF }: INeighborhoods) => {
        const data = {id, neighborhood, city, UF};

        const checkNeighborhood = contentSelected.find(content => (content.neighborhood === neighborhood && content.city === city));

        if(!!checkNeighborhood){
            return;
        }

		history.push(`/search/?neighborhoods=${neighborhood}&city=${city}&UF=${UF}`);

		setErrorInSearchBar('');
		setInputSearch(neighborhood);
		setShowOptions(false);
    }, [contentSelected, neighborhoods, cities]);

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const neighborhoodsSelected = contentSelected.map(content => content.neighborhood).toString();
		const city = contentSelected.find(content => content.city)?.city;
		const UF = contentSelected.find(content => content.UF)?.UF;

		setErrorInSearchBar('');

        if(contentSelected.length === 0){
            setErrorInSearchBar('Escolha um local');
        }

        if(contentSelected.length >= 1){
          	history.push(`/search/?neighborhoods=${neighborhoodsSelected}&city=${city}&UF=${UF}`);
        } 
    }, [contentSelected, history]);

    const handleShowContentSelected = useCallback(() => {
		setShowOptions(false);
		setShowContentSelected(true);
	}, []);

    const handleCloseContentSelected = useCallback(() => {
		setShowContentSelected(false);
	}, []);

    const handleRemoveContentSelected = useCallback((id: string | undefined) => {
        const removeContentSelected = contentSelected.filter(content => content.id !== id);

        if(removeContentSelected.length === 0){
            api.get('/api/client/addresses').then(response => {
             setNeighborhoods(response.data.data.neighborhoods);
             setCities(response.data.data.cities);
          });

            handleCloseContentSelected();
        }

        setContentSelected(removeContentSelected);
    }, [contentSelected, handleCloseContentSelected]);

    return(
        <SearchContainer isToShowSearchBarMobile={isToShowSearchBarMobile || showSearchBar} onSubmit={handleSubmit}>
			<InputContainer 
				hasError={!!errorInSearchBar} 
				isToShowContentSelectedNumber={contentSelected.length}>
				<FiSearch size={16} color="#DD6435"/>
				<input 
					placeholder={!!errorInSearchBar ? errorInSearchBar : "Pesquisar imóveis"}
					ref={inputRef}
					value={inputSearch}
					onFocus={() => setShowOptions(true)}
					onChange={e => {
						setInputSearch(e.target.value);
						setShowOptions(true);
						setErrorInSearchBar('');
						handleCloseContentSelected();
					}}
				/>
				<button 
					id="content-selected-button" 
					type="button"
					onClick={!!showContentSelected ? handleCloseContentSelected : handleShowContentSelected}>{contentSelected.length}</button>
				<button type="submit">PESQUISAR</button>
			</InputContainer>

			{!!showOptions && (
				<OutsideClickHandler onOutsideClick={() => setShowOptions(false)}>
				<OptionsToSearch>
					{neighborhoodsSearchs.length >= 1 && (
						<NeighborhoodsOptionsContainer>
							<h3>Bairros:</h3>

							{neighborhoodsSearchs.map(neighborhood => (
								<button key={uuid()} type="button" onClick={() => handleSelectNeighbohoods({ id: uuid(), neighborhood: neighborhood.neighborhood, city: neighborhood.city, UF: neighborhood.UF })}>
									{neighborhood.neighborhood}, {neighborhood.city}-{neighborhood.UF}
								</button>
							))}
						</NeighborhoodsOptionsContainer>
					)}
										
					{citiesSearchs.length >= 1 && (
						<CitiesOptionsContainer>
							<h3>Cidades:</h3>

							{citiesSearchs.map(city => (
								<Link key={city.city} to={{
									pathname: `/search/?city=${city.city}&UF=${city.UF}`,
								}}>
								{city.city}-{city.UF}
								</Link>
							))}
						</CitiesOptionsContainer>
					)}

					{property.address !== undefined && (
						<CitiesOptionsContainer>
	                            
				            <h3 className="AddressesOptionsTitle">Propriedades:</h3>
				            <Link to={`/imovel/${property.id}`}>{property.title} ,{property.address.address}, {property.address.city}, {property.address.uf}</Link>
				                          	
				      	</CitiesOptionsContainer>
					)}
				</OptionsToSearch>
				</OutsideClickHandler>
			)}

	    	{!!showContentSelected && (
	    		<OutsideClickHandler onOutsideClick={handleCloseContentSelected}>
	    		<ContentSelectedContainer>
	    			{contentSelected.map((content) => (
	    				<ContentSelectedTag key={content.id}>
	    					<p>{`${String(`${content.neighborhood}, ${content.city} - ${content.UF}`).substr(0, 20)}...`}</p>
	    					<button type="button" onClick={() => handleRemoveContentSelected(content.id)}><FiX color="#666666" size={14}/></button>
	    				</ContentSelectedTag>
	    			))}
	    		</ContentSelectedContainer>
	    		</OutsideClickHandler>
	    	)}
	    </SearchContainer>
    );
}