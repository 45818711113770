import React, { useEffect, useState, useCallback } from 'react';

import api from './../../services/api';

import { useAuth } from './../../hooks/auth';

import { useHistory } from 'react-router-dom';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';

import PropertiesRentedCard from './../../components/PropertiesRentedCard/';
import PropertiesAnnunciatedCard from './../../components/PropertiesAnnuciatedCard/';

import ModalVisits from './../../components/ModalVisits/';

import LoadingComponent from './../../components/Loading/';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {Container,
		Content,
		PropertiesRentedCardContainer,
		PropertiesAnnunciatedCardContainer} from './styles';

interface IPhotos{
	id: number;
	url_l: string;
	url_m: string;
}

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}

interface ISchedules{
	id: number;
	date: string;
}

interface IProperties{
	id: number;
	title: string;
	is_rented: number;
	photos: IPhotos[];
	schedules: ISchedules[];
	address: IAddress;
}		

export default function MyProperties(){
	const { access_token, user } = useAuth();
	const history = useHistory();

	const [propertiesRented, setPropertiesRented] = useState<IProperties[]>([]);
	const [propertiesAnnunciated, setPropertiesAnnunciated] = useState<IProperties[]>([]);

	const [loading, setLoading] = useState(true);

	const [openModalVisits, setOpenModalVisits] = useState(false);

	const [schedules, setSchedules] = useState<ISchedules[]>([]);
	const [title, setTitle] = useState<string>();
	const [address, setAddress] = useState({} as IAddress);
	const [photos, setPhotos] = useState({} as IPhotos);

	useEffect(() => {
		if(!user){
			history.goBack();
		}

		async function loadAPI(){
			api.defaults.headers.authorization = `Bearer ${access_token}`

			const response = await api.get('/api/client/owner/properties');

			setPropertiesRented(response.data.data.filter((apiResponse: IProperties) => apiResponse.is_rented === 1));
			setPropertiesAnnunciated(response.data.data.filter((apiResponse: IProperties) => apiResponse.is_rented === 0));
			setLoading(false);
		}

		loadAPI();
	}, [access_token, user, history]);

	const handleOpenModalVisits = useCallback((schedulesArray: ISchedules[], title: string, address: IAddress, photos: IPhotos) => {
		setSchedules(schedulesArray);
		setTitle(title);
		setAddress(address);
		setPhotos(photos);

		setOpenModalVisits(true);
	}, []);

	const handleCloseModalVisits = useCallback(() => {
		setOpenModalVisits(false);
	}, []);

	// const settings = {
	//     dots: true,
	//     arrows: true,
	//     speed: 500,
	//     slidesToShow: 3,
	//     slidesToScroll: 3,
	//     touchMove: false
	// }

	return(
		<Container>
			<Header hasBorder={window.innerWidth > 767 ? true : false} hasSearchBar hasOptionToSearch/>

			{!!openModalVisits && (
				<ModalVisits title={title} photos={photos} schedules={schedules} address={address} functionToCloseModalVisits={handleCloseModalVisits}/>
			)}

			{!!loading && (
				<LoadingComponent />
			)}

			{!loading && (
				<Content>
					<h3>PROPRIETÁRIO</h3>
					<h1>{propertiesRented.length > 0 ? 'Imóveis alugados' : 'Nenhum imóvel alugado ainda.'}</h1>

					{propertiesRented.length >= 1 && (
						<PropertiesRentedCardContainer numberOfElements={propertiesRented.length}>
							<div>
								{propertiesRented.map(property => (
									<PropertiesRentedCard 
										key={property.id}
										title={property.title}
										photos={property.photos}
										address={property.address}
									/>
								))}
							</div>
							
						</PropertiesRentedCardContainer>
					)}
					
					<hr />

					<h1>{propertiesAnnunciated.length > 0 ? 'Imóveis anunciados' : 'Nenhum imóvel anunciado ainda.'}</h1>
	
					{propertiesAnnunciated.length >= 1 && (
						<PropertiesAnnunciatedCardContainer numberOfElements={propertiesAnnunciated.length}>
							<div>
								{propertiesAnnunciated.map(property => (
									<PropertiesAnnunciatedCard 
										key={property.id}
										id={property.id}
										title={property.title}
										photos={property.photos}
										address={property.address}
										functionToOpenModalVisits={() => handleOpenModalVisits(property.schedules, property.title, property.address, property.photos[0])}
									/>
								))}
							</div>
								
						</PropertiesAnnunciatedCardContainer>
					)}
				</Content>
			)}
			
			<Footer />
		</Container>
	);
};