import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1200px;
	margin: auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h2{
		font-size: 19px;
		font-weight: 500;
		color: #DD6435;

		margin-top: 46px;
	}

	>h1{
		margin-top: 24px;

		font-size: 30px;
		font-weight: bold;
	}

	>span{
		font-size: 14px;

		margin-top: 12px;
	}

	>.button-container{

		display: flex;
		flex-direction: row;
		margin: 32px 0 64px;

		>a{
			
			margin: 10px;
	
			text-decoration: none;
	
			font-size: 9px;
			font-weight: 500;
			color: #666666;
	
			border: 1px solid #666666;
	
			padding: 4px 12px;
		}
	}


	>img{
		margin-bottom: 12%;

		width: 415px;
	}

	@media(max-width: 767px){
		>h2{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;
		}

		>span{
			font-size: 14px;

			width: 300px;

			text-align: center;
		}

		>.button-container{

			flex-direction: column;
		}

		>a{
			font-size: 12px;
			font-weight: 500;
		}

		>img{
			width: 342px;
			height: 211px;
		}
	}

	@media(min-width: 1441px){
		>h2{
			margin-top: 56px;

			font-size: 22px;
		}

		>h1{
			font-size: 34px;
		}

		>span{
			font-size: 16px;
		}

		>a{
			padding: 6px 12px;

			font-size: 10px;
		}

		>img{
			width: 460px;
		}
	}
`;