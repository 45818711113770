import styled from "styled-components";

export const SunneTitle = styled.h6`
  font-size: ${20 / 16}rem;
  font-weight: 600;
  margin-bottom: 12px;
  text-align: center;
  //linear-gradient(90deg, rgba(255,176,0,1) 0%, rgba(255,50,0,1) 100%);
  //rgba(17, 44, 38, 1)
`;

export const SunneText = styled.p`
  margin-top: 1rem;
  color: rgb(137, 137, 137);
  font-size: ${14 / 16}rem;
  font-weight: 400;
  text-align: center;
`;

export const SunneLink = styled.a`
  background-color: #dd6536;
  margin-top: 16px;
  padding: 12px;
  color: white;
  font-weight: 500;
  text-align: center;
  font-size: ${14 / 16}rem;
  transition: 200ms linear;
  width: 60%;
  border-radius: 4px;
`;
