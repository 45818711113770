import React, { useCallback, useEffect, useState } from "react";

import api from "./../../services/api";

import { useAuth } from "./../../hooks/auth";

import { useHistory, useLocation, Link } from "react-router-dom";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";
import Loading from "./../../components/Loading/";

import {
  Container,
  Content,
  ProposalsContainer,
  PropertyInformationsContainer,
  PropertyLocalContainer,
  PricesAndLinkContainer,
} from "./styles";

interface IApiResult {
  id: number;
  status: string;
  property_id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  photo: string;
  tentant_name: string;
}

interface IState {
  property_id: number;
}

export default function OwnerRentProcess() {
  const history = useHistory();
  const { state } = useLocation<IState>();

  const { user, access_token } = useAuth();

  const [apiResult, setApiResult] = useState<IApiResult[]>([]);

  const [isDownloadingContract, setIsDownloadingContract] = useState(false);
  const [errorInDownloadingContract, setErrorInDownloadingContract] =
    useState(false);

  const [loadingApi, setLoadingApi] = useState(true);

  useEffect(() => {
    if (!user) {
      history.goBack();

      return;
    }

    async function loadApi() {
      if (!!user) {
        api.defaults.headers.authorization = `Bearer ${access_token}`;

        const response = await api.get(
          `/api/client/owner/rent-process/${state.property_id}`
        );

        setApiResult(response.data.data);
        setLoadingApi(false);
      }
    }

    loadApi();
  }, [user, history, access_token, state]);

  const getCurrentStepLabel = (step: string) => {
    switch (step) {
      case "SCHEDULE_DONE":
        return "Agendamento realizado";
      case "STEPS":
        return "Proposta concluída";
      case "DOCUMENTS_TO_ANALYSIS":
        return "Documentação em análise";
      case "CONTRACT_DONE":
        return "Aguardando assinatura do contrato";
      default:
        return "Contrato finalizado";
    }
  };

  const toDownloadContractHandler = useCallback(
    async (rentProcessId: number) => {
      setIsDownloadingContract(true);
      try {
        const response = await api.get(
          `/api/client/rent-contract/dowload/${rentProcessId}`
        );
        const url = response.data.data.url;
        if (!!url) {
          window.open(url, "_blank");
        }
      } catch (error) {
        setErrorInDownloadingContract(true);
      }
      setIsDownloadingContract(false);
    },
    []
  );

  return (
    <Container>
      <Header
        hasBorder={window.innerWidth > 767 ? true : false}
        hasSearchBar
        hasOptionToSearch
      />
      {!!loadingApi && <Loading />}

      {!loadingApi && (
        <>
          {apiResult.length === 0 && (
            <Content style={{ height: "67vh" }}>
              <h1>Não há processos de locação.</h1>
            </Content>
          )}

          {apiResult.length >= 1 && (
            <Content>
              {!!user && (
                <h3>
                  {user.type === "inquilino" || !!user.type === false
                    ? "INQUILINO"
                    : "PROPRIETÁRIO"}
                </h3>
              )}

              {!!user && <h1>Processos de Locação</h1>}

              {apiResult.map((result) => (
                <ProposalsContainer key={result.property_id}>
                  <PropertyInformationsContainer>
                    <img src={result.photo} alt="houseImg" />

                    <PropertyLocalContainer>
                      <h3>{result.title}</h3>

                      <p>
                        {result.address}, {result.neighborhood} {result.city} -{" "}
                        {result.uf}
                      </p>
                      <p>
                        <b>Inquilino Interessado:</b> {result.tentant_name}
                      </p>
                      <p>
                        <b>Etapa Atual:</b> {getCurrentStepLabel(result.status)}
                      </p>

                      <div>
                        <strong>Aluguel: R$ {result.rent_value}</strong>
                        <strong>
                          Total: R${" "}
                          {(
                            parseFloat(result.rent_value) +
                            parseFloat(result.condominium_value) +
                            parseFloat(result.iptu_value)
                          ).toFixed(2)}
                        </strong>
                        {result.status === "CONTRACT_DONE" && (
                          <Link
                            to={{
                              pathname: "/sign-contract",
                              state: {
                                rent_process_id: result.id,
                              },
                            }}
                          >
                            VER CONTRATO
                          </Link>
                        )}
                        {result.status === "CONTRACT_FINISHED" && (
                          <button
                            onClick={() => {
                              toDownloadContractHandler(result.id);
                            }}
                          >
                            {isDownloadingContract
                              ? "BAIXANDO..."
                              : "BAIXAR CONTRATO"}
                          </button>
                        )}
                        {errorInDownloadingContract && (
                          <label className="error-lbl">
                            Ocorreu um erro. Tente novamente mais tarde.
                          </label>
                        )}
                      </div>
                    </PropertyLocalContainer>

                    <PricesAndLinkContainer>
                      <strong>Aluguel: R$ {result.rent_value}</strong>
                      <strong>
                        Total: R${" "}
                        {(
                          parseFloat(result.rent_value) +
                          parseFloat(result.condominium_value) +
                          parseFloat(result.iptu_value)
                        ).toFixed(2)}
                      </strong>
                      {result.status === "CONTRACT_DONE" && (
                        <Link
                          to={{
                            pathname: "/sign-contract",
                            state: {
                              rent_process_id: result.id,
                            },
                          }}
                        >
                          VER CONTRATO
                        </Link>
                      )}
                      {result.status === "CONTRACT_FINISHED" && (
                        <button
                          onClick={() => {
                            toDownloadContractHandler(result.id);
                          }}
                        >
                          {isDownloadingContract
                            ? "BAIXANDO..."
                            : "BAIXAR CONTRATO"}
                        </button>
                      )}
                      {errorInDownloadingContract && (
                        <label className="error-lbl">
                          Ocorreu um erro. Tente novamente mais tarde.
                        </label>
                      )}
                    </PricesAndLinkContainer>
                  </PropertyInformationsContainer>
                </ProposalsContainer>
              ))}
            </Content>
          )}
        </>
      )}
      <Footer />
    </Container>
  );
}
