import styled from "styled-components";

export const Content = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 3px 3px 3px 5px rgba(174, 175, 177, 0.3);
`;
