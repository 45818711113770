import React from "react";

import { Container, Content, MessageContainer } from "./styles";

import { FiX, FiCheck } from "react-icons/fi";

interface IModalSendDocumentationSuccess {
  functionToClose(): void;
}

export default function ModalSendDocumentationSuccess({
  functionToClose,
}: IModalSendDocumentationSuccess) {
  return (
    <Container>
      <Content>
        <button onClick={functionToClose}>
          <FiX size={30} color="#FFF" />
        </button>

        <MessageContainer>
          <FiCheck size={57} color="#FFF" />

          <h1>Tudo Certo!</h1>

          <h3>
            Documentos enviados com sucesso. Retornando à tela principal...
          </h3>
        </MessageContainer>
      </Content>
    </Container>
  );
}
