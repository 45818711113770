import React, { createContext, useState, useCallback, useContext } from 'react';

import api from './../services/api';

interface ISignInCredentials{
	email: string;
	password: string;
}

interface IRegisterUserCredentials{
	first_name: string | undefined;
	email: string | undefined;
	phone: string ;
	type: string;
	password: string | undefined;
	password_confirmation: string | undefined;
}

interface IUpdateUserCredentials{
	first_name: string;
	email: string;
	phone: string;
	password: string;
	password_confirmation: string;
}

interface IUpdateUserCredentialsResponse{
	message: string;
}

interface IScheduleCredentials{
	id: number;
	video_call?: string;
	property_id: string;
	userChannel?: string;
}

interface IScheduleCredentialsResponse{
	message: string;
	success: boolean;
}

interface IUser{
	id: number;
	first_name: string;
	email: string;
	phone: string;
	type: string;
}

interface IAuthState{
	user: IUser;
	access_token: string;
	dateToExpires: Date;
}

interface ISignInCredentialsReturn{
	message?: string;
}

interface IContext{
	signIn(credentials: ISignInCredentials): Promise<void>;
	signInFacebook(credentials: ISignInCredentials): Promise<ISignInCredentialsReturn | void>;
	registerUser(credentials: IRegisterUserCredentials): Promise<void>;
	logOut(): Promise<void>;
	updateUser(credentials: IUpdateUserCredentials): Promise<IUpdateUserCredentialsResponse>;
	scheduleAppointment(credentials: IScheduleCredentials): Promise<IScheduleCredentialsResponse>; 
	user: IUser | undefined;
	access_token: string;
	dateToExpires: Date;
}

const AuthContext = createContext<IContext>({} as IContext);

const AuthProvider: React.FC = ({ children }) => {
	const [data, setData] = useState<IAuthState>(() => {
		const access_token = localStorage.getItem('@7Cantos:token');
		const user = localStorage.getItem('@7Cantos:user');
		const dateToExpires = localStorage.getItem('@7Cantos:dateToExpires');

		if(access_token && user && dateToExpires){
			api.defaults.headers.authorization = `Bearer ${access_token}`;

			return {user: JSON.parse(user), access_token, dateToExpires: new Date(dateToExpires)};
		}

		return ({} as IAuthState);
	});

	const signIn = useCallback(async({email, password}: ISignInCredentials) => {
		const responseToken = await api.post('/api/auth/login', {
			email,
			password
		});

		const { access_token } = responseToken.data;

		const expiresIn = responseToken.data.expires_in;

		const actualDate = new Date(Date.now());

		const dateToExpires = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), actualDate.getHours(), actualDate.getMinutes(), actualDate.getSeconds() + expiresIn);

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUser = await api.post('/api/auth/me', {
			headers:{
				Authorization: `Bearer ${access_token}`
			}
		});
		
		const user = responseUser.data;

		localStorage.setItem('@7Cantos:token', access_token);
		localStorage.setItem('@7Cantos:user', JSON.stringify(user));
		localStorage.setItem('@7Cantos:dateToExpires', dateToExpires.toString());

		setData({user, access_token, dateToExpires});
	}, []);

	const signInFacebook = useCallback(async({email, password}: ISignInCredentials): Promise<ISignInCredentialsReturn | void> => {
		const response = await api.post('/api/auth/login/facebook', {
			email,
			password
		});

		if(!!response.data.success){
			return (response.data.message);
		}

		const { access_token } = response.data;

		const expiresIn = response.data.expires_in;

		const actualDate = new Date(Date.now());

		const dateToExpires = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), actualDate.getHours(), actualDate.getMinutes(), actualDate.getSeconds() + expiresIn);

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUser = await api.post('/api/auth/me', {
			headers:{
				Authorization: `Bearer ${access_token}`
			}
		});
		
		const user = responseUser.data;

		localStorage.setItem('@7Cantos:token', access_token);
		localStorage.setItem('@7Cantos:user', JSON.stringify(user));
		localStorage.setItem('@7Cantos:dateToExpires', dateToExpires.toString());

		setData({user, access_token, dateToExpires});
	}, []);

	const logOut = useCallback(async() => {
		const access_token = localStorage.getItem('@7Cantos:token');

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		api.post('/api/auth/logout', {
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		});

		localStorage.removeItem('@7Cantos:token');
		localStorage.removeItem('@7Cantos:user');
		localStorage.removeItem('@7Cantos:dateToExpires');

		window.location.reload(false);
	}, []);

	const registerUser = useCallback(async({first_name, email, phone, type, password, password_confirmation}: IRegisterUserCredentials) => {
		const responseRegister = await api.post('/api/auth/register', {
			first_name,
			email,
			phone,
			type,
			password,
			password_confirmation
		});

		const { access_token } = responseRegister.data;

		const expiresIn = responseRegister.data.expires_in;

		const actualDate = new Date(Date.now());

		const dateToExpires = new Date(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate(), actualDate.getHours(), actualDate.getMinutes(), actualDate.getSeconds() + expiresIn);

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUser = await api.post('/api/auth/me', {
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		});

		const user = responseUser.data;

		localStorage.setItem('@7Cantos:token', access_token);
		localStorage.setItem('@7Cantos:user', JSON.stringify(user));
		localStorage.setItem('@7Cantos:dateToExpires', dateToExpires.toString());

		setData({user, access_token, dateToExpires});
	}, []);

	const updateUser = useCallback(async({first_name, email, phone, password, password_confirmation}: IUpdateUserCredentials) => {
		const access_token = localStorage.getItem('@7Cantos:token');
		const dateToExpires = localStorage.getItem('@7Cantos:dateToExpires');

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const responseUpdate = await api.put('/api/client/users', {
			first_name,
			email,
			phone,
			password,
			password_confirmation
		});

		 const responseUser = await api.post('/api/auth/me', {
			headers: {
				Authorization: `Bearer ${access_token}`
			}
		});

		const user = responseUser.data;

		localStorage.setItem('@7Cantos:user', JSON.stringify(user));

		if(!!access_token && dateToExpires){
			setData({user, access_token, dateToExpires: new Date(dateToExpires)});
		}

		return ({
			message: responseUpdate.data.message
		})
	}, []);

	const scheduleAppointment = useCallback(async({ id, video_call, property_id, userChannel }: IScheduleCredentials) => {
		const access_token = localStorage.getItem('@7Cantos:token');
		const campaign = localStorage.getItem('@7Cantos:Campaign');
		const channel = localStorage.getItem('@7Cantos:Channel');

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const response = await api.put(`/api/client/schedules/${id}`, {
			campaign: !!campaign ? campaign : undefined,
			channel: channel ? channel : undefined,
			video_call,
			property_id,
			userChannel
		});

		localStorage.removeItem('@7Cantos:Campaign');

		return {
			message: response.data.message,
			success: response.data.success
		}
	}, []);

	return(
		<AuthContext.Provider value={{signIn, signInFacebook, registerUser, logOut, updateUser, scheduleAppointment, user: data.user, access_token: data.access_token, dateToExpires: data.dateToExpires}}>
			{children}
		</AuthContext.Provider>
	);
};

function useAuth():IContext{
	const context = useContext(AuthContext);

	return context;
}



export {AuthProvider, useAuth};