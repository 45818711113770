import React from "react";
import OptionsRow from "./OptionsRow";
import { FaChevronDown, FaFileDownload } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@material-ui/core";

interface IBills {
  address: string;
  due_date: string;
  link: string;
  status: string;
  value: string;
}

interface IData {
  bills: IBills[];
  onDownloadPaidBill: () => void;
}

export default function OptionsAccordion({ bills, onDownloadPaidBill }: IData) {
  const StatusCell = ({ status }: any) => {
    let statusText;
    let textColor;
    switch (status) {
      case "RECEIVED":
        statusText = "Pago";
        textColor = "#0ca11a";
        break;
      case "SENT":
        statusText = "Enviado";
        textColor = "#8b8a8e";
        break;
      default:
        statusText = "Atrasado";
        textColor = "#b91c1b";
        break;
    }
    return <p style={{ fontSize: "0.8rem", color: textColor }}>{statusText}</p>;
  };

  return (
    <div style={{ width: "100%", height: "30vh", overflow: "scroll" }}>
      {bills.map((bill) => {
        return (
          <Accordion key={bill.due_date}>
            <AccordionSummary expandIcon={<FaChevronDown size="14px" />}>
              <p>{bill.due_date}</p>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <OptionsRow title="Endereço">
                  <p style={{ fontSize: "0.8rem", textAlign: "right" }}>
                    {bill.address}
                  </p>
                </OptionsRow>
                <OptionsRow title="Valor">
                  <p style={{ fontSize: "0.8rem" }}>R$ {bill.value}</p>
                </OptionsRow>
                <OptionsRow title="Status">
                  <StatusCell status={bill.status} />
                </OptionsRow>
                <OptionsRow title="Boleto">
                  <a
                    href={
                      bill.status === "RECEIVED" ? undefined : `${bill.link}`
                    }
                    target={bill.status === "RECEIVED" ? undefined : "_blank"}
                    onClick={
                      bill.status === "RECEIVED"
                        ? () => onDownloadPaidBill()
                        : () => {}
                    }
                  >
                    <FaFileDownload
                      style={{
                        color:
                          bill.status === "RECEIVED"
                            ? "#8b8a8e"
                            : "rgb(110, 165, 255)",
                        cursor: "pointer",
                      }}
                    />
                  </a>
                </OptionsRow>
              </Grid>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
