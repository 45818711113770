import CardHeader from "../../../components/CardHeader";
import WrapperCard from "../../../components/WrapperCard";
import React from "react";
import OptionsTable from "./OptionsTable";
import OptionsAccordion from "./OptionsAccordion";

interface IRentByDate {
  date: string;
  final_value: number;
}

interface IRents {
  boxSizing: string;
  smDown: boolean;
  width: string;
  chartData: IRentByDate[];
}

export default function RentabilityChart({
  chartData = [],
  smDown,
  ...props
}: IRents) {
  let bills = [];

  let totalReceived = 0;
  for (let i = 0; i < chartData.length; i++) {
    totalReceived += chartData[i].final_value;
    bills[i] = { ...chartData[i], totalReceived };
  }
  bills.reverse();

  let content = smDown ? (
    <OptionsAccordion bills={bills} />
  ) : (
    <OptionsTable bills={bills} />
  );

  return (
    <WrapperCard {...props}>
      <CardHeader
        title="Sua Rentabilidade"
        flexDirection="column"
        alignItems="flex-start"
      >
        <p
          style={{
            fontSize: "0.8rem",
            fontWeight: 500,
            margin: "0.25rem 0 0",
          }}
        >
          Rendimento mensal do seu investimento
        </p>
      </CardHeader>
      {content}
    </WrapperCard>
  );
}
