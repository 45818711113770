import React from 'react';

import {Container,
		Content} from './styles';

import { IconBaseProps } from 'react-icons';

interface IConvenienceComponent{
	icon?: React.ComponentType<IconBaseProps>;
	color: string;
	title: string;
	locals: string[];
}

export default function ConvenienceComponent({ icon: Icon, color, title, locals }: IConvenienceComponent){
	return(
		<Container>
			<Content>
				{Icon && (<Icon size={29} color={color} />)}

				<h2>{title}</h2>

				{locals.map(local => (
					<p key={local}>{local}</p>
				))}
			</Content>
		</Container>
	);
};