import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function TicketsListSkeleton() {
  return (
    <div style={{ width: "100%", gap: "3rem" }}>
      <Skeleton variant="text" width="100%" height="4rem" />
      <Skeleton variant="text" width="100%" height="3rem" />
      <Skeleton variant="text" width="100%" height="3rem" />
      <Skeleton variant="text" width="100%" height="3rem" />
    </div>
  );
}
