import styled from 'styled-components'


export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100vh; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 
`;

export const Content = styled.div`
	background: transparent;
	
	position: fixed;

    left: 0;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    .image-gallery-image{
        height: 600px;
    }
    
    .image-gallery{
        position: static;
    }

    .image-gallery-content{
        align-self: center;
        position: static;
    }

    .image-gallery-thumbnails-wrapper{
        width: 201px;
        height: 100vh !important;
        position: absolute;
        right: 0;
        top: 0;
        margin: 0;
    }

    .image-gallery-thumbnails-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
    }

    .image-gallery-slide-wrapper.right{
        width: 800px;
        margin: 4% 0;
    }

    .image-gallery-left-nav{
        left: 0;
    }

    .image-gallery-right-nav{
        right: 0;
    }

    .image-gallery-thumbnails{
        height: 100vh;
    }

    .image-gallery-play-button{
        left: 0;
        bottom: 25px;
    }

    .image-gallery-index{
        right: 0;
        top: 33px;
    }

    .image-gallery-fullscreen-button{
        bottom: 0;
        right: 55px;
    }
    
    .image-gallery-thumbnails-wrapper{
		background: #FFFFFF;
	}


	@media(max-width: 767px){
        .image-gallery{
            position: relative;
        }

        .image-gallery-image{
            height: auto;
        }

        .image-gallery-content{
            align-self: center;
            position: relative;
        }

        .image-gallery-index{
            right: 0;
            top: 0;
        }

        .image-gallery-play-button{
            left: 0;
            bottom: 0;
        }

        .image-gallery-slide-wrapper.right{
            width: auto;
            margin: 0;
        }

        .image-gallery-thumbnails-wrapper{
            width: auto;
            height: auto;
            position: relative;
            height: auto !important;
        }

        .image-gallery-thumbnails{
            height: auto;
        }

        .image-gallery-thumbnails-container{
            display: inherit;
            flex-wrap: nowrap;
            text-align: center;
            white-space: nowrap;
            transition: transform .45s ease-out;
        }
	}

    @media(min-width: 1056px){
        
    }

	@media(min-width: 1500px){
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        .image-gallery-image{
            height: 693px;
        }
        
        .image-gallery{
            position: static;
        }

        .image-gallery-content{
            align-self: center;

            position: static;
        }

        .image-gallery-thumbnails-wrapper{
            width: 201px;
            height: 100vh !important;

            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
        }

        .image-gallery-thumbnails-container{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .image-gallery-slide-wrapper.right{
            width: 1040px;

            margin: 2% 0;
        }

        .image-gallery-thumbnails{
            height: 100vh;
        }

        .image-gallery-index{
            right: 0;
            top: 0;
        }

        .image-gallery-play-button{
            bottom: 0;
            left: 0;
        }

        .image-gallery-fullscreen-button{
            bottom: 0;
            right: 0;
        }

        .image-gallery-left-nav{
            left: 0;
        }

        .image-gallery-right-nav{
            right: 0;
        }
	}

    @media(min-width: 1900px){
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100vh;

        .image-gallery-image{
            height: 693px;
        }
        
        .image-gallery{
            position: static;
        }

        .image-gallery-content{
            align-self: center;

            position: static;
        }

        .image-gallery-thumbnails-wrapper{
            width: 201px;
            height: 100vh !important;

            position: absolute;
            right: 0;
            top: 0;
            margin: 0;
        }

        .image-gallery-thumbnails-container{
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
        }

        .image-gallery-slide-wrapper.right{
            width: 1040px;

            margin: 0;
        }

        .image-gallery-thumbnails{
            height: 100vh;
        }

        .image-gallery-index{
            right: 0;
            top: 0;
        }

        .image-gallery-play-button{
            bottom: 0;
            left: 0;
        }

        .image-gallery-fullscreen-button{
            bottom: 0;
            right: 0;
        }

        .image-gallery-left-nav{
            left: 0;
        }

        .image-gallery-right-nav{
            right: 0;
        }
    }
`;
