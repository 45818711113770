import React, { useMemo } from "react";
import { StepContainer } from "./styles";
import STEPS from "../../pages/ScheduleProcess/enum";

interface IScheduleProcessBeltComponent {
  step: number;
  isCurrentOrOld: boolean;
  isActive: boolean;
  stepLabel: string;
  buttonLabel: string;
  onClick(): void;
}

const ScheduleProcessBeltComponent = ({
  step,
  isCurrentOrOld,
  isActive,
  stepLabel,
  buttonLabel,
  onClick,
}: IScheduleProcessBeltComponent) => {
  const getButtonLayout = useMemo(() => {
    return step === STEPS.SCHEDULE_DONE
      ? "first-step-btn"
      : buttonLabel === ""
      ? "hide-btn"
      : buttonLabel === "Documentação Aprovada" ||
        buttonLabel === "Documentação em Análise"
      ? "dont-click-btn"
      : isActive
      ? "active"
      : "not-active";
  }, [buttonLabel, isActive, step]);

  return (
    <StepContainer>
      <div
        className={isCurrentOrOld ? "step currentOrOld" : "step future"}
      ></div>
      <label>{stepLabel}</label>
      <button className={getButtonLayout} onClick={onClick}>
        {buttonLabel}
      </button>
      {buttonLabel === "Erro!" && (
        <label className="error-lbl">
          Ocorreu um erro. Tente novamente mais tarde.
        </label>
      )}
    </StepContainer>
  );
};

export default ScheduleProcessBeltComponent;
