import React, { FormEvent, useCallback, useState } from 'react';
import ModalConfirmAnunciarImovel from '../ModalConfirmAnunciarImovel';

import CurrencyInput from './../../components/CurrencyInput/';
import MaskedInput from 'react-text-mask';

import * as Yup from 'yup';

import getValidationErrors from './../../utils/getValidationErrors';

import api from './../../services/api';

import {FormContainer,
        Form,} from './styles';

interface IValidationErrors{
	name?: string;
	phone?: string;
	email?: string;
	city?: string;
	neighborhood?: string;
	rent_value?: string;
}

export default function FormToAnunciarImovelPage(){
    const [name, setName] = useState('');
	const [phone, setPhone] = useState('');
	const [email, setEmail] = useState('');
	const [city, setCity] = useState('');
	const [neighborhood, setNeighborhood] = useState('');
	const [rent_value, setRent_Value] = useState('');

	const [validationErrors, setValidationErrors] = useState({} as IValidationErrors);

    const [loadingApiRequest, setLoadingApiRequest] = useState(false);

    const [openModalConfirmAnunciarImovel, setOpenModalConfirmAnunciarImovel] = useState(false);

	const handleCloseModalConfirmAnunciarImovel = useCallback(() => {
		setOpenModalConfirmAnunciarImovel(false);
	}, []);

	const handleOpenModalConfirmAnunciarImovel = useCallback(() => {
		setOpenModalConfirmAnunciarImovel(true);
	}, []);

    const handleAnunciarImovel = useCallback(async(e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setLoadingApiRequest(true);

		try{
			const formatRentValue = rent_value.includes(',') ? rent_value : `${rent_value},00`;

			const data = { name, phone, email, city, neighborhood, rent_value };

			// eslint-disable-next-line
			const phoneRegExp = /\(\d{2,}\) \d{4,}\-\d{4}/g;

			const schema = Yup.object().shape({
				name: Yup.string().required('Nome completo obrigatório'),
				phone: Yup.string().matches(phoneRegExp, 'Número inválido').required('Telefone obrigatório'),
				email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
				city: Yup.string().required('Cidade obrigatória'),
				neighborhood: Yup.string().required('Bairro obrigatório'),
				rent_value: Yup.string().required('Valor obrigatório')
			});

			await schema.validate(data, {
				abortEarly: false,
			});

			const response = await api.post('/api/client/advertise/announceProperty', {
				name,
				phone,
				email,
				city,
				rent_value: formatRentValue,
				neighborhood
			});

			if(!!response.data.success){
				handleOpenModalConfirmAnunciarImovel();

				setName('');
				setPhone('');
				setEmail('');
				setCity('');
				setNeighborhood('');
				setRent_Value('');
			}

			setValidationErrors({});
			setLoadingApiRequest(false);
		} catch(err){
			if(err instanceof Yup.ValidationError){
				const error = getValidationErrors(err);

				setValidationErrors(error);
			}

			setLoadingApiRequest(false);
		}
			
	}, [name, phone, email, city, neighborhood, rent_value, handleOpenModalConfirmAnunciarImovel]);

    return(
        <>
        {!!openModalConfirmAnunciarImovel && (<ModalConfirmAnunciarImovel functionToClose={handleCloseModalConfirmAnunciarImovel}/>)}

        <FormContainer>
			<Form onSubmit={handleAnunciarImovel}>
				<p>Nome Completo: {!!validationErrors.name && (<strong>{validationErrors.name}</strong>)}</p>
				<input 
					type="text" 
					name="name"
					value={name}
					onChange={(e) => setName(e.target.value)}
				/>

				<p>Telefone: {!!validationErrors.phone && (<strong>{validationErrors.phone}</strong>)}</p>
				<MaskedInput
					type="tel" 
					name="phone"
					value={phone}
					onChange={(e) => setPhone(e.target.value)}
					mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/,  /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
				/>

				<p>E-mail: {!!validationErrors.email && (<strong>{validationErrors.email}</strong>)}</p>
				<input 
					type="email"
					 name="email"
					 value={email}
					 onChange={(e) => setEmail(e.target.value)}
				/>

				<p>Cidade do imóvel: {!!validationErrors.city && (<strong>{validationErrors.city}</strong>)}</p>
				<input 
					type="text" 
					name="city"
					value={city}
					onChange={(e) => setCity(e.target.value)}
				/>

				<p>Bairro do imóvel: {!!validationErrors.neighborhood && (<strong>{validationErrors.neighborhood}</strong>)}</p>
				<input 
					type="text" 
					name="neighborhood"
					value={neighborhood}
					onChange={(e) => setNeighborhood(e.target.value)}
				/>

				<p>Valor do aluguel: {!!validationErrors.rent_value && (<strong>{validationErrors.rent_value}</strong>)}</p>
				<CurrencyInput 
					type="text" 
					name="rent_value"
					value={rent_value}
					onChange={(e) => setRent_Value(e.target.value)}
				/>

				{!loadingApiRequest ? (<button type="submit">ENVIAR</button>) : (<span>Carregando...</span>)}
			</Form>
		</FormContainer>
        </>
    );
}