import { Skeleton } from "@material-ui/lab";
import React from "react";

export default function DashboardSkeleton({ smDown }: any) {
  return (
    <div
      style={{
        display: "flex",
        gap: "2rem",
        flexDirection: smDown ? "column" : "row",
      }}
    >
      <Skeleton
        variant="rect"
        width={smDown ? "100%" : "25%"}
        height={smDown ? "90px" : "600px"}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: smDown ? "100%" : "75%",
          height: "100%",
          gap: smDown ? "1rem" : "2rem",
        }}
      >
        <Skeleton variant="rect" width="100%" height="284px" />
        <div
          style={{
            display: "flex",
            gap: smDown ? "1rem" : "2rem",
            flexDirection: smDown ? "column" : "row",
          }}
        >
          <Skeleton
            variant="rect"
            width={smDown ? "100%" : "60%"}
            height="284px"
          />
          <Skeleton
            variant="rect"
            width={smDown ? "100%" : "40%"}
            height="284px"
          />
        </div>
      </div>
    </div>
  );
}
