import styled, { keyframes } from 'styled-components';

export const Container = styled.div`

`;

const MenuProprietarioMobileAnimation = keyframes`
	from{
		transform: translateX(-1000px);
	}

	to{
		transform: translateX(0px);
	}
`;

export const Content = styled.div`
	display: none;

	@media(max-width: 767px){
		position: fixed;
		top: 0;
		left: 0;
		z-index: 20;

		width: 100%;
		height: 100vh;

		overflow-y: auto;

		padding: 18px 0 24px 0;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		flex-direction: column;

		background: #00ABA6;

		animation: ${MenuProprietarioMobileAnimation} 1s;

		>button{
			background: transparent;

			border: 0;

			margin-left: auto;
			margin-right: 24px;
			margin-bottom: 2px;
		}

		>hr{
			width: 100%;

			color: #FFF;
		}
	}
`;

export const LoginContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		padding-bottom: 26px;

		>h2{
			font-size: 15px;
			color: #FFF;

			margin-bottom: 16px;
		}

		>a{
			font-size: 12px;
			font-weight: 500;
			color: #FFF;

			background: #DD6435;

			text-decoration: none;

			padding: 12px 32px;
		}
	}
`;

export const About7CantosContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		padding: 36px 0;

		>a{
			display: flex;
			align-items: center;
			justify-content: center;

			flex-direction: column;

			text-decoration: none;

			color: #FFF;

			>p{
				margin-top: 12px;
			}

			& + a{
				margin-left: 38px;
			}
		}
	}
`;

export const UserRoutesContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		padding: 24px 0;

		>h3{
			color: #FFF;
		}
	}
`;

export const RoutesContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		flex-direction: column;
	}
`;

export const FirstOptionsContainer = styled.div`
	@media(max-width: 767px){
		width: 100%;

		margin: 24px auto 0;

		display: flex;
		align-items: center;
		justify-content: space-between;

		>a{
			display: flex;
			align-items: center;
			justify-content: center;

			flex-direction: column;

			text-decoration: none;

			color: #FFF;

			>p{
				margin-top: 12px;

				width: 105px;

				text-align: center;
			}

			& + a{
				margin-left: 48px;
			}
		}
	}
`;

export const SecondOptionsContainer = styled.div`
	@media(max-width: 767px){
		width: 100%;

		margin: 24px auto 0;

		display: flex;
		align-items: center;
		justify-content: space-between;

		>a{
			display: flex;
			align-items: center;
			justify-content: center;

			flex-direction: column;

			text-decoration: none;

			color: #FFF;

			>p{
				margin-top: 12px;

				width: 105px;

				text-align: center;
			}

			.creditCard-svg{
				margin-left: -8px;
			}

			.p-boletos{
				width: 105px;

				margin-left: -5px;
			}

			>img{
				width: 28px;
				height: 28px;
			}

			& + a{
				margin-left: 48px;
			}
		}
	}
`;

export const SettingsContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		padding: 32px 0;

		>a{
			text-decoration: none;

			color: #FFF;

			& + a{
				margin-top: 14px;
			}
		}

		>button{
			margin-top: 36px;

			background: transparent;

			border: 0;

			font-weight: bold;
			color: #FFF;
		}
	}
`;
