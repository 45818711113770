import styled, { keyframes } from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

const fadeIn = keyframes`
    from { opacity: 0; }
    to   { opacity: 1; }
`;

export const Container = styled.div`
    height: 100%;
    width: 100%;
    background: #F7F9FC;
`;

export const CardListContainer = styled.div`
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    overflow-x: auto;

    // MEDIUM
    @media(min-width: 768px){
        flex-direction: column;
        padding-top: 20px;
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
        padding-right: 20px;
    }

    // LARGE
    @media(min-width: 992px){
        flex-direction: column;
        padding-top: 20px;
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
        padding-right: 45px;
    }
`;

export const FadeIn = styled.div`
    -webkit-animation: ${fadeIn} 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: ${fadeIn} 1s; /* Firefox < 16 */
    -ms-animation: ${fadeIn} 1s; /* Internet Explorer */
    -o-animation: ${fadeIn} 1s; /* Opera < 12.1 */
    animation: ${fadeIn} 1s;
`;

export const EmptyListMessage = styled.p`
    width: 100%;
    text-align: center;
    margin-top: 50px;
`;
