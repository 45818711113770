import React, { useEffect, useState } from "react";

import {
  Content,
  PropertyInformartionsContainer,
  InformationsContainer,
} from "./styles";

import ScheduleProcessBelt from "../ScheduleProcessBelt";
import STEPS from "../../pages/ScheduleProcess/enum";

interface IApiResult {
  id: number;
  status: string;
  property_id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  photo: string;
}

interface IScheduleProcessComponent {
  rentInformation: IApiResult;
}

const ScheduleProcessComponent = ({
  rentInformation,
}: IScheduleProcessComponent) => {
  const [currentStep, setCurrentStep] = useState<number>(1);

  useEffect(() => {
    // setCurrentStep(4);
    setCurrentStep(() => {
      switch (rentInformation.status) {
        case "SCHEDULE_DONE":
          return STEPS.SCHEDULE_DONE;
        case "OFFER_DONE":
          return STEPS.OFFER_DONE;
        case "DOCUMENTS_TO_ANALYSIS":
          return STEPS.DOCUMENTS_TO_ANALYSIS;
        case "CONTRACT_DONE":
          return STEPS.CONTRACT_DONE;
        default:
          return STEPS.CONTRACT_FINISHED;
      }
    });
  }, [rentInformation]);
  const calculateTotalValue = () => {
    return (
      parseFloat(rentInformation.rent_value) +
      parseFloat(rentInformation.iptu_value) +
      parseFloat(rentInformation.condominium_value)
    );
  };

  return (
    <Content>
      <PropertyInformartionsContainer>
        <img src={rentInformation.photo} alt="houseImg" />

        <InformationsContainer>
          <h3>{rentInformation.title}</h3>

          <p>{rentInformation.address}</p>

          <strong>Aluguel: R$ {rentInformation.rent_value}</strong>
          <strong>Total: R$ {calculateTotalValue()}</strong>
        </InformationsContainer>
      </PropertyInformartionsContainer>

      <hr />

      <ScheduleProcessBelt currentStep={currentStep} rentInformation={rentInformation}/>
    </Content>
  );
};

export default ScheduleProcessComponent;
