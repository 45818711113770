import React from 'react';

import {
    CardContainer,
    CardCover,
    CardInfoContainer,
    CardPropertyCityAndName,
    CardPropertyName,
    CardPropertyAddress,
    CardPropertyGeneralInfo
  } from './styles';

export default function PropertyCard({ city, price, name, generalInfo, address, imageUrl } : any) {


    return (
        <CardContainer>
            <CardCover imgUrl={imageUrl} />
            <CardInfoContainer>
                <CardPropertyCityAndName>
                    <p className='city'>{city}</p>
                    <p className='price'>{price}</p>
                </CardPropertyCityAndName>
                <CardPropertyName>
                <p className='name'>{name}</p>
                </CardPropertyName>
                <CardPropertyAddress>
                    <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4 5C3.45 5 3 4.55 3 4C3 3.45 3.45 3 4 3C4.55 3 5 3.45 5 4C5 4.55 4.55 5 4 5ZM7 4.1C7 2.285 5.675 1 4 1C2.325 1 1 2.285 1 4.1C1 5.27 1.975 6.82 4 8.67C6.025 6.82 7 5.27 7 4.1ZM4 0C6.1 0 8 1.61 8 4.1C8 5.76 6.665 7.725 4 10C1.335 7.725 0 5.76 0 4.1C0 1.61 1.9 0 4 0Z" fill="#DD6536"/></svg>
                    <p className='address'>{address}</p>
                </CardPropertyAddress>
                <CardPropertyGeneralInfo>
                    <p className='infos'>{generalInfo}</p>
                </CardPropertyGeneralInfo>
            </CardInfoContainer>
        </CardContainer>
    );
}