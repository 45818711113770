import React, { useState, useCallback, FormEvent } from 'react';

import api from './../../services/api';

import { useAuth } from './../../hooks/auth';

import CurrencyInput from './../CurrencyInput/';

import {Container, 
		Content,
		InitialContainer,
		PropertyInformationsContainer,
		TitleAndValuesContainer,
		FormContainer,
		Form,
		UserInputContainer,
		InputContainer} from './styles';

import { FiX } from 'react-icons/fi';
import { BsPencil } from 'react-icons/bs';

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}


interface IModalCounterSendProposal{
	property_id: number;
	initial_offer_id: number;
	title: string;
	photo: string;
	address: IAddress;
	rent_value: string;
	total_value: string;
	functionToCloseModalSendCounterProposal(): void;
}

export default function ModalSendCounterProposal({ property_id, initial_offer_id, title, photo, address, rent_value, total_value, functionToCloseModalSendCounterProposal }: IModalCounterSendProposal){
	const { access_token } = useAuth();

	const [offerValue, setOfferValue] = useState('');
	const [requirements, setRequeriments] = useState('');

	const [offerValueError, setOfferValueError] = useState('');

	const [loadingApiRequest, setLoadingApiRequest] = useState(false);

	const handleSendProposal = useCallback(async(e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setLoadingApiRequest(true);

		const check80percent = parseFloat(rent_value)*0.8;

		if(parseFloat(offerValue) < check80percent){
			setOfferValueError('Valor menor que 80% do valor do aluguel');
			setLoadingApiRequest(false);

			return;
		}

		if(!offerValue){
			setOfferValueError('Valor do aluguel obrigatório');
			setLoadingApiRequest(false);

			return;
		}

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const response = await api.post('/api/client/tenant/offers',{
			initial_offer_id: !!initial_offer_id ? initial_offer_id : null,
			offer_value: parseFloat(offerValue).toFixed(2),
			requirements: !!requirements ? requirements : undefined,
			property_id
		});

		if(!!response.data.success){
			functionToCloseModalSendCounterProposal();
		};

		setLoadingApiRequest(false);
		setOfferValueError('');
	}, [access_token, offerValue, rent_value, property_id, requirements, initial_offer_id, functionToCloseModalSendCounterProposal]);


	return(
		<Container>
				<Content>
					<InitialContainer>
						<button onClick={functionToCloseModalSendCounterProposal}><FiX size={30} color="#FFF"/></button>

						<h1>FAZER PROPOSTA</h1>
					</InitialContainer>

					<PropertyInformationsContainer>
						<TitleAndValuesContainer>
							<h3>{title}</h3>

							<strong>{address.address}, {address.neighborhood} {address.city} - {address.uf}</strong>

							<p>Aluguel: R$ {rent_value}</p>
							<p>Total: R$ {total_value}</p>
						</TitleAndValuesContainer>

						<img src={photo} alt="houseImg"/>
					</PropertyInformationsContainer>

					<FormContainer>
						<h4>Proposta personalizada</h4>

						<Form onSubmit={handleSendProposal}>
							<UserInputContainer>
								<p>Valor do aluguel:</p>
								{!!offerValueError && (<label style={{color: '#FF3838'}}>{offerValueError}</label>)}

								<InputContainer>
									<CurrencyInput 
										type="text" 
										placeholder="R$ 1.500,00"
										value={offerValue}
										onChange={(e) => {
											const re = /^[0-9\b]+$/;

											if (e.target.value === '' || re.test(e.target.value.substring(3))) {
										    	setOfferValue(e.target.value.substring(3));
										    }
										}}
									/>

									<BsPencil size={14} color="#666666"/>
								</InputContainer>
							</UserInputContainer>

							<UserInputContainer>
								<p>Solicitações:</p>

								<InputContainer>
									<textarea 
										placeholder="Solicitar alterações no imóvel"
										value={requirements}
										onChange={(e) => setRequeriments(e.target.value)}
									/>

									<BsPencil size={14} color="#666666"/>
								</InputContainer>
							</UserInputContainer>

							{!loadingApiRequest ? (<button type="submit">ENVIAR PROPOSTA</button>) : (<p>Carregando</p>)}
						</Form>
					</FormContainer>

					<p>*É possível propor um valor diferente apenas para o aluguel, já que os outros valores são inalteráveis.</p>

					<p>** Propostas com valor inferior a 80% do valor do imóvel serão desconsideradas.</p>
				</Content>
		</Container>
	);
};