import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1200px;

	margin: 0 auto;

	padding: 64px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h3{
		font-size: 32px;
		color: #DD6435;

		margin-bottom: 32px;
	}

	>h1{
		font-size: 50px;
		font-weight: bold;

		margin-bottom: 16px;
	}

	>p{
		font-size: 24px;

		margin-bottom: 52px;
	}

	>a{
		text-decoration: none;
		color: #666666;

		padding: 12px 32px;

		border: 1px solid #666666;

		margin-bottom: 32px;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;
		}

		>p{
			font-size: 14px;

			width: 300px;

			text-align: center;
		}

		>a{
			font-size: 12px;
			font-weight: 500;
		}

		>img{
			width: 342px;
			height: 196px;
		}
	}
`;