import styled from "styled-components";

interface IInputContainer{
	hasError: boolean;
	isToShowContentSelectedNumber: number;
}

interface IHeader{
	hasBorder?: boolean;
	isToShowSearchBarMobile?: boolean;
}

export const SearchContainer = styled.form<IHeader>`
	@media(max-width: 767px){
		display: ${props => !!props.isToShowSearchBarMobile ? 'flex' : 'none'};
		align-items: flex-start;
		justify-content: center;

		flex-direction: column;

		margin: 0 auto;
	}

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	margin-left: 20px;

	position: relative;
`;

export const InputContainer = styled.div<IInputContainer>`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	padding-left: 12px;

	height: 31px;

	border: 1px solid ${props => !!props.hasError ? '#FF3838' : '#B6B6B6'};

	>input{
		width: 150px;

		margin-left: 12px;

		border: 0;

		background: transparent;

		font-size: 12px;

		&::placeholder{
			color: ${props => !!props.hasError ? '#FF3838' : '#B6B6B6'};
		}
	}

	>button#content-selected-button{
		padding: 0;

		width: 22px;
		height: 22px;

		margin-right: 8px;

		background: #cce6ea;

		border: 0;

		border-radius: 6px;

		font-size: 12px;
		color: #666666;

		visibility: ${props => props.isToShowContentSelectedNumber >= 1 ? 'visible' : 'hidden'}
	}

	>button{
		border: 0;
		border-top: ${props => !!props.hasError && '1px solid #FF3838'};
		border-bottom: ${props => !!props.hasError && '1px solid #FF3838'};

		background: #6EA5FF;

		font-size: 13px;
		font-weight: 600;
		color: #FFF;

		height: 31px;

		padding: 0 12px; 
	}

	@media(max-width: 767px){
		padding-left: 8px;
		height: 35px;

		>svg{
			width: 19px;
			height: 19px;
		}

		>input{
			width: 120px;

			font-size: 12px;

			&::placeholder{
				font-size: 12px;
			}
		}

		>button#content-selected-button{
			width: 24px;
			height: 24px;

			margin-right: 4px;

			font-size: 12px;
		}

		>button{
			display: flex;
			align-items: center;
			justify-content: center;

			height: 35px;

			padding: 12px 6px 12px 12px;

			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		height: 35px;

		>svg{
			width: 18px;
			height: 18px;
		}

		>input{
			width: 170px;

			font-size: 14px;
		}

		>button{
			font-size: 14px;

			height: 35px;
		}

		>button#content-selected-button{
			width: 27px;
			height: 27px;

			font-size: 14px;
		}
	}
`;

export const OptionsToSearch = styled.div`
    background: #eeeeee;

    padding: 0 24px;

    width: 221px;
    height: 320px;

    overflow: auto;

    position: absolute;
    top: 32px;
    left: 0;

    z-index: 12;

    -webkit-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);

	@media(max-width: 767px){
		width: 189px;

		top: 36px;
	    left: 0;
	}

	@media(min-width: 1441px){
		top: 35px;

		width: 252px;
	}
`;

export const ContentSelectedContainer = styled.div`
    background: #eeeeee;

    padding: 0 8px;

    width: 221px;
    height: 320px;

    overflow: auto;

    position: absolute;
    top: 32px;
    left: 0;

    z-index: 12;

    -webkit-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);
	box-shadow: 0px 10px 22px 0px rgba(0,0,0,0.75);

	@media(max-width: 767px){
		padding: 0 6px;

		width: 189px;

		top: 36px;
	    left: 0;
	}

	@media(min-width: 1441px){
		top: 35px;

		width: 252px;
	}
`;

export const ContentSelectedTag = styled.div`
	display: flex;
	align-items: center;

	padding: 8px;

	margin: 12px 0;

	border-radius: 6px;

	background: #CCE6EA;

	overflow: hidden;
	text-overflow: ellipsis;

	>p{
		font-size: 12px;
	}

	>button{
		background: transparent;

		border: 0;

		display: flex;
		align-items: center;
		justify-content: center;

		margin-left: auto;
	}

	@media(max-width: 767px){
		>p{
			font-size: 12px;
		}

		>button{
			>svg{
				width: 12px;
				height: 12px;
			}
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 16px;
		}
	}
`;

export const NeighborhoodsOptionsContainer = styled.div`
	display: flex;
    justify-content: center;
    align-items: flex-start;

    flex-direction: column;

    padding-bottom: 6px;

	>h3{
		font-size: 16px;
	}

    >button{
		font-size: 13px;
    	color: #666666;

        margin-top: 8px;
        margin-left: 12px;

        text-align: start;

        border: 0;
        background: transparent;

        & + button{
            margin-top: 16px;
        }
    }

    @media(max-width: 767px){
    	>h3{
    		font-size: 14px;
    	}

    	>button{
    		font-size: 12px;
    	}
    }    

	@media(min-width: 1441px){
		>h3{
			font-size: 18px;
		}

		>button{
			font-size: 16px;
		}
	}
`;

export const CitiesOptionsContainer = styled.div`
	margin-top: 16px;

	display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding-bottom: 6px;

	>h3{
		font-size: 16px;
	}

    >a{
    	text-decoration: none;

		font-size: 13px;
    	color: #666666;

        margin-top: 8px;
        margin-left: 12px;

        border: 0;
        background: transparent;

        & + a{
            margin-top: 16px;
        }
    }

    @media(max-width: 767px){
    	>h3{
    		font-size: 14px;
    	}

    	>a{
    		font-size: 12px;
    	}
    }

	@media(min-width: 1441px){
		>h3{
			font-size: 18px;
		}

		>a{
			font-size: 16px;
		}
	}
`;