import React from "react";
import { Title } from "./style";

export default function UserInfoActions({ children }: any) {
  return (
    <>
      <Title>Precisa de ajuda?</Title>
      {children}
    </>
  );
}
