import React from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import {Container,
		Content,
		MessageContainer} from './styles';

import { FiX, FiCheck } from 'react-icons/fi';

interface IModalConfirmRequestTermination{
	functionToClose(): void;
}

export default function ModalConfirmRequestTermination({ functionToClose }: IModalConfirmRequestTermination){
	return(
		<Container>
			<OutsideClickHandler onOutsideClick={functionToClose}>
			<Content>
				<button onClick={functionToClose}><FiX size={30} color="#FFF"/></button>

				<MessageContainer>
					<FiCheck size={57} color="#FFF" />

					<h1>SOLICITAÇÃO ENVIADA</h1>

					<h3>Em breve nossa equipe entrará em contato para confirmar a sua rescisão de contrato.</h3>
				</MessageContainer>
			</Content>
			</OutsideClickHandler>
		</Container>
	);
};