import styled from "styled-components";

export const Container = styled.div`
`;

export const Content = styled.div`
    margin: 2rem;
    display: flex;
    gap: 2rem;

    @media(max-width: 767px) {
        flex-direction: column;
    }
`;

export const PaymentsAndMenuContainer = styled.div`
    width: 75%;
    box-sizing: border-box;
    row-gap: 2rem;
    display: flex;
    flex-direction: column;
    @media(max-width: 767px) {
        width: 100%;
    }
`;
