import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.div`
  max-width: 767px;
  margin: 42px auto;

  > h2 {
    font-size: 1.5rem;
    color: #dd6435;
    margin-bottom: 8px;
  }
  > h3 {
    margin: 16px 0;
    font-size: 1.25rem;
  }

  .last-update {
    font-size: 0.9rem;
    color: hsl(0, 0, 0);
    margin-bottom: 18px;
  }
  .agreement {
	margin-top: 16px;
	font-size: 0.9rem;
  }

  @media (max-width: 767px) {
	margin: 0 16px;
    > h2 {
      font-size: 17px;
    }

    > h1 {
      width: 85%;

      font-size: 26px;
    }

    > p {
      font-size: 13px;
    }
    .last-update {
      font-size: 0.7rem;
      color: hsl(0, 0, 0);
      margin-bottom: 18px;
    }
  }
`;
