import styled from 'styled-components';

import { MAX_WIDTH_PAGE } from '../../styles';

// CONTAINER

export const Container = styled.div`
    max-width: ${MAX_WIDTH_PAGE};
    margin 0 auto;
    padding: 0;
`;