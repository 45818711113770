import React, { useEffect, useState } from "react";

import api from "../../services/api";

import { useAuth } from "../../hooks/auth";
import { useHistory, useLocation } from "react-router-dom";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { Container, Content } from "./styles";

import Loading from "../../components/Loading";
import Clicksign from "./embedded";

export default function SignContract() {
  const { user, access_token } = useAuth();
  const history = useHistory();
  const { state } = useLocation();
  const [contractAlreadySigned, setContractAlreadySigned] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  //   const [signerKey, setSignerKey] = useState();

  const [loading, setLoading] = useState(true);

  useEffect(() => {

    const createWidget = async () => {
      var widget, response;
      try {
        response = await api.get(
          `/api/client/signer-key/${state.rent_process_id}`
        );
      } catch (error) {
        setContractAlreadySigned(true);
        if (error?.response?.status === 404)
          return setErrorMessage(error?.response?.data?.message);

        return setErrorMessage("Ocorreu um erro! Tente novamente mais tarde.");
      }

      if (widget) {
        widget.unmount();
      }

      widget = new Clicksign(response.data.data.signer_key);

      widget.endpoint = "https://sandbox.clicksign.com";
      widget.origin = "https://www.7cantos.com/sign-contract";
      widget.mount("container");

      widget.on("loaded", function (ev) {
        console.log("loaded!");
      });
      widget.on("signed", function (ev) {
        console.log("signed!");
      });
      widget.on("resized", function (height) {
        console.log("resized!");
        document.getElementById("container").style.height = height + "px";
      });
    };
    try {
      createWidget();
    } catch (error) {
      console.log(error.message);
    }
    setLoading(false);
  }, []);

  return (
    <Container>
      <Header hasSearchBar hasBorder />
      {!!loading && <Loading />}
      {contractAlreadySigned ? (
        <div id="signed-contract">
          {errorMessage}
          <button onClick={() => history.goBack()}>Voltar</button>
        </div>
      ) : (
        <div id="container"></div>
      )}

      <Footer />
    </Container>
  );
}
