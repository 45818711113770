import { useHistory } from "react-router-dom";
import React from "react";
import { MdAccessTime, MdHome, MdPersonOutline } from "react-icons/md";
import { OutlinedButton } from "./style";

interface IOffer {
  date: string;
  tenant: string;
  address: string;
  status: string;
  tenant_id: number;
  property_id: number;
}

interface IData {
  offer: IOffer;
}

function getProposalTime(proposalDate: string) {
  const [day, month, year] = proposalDate.split("/");
  const dateInMs = new Date(
    parseInt(year),
    parseInt(month) - 1,
    parseInt(day)
  ).getTime();
  const currentDateInMs = new Date().getTime();

  const daysPassedInMs = currentDateInMs - dateInMs;

  const millisecondsInADay = 24 * 60 * 60 * 1000;

  const days = daysPassedInMs / millisecondsInADay;

  return Math.floor(Number(days));
}

export default function OptionsCard({ offer }: IData) {
  const history = useHistory();
  const daysPassed = getProposalTime(offer.date);

  const ICONS_SIZE = "16px";

  const StatusCell = ({ status, propertyId, tenantId }: any) => {
    let statusText;
    let textColor;
    switch (status) {
      case "ACCEPT":
        statusText = "Aceito";
        textColor = "#0ca11a";
        break;
      case "CANCELED":
        statusText = "Cancelado";
        textColor = "#b91c1b";
        break;
      default:
        statusText = "Pendente";
        textColor = "#debe0b";
        break;
    }
    return (
      <OutlinedButton
        style={{
          color: textColor,
          border: `1px solid ${textColor}`,
        }}
        onClick={() =>
          history.push(`/proposal-received/${propertyId}`, {
            client_id: tenantId,
          })
        }
      >
        {statusText}
      </OutlinedButton>
    );
  };

  return (
    <div
      style={{
        border: "0.5px solid rgb(139, 139, 139)",
        borderRadius: "0.5rem",
        padding: "0.5rem 1rem",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div
        style={{ display: "flex", flexDirection: "column", rowGap: "0.5rem" }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.25rem",
          }}
        >
          <MdAccessTime color="primary" fontSize={ICONS_SIZE} />
          <p style={{ fontSize: "12px" }}>{daysPassed} dias atrás</p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.25rem",
          }}
        >
          <MdPersonOutline color="primary" fontSize={ICONS_SIZE} />
          <p style={{ fontSize: "12px", color: "dark", fontWeight: 500 }}>
            {offer.tenant}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.25rem",
          }}
        >
          <MdHome color="primary" fontSize={ICONS_SIZE} />
          <p style={{ fontSize: "12px", color: "dark", fontWeight: 500 }}>
            {offer.address}
          </p>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        {/* <CheckCircleOutline
          color="success"
          fontSize="10px"
          style={{ cursor: "pointer" }}
        /> */}
        <StatusCell
          status={offer.status}
          propertyId={offer.property_id}
          tenantId={offer.tenant_id}
        />
      </div>
    </div>
  );
}
