import React, { useEffect } from 'react';

import { useAuth } from './../../hooks/auth';

import Header from './../../components/Header/'
import Footer from './../../components/Footer/';

import { Link, useHistory, useLocation } from 'react-router-dom';

import {Container, Content, FinancialReportContainer} from './styles';
import FormToLoginPage from '../../components/FormToLoginPage';

interface IState{
	fromSite: boolean | undefined;
	previousScreen?: 'property';
	propertyId?: string;
	consultant_id?: string;
}

export default function Login(){
	const { user } = useAuth();

	const history = useHistory();

	const { state } = useLocation<IState>();

	useEffect(() => {
		if(!!user && !!state){
			history.goBack();
		}

		if(!!user && !state){
			history.push("/");
		}

	// eslint-disable-next-line
	}, [history]);

	return(
		<Container>
			<Header />
			<Content>
				<h2>FAZER LOGIN</h2>

				<h1>Seu lar dos sonhos está ao alcance de um clique.</h1>

				<FormToLoginPage />

				<p>Ainda não tem cadastro? <Link to={{
					pathname: "/register",
					state: !!state && state.previousScreen === 'property' ?  {prevPath: history.location.pathname, previousScreen: 'property', propertyId: state.propertyId, consultant_id: state.consultant_id} : {prevPath: history.location.pathname}
				}}>Crie sua conta!</Link></p>

				<Link to="/forgot-password">Esqueci minha senha</Link>

				<FinancialReportContainer>
					<p>Já é Proprietário ou Inquilino de um imóvel gerido pela 7Cantos? Aqui você tem acesso a faturas para pagamento do aluguel, extrato detalhado de repasses e documentos pertinentes a locação do seu imóvel</p>

					<a href="https://pauloregisarcan.superlogica.net/clients/areadofornecedor">Já sou Proprietário</a>
					<a href="https://pauloregisarcan.superlogica.net/clients/areadocliente">Já sou Inquilino</a>
				</FinancialReportContainer>
			</Content>
			<Footer />
		</Container>
	);
};