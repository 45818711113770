import React from 'react';

import { MarkerPoint, MarkerPulse } from './styles';

interface IMarker{
	color: string;
	lat: number;
	lng: number;
}

export default function Marker({color, lat, lng}: IMarker){
	return(
		<>
			<MarkerPoint
		        style={{ backgroundColor: color, cursor: 'pointer'}}
		    />

		    <MarkerPulse/>
	    </>
	);
};
