import styled from 'styled-components';

import ReactSlider from 'react-slider';

interface IStyledTrack{
	index: number;
};

interface INumberOfContainer{
	isSelected?: boolean;
};

export const Container = styled.div`

`;

export const TitleContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	background: #DD6435;

	padding: 38px 16px 34px 38px;

	>button{
		background: transparent;

		border: 0;

		margin-left: auto;
	}

	>h2{
		font-size: 22px;
		font-weight: bold;
		color: #FFF;

		margin-top: -6px;
	}
`;

export const ButtonsContainer = styled.div`
	width: 100%;

	display: flex;
	align-items: center;
	justify-content: center;

	padding: 24px 38px;

	>button{
		background: transparent;

		border: 1px solid #666666;

		padding: 12px;

		width: 100%;

		font-size: 12px;
		font-weight: 500;

		& + button{
			margin-left: 16px;
		}
	}

	button.send-filter-button{
		background: #00BF33;

		border: 0;

		color: #FFF;
	}

	>p{
		border: 1px solid #00BF33;

		width: 100%;

		padding: 12px;

		font-size: 12px;
		font-weight: 500;
		color: #00BF33;

		text-align: center;

		margin-left: 16px;
	}
`;

export const LocalizationContainer = styled.div`
	width: 100%;

	padding: 24px 38px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	.css-2b097c-container{
		width: 100%;
	}

	>h3{
		margin-bottom: 16px;
	}
`;

export const ImovelTypeContainer = styled.div`
	width: 100%;

	padding: 24px 38px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;
`;

export const CheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 8px 0;

	cursor: pointer;

	>label{
		cursor: pointer;

		margin-left: 12px;
	}
`;

export const SliderContainer = styled.div`
	width: 100%;

	padding: 24px 56px 24px 38px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;
`;

export const ValuesToSearch = styled.p`
	font-size: 12px;

	text-align: center;

	align-self: center;

	margin: 20px 0 12px;
`;

export const StyledSlider = styled(ReactSlider)`
	width: 100%;
	height: 8px;

	margin: 8px 0;
`;

export const StyledThumb = styled.div`
	height: 12px;
	width: 12px;
	background-color: #6EA5FF;
	border-radius: 50%;
	cursor: grab;
`;

export const StyledTrack = styled.div<IStyledTrack>`
	top: 0;
	bottom: 0;
	margin-top: 4px;
	background: ${props => props.index === 2 ? '#999999' : props.index === 1 ? '#6EA5FF' : '#999999'};
`;

export const TotalCheckboxContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin: 0 auto 16px;

	>input{
		color: #B6B6B6;
	}

	>label{
		margin-left: 12px;

		font-size: 14px;

		cursor: pointer;
	}
`;

export const NumbersOfContainer = styled.div`
	width: 100%;

	padding: 24px 38px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		margin-bottom: 16px;
	}
`;

export const NumbersContainer = styled.div`
	margin-bottom: 42px;

	width: 100%;

	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const NumberOfButton = styled.button<INumberOfContainer>`
	background: transparent;

	border: 0;

	font-size: 17px;
	font-weight: bold;
	color: ${props => !!props.isSelected ? "#6EA5FF" : "#B6B6B6"};

	& + button{
		margin-left: 48px;
	}
`;

export const PropertyBenefitsContainer = styled.div`
	width: 100%;

	padding: 0px 38px 24px;

	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>h3{
		margin-bottom: 16px;
	}
`;

export const PropertyBenefitsContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	>label{
		margin-left: 8px;
	}
`;