import React, { useEffect, useState, useCallback, useMemo } from "react";

import api from "./../../services/api";

import { useAuth } from "./../../hooks/auth";

import { useHistory, useRouteMatch, useLocation } from "react-router-dom";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";
import Loading from "./../../components/Loading/";

import ModalSendCounterProposal from "./../../components/ModalSendCounterProposal/";

import {
  Container,
  Content,
  ProposalsContainer,
  PropertyInformationsContainer,
  PropertyLocalContainer,
  PricesContainer,
  OfferContainer,
  InformationsAboutOfferContainer,
  OfferActions,
} from "./styles";

interface IParams {
  id: string;
}

interface IState {
  client_id: number;
  prevRoute: string;
}

interface IAddress {
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
}

interface IPhotos {
  id: number;
  url_m: string;
}

interface IProperty {
  id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: IAddress;
  photos: IPhotos[];
}

interface IAuthor {
  id: number;
  first_name: string;
}

interface IOffer {
  id: number;
  author: IAuthor;
  offer_value: string;
  requirements: string | null;
  initial_offer_id: number | null;
  client_id: number;
  user_id: number;
  owner_id: number;
  status: number;
  updated_at: string;
}

interface IApiResult {
  property: IProperty;
  offer: IOffer[];
}

export default function ProposalReceived() {
  const { params } = useRouteMatch<IParams>();
  const { state } = useLocation<IState>();
  const history = useHistory();

  const { user, access_token } = useAuth();

  const [apiResult, setApiResult] = useState({} as IApiResult);
  const [totalValue, setTotalValue] = useState("");

  const [openModalSendCounterProposal, setOpenModalSendProposal] =
    useState(false);

  const [initialOfferID, setInitialOfferID] = useState(0);
  const [title, setTitle] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState({} as IAddress);
  const [rentValue, setRentValue] = useState("");

  const [reloadApi, setReloadApi] = useState(0);

  const [loadingApiRequest, setLoadingApiRequest] = useState(false);

  useEffect(() => {
    if (!user) {
      history.goBack();

      return;
    }

    async function loadApi() {
      api.defaults.headers.authorization = `Bearer ${access_token}`;

      if (!!user) {
        const response = await api.get(
          // (state.prevRoute === "/proposals" ||
          //   state.prevRoute === "/owner-dashboard") &&
          state.client_id && user.type === "proprietario"
            ? `/api/client/owner/offers/${params.id}/${state.client_id}`
            : `/api/client/tenant/offers/${params.id}`
        );

        const total_value =
          parseFloat(response.data.data.property.rent_value) +
          parseFloat(response.data.data.property.condominium_value) +
          parseFloat(response.data.data.property.iptu_value);

        setTotalValue(total_value.toFixed(2));
        setApiResult(response.data.data);
      }
    }

    loadApi();
    // eslint-disable-next-line
  }, [user, history, access_token, params.id, reloadApi]);

  const handleAcceptProposal = useCallback(
    async (proposal_id: number) => {
      try {
        setLoadingApiRequest(true);

        api.defaults.headers.authorization = `Bearer ${access_token}`;

        // eslint-disable-next-line
        const response = await api.put(
          `/api/client/tenant/offers/${proposal_id}`
        );

        if (!!response.data.success) {
          setReloadApi(1);
        }

        setLoadingApiRequest(false);
      } catch (err) {
        console.log(err);

        setLoadingApiRequest(false);
      }
    },
    [access_token]
  );

  const handleOpenSendCounterProposal = useCallback(
    (
      initial_offer_id: number,
      title: string,
      photo: string,
      address: IAddress,
      rent_value: string
    ) => {
      setInitialOfferID(initial_offer_id);
      setTitle(title);
      setPhoto(photo);
      setAddress(address);
      setRentValue(rent_value);

      setOpenModalSendProposal(true);
    },
    []
  );

  const handleCloseSendCounterProposal = useCallback(() => {
    setReloadApi(2);

    setOpenModalSendProposal(false);
  }, []);

  const formatDate = useMemo(() => {
    return apiResult.offer?.map(
      (offerFinded) =>
        `${String(new Date(offerFinded.updated_at).getDate()).padStart(
          2,
          "0"
        )}/${String(new Date(offerFinded.updated_at).getMonth()).padStart(
          2,
          "0"
        )}/${new Date(offerFinded.updated_at).getFullYear()} às ${String(
          new Date(offerFinded.updated_at).getHours()
        ).padStart(2, "0")}:${String(
          new Date(offerFinded.updated_at).getMinutes()
        ).padStart(2, "0")}:${String(
          new Date(offerFinded.updated_at).getSeconds()
        ).padStart(2, "0")}`
    );
  }, [apiResult]);

  return (
    <Container>
      <Header
        hasBorder={window.innerWidth > 767 ? true : false}
        hasSearchBar
        hasOptionToSearch
      />
      {!apiResult.property && <Loading />}

      {!!openModalSendCounterProposal && (
        <ModalSendCounterProposal
          property_id={apiResult.property.id}
          initial_offer_id={initialOfferID}
          title={title}
          photo={photo}
          address={address}
          rent_value={rentValue}
          total_value={totalValue}
          functionToCloseModalSendCounterProposal={
            handleCloseSendCounterProposal
          }
        />
      )}

      {!!apiResult.property && (
        <Content>
          {!!user && (
            <h3>
              {user.type === "inquilino" || !!user.type === false
                ? "INQUILINO"
                : "PROPRIETÁRIO"}
            </h3>
          )}

          {!!user && (
            <h1>
              {user.type === "inquilino" || !!user.type === false
                ? "Propostas enviadas"
                : "Propostas recebidas"}
            </h1>
          )}

          <ProposalsContainer>
            <PropertyInformationsContainer>
              <img src={apiResult.property.photos[0].url_m} alt="houseImg" />

              <PropertyLocalContainer>
                <h3>{apiResult.property.title}</h3>

                <p>
                  {apiResult.property.address.address},{" "}
                  {apiResult.property.address.neighborhood}{" "}
                  {apiResult.property.address.city} -{" "}
                  {apiResult.property.address.uf}
                </p>

                <div>
                  <strong>Aluguel: R$ {apiResult.property.rent_value}</strong>
                  <strong>Total: R$ {totalValue}</strong>
                </div>
              </PropertyLocalContainer>

              <PricesContainer>
                <strong>Aluguel: R$ {apiResult.property.rent_value}</strong>
                <strong>Total: R$ {totalValue}</strong>
              </PricesContainer>
            </PropertyInformationsContainer>

            {apiResult.offer.map((offerFinded, index) => (
              <OfferContainer key={offerFinded.id}>
                <InformationsAboutOfferContainer>
                  <p>
                    <strong>Autor da proposta:</strong>{" "}
                    {offerFinded.author.first_name}
                  </p>
                  <p>
                    <strong>Valor da proposta:</strong> R${" "}
                    {offerFinded.offer_value}
                  </p>
                  {!!offerFinded.requirements && (
                    <p>
                      <strong>Solicitações:</strong> {offerFinded.requirements}
                    </p>
                  )}

                  <span>Enviada em {formatDate[index]}</span>
                </InformationsAboutOfferContainer>

                <hr />

                {offerFinded.status === 0 && (
                  <>
                    {!!user && (
                      <>
                        {offerFinded.user_id === user.id && (
                          <OfferActions>
                            <h3>Aguardando resposta...</h3>
                          </OfferActions>
                        )}

                        {offerFinded.user_id !== user.id && (
                          <OfferActions>
                            {!loadingApiRequest ? (
                              <button
                                id="accept-proposal"
                                onClick={() =>
                                  handleAcceptProposal(offerFinded.id)
                                }
                              >
                                ACEITAR PROPOSTA
                              </button>
                            ) : (
                              <p>Carregando...</p>
                            )}

                            <button
                              id="answer-proposal"
                              onClick={() =>
                                handleOpenSendCounterProposal(
                                  offerFinded.id,
                                  apiResult.property.title,
                                  apiResult.property.photos[0].url_m,
                                  apiResult.property.address,
                                  apiResult.property.rent_value
                                )
                              }
                            >
                              RESPONDER
                            </button>
                          </OfferActions>
                        )}
                      </>
                    )}
                  </>
                )}

                {offerFinded.status === 1 && (
                  <OfferActions>
                    <h3 style={{ color: "#00BF33" }}>Proposta aceita</h3>
                  </OfferActions>
                )}

                {offerFinded.status === 2 && (
                  <OfferActions>
                    <h3>Contra proposta realizada</h3>
                  </OfferActions>
                )}

                {offerFinded.status === 3 && (
                  <OfferActions>
                    <h3>Proposta finalizada</h3>
                  </OfferActions>
                )}

                {offerFinded.status === 4 && (
                  <OfferActions>
                    <h3 style={{ color: "#BF0000" }}>Proposta recusada</h3>
                  </OfferActions>
                )}
              </OfferContainer>
            ))}
          </ProposalsContainer>
        </Content>
      )}
      <Footer />
    </Container>
  );
}
