import styled from "styled-components"

export const Container = styled.div`

`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    margin: 2% auto;

    max-width: 1200px;

    >h1{
        color: #666666;

        font-size: 36px;
        font-weight: bold;

        text-align: center;
    }

    >h3{
        font-weight: 400;

        font-size: 16px;

        margin: 48px 0;

        text-align: center;

        width: 90%;
    }
    
    >span{
        align-self: flex-start;

        font-size: 14px;

        margin-bottom: 32px;
    }

    @media(max-width: 767px){
        max-width: 90%;

        >h1{
            font-size: 30px;
        }
    }
`;

export const DocumentationContainer = styled.div`
    width: 100%;

    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media(max-width: 767px){
        max-height: none;

        align-items: center;

        flex-direction: column;
    }
`;

export const DocumentsSpecificationsContainer = styled.div`
    width: 70%;
    min-height: 175px;

    padding: 24px;

    border: 1px solid #666666;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    flex-direction: column;

    >h5{
        font-size: 18px;
        font-weight: 600;
    }

    >span{
        font-size: 14px;

        margin: 4px 0 12px;
    }

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const DocumentsAndSubmitButtonContainer = styled.div`
    width: 25%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    >button{
        width: 100%;

        background: #00BF33;

        margin-top: 16px;

        font-size: 14px;
        font-weight: bold;

        padding: 12px 0;

        border: 0;

        color: #FFFFFF;
    }

    >strong{
        width: 100%;

        background: #00BF33;

        margin-top: 16px;

        font-size: 14px;
        font-weight: bold;

        padding: 12px 0;

        border: 0;

        color: #FFFFFF;

        text-align: center;
    }

    @media(max-width: 767px){
        width: 100%;
    }
`;

export const DocumentsContainer = styled.div`
    width: 100%;
    min-height: 175px;

    padding: 24px;

    background: #F9F9F9;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    flex-direction: column;

    >label{
        padding: 8px;

        text-align: center;

        width: 80%;

        border: 1px solid #666666;

        display: flex;
        align-items: center;
        justify-content: flex-start;

        cursor: pointer;

        margin-bottom: 24px;

        >input{
            display: none;
        }

        >p{
            font-size: 12px;
            font-weight: bold;

            margin: 0 auto;
        }
    }

    @media(max-width: 767px){
        width: 100%;

        margin-top: 24px;

        min-height: auto;
    }
`;

export const DocumentContent = styled.div`
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    >p{
        margin-left: 4px;
    }
`;