import CardHeader from "../../../components/CardHeader";
import { MENU_ITEMS } from "./constants";
import MenuItem from "./MenuItem";
import WrapperCard from "../../../components/WrapperCard/";
import React, { useState } from "react";
import api from "../../../services/api";
import { MdWarning, MdWidgets } from "react-icons/md";
import { useHistory } from "react-router-dom";

function isContract(label: string) {
  return label === "Meu Contrato";
}

export default function Menu({ terminationId, processId, smDown, ...props }: any) {
  const history = useHistory();
  const [isDownloadingContract, setIsDownloadingContract] = useState(false);

  const handleItemNavigation = (link: string, external: boolean) => {
    if (!external) history.push(link);
  };

  async function toDownloadContractHandler() {
    setIsDownloadingContract(true);
    try {
      const response = await api.get(
        `/api/client/rent-contract/dowload/${processId}`
      );

      const url = response.data.data.url;
      if (!!url) {
        window.open(url, "_blank");
      }
    } catch (error) {
      console.log(
        "Houve um erro ao baixar o contrato. Tente novamente mais tarde."
      );
    }
    setIsDownloadingContract(false);
  }

  const filteredItems = !processId
    ? MENU_ITEMS.filter((item) => !isContract(item.label))
    : MENU_ITEMS;

  return (
    <WrapperCard {...props}>
      <CardHeader
        Icon={
          <MdWidgets
            style={{
              fontSize: "30px",
              marginRight: "0.25rem",
              color: "rgb(110, 165, 255)",
            }}
          />
        }
        title="Menu do Seu Aluguel"
        flexDirection={smDown ? "column" : "row"}
        alignItems={smDown ? "flex-start" : "center"}
        rowGap="0.5rem"
      >
        {/* <Text
          type="plain-text"
          sx={{
            fontSize: "0.8rem",
          }}
        >
          Faltam x dias para o seu contrato terminar.
        </Text> */}
        <div
          style={{
            border: `2px rgb(137, 137, 137) solid`,
            borderRadius: "1.5rem",
            cursor: "pointer",
            padding: "0.25rem 1.5rem",
            margin: "1rem 0",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
          onClick={() =>
            history.push("/request-termination", {
              state: {
                terminationId,
              },
            })
          }
        >
          <MdWarning style={{ color: "#b91c1b" }} />
          <p style={{ fontSize: "0.8rem", color: "#b91c1b" }}>
            Preciso Sair Antes do Imóvel
          </p>
        </div>
      </CardHeader>
      <div
        style={{
          display: smDown ? "grid" : "flex",
          gridTemplateColumns: "50% 50%",
          overflow: smDown ? undefined : "scroll",
          width: "100%",
          columnGap: smDown ? undefined : "1rem",
        }}
      >
        {filteredItems.map((item) => {
          const itemLabel = isContract(item.label)
            ? isDownloadingContract
              ? "Baixando..."
              : item.label
            : item.label;
          return (
            <MenuItem
              key={item.label}
              Icon={item.icon}
              label={itemLabel}
              onClick={
                isContract(item.label)
                  ? toDownloadContractHandler
                  : handleItemNavigation
              }
              link={item.link}
              external={item.external}
              width={"6rem"}
            />
          );
        })}
      </div>
    </WrapperCard>
  );
}
