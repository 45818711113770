import { Divider } from "@material-ui/core";
import React from "react";
import { Container, Content } from "./style";

export default function CardHeader({ Icon, title, children, ...props }: any) {
  return (
    <>
      <Container style={props}>
        <Content>
          {Icon}
          <h5 style={{ fontSize: "1.2rem" }}>{title}:</h5>
        </Content>
        {children}
      </Container>
      <Divider style={{ margin: "1rem 0", width: "100%" }} />
    </>
  );
}
