import styled from "styled-components";

export const FormContainer = styled.div`
	margin-left: 24px;

	@media(max-width: 767px){
		margin-top: 64px;
		margin-left: 0;
	}

	@media(min-width: 1441px){
		margin-left: 58px;
	}
`;

export const Form = styled.form`
	display: flex;
	align-items: flex-start;
	justify-content: center;

	flex-direction: column;

	>p{
		font-size: 12px;

		>strong{
			font-size: 14px;
			color: #FF3838;
		}
	}

	>input{
		width: 340px;

		background: transparent;

		border: 0;
		border-bottom: 1px solid #B6B6B6;

		padding: 8px 0 6px 8px;
		margin-bottom: 19px;
	}

	>button{
		margin-top: 12px;

		background: #6EA5FF;

		border: 0;

		width: 340px;

		padding: 8px 0;

		font-size: 14px;
		font-weight: 600;
		color: #FFF;
	}

	>span{
		background: transparent;

		border: 1px solid #6EA5FF;

		width: 340px;

		padding: 8px 0;

		color: #6EA5FF;

		font-size: 14px;
		text-align: center;
	}

	@media(max-width: 767px){
		>p{
			font-size: 13px;

			>strong{
				font-size: 12px;
			}
		}	

		>input{
			width: 310px;
		}

		>button{
			width: 310px;
		}

		>span{
			width: 310px;
		}
	}

	@media(min-width: 1441px){
		>p{
			font-size: 15px;

			>strong{
				font-size: 13px;
			}
		}

		>input{
			width: 390px;

			margin-bottom: 28px;

			font-size: 15px;
		}

		>button{
			width: 390px;

			padding: 8px 0;

			font-size: 15px;
			font-weight: 600;
		}

		>span{
			width: 390px;

			padding: 8px 0;

			font-size: 15px;
			font-weight: 600;
		}
	}
`;