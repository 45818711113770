import styled from 'styled-components';

export const Container = styled.div`

`;


export const Content = styled.div`
	width: 710px;

	.slick-prev:before,
    .slick-next:before {
      color: #212121;
    }

	.slick-prev, 
	.slick-next{
		background: #FFF;
		position: absolute;
	    top: 50%;
	    z-index:1;
	    border-radius: 50%;
	}

	.slick-next{
		right: 8px;
	}

	.slick-prev{
		left: 8px;
	}

	.slick-dots li button:before {
	   	color: #212121;
	}

	.slick-dots{
		bottom: 48px;
	}

	img{
		width: 100%;
		height: 390px;

		margin-bottom: 30px;
	}

	>p{
		font-size: 14px;

		margin-top: 4px;
		margin-bottom: 24px;
	}

	>strong{
		font-size: 11px;
	}

	@media(max-width: 767px){
		max-width: 360px;

		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		margin: 0 auto;

		.slick-slider{
			width: 330px;
		}

		img{
			width: 100%;
			height: 250px;
		}

		>p{
			font-size: 12px;

			width: 330px;
		}

		>strong{
			font-size: 12px;

			text-align: center;

			width: 330px;
		}
	}

	@media(min-width: 1441px){
		width: 800px;

		img{
			height: 460px;

			margin-bottom: 32px;
		}

		>p{
			font-size: 16px;

			margin: 8px 0 28px;
		}

		>strong{
			font-size: 12px;
		}
	}
`;

export const TitleContainer = styled.div`
	display: flex;
	align-items: flex-end;
	justify-content: flex-start;

	>h1{
		font-size: 24px;

		font-weight: bold;
	}

	>h4{
		font-size: 11px;
		color: #B6B6B6;
		margin-left: 16px;
	}

	@media(max-width: 767px){
		align-items: center;

		width: 330px;

		>h1{
			font-size: 23px;
		}

		>h4{
			font-size: 10px;

			margin-left: 0px;
		}
	}
	
	@media(min-width: 1441px){
		align-items: center;

		>h1{
			font-size: 28px;
		}

		>h4{
			font-size: 13px;
		}
	}
`;

export const ButtonsContainer = styled.div`
	display: none;

	@media(max-width: 767px){
		margin-left: 48px;

		display: flex;
		align-items: center;
		justify-content: center;

		>button{
			background: transparent;

			border: 0;

			margin-right: 4px;
		}

		>img{
			width: 21px;
			height: 21px;

			margin-top: 16px;
			margin-right: 4px;
		}
	}
`;

export const PropertyValuesAndVisitButtonContainer = styled.div`
	display: none;

	@media(max-width: 767px){
		position: fixed;
		bottom: 0;
		z-index: 4;

		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		background: #FFF;

		padding-bottom: 24px; 

		>a{
			margin-top: 14px;

			background: #6EA5FF;

			text-decoration: none;

			font-size: 17px;
			font-weight: bold;
			color: #FFF;

			padding: 12px 32px;
		}
	}
`;

export const PropertyValueContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: flex-start;
		justify-content: center;

		flex-direction: column;

		background: #F9F9F9;

		width: 330px;

		padding: 18px 0;

		>strong{
			margin-top: 12px;
			margin-left: 12px;

			font-size: 8px;
			color: #666666;
		}
	}
`;

export const ValuesContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

export const AccountValuesContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: flex-start;
		justify-content: center;

		flex-direction: column;

		border-right: 1px solid #66666666;

		padding: 0 16px;

		>h3{
			margin-bottom: 8px;

			font-size: 13px;
			font-weight: bold;

			>b{
				font-size: 15px;
				color: #6EA5FF;
			}
		}

		>h4{
			font-size: 12px;
		}
	}
`;

export const TotalContainer = styled.div`
	@media(max-width: 767px){
		display: flex;
		align-items: center;
		justify-content: center;

		flex-direction: column;

		margin-left: 12px;

		>h3{
			font-size: 15px;
			font-weight: bold;
		}

		>h2{
			font-size: 18px;
			font-weight: bold;
			color: #DD6435;
		}
	}
`;

export const InformationsAboutProperty = styled.div`
	margin-top: 0px;
	margin-bottom: 32px;

	padding: 6px 32px 24px;

	background: #F9F9F9;

	display: flex;
	align-items: center;

	flex-wrap: wrap;

	width: 100%;

	>div{
		margin: 18px 24px 0px;
	}

	@media(max-width: 767px){
		width: 330px;

		padding: 14px 12px 32px;
	}
`;

export const PropertyCharacteristics = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 160px;

	p{
		font-size: 15px;

		margin-left: 6px;
	}

	@media(max-width: 767px){
		width: 105px;

		p{
			font-size: 12px;
		}
	}

	@media(min-width: 1441px){
		>svg{
			width: 23px;
			height: 23px;
		}

		p{
			font-size: 18px;
		}
	}
`;

export const WhatLocalHasContainer = styled.div`
	margin: 12px 0 32px;

	padding: 24px 0 0px;

	display: flex;
	align-items: center;

	flex-wrap: wrap;

	>div{
		margin: 18px 12px 0px 12px;
	}

	@media(max-width: 767px){
		width: 330px;

		padding: 24px 12px;
	}

	@media(min-width: 1441px){
		>div{
			margin: 18px 24px 0px 0;
		}
	}
`;

export const WhatLocalHasCharacteristics = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	width: 210px;

	p{
		font-size: 13px;

		margin-left: 6px;
	}

	@media(max-width: 767px){
		width: 180px;

		p{
			font-size: 12px;
		}

		>svg{
			width: 17px;
			height: 17px;
		}
	}

	@media(min-width: 1441px){
		width: 230px;

		p{
			font-size: 16px;
		}

		>svg{
			width: 23px;
			height: 23px;
		}
	}
`;

export const HowToRentImovelContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;

	margin-top: 82px;

	>img{
		width: 390px;
		height: 240px;

		margin-bottom: 0;
	}

	@media(max-width: 767px){
		display: none;
	}

	@media(min-width: 1441px){
		>img{
			width: 400px;
			height: 245px;
		}
	}
`;

export const HowToRentImovelTexts = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;

	flex-direction: column;

	height: 250px;

	margin-left: 32px;

	>h1{
		font-size: 25px;
	}

	>p{
		font-size: 14px;

		margin: 25px 0 10px;
	}

	>strong{
		font-size: 10px;
	}

	@media(min-width: 1441px){
		margin-left: 52px;

		>h1{
			font-size: 28px;
		}

		>p{
			font-size: 16px;

			margin: 28px 0 15px;
		}

		>strong{
			font-size: 12px;
		}
	}
`;



