import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';

import api from './../../services/api';
import { useAuth } from './../../hooks/auth';
import { saveScheduleRequest, hasScheduleRequest, getScheduleRequest, clearScheduleRequest } from './../../utils/scheduleUtils';
import LoadingComponent from './../../components/Loading/';

import Select from 'react-select';
import { useHistory, useLocation } from 'react-router';

import {Form, CheckBoxContainer} from './styles';
import CustomSelect from '../Select'
import Cookies from 'js-cookie';

interface IState{
	consultant_id: number;
	property_id: number;
}

interface IDates{
	id: number,
	date: string;
	consultant_id: number;
}

interface ISelectLib{
	value: string;
	label: string;
}

const CHANNEL_LIST: Array<ISelectLib> = [
	{label:'OLX', value:'olx'},
	{label:'Facebook', value:'facebook'},
	{label:'ZAP Imóveis', value:'zap'},
	{label:'VivaReal', value:'vivaReal'},
	{label:'Google', value:'google'},
	{label:'Instagram', value:'instagram'},
	{label:'Outro', value:'outro'}
];

export default function FormToSchedulePage(){
	  
    const history = useHistory();
    const { scheduleAppointment, user } = useAuth();
	const { state } = useLocation<IState>();
    
	const [dates, setDates] = useState<IDates[]>([]);
	const [hours, setHours] = useState<ISelectLib[]>([]);
    const [loading, setLoading] = useState(true);

	const [selectedDate, setSelectedDate] = useState<string>("");
	const [selectedHour, setSelectedHour] = useState<string>("");
	const [videoCall, setVideoCall] = useState(0);
	const [selectedChannel, setSelectedChannel] = useState<string>(Cookies.get('campanha') ?? '');

	const [error, setError] = useState('');
	const [errorInSelectedDate, setErrorInSelectedDate] = useState(false);
	const [errorInSelectedHour, setErrorInSelectedHour] = useState(false);

    const [loadingSubmit, setLoadingSubmit] = useState(false);

    useEffect(() => {
		loadingData();
	}, [state.consultant_id, user, history]);

	const loadingData = async () => {
		try {
			let consultantId = state.consultant_id;

			if (!consultantId) {
				const response = await api.get(`/api/client/properties/${state.property_id}`);
				consultantId = response.data.data.consultant_id;
			}

			const response = await api.get(`/api/client/schedules/consultant/${consultantId}`);
			setDates(response.data.data);

			if (hasScheduleRequest()) {
				try {
					setLoading(true);
					const scheduleRequest = getScheduleRequest();
					const response = await scheduleAppointment(scheduleRequest.data);
					if (response.success) {
						setLoading(false);
						clearScheduleRequest();
						history.push(`/schedule/${state.property_id}/success`);
					}
				} catch (e) {
					alert("Ocorreu um erro no agendamento. Por favor, tente novamente!");
				}
			}
			setLoading(false);

		} catch(e) {
			console.error(e);
			setLoading(false);
			history.push(`/imovel/${state.property_id}`);
		}
	}

	const DatesFilter = useMemo(() => {
		const filter = dates.map(date => date.date);
		const getDates = filter.map(date => date.substring(0,10));
		const uniques = [...new Set(getDates)];
		const orderDates = uniques.sort((a,b) => (+new Date(a) - +new Date(b)));
		const formatDate = orderDates.map((date, index) => {
			return({
				value: orderDates[index],
				label: `${date.substring(8, 10)}/${date.substring(5, 7)}/${date.substring(0, 4)}`
			})
		});
		
		return(formatDate);
	}, [dates]);

	const handleSubmit = useCallback(async(event: FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		setLoadingSubmit(true);

		try {
			if(selectedDate === ""){
				setLoadingSubmit(false);
				return setErrorInSelectedDate(true);
			}

			if(selectedHour === ""){
				setLoadingSubmit(false);
				return setErrorInSelectedHour(true);
			}

			const getID = dates.find(date => (date.date === `${selectedDate} ${selectedHour}`))
			
			if(!!getID){

				const scheduleRequest = { id: getID.id, video_call: String(videoCall), property_id: String(state.property_id), userChannel: String(selectedChannel) };
				
				if (!!user) {
					const response = await scheduleAppointment(scheduleRequest);
					console.log(response);
					
					if (!response.success){
						setLoadingSubmit(false);
						setError(response.message);
						return;
					}
					clearScheduleRequest();
					history.push(`/schedule/${state.property_id}/success`);
				} else {
					saveScheduleRequest({ propertyId: state.property_id, state: state, data: scheduleRequest, fields: { selectedDate, selectedHour, selectedChannel, videoCall } });
					history.push(`/register`);
				}
			}
			
			setLoadingSubmit(false);
			setErrorInSelectedDate(false);
			setErrorInSelectedHour(false);
		} catch(err){
			setLoadingSubmit(false);
		}
	}, [selectedDate, selectedHour, dates, state.property_id, videoCall, scheduleAppointment, selectedChannel]);

	const handleSelectDate = useCallback((selectedDate: string | undefined) => {
		if(selectedDate === ""){
			setSelectedDate("");
			return setSelectedHour("");
		}

		if(!!selectedDate){
			const getHours = dates.filter(date => (date.date.includes(selectedDate)));

			const orderHours = getHours.sort((a, b) => {
				return Number(a.date.substring(11, 13)) - Number(b.date.substring(11, 13));
			});

			const formatHours = orderHours.map(hour => ({
				value: hour.date.substr(11, 19),
				label: hour.date.substr(11, 19)
			}));
	
			setSelectedDate(selectedDate);
			setHours(formatHours);
		}
	}, [dates]);

	const handleSelectHour = useCallback((hour: string | undefined) => {
		if(hour === ""){
			return setSelectedHour("");
		}
		if(!!selectedDate && !!hour){
			setSelectedHour(hour);
		}
	}, [selectedDate]);

	// const handleSelectChannel = useCallback((selectedChannel: string | undefined | null) => {
	// 	if(selectedChannel === "" || selectedChannel === undefined || selectedChannel === null) {
	// 		return setSelectedChannel("");
	// 	}

	// 	setSelectedChannel(selectedChannel);
	// }, [selectedChannel]);

	const handleSelectVideoCall = useCallback((option: number) => {
		if(videoCall === option){
			return setVideoCall(0);
		}

		setVideoCall(option);
	}, [videoCall]);

    return(
        <>
            {!!loading && (
			    <LoadingComponent />
			)}

            {!loading && (
                <Form onSubmit={handleSubmit} className="agendamento">
                    <p>Data de preferência: {!!errorInSelectedDate ? <span style={{color: '#FF3838'}}>Escolha uma data</span> : ""}</p>
                    <Select 
                        options={DatesFilter}
                        onChange={e => handleSelectDate(e?.value)}
                        placeholder="Escolha uma data"
                        noOptionsMessage={() => "Não temos datas para disponíveis para agendamento"}
                    />

                    <p>Hora de preferência: {!!errorInSelectedHour ? <span style={{color: '#FF3838'}}>Escolha uma hora</span> : ""}</p>
                    <Select 
                        options={hours}
                        onChange={e => handleSelectHour(e?.value)}
                        placeholder="Escolha um horário"
                        noOptionsMessage={() => "Não temos horários disponíveis nessa data."}
                    />

					<p>Onde você encontrou nosso anúncio?</p>
					<CustomSelect placeholder='Escolha um canal' value={selectedChannel} onChange={e => setSelectedChannel(e ?? '')} channelList={CHANNEL_LIST}>
						{CHANNEL_LIST.map(channel => {return <option key={channel.value} value={channel.value}>{channel.label}</option>})}
					</CustomSelect>
					{/* <Select
						options={CHANNEL_LIST}
						onChange={e => handleSelectChannel(e?.value)}
						placeholder="Escolha um canal"
					/> */}

                    <CheckBoxContainer onClick={() => handleSelectVideoCall(1)}>
                        <input type="checkbox" checked={videoCall === 1} onClick={() => handleSelectVideoCall(1)}/>
                        <label>Fazer visita via videochamada</label>
                    </CheckBoxContainer>

                    {!loadingSubmit ? (<button type="submit" className="agendamento" style={{background: !!error ? '#FF3838' : '#6EA5FF'}}>{!!error ? error : 'AGENDAR'}</button>) : (<label>Carregando...</label>)}
                </Form>
            )}
        </>
    );
}