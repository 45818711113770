import React from 'react';

import formatValue from '../../../../utils/formatValue';

import {
    Container,
    CoverImageContainer,
    PhotosSlider,
    PropertyInfosContainer,
    PropertyInfoFirstCollContainer,
    PropertyInfoSecondCollContainer
} from './style';

const SLIDER_SETTINGS = {
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false
};

export default function PropertyCard({ property } : any) {
    
    const getPropertyPhotos = () => {
        return property?.photos.slice(0, 4);
    }

    const formatCurrencyValue = (value: string) => {
        try {
            return formatValue(parseFloat(value));
        } catch(e) {
            return 0;
        }
    }

    const hasCurrencyValue = (value: string) => {
        return !(!value || value === "0.00");
    }

    return (
        <Container>
            <CoverImageContainer>
                <PhotosSlider {...SLIDER_SETTINGS}>
                    {getPropertyPhotos().map((photo: any, index: number) => (
                        <img 
                            key={photo.id} 
                            src={photo.url_m} 
                            alt={`${property?.title} - Foto ${index + 1}`} 
                            onClick={() => null}
                        />
                    ))}		
                </PhotosSlider>
            </CoverImageContainer>
            <PropertyInfosContainer href={`/imovel/${property?.id}`} target={"_blank"}>
                <PropertyInfoFirstCollContainer>
                    <span className='city'>{property?.address?.city}</span>
                    <div>
                        <h4 className='property-name'>{property?.title}</h4>
                        <div className='address-container'>
                            <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.00008 7.00001C5.26675 7.00001 4.66675 6.40001 4.66675 5.66668C4.66675 4.93334 5.26675 4.33334 6.00008 4.33334C6.73341 4.33334 7.33341 4.93334 7.33341 5.66668C7.33341 6.40001 6.73341 7.00001 6.00008 7.00001ZM10.0001 5.80001C10.0001 3.38001 8.23341 1.66668 6.00008 1.66668C3.76675 1.66668 2.00008 3.38001 2.00008 5.80001C2.00008 7.36001 3.30008 9.42668 6.00008 11.8933C8.70008 9.42668 10.0001 7.36001 10.0001 5.80001ZM6.00008 0.333344C8.80008 0.333344 11.3334 2.48001 11.3334 5.80001C11.3334 8.01334 9.55341 10.6333 6.00008 13.6667C2.44675 10.6333 0.666748 8.01334 0.666748 5.80001C0.666748 2.48001 3.20008 0.333344 6.00008 0.333344Z" fill="#DD6536"/></svg>
                            <p className="address">{property?.address?.address} - {property?.address?.neighborhood}</p>
                        </div>
                    </div>
                    <p className='property-description'>{property?.area} m² | {property?.rooms} {property?.rooms === 1? 'quarto' : 'quartos'}  | {property?.garages} {property?.garages === 1? 'vaga' : 'vagas'} </p>
                    <span className="cta">Ver imóvel <svg width="16" height="8" viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill="#DD6536"/></svg></span>
                </PropertyInfoFirstCollContainer>
                <PropertyInfoSecondCollContainer>
                    <div className='prices-container'>
                        <span className='rental-price'>{formatCurrencyValue(property?.rent_value)}</span>
                        <span className='rental-label'>Aluguel</span>
                        <div className='extras'>
                            {hasCurrencyValue(property?.condominium_value) && <div className='price-label-container'>
                                <span className='price'>+{formatCurrencyValue(property?.condominium_value)}</span>
                                <span className='label'>Condomínio</span>
                            </div>}
                        </div>
                    </div>
                    <div className='buttons-container'>
                        {/* <div>
                            <svg className='favorite' width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M14.5 0.825012C12.76 0.825012 11.09 1.63501 10 2.91501C8.91 1.63501 7.24 0.825012 5.5 0.825012C2.42 0.825012 0 3.24501 0 6.32501C0 10.105 3.4 13.185 8.55 17.865L10 19.175L11.45 17.855C16.6 13.185 20 10.105 20 6.32501C20 3.24501 17.58 0.825012 14.5 0.825012ZM10.1 16.375L10 16.475L9.9 16.375C5.14 12.065 2 9.21501 2 6.32501C2 4.32501 3.5 2.82501 5.5 2.82501C7.04 2.82501 8.54 3.81501 9.07 5.18501H10.94C11.46 3.81501 12.96 2.82501 14.5 2.82501C16.5 2.82501 18 4.32501 18 6.32501C18 9.21501 14.86 12.065 10.1 16.375Z" fill="#D6D6D6"/></svg>
                            <svg className='share' width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15 14.12C14.24 14.12 13.56 14.42 13.04 14.89L5.91 10.74C5.96 10.51 6 10.28 6 10.04C6 9.79998 5.96 9.56998 5.91 9.33998L12.96 5.22998C13.5 5.72998 14.21 6.03998 15 6.03998C16.66 6.03998 18 4.69998 18 3.03998C18 1.37998 16.66 0.039978 15 0.039978C13.34 0.039978 12 1.37998 12 3.03998C12 3.27998 12.04 3.50998 12.09 3.73998L5.04 7.84998C4.5 7.34998 3.79 7.03998 3 7.03998C1.34 7.03998 0 8.37998 0 10.04C0 11.7 1.34 13.04 3 13.04C3.79 13.04 4.5 12.73 5.04 12.23L12.16 16.39C12.11 16.6 12.08 16.82 12.08 17.04C12.08 18.65 13.39 19.96 15 19.96C16.61 19.96 17.92 18.65 17.92 17.04C17.92 15.43 16.61 14.12 15 14.12ZM15 2.03998C15.55 2.03998 16 2.48998 16 3.03998C16 3.58998 15.55 4.03998 15 4.03998C14.45 4.03998 14 3.58998 14 3.03998C14 2.48998 14.45 2.03998 15 2.03998ZM3 11.04C2.45 11.04 2 10.59 2 10.04C2 9.48998 2.45 9.03998 3 9.03998C3.55 9.03998 4 9.48998 4 10.04C4 10.59 3.55 11.04 3 11.04ZM15 18.06C14.45 18.06 14 17.61 14 17.06C14 16.51 14.45 16.06 15 16.06C15.55 16.06 16 16.51 16 17.06C16 17.61 15.55 18.06 15 18.06Z" fill="#D6D6D6"/></svg>
                        </div> */}
                    </div>
                </PropertyInfoSecondCollContainer>
            </PropertyInfosContainer>
        </Container>
    );
}