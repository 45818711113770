import React from "react";

import { useAuth } from "./../../hooks/auth";

import { Link } from "react-router-dom";

import {
  Container,
  Content,
  CompanyContainer,
  About7CantosContainer,
  RouteOptions,
  Options,
  UserActions,
} from "./styles";

import { FaCalendarAlt } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BsHouseFill } from "react-icons/bs";

import clube7CantosImg from "./../../assets/components/Menu/7-cantos.png";
import logo7cantosImg from "./../../assets/components/Menu/7-cantos-logo.png";

import CreditCardIcon from "./../../assets/components/Menu/creditCardIcon.svg";
import { MdDashboard } from "react-icons/md";

export default function MenuProprietario() {
  const { logOut, user } = useAuth();

  return (
    <Container>
      <Content>
        <CompanyContainer>
          <About7CantosContainer to="/about-us" id="first-link">
            <img src={logo7cantosImg} alt="7CantosLogo" />

            <p>Sobre a 7Cantos</p>
          </About7CantosContainer>

          <a href="https://clube7cantos.com.br/">
            <img src={clube7CantosImg} alt="clube7Cantos" />
          </a>
        </CompanyContainer>

        <hr style={{ height: "1px", width: "100%", color: "#FFF" }} />

        <RouteOptions hasUser={!!user}>
          {!!user && <h3>PROPRIETÁRIO</h3>}
          <Options>
            <div>
              <Link
                to={
                  !!user
                    ? "/my-properties"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <BsHouseFill size={27} color="#FFF" />
                <p>Meus Imóveis</p>
              </Link>
            </div>
            <div>
              <Link
                to={
                  !!user
                    ? "/scheduled-visits"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <FaCalendarAlt size={27} color="#FFF" />
                <p>Visitas Agendadas</p>
              </Link>
            </div>

            <div>
              <Link
                to={
                  !!user
                    ? "/proposals"
                    : {
                        pathname: "/login",
                        state: { fromSite: true },
                      }
                }
              >
                <GiWallet size={27} color="#FFF" />
                <p>Propostas Recebidas</p>
              </Link>
            </div>
            {!!user && (
              <div>
                <a href="https://checkout-parceleja.cappta.com.br/7cantos">
                  <img src={CreditCardIcon} alt="creditCardIcon" />
                  <p>Parcelamento de boletos</p>
                </a>
              </div>
            )}
            <div>
              <Link to={"/owner-dashboard"}>
                <MdDashboard size={27} color="#FFF" />
                <p>Área do Proprietário</p>
              </Link>
            </div>
          </Options>
        </RouteOptions>

        <hr style={{ height: "1px", width: "100%", color: "#FFF" }} />

        {!!user && (
          <UserActions>
            {/*<Link to="/settings">CONFIGURAÇÕES</Link>*/}
            <Link to="/me">Editar Perfil</Link>

            <button onClick={logOut}>Sair</button>
          </UserActions>
        )}
      </Content>
    </Container>
  );
}
