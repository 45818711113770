import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;
    min-height: 300px;
    background: rgb(249, 249, 249);
`;

export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`;