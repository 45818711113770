import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 380px;

	margin: 46px auto 12% auto;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h2{
		font-size: 20px;
		color: #DD6435;

		margin-bottom: 21px;
	}

	@media(min-width: 1441px){
		max-width: 420px;

		margin: 58px auto 12% auto;

		>h2{
			font-size: 22px;
		}
	}
`;