import styled, {css} from 'styled-components';

import { MAX_WIDTH_PAGE, HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../styles'

interface IHeader{
	hasBorder?: boolean;
	isToShowSearchBarMobile?: boolean;
	hasSearchBar?: boolean;
}
interface ILinkAndSearchContainer{
	isToDisapear?: boolean;
}
export const Container = styled.div`

`;

export const Header = styled.header<IHeader>`
	padding: 24px 0px;

	${props => !!props.hasBorder && css`
		border-bottom: 1px solid #E7E7E7;
	`}
`;

export const HeaderContent = styled.header`
	max-width: ${MAX_WIDTH_PAGE};
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
	padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};

	// MEDIUM

    @media(min-width: 768px){
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
        padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
    }

    // LARGE

    @media(min-width: 992px){
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
        padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
    }
`;

export const LogoAndButtonContainer = styled.div<IHeader>`
	display: flex;
	align-items: center;
	justify-content: center;

	a {
		padding-left: 18px;
		${props => !!props.isToShowSearchBarMobile && css`
			display: none;
		` }
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: 0;
		${props => !!props.isToShowSearchBarMobile && css`
			margin-left: -2px;
			margin-right: 4px;
		`}
	}	

	// MEDIUM

    @media(min-width: 768px){
		button {
			display: none;
		}

		a {
			padding-left: 0;
			display: block;
		}
    }
`;

export const LinkAndSearchContainer = styled.div<ILinkAndSearchContainer>`
	display: flex;
	align-items: center;
	justify-content: center;

	>button{
		display: none;
	}

	@media(max-width: 767px){
		width: 100%;

		display: flex;
		align-items: center;
		justify-content: flex-start;

		>button{
			display: ${props => !!props.isToDisapear ? 'none' : 'flex'};

			background: transparent;

			border: 0;

			margin-left: auto;
			margin-right: 22px;
		}
	}
`;

export const Options = styled.nav<IHeader>`
	margin-left: 100px;

	${props => !!props.hasSearchBar && css`
		margin-left: 40px;
	`}
`;

export const OptionsItemsContainer = styled.div`
	display: none;
	align-items: center;
	justify-content: center;
	gap: 30px;
	
	a {
		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 17px;
		letter-spacing: 0em;
		text-align: left;
		text-decoration: none;
		color: #606060;
	}

	// MEDIUM

    @media(min-width: 768px){
        display: none;
    }

	// LARGE

    @media(min-width: 992px){
		display: flex;
    }
`;


export const ButtonLogin = styled.div`
	
	align-items: center;
	justify-content: center;
	display: none;

	a#button-link {
		min-width: 130px;
		height: 50px;
		line-height: 50px;
		border-radius: 45px;
		padding: 0px 20px;
		border: 2px solid #6EA6FF;
		background: transparent;
		color: #6EA6FF;
		text-align: center;
		font-family: Montserrat;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		letter-spacing: 0em;
	}

	>p {
		margin-left: 16px;
		font-size: 14px;
		font-weight: bold;
		color: #6EA5FF;
	}

	>button {
		display: flex;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: 0;
		margin-left: 18px;
	}

	// MEDIUM

    @media(min-width: 768px){
		display: flex;

		>p {
			font-size: 16px;
		}
    }
`;