import React, { useState } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import GoogleMapsReact from 'google-map-react';

import Marker from './Marker';

import {
    Container,
    MapContainer
} from './style';

const DEFAULT_CENTER = { lat: -3.7301571, lng: -38.5144071 };

export default function SearchMap({
    properties = [],
    selectedProperties = [],
    onMarkerClick = () => null,
    onMapClick = () => null,
    onMapChange = () => null,
    loading = true
}: any) {

    const [isMapLoaded, setMapLoaded] = useState(false);

    const getPropertyLat = (property : any) => {
        return parseFloat(property?.address?.latitude);
    }

    const getPropertyLng = (property : any) => {
        return parseFloat(property?.address?.longitude);
    }

    const getCenter = () => {
        if (!properties || properties?.length === 0) {
            return DEFAULT_CENTER;
        }
        return { lat: getPropertyLat(properties[0]), lng: getPropertyLng(properties[0]) };
    }

    const getMapBonds = (map: any) => {
        if (!map) return;

        const mapBonds = map.getBounds();
        const northEast = mapBonds.getNorthEast();
        const southWest = mapBonds.getSouthWest();
        return {
            NE: { lat: northEast.lat(), lng: northEast.lng() },
            SW: { lat: southWest.lat(), lng: southWest.lng() }
        };
    }

    const handleApiLoaded = (map: any, maps: any) => {
        setMapLoaded(true)
        maps.event.addListener(map, 'dragend', () => {
            const bonds = getMapBonds(map);
            typeof onMapChange === 'function' && onMapChange(bonds);
        });
        maps.event.addListener(map, 'zoom_changed', () => {
            const bonds = getMapBonds(map);
            typeof onMapChange === 'function' && onMapChange(bonds);
        });
        maps.event.addListener(map, 'click', () => {
            typeof onMapClick === 'function' && onMapClick();
        });
    };

    const handleMarkerClick = (propertyId : string) => {
        typeof onMarkerClick === 'function' && onMarkerClick(propertyId);
    }

    const isMarkerActived = (propertyId: number) => {
        return Boolean(selectedProperties && selectedProperties.length > 0 && selectedProperties.includes(propertyId))
    }

    return (
        <Container>
            <MapContainer>
                {loading ?
                    <Skeleton variant="rect" width={'100%'} height={"100%"} />
                    :
                    <GoogleMapsReact 
                        bootstrapURLKeys={{ key: "AIzaSyDSD2QM4JfZsREcRj_8StrW3E587RayIMc" }}
                        defaultCenter={DEFAULT_CENTER}
                        center={getCenter()}
                        defaultZoom={14}
                        options={{ 
                            gestureHandling: 'greedy',
                            streetViewControl:false,
                            fullscreenControl:false,
                            zoomControlOptions: { position: 7 }
                        }}
                        yesIWantToUseGoogleMapApiInternals={true}
                        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                    >
                        {(isMapLoaded && properties && properties.length > 0) && properties.map((property: any) => (
                            <Marker
                                key={property?.id}
                                lat={getPropertyLat(property)}
                                lng={getPropertyLng(property)}
                                color="#6EA6FF"
                                active={isMarkerActived(property?.id)}
                                onClick={() => handleMarkerClick(property?.id)}
                            />
                        ))}
                    </GoogleMapsReact>
                }
            </MapContainer>
        </Container>
    );
}