import styled from 'styled-components';

interface IPropertiesRentedCardContainer{
	numberOfElements: number;
}

export const Container = styled.div`

`;

export const Content = styled.div`
	max-width: 1200px;

	margin: 0 auto;

	padding: 48px 0 12%;

	flex-direction: column;

	>h3{
		color: #B6B6B6;
		font-size: 14px;
		font-weight: 600;

		text-align: center;

		margin-bottom: 24px;
	}

	>h1{
		font-size: 30px;
		font-weight: bold;

		text-align: center;
	}

	>hr{
		width: 75%;
		height: 0.01px;

		color: #66666666;

		margin: 64px auto;
	}

	@media(max-width: 767px){
		>h3{
			font-size: 17px;
		}

		>h1{
			font-size: 26px;
		}
	}

	@media(min-width: 1441px){
		padding: 56px 0 12%;

		>h3{
			font-size: 16px;

			margin-bottom: 36px;
		}

		>h1{
			font-size: 35px;
		}
	}
`;

export const PropertiesRentedCardContainer = styled.div<IPropertiesRentedCardContainer>`
	margin-top: 46px;

	
	>div{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	.slick-prev:before,
    .slick-next:before {
    	color: #212121;
    }

    >.slider.slick-prev,
    .slider.slick-next {
	    position: absolute;
	    top: 50%;
	    z-index:1;
	    border-radius: 50%;
	    background: #fff;
    }

	>div{
		>div{
			margin: 10px;
		}	
	}

	@media(max-width: 767px){
		>div{
			flex-direction: column;
		}

		>div{
			>div{
				& + div{
					margin-top: 24px;
					margin-left: 0;
				}
			}	
		}
	}
`;

export const PropertiesAnnunciatedCardContainer = styled.div<IPropertiesRentedCardContainer>`
	margin-top: 46px;

	>div{
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
	}

	.slick-prev:before,
    .slick-next:before {
    	color: #212121;
    }

    >.slider.slick-prev,
    .slider.slick-next {
	    position: absolute;
	    top: 50%;
	    z-index:1;
	    border-radius: 50%;
	    background: #fff;
    }

	>div{
		>div{
			margin: 10px;
		}	
	}

	@media(max-width: 767px){
		>div{
			flex-direction: column;
		}

		>div{
			>div{
				& + div{
					margin-top: 24px;
					margin-left: 0;
				}
			}	
		}
	}
`;