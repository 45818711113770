import React, { useEffect, useState, useCallback, useMemo } from "react";

import api from "./../../services/api";

import { useAuth } from "./../../hooks/auth";

import formatValue from "./../../utils/formatValue";

import { useRouteMatch, useHistory, Link } from "react-router-dom";

import Header from "./../../components/Header/";
import Footer from "./../../components/Footer/";
import PropertyInformationsToImovelPage from "./../../components/PropertyInformationsToImovelPage/";
import LoadingComponent from "./../../components/Loading/";

import ErrorPropertyRented from "./../../components/ErrorPropertyRented/";

import {
  Container,
  Content,
  InitialContainer,
  PropertyValues,
  Values,
  Actions,
} from "./styles";

import { FiHeart, FiShare2 } from "react-icons/fi";
import { FaHeart } from "react-icons/fa";

import triangulosImg from "./../../assets/pages/Imovel/triangulos.svg";

import loadingGif from "./../../assets/pages/Imovel/loading.gif";
import ModalShowImages from "../../components/ModalShowImages";
import { Helmet } from "react-helmet";

interface IParams {
  id: string;
}

interface IAddress {
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  number: number;
  complement: string;
  latitude: string;
  longitude: string;
}

interface IPhotos {
  id: number;
  url_l: string;
  url_s: string;
}

interface IApiResults {
  id: number;
  consultant_id: number;
  address_id: number;
  address: IAddress;
  photos: IPhotos[];
  title: string;
  description: string;
  allow_pets: number;
  water_included: number;
  gas_included: number;
  area: number;
  rooms: number;
  bathrooms: number;
  suites: number;
  garages: number;
  garbage_tax: string;
  is_furnished: number;
  rent_value: string;
  condominium_value: string;
  condominium_free: number;
  iptu_value: string;
  is_nearby_clinics: number;
  is_nearby_major_roads: number;
  is_nearby_schools: number;
  is_nearby_mall: number;
  is_nearby_public_transport: number;
  is_rented: number;
  has_ac: number;
  has_backyard: number;
  has_porch: number;
  has_storage: number;
  has_cable_tv: number;
  has_bbq: number;
  has_elevator: number;
  has_garden: number;
  has_gourmet: number;
  has_gym: number;
  has_party: number;
  has_playground: number;
  has_pool: number;
  has_home_cinema: number;
  has_tennis_court: number;
  has_sports: number;
  has_sauna: number;
  has_spa: number;
  has_sea_view: number;
  has_gourmet_porch: number;
  has_24h_concierge: number;
  has_game_room: number;
}

interface IFavorites {
  id: number;
  favorite_id: number;
}

function hasGarbageTax(tax: string | null) {
  if (tax === "0.00") return false;

  return !!tax;
}

function isHashParam(id: string) {
  return id.length > 10;
}

export default function Imovel() {
  const { params } = useRouteMatch<IParams>();
  const history = useHistory();

  const { user, access_token } = useAuth();

  const [loading, setLoading] = useState(true);
  const [loadingFavorite, setLoadingFavorite] = useState(false);

  const [apiResults, setApiResults] = useState({} as IApiResults);

  const [isFavorite, setIsFavorite] = useState(false);

  const [openModalShowImages, setOpenModalShowImages] = useState(false);

  const [thumbnailUrl, setThumbnailUrl] = useState<string>();

  useEffect(() => {
    api
      .get(
        isHashParam(params.id)
          ? `/api/client/properties/shared/${params.id}`
          : `/api/client/properties/${parseInt(params.id)}`
      )
      .then((response) => {
        setApiResults(response.data.data);
        setThumbnailUrl(response.data.data.photos[0]?.url_s);

        if (!!user) {
          setLoadingFavorite(true);

          api.defaults.headers.authorization = `Bearer ${access_token}`;

          api.get("/api/client/tenant/favorites").then((responseApi) => {
            const checkFavorite = responseApi.data.data.find(
              (property: IFavorites) => property.id === response.data.data.id
            );

            setIsFavorite(!!checkFavorite);
            setLoadingFavorite(false);
          });
        }
        setLoading(false);
      })
      .catch((err) => history.push("/404"));
  }, [params.id, history, user, access_token]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    if (params.has("gallery")) {
      setOpenModalShowImages(true);
    }
    // eslint-disable-next-line
  }, [window.location.search]);

  const handleFavorite = useCallback(
    async (property_id: number) => {
      if (!user) {
        return history.push({
          pathname: "/login",
          state: { fromSite: true },
        });
      }

      setLoadingFavorite(true);

      api.defaults.headers.authorization = `Bearer ${access_token}`;

      const response = await api.post("/api/client/tenant/favorites", {
        property_id: String(property_id),
      });

      if (!!response.data.success) {
        setIsFavorite(true);
      }

      setLoadingFavorite(false);
    },
    [access_token, history, user]
  );

  const handleRemoveFavorite = useCallback(
    async (property_id: number) => {
      setLoadingFavorite(true);

      api.defaults.headers.authorization = `Bearer ${access_token}`;

      const response = await api.get("/api/client/tenant/favorites");

      const filter = response.data.data.find(
        (apiResponse: IFavorites) => apiResponse.id === apiResults.id
      );

      if (!!filter) {
        const apiResponse = await api.delete(
          `api/client/tenant/favorites/${filter.favorite_id}`
        );

        if (!!apiResponse.data.success) {
          setIsFavorite(false);
        }
      }

      setLoadingFavorite(false);
    },
    [apiResults, access_token]
  );

  const calcTotal = useMemo(() => {
    const calc =
      parseFloat(apiResults.rent_value) +
      parseFloat(apiResults.condominium_value) +
      parseFloat(apiResults.iptu_value) +
      (hasGarbageTax(apiResults.garbage_tax)
        ? parseFloat(apiResults.garbage_tax)
        : 0);
    return calc;
  }, [
    apiResults.rent_value,
    apiResults.condominium_value,
    apiResults.iptu_value,
  ]);

  const formatPhotos = useMemo(() => {
    return apiResults.photos?.map((photo) => ({
      original: photo.url_l,
      thumbnail: photo.url_s,
    }));
  }, [apiResults.photos]);

  return (
    <Container>
      <Helmet>
        <title>{apiResults.title}</title>

        <meta property="og:title" content={apiResults.title} />

        <meta property="twitter:title" content={apiResults.title} />

        <meta name="description" content={apiResults.description} />

        <meta name="og:description" content={apiResults.description} />

        <meta name="twitter:description" content={apiResults.description} />

        <meta property="og:image" content={thumbnailUrl} />

        <meta property="twitter:image" content={thumbnailUrl} />
      </Helmet>
      <Header hasBorder hasSearchBar isToShowSearchBarMobile />

      {loading === true && <LoadingComponent />}

      {loading === false && (
        <>
          {!!apiResults.is_rented && !isHashParam(params.id) && (
            <ErrorPropertyRented />
          )}

          {!!openModalShowImages && (
            <ModalShowImages
              photos={formatPhotos}
              functionToCloseModal={() => setOpenModalShowImages(false)}
            />
          )}

          {(!apiResults.is_rented || isHashParam(params.id)) && (
            <Content>
              <InitialContainer>
                <PropertyInformationsToImovelPage
                  id={apiResults.id}
                  consultant_id={apiResults.consultant_id}
                  title={apiResults.title}
                  address={apiResults.address}
                  photos={apiResults.photos}
                  description={apiResults.description}
                  bathrooms={apiResults.bathrooms}
                  rooms={apiResults.rooms}
                  suites={apiResults.suites}
                  area={apiResults.area}
                  garages={apiResults.garages}
                  garbage_tax={apiResults.garbage_tax}
                  rent_value={apiResults.rent_value}
                  condominium_value={apiResults.condominium_value}
                  condominium_free={apiResults.condominium_free}
                  iptu_value={apiResults.iptu_value}
                  allow_pets={apiResults.allow_pets}
                  water_included={apiResults.water_included}
                  gas_included={apiResults.gas_included}
                  has_cable_tv={apiResults.has_cable_tv}
                  has_24h_concierge={apiResults.has_24h_concierge}
                  has_game_room={apiResults.has_game_room}
                  has_sea_view={apiResults.has_sea_view}
                  has_gourmet_porch={apiResults.has_gourmet_porch}
                  has_home_cinema={apiResults.has_home_cinema}
                  has_tennis_court={apiResults.has_tennis_court}
                  has_ac={apiResults.has_ac}
                  has_pool={apiResults.has_pool}
                  has_playground={apiResults.has_playground}
                  has_backyard={apiResults.has_backyard}
                  has_elevator={apiResults.has_elevator}
                  has_bbq={apiResults.has_bbq}
                  has_garden={apiResults.has_garden}
                  has_gourmet={apiResults.has_gourmet}
                  has_sauna={apiResults.has_sauna}
                  has_storage={apiResults.has_storage}
                  has_gym={apiResults.has_gym}
                  has_spa={apiResults.has_spa}
                  has_sports={apiResults.has_sports}
                  has_party={apiResults.has_party}
                  has_porch={apiResults.has_porch}
                  is_furnished={apiResults.is_furnished}
                  is_rented={apiResults.is_rented}
                  is_nearby_clinics={apiResults.is_nearby_clinics}
                  is_nearby_mall={apiResults.is_nearby_mall}
                  is_nearby_schools={apiResults.is_nearby_schools}
                  is_nearby_public_transport={
                    apiResults.is_nearby_public_transport
                  }
                  is_nearby_major_roads={apiResults.is_nearby_major_roads}
                  user={!!user}
                  isFavorite={isFavorite}
                  loadingFavorite={loadingFavorite}
                  loadingGif={loadingGif}
                  isHashParam={isHashParam(params.id)}
                  functionToFavorite={
                    !!isFavorite
                      ? () => handleRemoveFavorite(apiResults.id)
                      : () => handleFavorite(apiResults.id)
                  }
                />
                {!isHashParam(params.id) && (
                  <PropertyValues>
                    <Values>
                      <h3>ALUGUEL</h3>
                      <h1 id="aluguel-value">
                        {formatValue(parseFloat(apiResults.rent_value))}
                      </h1>

                      <p>
                        <b>Condomínio:</b>{" "}
                        {!!apiResults.condominium_free
                          ? "Isento"
                          : `${formatValue(
                              parseFloat(apiResults.condominium_value)
                            )}`}
                      </p>
                      <p>
                        <b>IPTU:</b>{" "}
                        {formatValue(parseFloat(apiResults.iptu_value))}
                      </p>
                      {hasGarbageTax(apiResults.garbage_tax) && (
                        <p>
                          <b>TMRSU</b>{" "}
                          {formatValue(parseFloat(apiResults.garbage_tax))}
                        </p>
                      )}

                      <p id="p-warning">
                        *Os valores do condomínio e do IPTU podem sofrer
                        alterações.
                      </p>

                      <h3 id="total">TOTAL</h3>
                      <h1>{formatValue(calcTotal)}</h1>

                      <Actions>
                        {!loadingFavorite ? (
                          <button
                            onClick={
                              !!isFavorite
                                ? () => handleRemoveFavorite(apiResults.id)
                                : () => handleFavorite(apiResults.id)
                            }
                          >
                            {!!isFavorite ? (
                              <FaHeart size={20} color="#FF3838" />
                            ) : (
                              <FiHeart size={20} color="#B6B6B6" />
                            )}
                          </button>
                        ) : (
                          <img src={loadingGif} alt="loadingGif" />
                        )}
                        <a
                          href={`https://api.whatsapp.com/send?text=${window.location.origin}/imovel/${apiResults.id}`}
                        >
                          <FiShare2 size={20} color="#B6B6B6" />
                        </a>
                      </Actions>
                    </Values>

                    <img src={triangulosImg} alt="triangulos" />

                    <h2>Gostou deste imóvel?</h2>

                    <Link
                      to={{
                        pathname: `/schedule/${apiResults.id}`,
                        state: {
                          consultant_id: apiResults.consultant_id,
                          property_id: apiResults.id,
                        },
                      }}
                    >
                      AGENDAR VISITA
                    </Link>
                  </PropertyValues>
                )}
              </InitialContainer>
            </Content>
          )}
        </>
      )}

      <Footer />
    </Container>
  );
}
