import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';

import {
    Container,
    CoverImageContainer,
    PropertyInfosContainer,
} from './style';

export default function PropertyCardLoading() {

    return (
        <Container>
            <CoverImageContainer>
                <Skeleton variant="rect" width={'100%'} height={"100%"} style={{ borderTopLeftRadius: '25px',  borderBottomLeftRadius: '25px'  }} />
            </CoverImageContainer>
            <PropertyInfosContainer>
                <Skeleton height={50} />
                <Skeleton />
                <Skeleton />
                <Skeleton />
            </PropertyInfosContainer>
        </Container>
    );
}