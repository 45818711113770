import React, { useEffect, useState, useCallback, FormEvent } from "react";

import api from "./../../services/api";

import { useAuth } from "./../../hooks/auth";

import { Link, useHistory } from "react-router-dom";

import CurrencyInput from "./../CurrencyInput/";
import Loading from "./../Loading/";

import {
  Container,
  Content,
  LoadingContainer,
  InitialContainer,
  PropertyInformationsContainer,
  TitleAndValuesContainer,
  FormContainer,
  Form,
  UserInputContainer,
  InputContainer,
} from "./styles";

import { FiX } from "react-icons/fi";
import { BsPencil } from "react-icons/bs";

interface IRentInformation {
  id: number;
  status: string;
  property_id: number;
  title: string;
  rent_value: string;
  condominium_value: string;
  iptu_value: string;
  address: string;
  neighborhood: string;
  city: string;
  uf: string;
  photo: string;
}

interface IOffer {
  id: number;
  initial_offer_id: number | null;
}

interface IApiResult {
  offer: IOffer[];
}

interface IModalSendProposal {
  rentInformation: IRentInformation;
  functionToCloseModalSendProposal(): void;
}

export default function ModalSendProposal({
  rentInformation,
  functionToCloseModalSendProposal,
}: IModalSendProposal) {
  const { access_token, user } = useAuth();

  const history = useHistory();

  const [apiResult, setApiResult] = useState({} as IApiResult);
  const [rentValue, setRentValue] = useState("");
  const [totalValue, setTotalValue] = useState("");

  const [offerValue, setOfferValue] = useState("");
  const [requirements, setRequeriments] = useState("");

  const [offerValueError, setOfferValueError] = useState("");

  const [loadingApiRequest, setLoadingApiRequest] = useState(false);
  const [loadingApiResult, setLoadingApiResult] = useState(false);

  useEffect(() => {
    async function loadApi() {
      api.defaults.headers.authorization = `Bearer ${access_token}`;
      setLoadingApiResult(true);
      const response = await api.get(
        `/api/client/tenant/offers/${rentInformation.property_id}`
      );

      if (response.data.data?.offer?.length >= 1 && !!user) {
        history.push({
          pathname: `/proposal-received/${String(rentInformation.property_id)}`,
          state: { prevRoute: "/scheduled-visits" },
        });
      }
      setLoadingApiResult(false);
      const total_value =
        parseFloat(rentInformation.rent_value) +
        parseFloat(rentInformation.condominium_value) +
        parseFloat(rentInformation.iptu_value);

      setApiResult(response.data.data);
      setRentValue(rentInformation.rent_value);
      setTotalValue(total_value.toFixed(2));
    }

    loadApi();
  }, [access_token, rentInformation, history, user]);

  const handleSendProposal = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      setLoadingApiRequest(true);

      const check80percent = parseFloat(rentValue) * 0.8;

      const lastOffer = apiResult?.offer?.slice(-1)[0];

      if (parseFloat(offerValue) < check80percent) {
        setOfferValueError("Valor menor que 80% do valor do aluguel");
        setLoadingApiRequest(false);

        return;
      }

      if (!offerValue) {
        setOfferValueError("Valor do aluguel obrigatório");
        setLoadingApiRequest(false);

        return;
      }

      api.defaults.headers.authorization = `Bearer ${access_token}`;

      const response = await api.post("/api/client/tenant/offers", {
        initial_offer_id: !!lastOffer ? lastOffer.id : null,
        offer_value: parseFloat(offerValue).toFixed(2),
        requirements: !!requirements ? requirements : undefined,
        property_id: rentInformation.property_id,
      });

      if (!!response.data.success && !!user) {
        history.push({
          pathname: `/proposal-received/${String(rentInformation.property_id)}`,
          state: { prevRoute: "/scheduled-visits" },
        });
      }

      setLoadingApiRequest(false);
      setOfferValueError("");
    },
    [
      access_token,
      offerValue,
      rentValue,
      rentInformation,
      requirements,
      apiResult,
      history,
      user,
    ]
  );

  return (
    <>
      {!!loadingApiResult && (
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      )}

      <Container>
        {!rentInformation && (
          <LoadingContainer>
            <Loading />
          </LoadingContainer>
        )}

        {!!rentInformation && (
          <Content>
            <InitialContainer>
              <button onClick={functionToCloseModalSendProposal}>
                <FiX size={30} color="#FFF" />
              </button>

              <h1>FAZER PROPOSTA</h1>
            </InitialContainer>

            <PropertyInformationsContainer>
              <TitleAndValuesContainer>
                <h3>{rentInformation.title}</h3>

                <strong>
                  {rentInformation.address}, {rentInformation.neighborhood}{" "}
                  {rentInformation.city} - {rentInformation.uf}
                </strong>

                <p>Aluguel: R$ {rentValue}</p>
                <p>Total: R$ {totalValue}</p>
              </TitleAndValuesContainer>

              <img src={rentInformation.photo} alt="houseImg" />
            </PropertyInformationsContainer>

            <FormContainer>
              <h4>Proposta personalizada</h4>

              <Form onSubmit={handleSendProposal}>
                <UserInputContainer>
                  <p>Valor do aluguel:</p>
                  {!!offerValueError && (
                    <label style={{ color: "#FF3838" }}>
                      {offerValueError}
                    </label>
                  )}

                  <InputContainer>
                    <CurrencyInput
                      type="text"
                      placeholder="R$ 1.500,00"
                      value={offerValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (
                          e.target.value === "" ||
                          re.test(e.target.value.substring(3))
                        ) {
                          setOfferValue(e.target.value.substring(3));
                        }
                      }}
                    />

                    <BsPencil size={14} color="#666666" />
                  </InputContainer>
                </UserInputContainer>

                <UserInputContainer>
                  <p>Solicitações:</p>

                  <InputContainer>
                    <textarea
                      placeholder="Solicitar alterações no imóvel"
                      value={requirements}
                      onChange={(e) => setRequeriments(e.target.value)}
                    />

                    <BsPencil size={14} color="#666666" />
                  </InputContainer>
                </UserInputContainer>

                {!loadingApiRequest ? (
                  <button type="submit">ENVIAR PROPOSTA</button>
                ) : (
                  <p>Carregando</p>
                )}
              </Form>
            </FormContainer>

            <Link
              to={{
                pathname: "/send-documentation",
                state: {
                  property_id: String(rentInformation.property_id),
                  rent_process_id: rentInformation.id,
                  isToSendExpressOffer: true,
                },
              }}
            >
              PROPOSTA EXPRESSA
            </Link>

            <p>
              * O proprietário do imóvel pode receber outras propostas e
              escolher a que julgar como melhor pra ele. Se quiser sair na
              frente dos outros interessados, você pode fazer uma proposta
              expressa, na qual concorda com os valores contidos no anúncio e a
              proposta será automaticamente aprovada e dessa forma você passa
              para a próxima etapa do processo
            </p>

            <p>
              ** É possível propor um valor diferente apenas para o aluguel, já
              que os outros valores são inalteráveis.
            </p>

            <p id="last-p">
              *** Propostas com valor inferior a 80% do valor do imóvel serão
              desconsideradas.
            </p>
          </Content>
        )}
      </Container>
    </>
  );
}
