import React from 'react';

import {Container,
		Content,
		MessageContainer} from './styles';

import { FiX, FiCheck } from 'react-icons/fi';

interface IModalConfirmAnunciarImovel{
	functionToClose(): void;
}

export default function ModalConfirmAnunciarImovel({ functionToClose }: IModalConfirmAnunciarImovel){
	return(
		<Container>
			<Content>
				<button onClick={functionToClose}><FiX size={30} color="#FFF"/></button>

				<MessageContainer>
					<FiCheck size={57} color="#FFF" />

					<h1>OBRIGADO!</h1>

					<h3>Recebemos as informações. Em breve nossa equipe entrará em contato para finalizar a criação do anúncio.</h3>
				</MessageContainer>
			</Content>
		</Container>
	);
};