import styled from "styled-components";

export const Container = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    max-height:6rem;
    padding:0.75rem;
    margin-top:1rem;
    border: 1.25px #6ea8ff solid;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background: #edf1fa;
      transition: 0.25s linear;
      >p {
        color: #262f90;
      },
    },
`;
