import React from 'react';

import { Link } from 'react-router-dom';

import {Container,
		Content} from './styles';

import errorImg from './../../assets/components/ErrorPropertyRented/errorImg.png';

export default function ErrorPropertyRented(){
	return(
		<Container>
			<Content>
				<h1>Imóvel não encontrado</h1>

				<p>Esse imóvel já foi alugado e sua página não está mais disponível.</p>

				<Link to="/">IR PARA O INÍCIO</Link>

				<img src={errorImg} alt="errorImg" />
			</Content>
		</Container>
	);
};