import React, { useEffect, useState } from 'react';

import api from './../../services/api';

import { useAuth } from './../../hooks/auth';

import { useHistory, useLocation, Link } from 'react-router-dom';

import Header from './../../components/Header/';
import Footer from './../../components/Footer/';
import Loading from './../../components/Loading/';

import {Container,
		Content,
		ProposalsContainer,
		PropertyInformationsContainer,
		PropertyLocalContainer,
		PricesAndLinkContainer} from './styles';

interface IAddress{
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}

interface IPhotos{
	id: number;
	url_s: string;
}

interface IProperty{
	id: number;
	title: string;
	rent_value: string;
	condominium_value: string;
	iptu_value: string;
	address: IAddress;
	photos: IPhotos[];
}

interface IAuthor{
	id: number;
	first_name: string;
}

interface IApiResult{
	id: number;
	property_id: number;
	client_id: number;
	offer_value: string;
	requirements: string;
	updated_at: string;
	property: IProperty;
}

interface IState{
	property_id: number;
}

export default function Proposals(){
	const history = useHistory();
	const { state } = useLocation<IState>();

	const { user, access_token } = useAuth();

	const [apiResult, setApiResult] = useState<IApiResult[]>([]);

	const [loadingApi, setLoadingApi] = useState(true);

	useEffect(() => {
		if(!user){
			history.goBack();

			return;
		}

		async function loadApi(){
			if(!!user){
				api.defaults.headers.authorization = `Bearer ${access_token}`;

				const response = await api.get(user.type === 'inquilino' || !user.type ? "/api/client/tenant/offers" : "/api/client/owner/offers");
				
				if(!!state){
					const filterResponse = response.data.data.filter((apiResponse: IApiResult) => apiResponse.property_id === state.property_id);

					setApiResult(filterResponse);
					setLoadingApi(false);
					return;
				}

				setApiResult(response.data.data);
				setLoadingApi(false);
			}
		}

		loadApi();
	}, [user, history, access_token, state]);

	return(
		<Container>
			<Header hasBorder={window.innerWidth > 767 ? true : false} hasSearchBar hasOptionToSearch/>
			{!!loadingApi && (<Loading />)}

			{!loadingApi && (
				<>
				{apiResult.length === 0 && (
					<Content style={{height: '67vh'}}>
						<h1>Você não tem nenhuma proposta realizada.</h1>
					</Content>
				)}

				{apiResult.length >= 1 && (
					<Content>
						{!!user && (<h3>{user.type === 'inquilino' || !!user.type === false ? 'INQUILINO' : 'PROPRIETÁRIO'}</h3>)}

						{!!user && (<h1>{user.type === 'inquilino' || !user.type ? 'Propostas enviadas' : 'Propostas recebidas'}</h1>)}

						{apiResult.map(result => (
							<ProposalsContainer key={result.property_id}>
								<PropertyInformationsContainer>
									<img src={result.property?.photos[0].url_s} alt="houseImg" />

									<PropertyLocalContainer>
										<h3>{result.property?.title}</h3>

										<p>{result.property?.address.address}, {result.property?.address.neighborhood} {result.property?.address.city} - {result.property?.address.uf}</p>
									
										<div>
											<strong>Aluguel: R$ {result.property?.rent_value}</strong>
											<strong>Total: R$ {(parseFloat(result.property?.rent_value) + parseFloat(result.property?.condominium_value) + parseFloat(result.property?.iptu_value)).toFixed(2)}</strong>
										
											<Link to={{
												pathname: `/proposal-received/${result.property_id}`,
												state: {client_id: result.client_id, prevRoute: '/proposals'}
											}}>VER PROPOSTAS</Link>
										</div>
									</PropertyLocalContainer>

									<PricesAndLinkContainer>
										<strong>Aluguel: R$ {result.property?.rent_value}</strong>
										<strong>Total: R$ {(parseFloat(result.property?.rent_value) + parseFloat(result.property?.condominium_value) + parseFloat(result.property?.iptu_value)).toFixed(2)}</strong>
									
										<Link to={{
											pathname: `/proposal-received/${result.property_id}`,
											state: {client_id: result.client_id, prevRoute: '/proposals'}
										}}>VER PROPOSTAS</Link>
									</PricesAndLinkContainer>
								</PropertyInformationsContainer>
							</ProposalsContainer>
						))}
					</Content>
			)}
			</>
			)}
			<Footer />
		</Container>
	);
};