import React from "react";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

import { Container, Content } from "./styles";
import { Link } from "react-router-dom";

export default function PrivacyPolitics() {
  return (
    <Container>
      <Header />
      <Content>
        <h2>Política de Privacidade da 7Cantos</h2>
        <p className="last-update">Última atualização: 16 de outubro de 2023</p>
        <p>
          A 7Cantos ("nós", "nosso" ou "nossa") respeita sua privacidade e está
          comprometida em proteger as informações pessoais que você nos fornece
          através deste site. Esta Política de Privacidade descreve como
          coletamos, usamos, divulgamos e protegemos as informações pessoais que
          você nos fornece. Ao acessar ou usar este site, você concorda com os
          termos descritos nesta Política de Privacidade.
        </p>
        <h3>1. Informações que Coletamos:</h3>
        <p>
          Coletamos informações pessoais que você nos fornece quando interage
          com nosso site, como seu nome, endereço de e-mail, número de telefone
          e outras informações que você escolhe compartilhar conosco.
        </p>
        <h3>2. Como Usamos Suas Informações:</h3>
        <p>
          Usamos suas informações pessoais para melhorar nosso site, fornecer
          atendimento ao cliente, enviar informações sobre nossos produtos e
          serviços, processar transações e cumprir com nossas obrigações legais.
        </p>
        <h3>3. Compartilhamento de Informações:</h3>
        <p>
          Não compartilhamos suas informações pessoais com terceiros, exceto
          quando necessário para fornecer os serviços solicitados por você ou
          quando exigido por lei.
        </p>
        <p className="agreement">
          Ao acessar nosso site, você concorda com esta Política de Privacidade
          e com os <Link to='/use-terms'>Termos de Uso</Link>. Se você tiver alguma dúvida ou preocupação
          sobre nossas práticas de privacidade ou termos de uso, entre em
          contato conosco através das informações fornecidas em nosso site.
        </p>
      </Content>
      <Footer />
    </Container>
  );
}
