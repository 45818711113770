import React, { Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useAuth } from '../../hooks/auth';

import api from './../../services/api';

import LoadingComponent from './../../components/Loading/';

import {HouseCardToFavoritePageContainer,
		EmptyFavoritesContainer,
        Card,
		LoadingImageContainer,
		HouseInformationsAndActionsContainer,
		HouseInformationsAndActions,
		HouseTitleAndAction,
		TitleContainer,
		Actions,
		HouseAdditionalInfoemationsContainer,
		HousePrice} from './styles';

import Slider from 'react-slick';
import { RiDragMove2Fill, RiHotelBedLine } from 'react-icons/ri';
import { BiCar } from 'react-icons/bi';
import formatValue from '../../utils/formatValue';
import { Link } from 'react-router-dom';
import { FaHeart } from 'react-icons/fa';
import { FiShare2 } from 'react-icons/fi';

interface IAddress{
	id: number;
	address: string;
	neighborhood: string;
	city: string;
	uf: string;
}

interface IPhotos{
	id: number;
	url_m: string;
}

interface IApiResult{
	id: number;
	title: string;
	area: number;
	rooms: number;
	garages: number;
	rent_value: string;
	condominium_value: string;
	iptu_value: string;
	address: IAddress;
	photos: IPhotos[];
}

interface IFavorites{
	id: number;
	favorite_id: number;
}

export default function HouseCardToFavoritePage(){
    const { user, access_token } = useAuth();

    const history = useHistory();

	const [apiResult, setApiResult] = useState<IApiResult[]>([]);

	const [loading, setLoading] = useState(true);

    useEffect(() => {
		if(!user){
			history.goBack();
		}

		api.defaults.headers.authorization = `Bearer ${access_token}`;

		api.get("/api/client/tenant/favorites").then(response => {
			setApiResult(response.data.data);
			setLoading(false);
		}).catch(err => console.log(err));
	}, [access_token, user, history]);

    const settings = useMemo(() => {
		return({
			dots: false,
			arrows: window.innerWidth > 767 ? true : false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			className: "sliderHouseCard",
		});
	}, []);

    const formatTitle = useMemo(() => {
        return apiResult.map(result => {
            if(result.title.length >= 22){
                return `${result.title.substr(0, 22)}...`;
            };

            return result.title;
        });
	}, [apiResult]);

	const formatAddress = useMemo(() => {
        return apiResult.map(result => {
            const addressFormatted = `${result.address.address}, ${result.address.neighborhood}, ${result.address.city} - ${result.address.uf}`;

            if(addressFormatted.length >= 65){
                return `${addressFormatted.substr(0, 65)}...`;
            };

            return `${result.address.address}, ${result.address.neighborhood}, ${result.address.city} - ${result.address.uf}`;
        });
	}, [apiResult]);

    const account = useMemo(() => {
        return apiResult.map(result => (!!result.iptu_value ? parseFloat(result.condominium_value) + parseFloat(result.iptu_value) + parseFloat(result.rent_value) : parseFloat(result.condominium_value) + parseFloat(result.rent_value)));
	}, [apiResult]);
	
	const handleRemoveFavorite = useCallback(async(id: number) => {
		api.defaults.headers.authorization = `Bearer ${access_token}`;

		const response = await api.get('/api/client/tenant/favorites');

		const filter = response.data.data.find((apiResponse: IFavorites) => (apiResponse.id === id));

		if(!!filter){
			const response = await api.delete(`/api/client/tenant/favorites/${filter.favorite_id}`);

			if(!!response.data.success){
				const newArray = apiResult.filter(result => result.id !== id);

                setApiResult(newArray);
			}
		}
	}, [access_token, apiResult]);
    
    return(
        <>
        {!!loading && (
            <LoadingComponent />
        )}

        {!loading && (
            <>
            {apiResult.length >= 1 && (
                <HouseCardToFavoritePageContainer>
                    {apiResult.map((result, index) => (
                        <div key={result.id}>
                            <Card>
                                <Suspense fallback={<LoadingImageContainer />}>
                                    <Slider {...settings}>
                                        {result.photos.map(photo => (
                                            <img 
                                                key={photo.id} 
                                                src={photo.url_m} 
                                                alt={result.title} 
                                                onClick={() => history.push(`/imovel/${result.id}`)}
                                            />
                                        ))}		
                                    </Slider>
                                </Suspense>

                                <HouseInformationsAndActionsContainer>
                                    <HouseInformationsAndActions>
                                        <HouseTitleAndAction>
                                            <TitleContainer onClick={() => history.push(`/imovel/${result.id}`)}>
                                                <h3>{formatTitle[index]}</h3>
                                            </TitleContainer>

                                            <Actions>
                                                <button onClick={() => handleRemoveFavorite(result.id)}><FaHeart size={18} color="#FF3838"/></button>
                                                <a href={`https://api.whatsapp.com/send?text=${window.location.origin}/imovel/${result.id}`}><FiShare2 size={18} color="#B6B6B6" /></a>
                                            </Actions>
                                        </HouseTitleAndAction>	

                                        <span onClick={() => history.push(`/imovel/${result.id}`)}>{formatAddress[index]}</span>
                                    </HouseInformationsAndActions>
                                </HouseInformationsAndActionsContainer>

                                <HouseAdditionalInfoemationsContainer onClick={() => history.push(`/imovel/${result.id}`)}>
                                    <div>
                                        <RiDragMove2Fill size={14} color="#6EA6FF" />
                                        <span>{result.area} m²</span>
                                    </div>

                                    <div>
                                        <RiHotelBedLine size={14} color="#6EA6FF" />
                                        <span>{result.rooms} Quartos</span>
                                    </div>

                                    <div>
                                        <BiCar size={14} color="#6EA6FF" />
                                        <span>{result.garages} Vagas</span>
                                    </div>
                                </HouseAdditionalInfoemationsContainer>

                                <HousePrice onClick={() => history.push(`/imovel/${result.id}`)}>
                                    <b>Aluguel: {formatValue(parseFloat(result.rent_value))}</b>
                                    <h2>TOTAL: {formatValue(account[index])}</h2>
                                    <Link to={`/imovel/${result.id}`}>VER MAIS</Link>
                                </HousePrice>
                            </Card>
                        </div>
                    ))}
                </HouseCardToFavoritePageContainer>
                )}

                {apiResult.length === 0 && (
                <EmptyFavoritesContainer>
                    <h1>Você ainda não tem nenhum imóvel favoritado.</h1>
                </EmptyFavoritesContainer>
            )}
            </>
        )}
        </>
    );
}