const KEY = "@7cantos:Schedule_req";
const TIME_TO_EXPIRY = 600000; // 10 minutos 

export function saveScheduleRequest(scheduleRequest: any = null) {
    localStorage.setItem(KEY, JSON.stringify({ ...scheduleRequest, expiry: new Date().getTime() + TIME_TO_EXPIRY }));
}

export function getScheduleRequest() {
    const scheduleRequest = localStorage.getItem(KEY);

    if (!scheduleRequest) {
		return null
	}
    
    const parsedRequest = JSON.parse(scheduleRequest);
    const now = new Date();
	
    if (now.getTime() > parsedRequest.expiry) {
		clearScheduleRequest();
		return null;
	}

    return parsedRequest;
}

export function clearScheduleRequest() {
    localStorage.removeItem(KEY);
}

export function hasScheduleRequest() {
    return getScheduleRequest() === null ? false : true;
}