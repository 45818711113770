import styled from 'styled-components';

export const Container = styled.div`

`;

export const Content = styled.div`
	width: 300px;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	>h2{
		font-weight: bold;

		margin: 18px 0 32px;
	}

	@media(max-width: 767px){
		>svg{
			width: 26px;
			height: 26px;
		}

		>h2{
			font-size: 16px;
		}

		>p{
			font-size: 14px;
		}
	}
`;