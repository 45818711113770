import { TABLE_TITLES } from "./constants";
import React, { ChangeEvent, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FaFileDownload } from "react-icons/fa";

interface IBills {
  address: string;
  due_date: string;
  link: string;
  status: string;
  value: string;
}

interface IData {
  bills: IBills[];
  onDownloadPaidBill: () => void;
}

const StatusCell = ({ status }: any) => {
  let statusText;
  let textColor;
  switch (status) {
    case "RECEIVED":
      statusText = "Pago";
      textColor = "#0ca11a";
      break;
    case "SENT":
      statusText = "Enviado";
      textColor = "#8b8a8e";
      break;
    default:
      statusText = "Atrasado";
      textColor = "#b91c1b";
      break;
  }
  return <TableCell style={{ color: textColor }}>{statusText}</TableCell>;
};

const isReceived = (status: string) => status === "RECEIVED";

const PAGE_SIZE = 7;

export default function OptionsTable({ bills, onDownloadPaidBill }: IData) {
  const billsListLength = bills.length;
  const paginationLength = Math.floor(billsListLength / 7) + 1;
  const [range, setRange] = useState({
    startIndex: 0,
    endIndex: PAGE_SIZE - 1,
  });

  function pageChangeHandler(event: ChangeEvent<unknown>, page: number) {
    setRange((prevValue) => ({
      ...prevValue,
      startIndex: (page - 1) * PAGE_SIZE,
      endIndex:
        page * PAGE_SIZE - 1 > billsListLength
          ? billsListLength
          : page * PAGE_SIZE - 1,
    }));
  }

  const displayableBills = bills.slice(range.startIndex, range.endIndex + 1);

  return (
    <TableContainer
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "2rem",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {TABLE_TITLES.map((title) => (
              <TableCell align="left" key={title} style={{ color: "#8b8a8e" }}>
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {displayableBills?.map((bill) => (
            <TableRow key={bill.due_date}>
              <TableCell>{bill.due_date}</TableCell>
              <TableCell>{bill.address}</TableCell>
              <TableCell>R$ {bill.value}</TableCell>
              <StatusCell status={bill.status} />
              <TableCell>
                <a
                  href={isReceived(bill.status) ? undefined : `${bill.link}`}
                  target={isReceived(bill.status) ? undefined : "_blank"}
                  onClick={
                    isReceived(bill.status)
                      ? () => onDownloadPaidBill()
                      : () => {}
                  }
                >
                  <FaFileDownload
                    style={{
                      color: isReceived(bill.status)
                        ? "#8b8a8e"
                        : "rgb(110, 165, 255)",
                      cursor: "pointer",
                    }}
                  />
                </a>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination count={paginationLength} onChange={pageChangeHandler} />
    </TableContainer>
  );
}
