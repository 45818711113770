import React from 'react';

import Footer from './../../../components/Footer';

import PropertyCard from './PropertyCard';
import PropertyCardLoading from './PropertyCardLoading';

import {
    Container,
    CardListContainer,
    FadeIn,
    EmptyListMessage
} from './style';

export default function PropertyCardList({
    properties = [],
    selectedProperties = [],
    loading = true
} : any) {

    const getProperties = () : Array<any> => {
        if (!selectedProperties || selectedProperties.length === 0) {
            return properties;
        }
        return properties.filter((property : any) => {
            return (selectedProperties.includes(property.id));
        });
    }

    return (
        <Container>
            <CardListContainer>
                {!loading && properties && getProperties().map((property : any) => (
                    <FadeIn key={property?.id}>
                        <PropertyCard property={property} />
                    </FadeIn>
                ))}
                {loading && (
                    <>
                        <PropertyCardLoading />
                        <PropertyCardLoading />
                        <PropertyCardLoading />
                        <PropertyCardLoading />
                        <PropertyCardLoading />
                    </>
                )}
                {!loading && properties.length === 0 && 
                    <EmptyListMessage>
                        Nenhum imóvel encontrado nessa região.
                    </EmptyListMessage>
                }
            </CardListContainer>
            <Footer />
        </Container>
    );
}