import React, { useCallback, useState, useEffect, useMemo } from 'react';

import OutsideClickHandler from 'react-outside-click-handler';

import {
	Container, 
	Header,
	LogoAndButtonContainer,
	LinkAndSearchContainer,
	HeaderContent,
	Options,
	OptionsItemsContainer,
	ButtonLogin
} from './styles';

import MenuInquilino from './../MenuInquilino/';
import MenuProprietario from './../MenuProprietario/';

import MenuInquilinoToMobile from './../MenuInquilinoToMobile/';
import MenuProprietarioMobile from './../MenuProprietarioToMobile/';

import { useAuth } from './../../hooks/auth';

import logoImg from './../../assets/components/Header/logo-7cantos.png';

import { Link, useLocation } from 'react-router-dom';

import { FiMenu, FiSearch } from 'react-icons/fi';
import SearchBarToHeader from '../SearchBarToHeader';

interface IHeader{
	hasBorder?: boolean;
	hasSearchBar?: boolean;
	isToShowSearchBarMobile?: boolean;
	hasOptionToSearch?: boolean;
	showAboutUs?: boolean;
}

export default function HeaderComponent({hasBorder, hasSearchBar, isToShowSearchBarMobile, hasOptionToSearch, showAboutUs}: IHeader){
	const { user } = useAuth();
	
	// eslint-disable-next-line
	const { pathname } = useLocation();

	const [showMenu, setShowMenu] = useState(false);
	const [isToCloseClickingInButton, setIsToCloseClickingInButton] = useState(false);

	const [showMenuMobile, setShowMenuMobile] = useState(false);

	const [showSearchBar, setShowSearchBar] = useState(isToShowSearchBarMobile);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	const handleShowMenu = useCallback(() => {
		setIsToCloseClickingInButton(true);

		setShowMenu(true);
	}, []);

	const handleCloseMenu = useCallback(() => {
		setIsToCloseClickingInButton(true);
		setShowMenu(false);
	}, []);

	const handleCloseMenuClickingOutside = useCallback(() => {
		setIsToCloseClickingInButton(false);
		setShowMenu(false);
	}, []);

	const handleShowMenuMobile = useCallback(() => {
		setShowMenuMobile(true);
	}, []);

	const handleCloseMenuMobile = useCallback(() => {
		setShowMenuMobile(false);
	}, []);

	const usernameFormated = useMemo(() => {
		if(!!user){
			if(/\s/.test(user.first_name)){
				const index = user.first_name.indexOf(' ');
				const usernameFormated = user.first_name.substr(0, index);
				return(`${usernameFormated}`);
			}
			return (user.first_name);	
		}
	}, [user]);

	return(
		<Container>
			<Header hasBorder={!!hasBorder}>
				<HeaderContent>
					<LinkAndSearchContainer isToDisapear={showSearchBar}>
						<LogoAndButtonContainer isToShowSearchBarMobile={isToShowSearchBarMobile || showSearchBar}>
							<button onClick={handleShowMenuMobile}><FiMenu size={30} color="#DD6435" /></button>

							<Link to="/">
								<img src={logoImg} alt="logo"/>
							</Link>
						</LogoAndButtonContainer>

						{!!hasSearchBar && (
							<SearchBarToHeader hasSearchBar={hasSearchBar} isToShowSearchBarMobile={isToShowSearchBarMobile} showSearchBar={showSearchBar}/>
						)}
						
						{!!hasOptionToSearch && (
							<button onClick={() => setShowSearchBar(true)}>
								<FiSearch size={25} color="#DD6435"/>
							</button>
						)}
						<Options hasSearchBar={hasSearchBar}>
							<OptionsItemsContainer>
								<a href="https://mkt.7cantos.com/proprietarios" target="_blank">Anunciar Imóvel</a>
								<Link to="/indicar-imovel">Indique & Ganhe</Link>
								{!!showAboutUs && (<Link to="/about-us">Sobre nós</Link>)}
							</OptionsItemsContainer>
						</Options>
					</LinkAndSearchContainer>


					<ButtonLogin>
						{!!user === false && (
							<Link id="button-link" to={{
								pathname: "/login",
								state: {fromSite: true}
							}}>Entrar</Link>
						)}
						<p style={user ? {visibility: 'visible'} : {display: 'none'}}>{`Olá, ${usernameFormated}`}</p>
						<button onClick={!!isToCloseClickingInButton ? handleCloseMenuClickingOutside : handleShowMenu}><FiMenu size={24} color="#DD6435"/></button>
					</ButtonLogin>
						
				</HeaderContent>

				{showMenu === true && (
					<>
						{!!user && (
							<div>
								{user.type === 'inquilino' && (
									<OutsideClickHandler onOutsideClick={handleCloseMenu}>
										<MenuInquilino />
									</OutsideClickHandler>	
								)}

								{user.type === 'proprietario' && (
									<OutsideClickHandler onOutsideClick={handleCloseMenu}>
										<MenuProprietario />
									</OutsideClickHandler>	
								)}

								{!!user.type === false && (
									<OutsideClickHandler onOutsideClick={handleCloseMenu}>
										<MenuInquilino />
									</OutsideClickHandler>	
								)}
							</div>
						)}

						{!!user === false && (
							<OutsideClickHandler onOutsideClick={handleCloseMenu}>
								<MenuInquilino />
							</OutsideClickHandler>	
						)}
					</>
				)}

				{!!showMenuMobile && (
					<>
						{!!user && (
							<div>
								{user.type === 'inquilino' && (
									<MenuInquilinoToMobile functionToCloseMenuInquilinoToMobile={handleCloseMenuMobile} />
								)}

								{user.type === 'proprietario' && (
									<MenuProprietarioMobile functionToCloseMenuProprietarioToMobile={handleCloseMenuMobile} />
								)}

								{!!user.type === false && (
									<MenuInquilinoToMobile functionToCloseMenuInquilinoToMobile={handleCloseMenuMobile} />
								)}
							</div>
						)}

						{!!user === false && (
							<MenuInquilinoToMobile functionToCloseMenuInquilinoToMobile={handleCloseMenuMobile} />
						)}
					</>
				)}
			</Header>
		</Container>
	);
};