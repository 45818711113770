import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const  NewcomersPropertiesSectionContainer = styled.section`
display: flex;
flex-direction: column;
background-color: #F7F9FC;
padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};

// MEDIUM
@media(min-width: 768px){
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
}

// LARGE
@media(min-width: 992px){
    
    padding 75px ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
}

`;

export const  NewcomersPropertiesSectionHeaderContainer = styled.div`

position: relative;
padding-left: 25px;

&:before {
    content: '';
    position: absolute;
    background-color: #6EA6FF;
    width: 4px;
    height: 100%;
    bottom: 0;
    left: 0;
}

h3.title {
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
}

p.description {
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 10px;
}

// LARGE
@media(min-width: 992px){

    h3.title {
        font-size: 36px;
    }

    p.description {
        font-size: 16px;
    }
}
`;

export const NewcomersPropertiesSectionCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    justify-content: space-between;
    margin-top: 50px;
    gap: 30px;
    padding-bottom: 20px;

    a {
        width: 280px;
        min-width: 240px;
    }
`;