import styled from 'styled-components';

import { HORIZONTAL_PADDING_PAGE_DEFAULT } from '../../../styles';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: #F7F9FC;
    padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    padding-right: ${HORIZONTAL_PADDING_PAGE_DEFAULT.SM};
    padding-bottom: 20px;
    padding-top: 40px;
    
    // MEDIUM
    @media(min-width: 768px){
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.MD};
        padding-right: 20px;
    }

    // LARGE
    @media(min-width: 992px){
        padding-left: ${HORIZONTAL_PADDING_PAGE_DEFAULT.LG};
        padding-right: 45px;
    }
`;

export const TextContainer = styled.div`
    display: flex;
    flex-direction: column;

    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #606060;
    }

    .propeties-quantity {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #898989;
    }
`;

export const FilterButtonContainer = styled.div`
    display: flex;
    flex-direction: column;

    .filter-button {
        width: 104px;
        height: 40px;
        background: #DD6536;
        border: none;
        border-radius: 50px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 40px;
        color: #FFFFFF;

        &:disabled, &[disabled]{
          cursor: not-allowed;
        }
    }
`;