import React from 'react';

import { AuthProvider } from './auth';
import { RefreshProvider } from './refresh';

const AppProvider: React.FC = ({children}) => {
	return(
		<AuthProvider>
			<RefreshProvider>
				{children}
			</RefreshProvider>
		</AuthProvider>
	);
};

export default AppProvider;