import React from 'react';

import {DocumentsRequestContainer,
        DocumentsSpecificationsContainer,} from './styles';

interface IDocumentsRequestComponent{
    title: string;
    document_specifications: string[];
}

export default function DocumentsRequestComponent(props: IDocumentsRequestComponent){
    return(
        <DocumentsRequestContainer>
            <DocumentsSpecificationsContainer>
                <h2>{props.title.toLocaleUpperCase()}</h2>

                <h4>Anexe os arquivos clicando no botão ao lado.</h4>

                {props.document_specifications.map(document_specification => (
                    <strong>{document_specification}</strong>
                ))}
            </DocumentsSpecificationsContainer>
        </DocumentsRequestContainer>
    );
}