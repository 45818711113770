import React from "react";

import { Switch, Route } from "react-router-dom";

import Home from "./../pages/Home/";
import SearchPropertiesMap from "./../pages/SearchPropertiesMap/";
import Imovel from "./../pages/Imovel/";
import CondominiumScreen from "./../pages/CondominiumScreen/";
import LaCitta from "./../pages/LaCitta/";
import Schedule from "./../pages/Schedule/";
import Login from "./../pages/Login/";
import Register from "./../pages/Register/";
import FavoriteProperites from "./../pages/FavoriteProperties/";
import MyProperties from "./../pages/MyProperties/";
import Proposals from "./../pages/Proposals/";
import ProposalReceived from "./../pages/ProposalReceived/";
import ScheduledVisits from "./../pages/ScheduledVisits/";
import ScheduleProcess from "./../pages/ScheduleProcess/";
import SendDocumentation from "./../pages/SendDocumentation/";
import MyAccount from "./../pages/MyAccount/";
import Error404 from "./../pages/404/";
import ScheduleSuccess from "./../pages/ScheduleSuccess/";

import ForgotPassword from "./../pages/ForgotPassword/";
import ResetPassword from "./../pages/ResetPassword/";

import AnunciarImovel from "./../pages/AnunciarImovel/";
import IndicateProperty from "./../pages/IndicateProperty/";
import AboutUs from "./../pages/AboutUs/";
import RequestTermination from "../pages/RequestTermination";
import RequestTerminationUploadDocs from "../pages/RequestTerminationUploadDocs";
import SignContract from "../pages/SignContract";
import OwnerRentProcess from "../pages/OwnerRentProcess";
import TenantDashboard from "../pages/TenantDashboard/";
import OwnerDashboard from "../pages/OwnerDashboard";
import UseTerms from "../pages/UseTerms";
import PrivacyPolitics from "../pages/PrivacyPolitics";

const AppRoutes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/search" component={SearchPropertiesMap} />
      <Route path="/imovel/:id" component={Imovel} />
      <Route path="/condominium" component={CondominiumScreen} />
      <Route path="/schedule/:id" component={Schedule} exact />
      <Route path="/schedule/:id/success" component={ScheduleSuccess} exact />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/password/reset/:token" component={ResetPassword} />
      <Route path="/anunciar-imovel" component={AnunciarImovel} />
      <Route path="/indicar-imovel" component={IndicateProperty} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/favorites" component={FavoriteProperites} />
      <Route path="/my-properties" component={MyProperties} />
      <Route path="/proposals" component={Proposals} />
      <Route path="/proposal-received/:id" component={ProposalReceived} />
      <Route path="/request-termination" component={RequestTermination} />
      <Route
        path="/request-termination-upload-documents"
        component={RequestTerminationUploadDocs}
      />
      <Route path="/scheduled-visits" component={ScheduledVisits} />
      <Route path="/schedule-process" component={ScheduleProcess} />
      <Route path="/owner-rent-process" component={OwnerRentProcess} />
      <Route path="/send-documentation" component={SendDocumentation} />
      <Route path="/sign-contract" component={SignContract} />
      <Route path="/tenant-dashboard" component={TenantDashboard} />
      <Route path="/owner-dashboard" component={OwnerDashboard} />
      <Route path="/use-terms" component={UseTerms} />
      <Route path="/privacy-politics" component={PrivacyPolitics} />
      <Route path="/jsmart" component={CondominiumScreen} />
      <Route path="/laCitta" component={LaCitta} />
      <Route path="/me" component={MyAccount} />
      <Route path="/404" component={Error404} />

      <Route component={Error404} />
    </Switch>
  );
};

export default AppRoutes;
