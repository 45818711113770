import React from "react";
import { AvatarContainer } from "./style";
import { Link } from "react-router-dom";

interface IUser {
  name: string;
  isTenant: boolean;
}

export default function UserAvatar({ name, isTenant }: IUser) {
  return (
    <>
      <h5 style={{ fontSize: "1.2rem" }}>Bem-vindo(a), {name}!</h5>
      <p style={{ fontSize: "1rem" }}>
        {isTenant ? "Inquilino(a)" : "Proprietário(a)"} 7cantos
      </p>
      <AvatarContainer>
        <p
          style={{
            fontSize: "5rem",
            fontWeight: 500,
            color: "#FFFFFF",
          }}
        >
          {name?.charAt(0)}
        </p>
      </AvatarContainer>
      <Link to="/me" style={{ marginTop: "1rem", textDecoration: "underline" }}>
        Edite seu perfil
      </Link>
    </>
  );
}
