import styled from "styled-components";

export const StepContainer = styled.div`
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: relative;

    >div.step {
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    >label {
        font-size: 0.8rem;
        font-weight: 600;
        margin-top: 0.75rem;
        white-space: nowrap;
    }
    >label.error-lbl {
        font-size: 0.6rem;
        font-weight: 500;
        margin-top: 0.25rem;
        color: #FF3838;
        white-space: wrap;
        position: absolute;
        bottom: -1.75rem;
    }

    >div.currentOrOld {
        
        background: #dd6434;
    }
    >div.future {
        
        background: #b6b6b6;
    }
    >button {
        margin-top: 3rem;
        text-transform: uppercase;
        font-size: 0.7rem;
        width: 80%;
        padding: 0.75em 0;
        font-weight: 600;
        color: #666666;
        background: #FFFFFF;
        border: 1px solid #666666;
        min-height: 3rem;
    }
    >button.first-step-btn {
        border: none;
        color: #6ea4ff;
        cursor: pointer;
    }
    >button.active {
        background: #03bf34;
        border: none;
        color: #FFFFFF;
    }
    >button.not-active {
        cursor: pointer;
    }
    >button.dont-click-btn {
        cursor: not-allowed;
    }
    >button.hide-btn {
        border: none;
        cursor: default;
    }
    @media(max-width: 767px) {
        display: grid;
        grid-template-columns: 12.5% 32.5% 50%;
        gap: 2.5%;
        width: 80%;

        >label {
            margin: 0;
            white-space: wrap;
        }
        >label.error-lbl {
            bottom: -0.75rem;
            right: 0.25rem;
        }

        >button {
            margin: 0;
            width: 100%;
            
        }
        >button.active {
            background: #03bf34;
            border: none;
            color: #FFFFFF;
        }
        >button.not-active {
            cursor: not-allowed;
        }
    }
`;
