import CardHeader from "../../../components/CardHeader";
// import Text from "../../../components/Text";
import React, { useState } from "react";
import { ITEM_PROPS } from "./constants";
import WrapperCard from "../../../components/WrapperCard";
import noBillsImg from "../../../assets/pages/TenantDashboard/mensagem-prancheta 1.png";
import {
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FaRegListAlt } from "react-icons/fa";
import OptionsTable from "./OptionsTable";
import OptionsAccordion from "./OptionsAccordion";
import { CgClose } from "react-icons/cg";

interface IBills {
  address: string;
  due_date: string;
  link: string;
  status: string;
  value: string;
}

interface IData {
  bills: IBills[] | undefined;
}

export default function Payments({ bills = [] }: IData) {
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const [openWarning, setOpenWarning] = useState(false);

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));

  let billsList = bills;

  if (selectedStatus !== "") {
    billsList = billsList.filter((bill) => bill.status === selectedStatus);
  }

  let content = (
    <img
      src={noBillsImg}
      alt="Imagem de prédio com texto descritivo"
      width="100%"
    />
  );

  if (billsList.length !== 0) {
    if (smDown)
      content = (
        <OptionsAccordion
          bills={billsList}
          onDownloadPaidBill={() => setOpenWarning(true)}
        />
      );
    else
      content = (
        <OptionsTable
          bills={billsList}
          onDownloadPaidBill={() => setOpenWarning(true)}
        />
      );
  }

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => setOpenWarning(false)}
      >
        <CgClose fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <WrapperCard>
      <CardHeader
        Icon={
          <FaRegListAlt
            style={{
              fontSize: "30px",
              marginRight: "0.5rem",
              color: "#6ea7ff",
            }}
          />
        }
        title="Histórico de Pagamentos"
        flexDirection={smDown ? "column" : "row"}
        alignItems={smDown ? "flex-start" : "center"}
        rowGap="0.5rem"
      >
        <FormControl style={{ width: smDown ? "100%" : "20%" }}>
          <InputLabel>Status</InputLabel>
          <Select
            value={selectedStatus}
            label="status"
            onChange={(event) => setSelectedStatus(String(event.target.value))}
          >
            {ITEM_PROPS.map((item) => (
              <MenuItem key={item.label} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </CardHeader>
      {content}
      <Snackbar
        open={openWarning}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        onClose={() => setOpenWarning(false)}
        autoHideDuration={3000}
        message="Não é possível baixar um boleto já pago"
        action={action}
      />
    </WrapperCard>
  );
}
