import React from "react";
import {
  IconWrapper,
  NativeSelect,
  PresentationalBit,
  Wrapper,
} from "./styles";
import { HiChevronDown } from "react-icons/hi";

interface ISelectLib {
  value: string;
  label: string;
}

interface ISelect {
  placeholder: string;
  value: string;
  onChange: (e: any) => void;
  channelList: ISelectLib[];
}

const Select: React.FC<ISelect> = ({
  value,
  placeholder,
  onChange,
  children,
  channelList,
}) => {
  const displayedValue = channelList.find(
    (child) => child.value === value
  )?.label;

  return (
    <Wrapper>
      <NativeSelect
        id="native-select"
        value={value}
        onChange={(e) => {
          e.preventDefault();
          onChange(e.target.value);
        }}
      >
        {children}
      </NativeSelect>
      <PresentationalBit isSelected={value !== ""}>
        <p>{value === "" ? placeholder : displayedValue}</p>
        <IconWrapper size="24px">
          <HiChevronDown size={24} />
        </IconWrapper>
      </PresentationalBit>
    </Wrapper>
  );
};

export default Select;
