import styled from 'styled-components';

export const Container = styled.div`
	position: fixed;
	width: 100%; 
	height: 100vh; 
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0,0,0,0.5); 
	z-index: 5; 

`;

export const Content = styled.div`
	position: fixed;
    top: 40%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);

	width: 500px;

	z-index: 6px;

	padding: 16px 0 52px 0;

	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	background: #00BF33;

	color: #FFF;

	border-radius: 6px;

	-webkit-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	-moz-box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);
	box-shadow: 0px 0px 67px -3px rgba(0,0,0,0.75);

	>button{
		background: transparent;
		border: 0;

		margin-left: auto;
		margin-right: 32px;
	}

	@media(max-width: 767px){
		width: 330px;
	}
`;

export const MessageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	flex-direction: column;

	text-align: center;

	>h1{
		font-weight: bold;

		margin: 18px 0;
	}

	>h3{
		max-width: 350px;

		>a{
			font-weight: bold;
			color: #FFF;
		}
	}

	@media(max-width: 767px){
		>h1{
			font-size: 18px;
		}

		>h3{
			max-width: 240px;

			font-size: 12px;
		}
	}
`;

